import React, { useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonText,
  IonCard,
  IonCardContent,
  IonList,
  IonCol,
  IonIcon,
  IonPage,
  IonTitle,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
  IonImg,
  IonSkeletonText,
} from "@ionic/react";
import "./Lessoncompleted.scss";
import {
  chevronBack,
  close,
  lockClosed,
  lockClosedOutline,
} from "ionicons/icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { CONFIG } from "../App";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface OwnProps extends RouteComponentProps {}

interface LessoncompletedProps extends OwnProps {}

const Lessoncompleted: React.FC<LessoncompletedProps> = ({ history }) => {
  const themeData: any = localStorage.getItem("itemlist");
  //const tDataCon = JSON.parse(themeData);
  let url: string = CONFIG.API_ENDPOINT; //live

  const { t } = useTranslation();

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any[]>([]);
  const [itemLessonslist, setLessonsitems] = React.useState<any>();
  const [showImage, setShowImage] = useState(false);
  const historyss = useHistory<{
    themeArray: any;
    themeId: string;
    lessonsId: string;
    index: string;
    lessonname: string;
    themename: string;
    levelId: string;
    level: string;
    levname: string;
    backpage: string;
    backpageValue: number;
  }>();
  const themeArray = historyss.location.state
  ? historyss.location.state.themeArray
  : "";
  const themeId = historyss.location.state
    ? historyss.location.state.themeId
    : "";
  const lessonsId = historyss.location.state
    ? historyss.location.state.lessonsId
    : "";
  const index = historyss.location.state ? historyss.location.state.index : "";
  const lessonname = historyss.location.state
    ? historyss.location.state.lessonname
    : "";

  const themename = historyss.location.state
    ? historyss.location.state.themename
    : "";
  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";
  const levelId = historyss.location.state
    ? historyss.location.state.levelId
    : "";
  const level = historyss.location.state ? historyss.location.state.level : "";
  const backpage = historyss.location.state
    ? historyss.location.state.backpage
    : "";
    const backpageValue = historyss.location.state
    ? historyss.location.state.backpageValue
    : "";
  let token = "";
  let resfreshtoken = "";

  useIonViewWillEnter(() => {
    //setMenuEnabled(false);
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    getthemes()
  });

  const getthemes = async () => {
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   duration: 1000,
    // });

    try {
      const response = await fetch(url + "/book/GetThemesAndRecLesson", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      // if (!response.ok) {
      //   throw Error(response.statusText)
      // }
      const json = await response.json();
      console.log(json);
      localStorage.setItem("ThemesAndRecLesson", JSON.stringify(json))
      setShowImage(true);
      if (json.StatusCode == 1) {
        setLessonsitems(json.Result.Lessons);
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const refreshtoken = async () => {
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   //duration: 3000
    // });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      console.log(json);

      if (json.StatusCode == 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const Subscribe = async () => {
    history.push("/tabs/Subscribe", { direction: "none" });
  };

  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <div className="close">
              <IonIcon onClick={onClose} icon={close} placeholder={undefined} />
            </div>
            <div className="icon">
              <IonIcon icon={lockClosed} placeholder={undefined} />
            </div>
            <p>{t("lessoncompleted.submit.subscribe_prompt")}</p>
            <div className="react-confirm-alert-button-group">
              <IonButton
                onClick={() => {
                  Subscribe();
                  onClose();
                } }
                className="alertcancel w-150" placeholder={undefined}>
                {t("lessoncompleted.submit.subscribe")}
              </IonButton>
            </div>
          </div>
        );
      },
    });
  };
  const submit1 = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <div className="close">
              <IonIcon onClick={onClose} icon={close} placeholder={undefined} />
            </div>
            <div className="icon">
              <IonIcon icon={lockClosed} placeholder={undefined} />
            </div>
            <p>Subscribe to unlock</p>
            <div className="react-confirm-alert-button-group">
              <IonButton
                onClick={() => {
                  Subscribe();
                  onClose();
                } }
                className="alertcancel w-150" placeholder={undefined}>
                Subscribe
              </IonButton>
            </div>
          </div>
        );
      },
    });
  };

  const gotoPractice = () => {
    history.push({
      pathname: "/tabs/home/Practicequestion",
      state: {
        themeArray: themeArray,
        themeId: themeId,
        lessonname: lessonname,
        index: index,
        lessonsId: lessonsId,
        themename: themename,
        levelId: levelId,
        level: level,
        levname: levname,
        backpage: "Lessoncompleted",
        backpageValue: backpageValue,
      },
    });
  };
  const getExercise = async () => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 1000,
    });
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    try {
      const response = await fetch(
        url + "/Book/GetExercise?lessonGuid=" + lessonsId,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        }
      );

      const json = await response.json();
      console.log("GetExercise ", json);

      dismiss();
      if (json.StatusCode == 1) {
        setitems(json.Result);
        let E1s = json.Result.E1s.length;
        let E2s = json.Result.E2s.length;
        let E3s = json.Result.E3s.length;
        let E4s = json.Result.E4s.length;
        let E5s = json.Result.E5s.length;
        let E6s = json.Result.E6s.length;
        //let E7ev1s = json.Result.E7ev1s.length;

        if (E1s > 0 || E2s > 0 || E3s > 0 || E4s > 0 || E5s > 0 || E6s > 0) {
          gotoPractice();
        } else {
          presenttost({
            message: "Practices not available",
            duration: 1500,
            position: "bottom",
          });
        }
        // setLengthQue(json.Result.length)
        history.push({
          state: {
            themeArray: themeArray,
            themeId: themeId,
            lessonname: lessonname,
            index: index,
            lessonsId: lessonsId,
            themename: themename,
            levelId: levelId,
            level: level,
            levname: levname,
            backpage: "Lessoncompleted",
            backpageValue: backpageValue,
          },
        });
      } else if (json.StatusCode == 2) {
        // refreshtoken()
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const gobacklesson = () => {
    //history.push('/tabs/home/themesports/5');
    history.push({
      pathname: "/tabs/home/lesson",
      state: {
        themeArray: themeArray,
        themeId: themeId,
        lessonname: lessonname,
        index: index,
        lessonsId: lessonsId,
        themename: themename,
        levelId: levelId,
        level: level,
        levname: levname,
        backpageValue: backpageValue,
      },
    });
        history.go(0)
    // window.location.reload();
  };
  return (
    <IonPage className="intro" placeholder={undefined}>
      <IonHeader className="ion-text-center dubbletitle" placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <div>
            <IonButtons slot="start" placeholder={undefined}>
              <IonButton color="primary" onClick={gobacklesson} placeholder={undefined}>
                <IonIcon icon={chevronBack} placeholder={undefined} />
              </IonButton>
            </IonButtons>
            <IonTitle color="primary" className="font20 ion-no-padding" placeholder={undefined}>
              <span>
                {t("lessoncompleted.lesson")}{" "}{themeArray[index?index:0]?.number}
                <p className="ion-no-margin">{lessonname}</p>
              </span>
            </IonTitle>
            <IonButtons slot="end" placeholder={undefined}>
              {/* <IonButton color='primary'>
                <IonIcon icon={ellipsisVertical} />
              </IonButton> */}
            </IonButtons>
          </div>
        </IonToolbar>
      </IonHeader>

      <IonContent className="listing ion-padding-horizontal" placeholder={undefined}>
        <IonList className="completed ion-text-center" placeholder={undefined}>
          <IonCol size="12" placeholder={undefined}>
            <IonImg src="./assets/images/LessonCompleted.png" alt="" placeholder={undefined} />
            <p>{t("lessoncompleted.lesson_completed")}</p>
          </IonCol>
          <IonCol size="12" placeholder={undefined}>
            <div className="rightside">
              {/* <IonIcon onClick={submit} icon={lockClosedOutline} /> */}
              <IonButton color="warning" onClick={getExercise} expand="block" placeholder={undefined}>
              {t("lessoncompleted.practice")}
              </IonButton>
            </div>
            <IonButton
              expand="full"
              shape="round"
              onClick={gobacklesson}
              className="blueborder" placeholder={undefined}>
              {t("lessoncompleted.read_again")}
            </IonButton>
          </IonCol>
        </IonList>
        {itemLessonslist !== null ? (<></>):(<>
          <IonList className="themes bordertop" placeholder={undefined}>
            <IonCol placeholder={undefined}>
              <IonText placeholder={undefined}>{t("lessoncompleted.check_this_out")}</IonText>
            </IonCol>
          </IonList>

          {itemLessonslist.length === 0 ? (
                  <IonSkeletonText animated style={{ width: "100%", height: "138px", margin: "0px" }} placeholder={undefined} />
                ) : (
                  <>
                    {itemLessonslist.map((items, index) => {
                      return (
                        // onClick={() => goLessonPage(items, index)}
                        <IonCard key={index}  placeholder={undefined}>
                          {showImage ? (
                            <div className="imglist">
                              <img src={items.ImageUrl} alt="Silhouette of mountains" />
                            </div>
                          ) : (
                            <IonSkeletonText animated style={{ width: "100%", height: "138px", margin: "0px" }} placeholder={undefined} />
                          )}
                          <IonCardContent placeholder={undefined}>
                            <span>{items.Title_tc}</span>
                          </IonCardContent>
                        </IonCard>
                      );
                    })}
                  </>
        )}
        </>)}

        {/* <IonList className="recommended-listing checkout" placeholder={undefined}>
          <IonCard placeholder={undefined}>
            <div className="overlay" onClick={submit}></div>
            <IonIcon className="lock" icon={lockClosedOutline} placeholder={undefined} />
            <div className="imglist">
              <img src="./assets/images/img1.png" alt="" />
            </div>
            <IonCardContent placeholder={undefined}>{t("lessoncompleted.dialogue")}</IonCardContent>
          </IonCard>
          <IonCard placeholder={undefined}>
            <div className="overlay" onClick={submit}></div>
            <IonIcon className="lock" icon={lockClosedOutline} placeholder={undefined} />
            <div className="imglist">
              <img src="./assets/images/img2.png" alt="" />
            </div>
            <IonCardContent placeholder={undefined}>{t("lessoncompleted.lesson")}</IonCardContent>
          </IonCard>
        </IonList> */}
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Lessoncompleted);
