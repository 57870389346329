import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonItem,
  IonLabel,
  IonToggle,
  IonContent,
  IonList,
  IonText,
  IonRow,
  IonIcon,
  IonPage,
  IonTitle,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
  useIonViewDidLeave,
} from "@ionic/react";
import "./Practicequestion.scss";
import { chevronBack, ellipsisVertical } from "ionicons/icons";
import Drawer from "react-bottom-drawer";
import "react-confirm-alert/src/react-confirm-alert.css";
import { CONFIG } from "../App";
import { RouteComponentProps, useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import E1s from "./E1s"; // 4 images and 1 text
import E2s from "./E2s"; // 1 image and 4 text
import E3s from "./E3s"; // Writing
import E4s from "./E4s"; // 1 audio and 4 text
import E5s from "./E5s"; // recoding
import E6s from "./E6s"; // rearrage word
interface OwnProps extends RouteComponentProps {}
interface PracticequestionProps extends OwnProps {
  isSuccess: boolean;
  isWrong: boolean;
}
interface IObjectKeys {
  [key: string]: string | number | boolean | null;
}
interface MyObject {
  id: number;
  name: string;
  type: string;
  pagecouter: number;
  totalque: number;
  back: number;
}
interface Answer extends IObjectKeys {
  q1: string | null;
  q2: string | null;
  q3: string | null;
}
interface Result extends IObjectKeys {
  q1: boolean | null;
  q2: boolean | null;
  q3: boolean | null;
  score: number | null;
}
const Practicequestion: React.FC<PracticequestionProps> = ({
  history,
  isSuccess,
  isWrong,
}) => {
  const themeData: any = localStorage.getItem("itemlist");
  //const tDataCon = JSON.parse(themeData);
  // console.log(tDataCon[0]["number"]);
  let url: string = CONFIG.API_ENDPOINT; //live
  const { t } = useTranslation();
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any[]>([]);
  const [itemE4EV1list, setE4EV1tems] = React.useState<any[]>([]);
  const [itemE5EV1list, setE5EV1tems] = React.useState<any[]>([]);
  const [itemE6EV1list, setE6EV1tems] = React.useState<any[]>([]);
  const [itemE7EV1list, setE7EV1tems] = React.useState<any[]>([]);
  const [itemE8EV1list, setE8EV1tems] = React.useState<any[]>([]);
  const [isitemE4EV1, setE4EV1] = useState<boolean | undefined>(false);
  const [isitemE5EV1, setE5EV1] = useState<boolean | undefined>(false);
  const [isitemE6EV1, setE6EV1] = useState<boolean | undefined>(false);
  const [isitemE7EV1, setE7EV1] = useState<boolean | undefined>(false);
  const [isitemE8EV1, setE8EV1] = useState<boolean | undefined>(false);
  const [sete4, setE4data] = useState({ total: 0, attendans: 0 });
  const [sete5, setE5data] = useState({ total: 0, attendans: 0 });
  const [sete6, setE6data] = useState({ total: 0, attendans: 0 });
  const [sete7, setE7data] = useState({ total: 0, attendans: 0 });
  const [sete8, setE8data] = useState({ total: 0, attendans: 0 });
  let te4: any = "";
  let te5: any = "";
  let te6: any = "";
  let te7: any = "";
  let te8: any = "";
  const myStateRef = useRef(sete6);
  //New Code

    //Setting
    const [fontSize, setFontSize] = useState(18);
    const [clickedUp, setClickedUp] = useState(false);
    const [clickedDown, setClickedDown] = useState(false);
    const [cheChar, setCheChar] = useState<any>();
    const [piniyin, setPiniyin] = useState<any>();
    const [isTransChecked, setIsTransChecked] = useState<any>();
    const [bookmarkindex, setBookmarkindex] = React.useState<any>(-1);
    const [playbackSpeed, setPlaybackSpeed] = useState(1.0);
    const [getThemes, setThemes] = useState<any>()
    let allThemes: any[] = []
    let rap;
    //End setting

  const [allexcieslist, setAllExcies] = React.useState<any[]>([]);
  const [isApicall, setAPIcall] = useState<boolean | undefined>(true);
  const [itemE1slist, setE1stems] = React.useState<any[]>([]);
  const [itemE2slist, setE2stems] = React.useState<any[]>([]);
  const [itemE3slist, setE3stems] = React.useState<any[]>([]);
  const [itemE4slist, setE4stems] = React.useState<any[]>([]);
  const [itemE5slist, setE5stems] = React.useState<any[]>([]);
  const [itemE6slist, setE6stems] = React.useState<any[]>([]);
  const [isitemE1s, setE1s] = useState<boolean | undefined>(false);
  const [isitemE2s, setE2s] = useState<boolean | undefined>(false);
  const [isitemE3s, setE3s] = useState<boolean | undefined>(false);
  const [isitemE4s, setE4s] = useState<boolean | undefined>(false);
  const [isitemE5s, setE5s] = useState<boolean | undefined>(false);
  const [isitemE6s, setE6s] = useState<boolean | undefined>(false);
  const [seteE1, setE1sdata] = useState({ total: 0, attendans: 0 });
  const [seteE2, setE2sdata] = useState({ total: 0, attendans: 0 });
  const [seteE3, setE3sdata] = useState({ total: 0, attendans: 0 });
  const [seteE4, setE4sdata] = useState({ total: 0, attendans: 0 });
  const [seteE5, setE5sdata] = useState({ total: 0, attendans: 0 });
  const [seteE6, setE6sdata] = useState({ total: 0, attendans: 0 });
  const [backValue, setBackValue] = useState<any>();
  let tE1s: any = "";
  let tE2s: any = "";
  let tE3s: any = "";
  let tE4s: any = "";
  let tE5s: any = "";
  let tE6s: any = "";
  //End
  //Commaon Code
  const [myObject, setMyObject] = useState<MyObject>({
    id: 0,
    name: "",
    type: "",
    pagecouter: 0,
    totalque: 0,
    back: 0,
  });
  async function handleMyObjectChange(newObject: MyObject) {
    console.log("newObject", newObject);
    console.log("itemE1slist.length", itemE1slist.length);
    console.log("itemE2slist.length", itemE2slist.length);
    console.log("itemE3slist.length", itemE3slist.length);
    console.log("itemE4slist.length", itemE4slist.length);
    console.log("itemE5slist.length", itemE5slist.length);
    console.log("itemE6slist.length", itemE6slist.length);
    setMyObject(newObject);
    setAPIcall(false);
    debugger;
    if (newObject.back == 1) { // this is for retrun to back page conditon
      setBackValue(1);
      if (newObject.type === "e2s" && itemE1slist.length !== 0) {
        setE1s(true);
        setE2s(false);
        setE3s(false);
        setE4s(false);
        setE5s(false);
        setE6s(false);
        tE1s = newObject.pagecouter;
        setE1sdata({
          total: itemE1slist.length,
          attendans: newObject.pagecouter,
        });
        //getExercise(1);
      } else if (newObject.type === "e3s" && itemE2slist.length !== 0) {
        setE1s(false);
        setE2s(true);
        setE3s(false);
        setE4s(false);
        setE5s(false);
        setE6s(false);
        tE2s = newObject.pagecouter;
        setE2sdata({
          total: itemE2slist.length,
          attendans: newObject.pagecouter,
        });
        //getExercise(1);
      } else if (newObject.type === "e4s" && itemE3slist.length !== 0) {
        setE1s(false);
        setE2s(false);
        setE3s(true);
        setE4s(false);
        setE5s(false);
        setE6s(false);
        tE3s = newObject.pagecouter;
        setE3sdata({
          total: itemE3slist.length,
          attendans: newObject.pagecouter,
        });
        //getExercise(1);
      } else if (newObject.type === "e5s" && itemE4slist.length !== 0) {
        setE1s(false);
        setE2s(false);
        setE3s(false);
        setE4s(true);
        setE5s(false);
        setE6s(false);
        tE4s = newObject.pagecouter;
        setE4sdata({
          total: itemE4slist.length,
          attendans: newObject.pagecouter,
        });
        //getExercise(1);
      } else if (newObject.type === "e6s" && itemE5slist.length !== 0) {
        setE1s(false);
        setE2s(false);
        setE3s(false);
        setE4s(false);
        setE5s(true);
        setE6s(false);
        tE5s = newObject.pagecouter;
        setE5sdata({
          total: itemE6slist.length,
          attendans: newObject.pagecouter,
        });
        //getExercise(1);
      }
    } else
    if (newObject.back == 0) { // this is for retrun to forward page conditon
      debugger
      setBackValue(0);
      if ( newObject.type === "e1s" && itemE1slist.length > 0 && itemE2slist.length > 0 ) {
        setE1s(false);
        setE2s(true);
        setE3s(false);
        setE4s(false);
        setE5s(false);
        setE6s(false);
        tE1s = newObject.pagecouter;
        setE1sdata({
          total: itemE1slist.length,
          attendans: newObject.pagecouter,
        });
        //getExercise(1);
      } else if ( newObject.type === "e1s" && itemE1slist.length > 0 && itemE2slist.length === 0 ) {
        setE1s(false);
        setE2s(true);
        setE3s(false);
        setE4s(false);
        setE5s(false);
        setE6s(false);
        tE1s = newObject.pagecouter;
        setE1sdata({
          total: itemE1slist.length,
          attendans: newObject.pagecouter,
        });
        gopracticecompleted(newObject);
      } else if ( newObject.type === "e2s" && itemE2slist.length > 0 && itemE3slist.length > 0 ) {
        setE1s(false);
        setE2s(false);
        setE3s(true);
        setE4s(false);
        setE5s(false);
        setE6s(false);
        tE2s = newObject.pagecouter;
        setE2sdata({
          total: itemE2slist.length,
          attendans: newObject.pagecouter,
        });
        //getExercise(1);
        // gopracticecompleted(newObject);
      } else if ( newObject.type === "e2s" && itemE2slist.length > 0 && itemE3slist.length === 0 ) {
        setE1s(false);
        setE2s(false);
        setE3s(true);
        setE4s(false);
        setE5s(false);
        setE6s(false);
        tE2s = newObject.pagecouter;
        setE2sdata({
          total: itemE2slist.length,
          attendans: newObject.pagecouter,
        });
        //getExercise(1);
        gopracticecompleted(newObject);
      } else if ( newObject.type === "e3s" && itemE3slist.length > 0 && itemE4slist.length > 0 ) {
        setE1s(false);
        setE2s(false);
        setE3s(false);
        setE4s(true);
        setE5s(false);
        setE6s(false);
        tE3s = newObject.pagecouter;
        setE3sdata({
          total: itemE3slist.length,
          attendans: newObject.pagecouter,
        });
        // getExercise(1);
      } else if ( newObject.type === "e3s" && itemE3slist.length > 0 && itemE4slist.length === 0 ) {
        setE1s(false);
        setE2s(false);
        setE3s(false);
        setE4s(true);
        setE5s(false);
        setE6s(false);
        tE3s = newObject.pagecouter;
        setE3sdata({
          total: itemE3slist.length,
          attendans: newObject.pagecouter,
        });
         gopracticecompleted(newObject);
      } else if ( newObject.type === "e4s" && itemE4slist.length > 0 && itemE5slist.length > 0 ) {
        setE1s(false);
        setE2s(false);
        setE3s(false);
        setE4s(false);
        setE5s(true);
        setE6s(false);
        tE4s = newObject.pagecouter;
        setE4sdata({
          total: itemE4slist.length,
          attendans: newObject.pagecouter,
        });
        getExercise(1);
      } else if ( newObject.type === "e4s" && itemE4slist.length > 0 && itemE5slist.length === 0 ) {
        setE1s(false);
        setE2s(false);
        setE3s(false);
        setE4s(false);
        setE5s(true);
        setE6s(false);
        tE4s = newObject.pagecouter;
        setE4sdata({
          total: itemE4slist.length,
          attendans: newObject.pagecouter,
        });
        getExercise(1);
        gopracticecompleted(newObject);
      } else if ( newObject.type === "e5s" && itemE5slist.length > 0 && itemE6slist.length > 0 ) {
        setE1s(false);
        setE2s(false);
        setE3s(false);
        setE4s(false);
        setE5s(false);
        setE6s(true);
        tE5s = newObject.pagecouter;
        setE5sdata({
          total: itemE5slist.length,
          attendans: newObject.pagecouter,
        });
        getExercise(1);
      } else if ( newObject.type === "e5s" && itemE5slist.length > 0 && itemE6slist.length === 0 ) {
        setE1s(false);
        setE2s(false);
        setE3s(false);
        setE4s(false);
        setE5s(false);
        setE6s(true);
        tE5s = newObject.pagecouter;
        setE5sdata({
          total: itemE5slist.length,
          attendans: newObject.pagecouter,
        });
        getExercise(1);
        gopracticecompleted(newObject);
      } else if (newObject.type === "e6s" && itemE6slist.length > 0) {
        setE1s(false);
        setE2s(false);
        setE3s(false);
        setE4s(false);
        setE5s(false);
        setE6s(false);
        tE6s = newObject.pagecouter;
        setE6sdata({
          total: itemE6slist.length,
          attendans: newObject.pagecouter,
        });
        getExercise(1);
        gopracticecompleted(newObject);
      } else {
        setE1s(false);
        setE2s(false);
        setE3s(false);
        setE4s(false);
        setE5s(false);
        setE6s(false);
        gopracticecompleted("");
      }
    }
 
  }
  useEffect(() => {
    myStateRef.current = sete6;
    console.log("isApicall", isApicall);
    if (isApicall) {
      debugger;
      getExercise(0);
    }
    debugger;
  });
  const [length, setLength] = React.useState(-1);
  const [lengthque, setLengthQue] = React.useState(1);
  const result: Result = { q1: false, q2: false, q3: false, score: 0 };
  //CompareSentences
  const historyss = useHistory<{
    themeArray: string;
    themeId: string;
    lessonsId: string;
    index: string;
    lessonname: string;
    themename: string;
    levelId: string;
    level: string;
    levname: string;
    backpage: string;
    backpageValue: number;
  }>();
  let themeArray:any = historyss.location.state
    ? historyss.location.state.themeArray
    : "";
  const itemArray: any = themeArray;
  const themeId = historyss.location.state
    ? historyss.location.state.themeId
    : "";
  const lessonsId = historyss.location.state
    ? historyss.location.state.lessonsId
    : "";
  const index = historyss.location.state ? historyss.location.state.index : "";
  const lessonname = historyss.location.state
    ? historyss.location.state.lessonname
    : "";
  const themename = historyss.location.state
    ? historyss.location.state.themename
    : "";
  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";
  const levelId = historyss.location.state
    ? historyss.location.state.levelId
    : "";
  const level = historyss.location.state ? historyss.location.state.level : "";
  const backpage = historyss.location.state
    ? historyss.location.state.backpage
    : "";
  const backpageValue = historyss.location.state
    ? historyss.location.state.backpageValue
    : "";
  const [isVisible, setIsVisible] = React.useState(false);
  const openDrawer = React.useCallback(() => setIsVisible(true), []);
  let token = "";
  let resfreshtoken = "";
  useIonViewWillEnter(() => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    GetSettings()
    setLength(-1);
  });

  const getthemes = async () => {
    // present({
    //   message: "",
    //   duration: 1000,
    // });
    console.log("APIthemeId. ", themeId);
    try {
      const response = await fetch(
        url + "/Book/GetLessons?themeGuid=" + themeId,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        }
      );
      const json = await response.json();
      // console.log(json);
      if (json.StatusCode == 1) {
        debugger
        for (let index = 0; index < json.Result.Lessons.length; index++) {
          const element = json.Result.Lessons[index];
        }
        let test: any[] = [];
        const array1 = json.Result.Lessons;
        const array2 = json.Result.Dialogues;
        // const combinedArray = array1.concat(array2);
        const maxLength = Math.max(array1.length, array2.length);
        for (let i = 0; i < maxLength; i++) {
          // Your logic here
          if (array1[i]) {
            array1[i]["type"] = "lesson";
            array1[i]["number"] = i + 1;
            test.push(array1[i]);
          }
          if (array2[i]) {
            array2[i]["type"] = "dialogues";
            array2[i]["number"] = i + 1;
            test.push(array2[i]);
          }
        }
        localStorage.setItem("itemlist", JSON.stringify(test));
        console.log("test array", test);
        allThemes = test
        setThemes(test)
        // console.log(themeArray);        
        // console.log("Get Themes",getThemes);


      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      // dismiss();
    } catch (error) {
      console.error(error);
      // dismiss();
    }
  };

  const getExercise = async (type) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 1000,
    });
    console.log(lessonsId);
    try {
      const response = await fetch(
        url + "/Book/GetExercise?lessonGuid=" + lessonsId,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        }
      );
      const json = await response.json();
      //console.log('GetExercise ', json)
      // debugger
      dismiss();
      if (json.StatusCode == 1) {
        // setitems(json.Result.E4ev1s)
        // setLengthQue(json.Result.length)
        console.log("GetExercise", json.Result);
        setAllExcies(json.Result);
        setAPIcall(false);
        if (type == 0) {
          //When oringal code then open this code
          if (json.Result.E1s.length > 0) {
            setE1s(true);
          } else if (json.Result.E2s.length > 0) {
            setE2s(true);
          } else if (json.Result.E3s.length > 0) {
            setE3s(true);
          } else if (json.Result.E4s.length > 0) {
            setE4s(true);
          } else if (json.Result.E5s.length > 0) {
            setE5s(true);
          } else if (json.Result.E6s.length > 0) {
            setE6s(true);
          }
          //For testing code
          // setE3s(true);
        }
        debugger;
        //New Code
        setE1stems(json.Result.E1s);
        setE2stems(json.Result.E2s);
        setE3stems(json.Result.E3s);
        setE4stems(json.Result.E4s);
        setE5stems(json.Result.E5s);
        setE6stems(json.Result.E6s);

        //Exercise E1s  4 images and 1 text logic set
        let tmpoptione1s: any = [];
        let resulteE1s = json.Result.E1s;
        resulteE1s.forEach((axq: any[], indexq: number) => {
          // console.log('Option', result[indexq].Word1)
          let qq = [
            {
              Word: resulteE1s[indexq].ImageUrl1,
              questionno: 1,
            },
            {
              Word: resulteE1s[indexq].ImageUrl2,
              questionno: 2,
            },
            {
              Word: resulteE1s[indexq].ImageUrl3,
              questionno: 3,
            },
            {
              Word: resulteE1s[indexq].ImageUrl4,
              questionno: 4,
            },
          ];
          const random_o = qq
            .map((a) => ({ sort: Math.random(), value: a }))
            .sort((a, b) => a.sort - b.sort)
            .map((a) => a.value);
          console.log("random", qq);
          let final = {
            EId: resulteE1s[indexq].EId,
            //Ev_guid: resulteE1s[indexq].Ev_guid,
            TemplateCode: resulteE1s[indexq].TemplateCode,
            PageId: resulteE1s[indexq].PageId,
            BookId: resulteE1s[indexq].BookId,
            Options: random_o,
            Question:
              resulteE1s[indexq].WordCh + " " + resulteE1s[indexq].WordPy,
            WordCh: resulteE1s[indexq].WordCh,
            WordPy: resulteE1s[indexq].WordPy,
            answer: resulteE1s[indexq].CorrectImgUrl,
            score: false,
          };
          tmpoptione1s.push(final);
        });
        setitems(tmpoptione1s);
        setE1stems(tmpoptione1s);
        console.log("this.itemlist", itemlist);
        // End E1s

        //Exercise E2s 1 images and 4 text logic set
        let tmpoptione2s: any = [];
        let resulteE2s = json.Result.E2s;
        resulteE2s.forEach((axq: any[], indexq: number) => {
          // console.log('Option', result[indexq].Word1)
          let qq = [
            {
              Word: resulteE2s[indexq].WordCh1,
              WordPy: resulteE2s[indexq].WordPy1,
              questionno: 1,
            },
            {
              Word: resulteE2s[indexq].WordCh2,
              WordPy: resulteE2s[indexq].WordPy2,
              questionno: 2,
            },
            {
              Word: resulteE2s[indexq].WordCh3,
              WordPy: resulteE2s[indexq].WordPy3,
              questionno: 3,
            },
            {
              Word: resulteE2s[indexq].WordCh4,
              WordPy: resulteE2s[indexq].WordPy4,
              questionno: 4,
            },
          ];
          const random_b = qq
            .map((a) => ({ sort: Math.random(), value: a }))
            .sort((a, b) => a.sort - b.sort)
            .map((a) => a.value);
          console.log("random", qq);
          let final = {
            EId: resulteE2s[indexq].EId,
            //Ev_guid: resulteE1s[indexq].Ev_guid,
            TemplateCode: resulteE2s[indexq].TemplateCode,
            PageId: resulteE2s[indexq].PageId,
            BookId: resulteE2s[indexq].BookId,
            Options: random_b,
            Question: resulteE2s[indexq].ImageUrl,
            // WordCh: resulteE2s[indexq].WordCh,
            // WordPy: resulteE2s[indexq].WordPy,
            answer: resulteE2s[indexq].CorrectCh,
            score: false,
          };
          tmpoptione2s.push(final);
        });
        // setitems(tmpoptione2s);
        setE2stems(tmpoptione2s);
        console.log("this.itemlist", tmpoptione2s);
        // End E2s

        //Exercise E4s 1 audio and 4 text logic set
        let tmpoptione4s: any = [];
        let resulteE4s = json.Result.E4s;
        resulteE4s.forEach((axq: any[], indexq: number) => {
          // console.log('Option', result[indexq].Word1)
          let qq = [
            {
              Word: resulteE4s[indexq].WordCh1,
              WordPy: resulteE4s[indexq].WordPy1,
              questionno: 1,
            },
            {
              Word: resulteE4s[indexq].WordCh2,
              WordPy: resulteE4s[indexq].WordPy2,
              questionno: 2,
            },
            {
              Word: resulteE4s[indexq].WordCh3,
              WordPy: resulteE4s[indexq].WordPy3,
              questionno: 3,
            },
            {
              Word: resulteE4s[indexq].WordCh4,
              WordPy: resulteE4s[indexq].WordPy4,
              questionno: 4,
            },
          ];
          const random_c = qq
            .map((a) => ({ sort: Math.random(), value: a }))
            .sort((a, b) => a.sort - b.sort)
            .map((a) => a.value);
          console.log("random", qq);
          let final = {
            EId: resulteE4s[indexq].EId,
            //Ev_guid: resulteE1s[indexq].Ev_guid,
            TemplateCode: resulteE4s[indexq].TemplateCode,
            PageId: resulteE4s[indexq].PageId,
            BookId: resulteE4s[indexq].BookId,
            Options: random_c,
            Question: resulteE4s[indexq].AudioUrl,
            // WordCh: resulteE2s[indexq].WordCh,
            // WordPy: resulteE2s[indexq].WordPy,
            answer: resulteE4s[indexq].CorrectCh,
            score: false,
          };
          tmpoptione4s.push(final);
        });
        // setitems(tmpoptione2s);
        setE4stems(tmpoptione4s);
        console.log("this.itemlist", tmpoptione4s);
        // End E2s

        //Old Code
        setLengthQue(3);
        //Logic E4ev1s
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      // console.log(json)
      if (json.StatusCode == 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);
  useEffect(() => {
    // console.log('showResults', showResults)
    console.log("finalindex", length);
    console.log("lengthque", lengthque);
    console.log("this.itemlist-Final", itemlist);
    if (length > lengthque) {
      console.log("Done");
      debugger;
      // setE4EV1(false);
      // setE5EV1(true);
      setLength(-1);
    }
    //debugger
  });
  const gobackpage = () => {
    //history.push('/tabs/home/themesports/5');
    history.push({
      pathname:
        backpage === "progress"
          ? "/tabs/" + backpage
          : "/tabs/home/" + backpage,
      state: {
        themeArray: themeArray,
        themeId: themeId,
        lessonname: lessonname,
        index: index,
        lessonsId: lessonsId,
        themename: themename,
        levelId: levelId,
        level: level,
        levname: levname,
        backpageValue: backpageValue,
      },
    });
    history.go(0);
    // window.location.reload();
  };
  const gopracticecompleted = (newObject) => {
    //history.push('/tabs/home/themesports/5');
    console.log("sete1", seteE1);
    console.log("sete2", seteE2);
    console.log("sete3", seteE3);
    console.log("sete4", seteE4);
    console.log("sete5", seteE5);
    console.log("sete6", seteE6);
    console.log(myStateRef.current);
    console.log("myObject", myObject);
    debugger;
    if (tE1s == "") {
      tE1s = seteE1.attendans;
    }
    if (tE2s == "") {
      tE2s = seteE2.attendans;
    }
    if (tE3s == "") {
      tE3s = seteE3.attendans;
    }
    if (tE4s == "") {
      tE4s = seteE4.attendans;
    }
    if (tE5s == "") {
      tE5s = seteE5.attendans;
    }
    if (tE6s == "") {
      tE6s = seteE6.attendans;
    }
    console.log("tE1s", tE1s);
    console.log("tE2s", tE2s);
    console.log("tE3s", tE3s);
    console.log("tE4s", tE4s);
    console.log("tE5s", tE5s);
    console.log("tE6s", tE6s);
    if (tE1s && tE2s && tE3s && tE4s && tE5s && tE6s) {
      if (
        tE1s == itemE1slist.length &&
        tE2s == itemE2slist.length &&
        tE3s == itemE3slist.length &&
        tE4s == itemE4slist.length &&
        tE5s == itemE5slist.length &&
        tE6s == itemE6slist.length
      ) {
        LessonComplete();
      }
      //tE1s
    } else if (tE1s && tE2s) {
      if (tE1s == itemE1slist.length && tE2s == itemE2slist.length) {
        LessonComplete();
      }
    } else if (tE1s && tE3s) {
      if (tE1s == itemE1slist.length && tE3s == itemE3slist.length) {
        LessonComplete();
      }
    } else if (tE1s && tE4s) {
      if (tE1s == itemE1slist.length && tE4s == itemE4slist.length) {
        LessonComplete();
      }
    } else if (tE1s && tE5s) {
      if (tE1s == itemE1slist.length && tE5s == itemE5slist.length) {
        LessonComplete();
      }
    } else if (tE1s && tE6s) {
      if (tE1s == itemE1slist.length && tE6s == itemE6slist.length) {
        LessonComplete();
      }
      //tE2s
    } else if (tE2s && tE1s) {
      if (tE2s == itemE2slist.length && tE1s == itemE1slist.length) {
        LessonComplete();
      }
    } else if (tE2s && tE3s) {
      if (tE2s == itemE2slist.length && tE3s == itemE3slist.length) {
        LessonComplete();
      }
    } else if (tE2s && tE4s) {
      if (tE2s == itemE2slist.length && tE4s == itemE4slist.length) {
        LessonComplete();
      }
    } else if (tE2s && tE5s) {
      if (tE2s == itemE2slist.length && tE5s == itemE5slist.length) {
        LessonComplete();
      }
    } else if (tE2s && tE6s) {
      if (tE2s == itemE2slist.length && tE6s == itemE6slist.length) {
        LessonComplete();
      }
    }
    //tE3s
    else if (tE3s && tE1s) {
      if (tE3s == itemE3slist.length && tE1s == itemE1slist.length) {
        LessonComplete();
      }
    } else if (tE3s && tE2s) {
      if (tE3s == itemE3slist.length && tE2s == itemE2slist.length) {
        LessonComplete();
      }
    } else if (tE3s && tE4s) {
      if (tE3s == itemE3slist.length && tE4s == itemE4slist.length) {
        LessonComplete();
      }
    } else if (tE3s && tE5s) {
      if (tE3s == itemE3slist.length && tE5s == itemE5slist.length) {
        LessonComplete();
      }
    } else if (tE3s && tE6s) {
      if (tE3s == itemE3slist.length && tE6s == itemE6slist.length) {
        LessonComplete();
      }
    }
    //tE4s
    else if (tE4s && tE1s) {
      if (tE4s == itemE3slist.length && tE1s == itemE1slist.length) {
        LessonComplete();
      }
    } else if (tE4s && tE2s) {
      if (tE4s == itemE3slist.length && tE2s == itemE2slist.length) {
        LessonComplete();
      }
    } else if (tE4s && tE3s) {
      if (tE4s == itemE3slist.length && tE3s == itemE3slist.length) {
        LessonComplete();
      }
    } else if (tE4s && tE5s) {
      if (tE4s == itemE3slist.length && tE5s == itemE5slist.length) {
        LessonComplete();
      }
    } else if (tE4s && tE6s) {
      if (tE4s == itemE3slist.length && tE6s == itemE6slist.length) {
        LessonComplete();
      }
    }
    //tE5s
    else if (tE5s && tE1s) {
      if (tE5s == itemE5slist.length && tE1s == itemE1slist.length) {
        LessonComplete();
      }
    } else if (tE5s && tE2s) {
      if (tE5s == itemE5slist.length && tE2s == itemE2slist.length) {
        LessonComplete();
      }
    } else if (tE5s && tE3s) {
      if (tE5s == itemE5slist.length && tE3s == itemE3slist.length) {
        LessonComplete();
      }
    } else if (tE5s && tE4s) {
      if (tE5s == itemE5slist.length && tE4s == itemE4slist.length) {
        LessonComplete();
      }
    } else if (tE5s && tE6s) {
      if (tE5s == itemE5slist.length && tE6s == itemE6slist.length) {
        LessonComplete();
      }
    }
    //tE5s
    else if (tE6s && tE1s) {
      if (tE6s == itemE6slist.length && tE1s == itemE1slist.length) {
        LessonComplete();
      }
    } else if (tE6s && tE2s) {
      if (tE6s == itemE6slist.length && tE2s == itemE2slist.length) {
        LessonComplete();
      }
    } else if (tE6s && tE3s) {
      if (tE6s == itemE6slist.length && tE3s == itemE3slist.length) {
        LessonComplete();
      }
    } else if (tE6s && tE4s) {
      if (tE6s == itemE6slist.length && tE4s == itemE4slist.length) {
        LessonComplete();
      }
    } else if (tE6s && tE5s) {
      if (tE6s == itemE6slist.length && tE6s == itemE5slist.length) {
        LessonComplete();
      }
    }
    //singal
    else if (tE1s) {
      if (tE1s == itemE1slist.length) {
        LessonComplete();
      }
    } else if (tE2s) {
      if (tE2s == itemE2slist.length) {
        LessonComplete();
      }
    } else if (tE3s) {
      if (tE3s == itemE3slist.length) {
        LessonComplete();
      }
    } else if (tE4s) {
      if (tE4s == itemE4slist.length) {
        LessonComplete();
      }
    } else if (tE5s) {
      if (tE5s == itemE5slist.length) {
        LessonComplete();
      }
    } else if (tE6s) {
      if (tE6s == itemE6slist.length) {
        LessonComplete();
      }
    }
    // setE6data({ total: itemE6EV1list.length, attendans: newObject.pagecouter })
    debugger;
    history.push({
      pathname: "/tabs/home/Practicecompleted",
      state: {
        themeArray: themeArray,
        themeId: themeId,
        lessonname: lessonname,
        index: index,
        lessonsId: lessonsId,
        themename: themename,
        levelId: levelId,
        level: level,
        levname: levname,
        backpage: backpage,
        backpageValue: backpageValue,
      },
    });
  };
  const LessonComplete = async () => {
    console.log("sete4", sete4);
    console.log("sete5", sete5);
    console.log("sete6", sete6);
    console.log("sete7", sete7);
    console.log("sete8", sete8);
    debugger;
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 1000,
    });
    console.log(lessonsId);
    const data = {
      bookGuid: lessonsId,
    };
    try {
      const response = await fetch(url + "/Book/LessonComplete", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      const json = await response.json();
      dismiss();
      if (json.StatusCode == 1) {
        console.log(json);        
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  useIonViewDidLeave(() => {
    // setE4EV1(false);
    // setE5EV1(false);
    // setE6EV1(false);
    // setE7EV1(false);
    // setE8EV1(false);
    //New
    setE1s(false);
    setE2s(false);
    setE3s(false);
    setE4s(false);
    setE5s(false);
    setE6s(false);
  });

  //Setting Code

  const fontsize = (data) => {

    if (data == 0) {
      if (fontSize > 18) {
        let font = fontSize - 3;
        setFontSize(font);
        setClickedDown(true);
      }
    } else if (data == 1) {
      if (fontSize < 24) {
        let font = fontSize + 3;
        setFontSize(font);
        setClickedUp(true);
      }
    }

    // Remove the 'clicked' class after a short delay (adjust as needed)
    setTimeout(() => {
      setClickedUp(false);
      setClickedDown(false);
    }, 300);
  };

  const SaveUserSettings = async (char: any, piniyin: any, trans: any) => {
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   duration: 1000,
    // });
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    const settings = {
      isPinyin: piniyin,
      isTranslation: trans,
      isChineseCharacter: char,
    };
    console.log(settings);    
    try {
      const response = await fetch(url + "/User/SaveSetting", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
        body: JSON.stringify(settings),
      });
      const json = await response.json();
      console.log("SaveSetting Response", json);
      // dismiss();
      if (json.StatusCode === 1) {
        // Settings saved successfully
        // Handle the response as needed
      } else if (json.StatusCode === 2) {
        // Handle token refresh logic
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      // dismiss();
    }
  };

  const GetSettings = async () => {
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   duration: 1000,
    // });
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    try {
      const response = await fetch(url + `/User/GetSetting`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
          // Include other headers if needed for User/GetSetting
        },
      });
      const json = await response.json();
      console.log("Settings", json);
      // dismiss();
      if (json.StatusCode === 1) {
        console.log(json.Result);
        setCheChar(json.Result.IsChineseCharacter);
        setPiniyin(json.Result.IsPinyin);
        setIsTransChecked(json.Result.IsTranslation);
      } else if (json.StatusCode === 2) {
      } else {
        // Handle other StatusCode values
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      // dismiss();
    }
  };

  const toggleChe = () => {
    setCheChar((prevCheChar) => {
      const newCheChar = !prevCheChar;
      console.log(newCheChar); // Log the new state (true or false)
      let newpiniyin:any
      if(newCheChar === false){
        setPiniyin(true)
        newpiniyin = true
      } else {
        newpiniyin = null
      }
      SaveUserSettings(newCheChar, newpiniyin !== null ? newpiniyin : piniyin, isTransChecked); // Save the new state if needed
      return newCheChar;
    });
  };

  const togglepiniyin = () => {
    setPiniyin((prevPiniyin) => {
      const newPiniyin = !prevPiniyin;
      console.log(newPiniyin); // Log the new state (true or false)
      let newchechar:any
      if (newPiniyin === false) {
        setCheChar(true)
        newchechar = true
      } else {
        newchechar = null
      }
      SaveUserSettings( newchechar !== null ? newchechar : cheChar, newPiniyin, isTransChecked); // Save the new state if needed
      return newPiniyin;
    });
  };

  const translationkModeToggle = () => {
    setIsTransChecked((prevIsTransChecked) => {
      const newIsTransChecked = !prevIsTransChecked;
      console.log(newIsTransChecked); // Log the new state (true or false)
      SaveUserSettings(cheChar, piniyin, newIsTransChecked); // Save the new state if needed
      return newIsTransChecked;
    });
  };

  const savebookmark = (val, index) => {
    // Toggle the bookmark status of the current lesson
    themeArray[index].IsFavourite = !themeArray[index].IsFavourite;

    // Create a copy of the themeArray with the updated bookmark status
    const updatedThemeArray = [...themeArray];
    updatedThemeArray[index] = { ...themeArray[index] }; // Create a new object to trigger a state update

    if (themeArray[index].IsFavourite === 0) {
      // If the lesson is no longer a favorite, update the state
      setBookmarkindex(-1);
      saveFav(val, updatedThemeArray[index]?.IsFavourite);
    } else {
      // If the lesson is now a favorite, update the state
      setBookmarkindex(index);
      saveFav(val, updatedThemeArray[index]?.IsFavourite);
    }

    // Log the updated themeArray
    console.log("Updated themeArray:", updatedThemeArray);
    themeArray = updatedThemeArray
  };

  const saveFav = async (val, isFavourite: boolean) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    debugger;
    const data = {
      referenceId: themeArray[index].BookId,
      isFavourite: isFavourite,
      typeId: 1,
    };
    //dismiss()
    console.log(data);
    try {
      const response = await fetch(url + "/User/Favourite", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      const json = await response.json();
      console.log(json);
      //dismiss()
      if (json.StatusCode == 1) {
        getthemes();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };

  const decreasePlaybackSpeed = () => {
    if (playbackSpeed > 1) {
      changePlaybackSpeed(-1);
    }
  };

  const increasePlaybackSpeed = () => {
    if (playbackSpeed < 15) {
      changePlaybackSpeed(1);
    }
  };

  const changePlaybackSpeed = (speed: number) => {
    if (rap) {
      const audioElement = rap.audioEl.current;
      if (audioElement) {
        audioElement.playbackRate += speed;
        // Ensure playback speed is within bounds (1 to 15)
        if (audioElement.playbackRate < 1) {
          audioElement.playbackRate = 1;
        } else if (audioElement.playbackRate > 15) {
          audioElement.playbackRate = 15;
        }
        setPlaybackSpeed(audioElement.playbackRate);
      }
    }
  }

  //End Setting

  return (
    <IonPage className="intro" placeholder={undefined}>
      <IonHeader className="ion-text-center dubbletitle" placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <div>
            <IonButtons slot="start" placeholder={undefined}>
              <IonButton color="primary" onClick={gobackpage} placeholder={undefined}>
                <IonIcon icon={chevronBack} placeholder={undefined} />
              </IonButton>
            </IonButtons>
            <IonTitle color="primary" className="font20 ion-no-padding" placeholder={undefined}>
              <span>
                {t("practicequestion.lesson")}{" "}
                {themeArray[index ? index : 0]?.number}:{" "}
                {t("practicequestion.practice")}
                <p className="ion-no-margin">{themename}</p>
                {isitemE2s}
              </span>
            </IonTitle>
            <IonButtons slot="end" placeholder={undefined}>
              <IonButton onClick={openDrawer} color="primary" placeholder={undefined}>
                <IonIcon icon={ellipsisVertical} placeholder={undefined} />
              </IonButton>
            </IonButtons>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className="listing" placeholder={undefined}>
        {/* E1s 4 images and 1 text */}
        {isitemE1s && itemE1slist.length > 0 ? (
          <div className="audiorecord E1s">
            <E1s
              itemE1s={itemE1slist}
              onObjectChange={handleMyObjectChange}
              lastSlide={backValue}
              cheChar={cheChar}
              piniyin={piniyin}
            />
          </div>
        ) : (
          ""
        )}
        {/*#################################################################################### */}
        {/* E2s 1 image and 4 text */}
        {isitemE2s && itemE2slist.length > 0 ? (
          <div className="audiorecord E2s">
            <E2s
              itemE2s={itemE2slist}
              onObjectChange={handleMyObjectChange}
              lastSlide={backValue}
              cheChar={cheChar}
              piniyin={piniyin}
            />
          </div>
        ) : (
          ""
        )}
        {/*#################################################################################### */}
        {/* E3s Writing  */}
        {isitemE3s && itemE3slist.length > 0 ? (
          <div className="audiorecord E3s">
            <E3s
              itemE3s={itemE3slist}
              onObjectChange={handleMyObjectChange}
              lastSlide={backValue}
              cheChar={cheChar}
              piniyin={piniyin}
            />
          </div>
        ) : (
          ""
        )}
        {/*#################################################################################### */}
        {/* E4s 1 audio and 4 text */}
        {isitemE4s && itemE4slist.length > 0 ? (
          <div className="E4s">
            <E4s
              itemE4s={itemE4slist}
              onObjectChange={handleMyObjectChange}
              lastSlide={backValue}
              cheChar={cheChar}
              piniyin={piniyin}
            />
          </div>
        ) : (
          ""
        )}
        {/*#################################################################################### */}
        {/* E5s recoding  */}
        {isitemE5s && itemE5slist.length > 0 ? (
          <div className="audiorecord E5s">
            <E5s
              itemE5s={itemE5slist}
              onObjectChange={handleMyObjectChange}
              lastSlide={backValue}
              cheChar={cheChar}
              piniyin={piniyin}
            />
          </div>
        ) : (
          ""
        )}
        {/*#################################################################################### */}
        {/* E6s rearrage word */}
        {isitemE6s && itemE6slist.length > 0 ? (
          <div className="audiorecord E6s">
            <E6s
              itemE6s={itemE6slist}
              onObjectChange={handleMyObjectChange}
              lastSlide={backValue}
              cheChar={cheChar}
              piniyin={piniyin}
            />
          </div>
        ) : (
          ""
        )}
        {/*#################################################################################### */}
      </IonContent>
      <Drawer
        className="bottompopup skateInBottom"
        isVisible={isVisible}
        onClose={onClose}
      >
        <IonRow placeholder={undefined}>
          <IonList className="list" placeholder={undefined}>
            {/* <IonItem class="ion-no-padding playbook">
              <div>
                <IonImg src="./assets/images/play.png" />
              </div>
              <div className="details ion-padding-start">
                <h4>Play book</h4>
                <span>Autoplay book audio</span>
              </div>
            </IonItem> */}
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>
                {t("practicequestion.drawer.lesson_language")}
              </IonLabel>
              <div className="right-space">
                <IonText color="primary" placeholder={undefined}>簡</IonText>
                <IonText placeholder={undefined}>繁</IonText>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>
                {t("practicequestion.drawer.chinese_characters")}
              </IonLabel>
              <IonToggle
                checked={cheChar}
                onClick={toggleChe}
                slot="end" placeholder={undefined}/>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("practicequestion.drawer.pinyin")}</IonLabel>
              <IonToggle
                checked={piniyin}
                onClick={togglepiniyin}
                slot="end" placeholder={undefined}/>
            </IonItem>
            {/* <IonItem class="ion-no-padding">
              <IonLabel>Translation</IonLabel>
              <IonToggle slot="end"></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding">
              <IonLabel>Font size</IonLabel>
              <IonImg src="./assets/images/Fontdown.png" />
              <IonImg src="./assets/images/Fontup.png" />
            </IonItem> */}
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("practicequestion.drawer.playback_speed")}</IonLabel>
              <div className="leftright">
                {/* <span onClick={() => changePlaybackSpeed(-1)}>&lt;</span> {playbackSpeed.toFixed(1)} <span onClick={() => changePlaybackSpeed(1)}>&gt;</span> */}
                <span onClick={decreasePlaybackSpeed} style={{ cursor: playbackSpeed > 1 ? 'pointer' : 'not-allowed' }}> &lt; </span>
                {playbackSpeed.toFixed(1)}
                <span onClick={increasePlaybackSpeed} style={{ cursor: playbackSpeed < 15 ? 'pointer' : 'not-allowed' }}> &gt; </span>
              </div>
            </IonItem>
            {/* <IonItem class="ion-no-padding">
              <IonLabel>Bookmark</IonLabel>
              <IonIcon color='primary' icon={bookmark} />
            </IonItem> */}
          </IonList>
        </IonRow>
      </Drawer>
    </IonPage>
  );
};
export default React.memo(Practicequestion);
