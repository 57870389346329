import React from 'react';
import { IonHeader, IonToolbar, IonButtons, IonButton, IonContent, IonItem, IonImg, IonLabel, IonToggle, IonList, IonCol, IonText, IonRow, IonCard, IonCardContent, IonIcon, IonPage, IonTitle } from '@ionic/react';
import './Lessonpractice.scss';
import { chevronBack, ellipsisVertical } from 'ionicons/icons';
import Drawer from "react-bottom-drawer";
import ReactAudioPlayer from 'react-audio-player';
import useMediaRecorder from '@wmik/use-media-recorder';
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

function Player({ srcBlob }: {srcBlob:any}) {
  console.log(srcBlob);
  if (!srcBlob) {
    return null;
  } else {
    return <audio src={URL.createObjectURL(srcBlob)} controls />;
  }
}

interface LessonpracticeProps { }

const Lessonpractice: React.FC<LessonpracticeProps> = () => {
  const [isVisible, setIsVisible] = React.useState(false);
  const openDrawer = React.useCallback(() => setIsVisible(true), []);

  const { t } = useTranslation();

  let { error, status, mediaBlob, stopRecording, startRecording } =
  useMediaRecorder({
    recordScreen: false,
    blobOptions: { type: 'audio/webm' },
    mediaStreamConstraints: { audio: true, video: false },
  });

  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);
  return (
    <IonPage className='intro' placeholder={undefined}>
      <IonHeader className='ion-text-center' placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <IonButtons slot="start" placeholder={undefined}>
            <IonButton color='primary' placeholder={undefined}>
              <IonIcon icon={chevronBack} placeholder={undefined} />
            </IonButton>
          </IonButtons>
          <IonTitle color='primary' className='font20 ion-no-padding' placeholder={undefined}><span>{t("lessonpractice.lesson")}</span></IonTitle>
          <IonButtons slot="end" placeholder={undefined}>
            <IonButton onClick={openDrawer} color='primary' placeholder={undefined}>
              <IonIcon icon={ellipsisVertical} placeholder={undefined} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className='listing' placeholder={undefined}>
        <IonList className="ion-no-padding bottomfix record" placeholder={undefined}>

          <IonRow className='Practice leftrightspace ion-padding-horizontal' placeholder={undefined}>
            <IonCol placeholder={undefined}>
              <IonText placeholder={undefined}>{t("lessonpractice.chse_corr_ans")}</IonText>
            </IonCol>
            <IonCol size="12" className='test-audio ion-text-left whitebg' placeholder={undefined}>
              <ReactAudioPlayer className='String' controls controlsList="nodownload noplaybackrate">
                <source src="/assets/audio.mp3" type="audio/mpeg"></source>
              </ReactAudioPlayer>
              {/* <audio controls src="/media/cc0-audio/t-rex-roar.mp3"></audio> */}
              {/* <div className='audio'></div> */}
            </IonCol>
          </IonRow>

          <IonRow className='answer leftrightspace ion-padding-horizontal' placeholder={undefined}>
            <IonCol size='12' placeholder={undefined}>
              <IonCard className='select' placeholder={undefined}>
                <IonCardContent className='Lessonpracticeaudio' placeholder={undefined}>
                  <ul className='lession-icon'>
                    <li><span>你</span>Nǐ</li>
                    <li><span>们</span>mén</li>
                    <li><span>做</span>zuò</li>
                    <li><span>做</span>dàn</li>
                    <li><span>蛋</span>gāo</li>
                  </ul>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonList>
      

        <IonList className='ion-text-center audio-bottom ion-padding-horizontal' placeholder={undefined}>
          <IonRow placeholder={undefined}>
            <IonCol size='12' className='back-forward' placeholder={undefined}>
              <div className='audio-record'>
                {/* <section>
                  <button className='whitebtn' type="button" onClick={(e: any) => startRecording()} disabled={status === 'recording'}>Start recording</button>
                  <button className='whitebtn' type="button" onClick={stopRecording} disabled={status !== 'recording'}> Stop recording</button>
                </section>
                <Player srcBlob={mediaBlob} /> */}
                <span><IonImg src="./assets/images/micCircle.svg" placeholder={undefined} /></span>
                {/* <span><IonIcon icon={stopCircleSharp} /></span>
                <span><IonIcon icon={micCircleSharp} /></span> */}
                <p>{t("lessonpractice.press_to_record")}</p>
              </div>
            </IonCol>
          </IonRow>
        </IonList>

      </IonContent>
      
      <Drawer className='bottompopup skateInBottom' isVisible={isVisible} onClose={onClose}>
        <IonRow placeholder={undefined}>
          <IonList className='list' placeholder={undefined}>
            {/* <IonItem class="ion-no-padding playbook">
              <div>
                <IonImg src="./assets/images/play.png" />
              </div>
              <div className="details ion-padding-start">
                <h4>Play book</h4>
                <span>Autoplay book audio</span>
              </div>
            </IonItem> */}
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("lessonpractice.drawer.lesson_language")}</IonLabel>
              <div className='right-space'>
                <IonText color='primary' placeholder={undefined}>簡</IonText>
                <IonText placeholder={undefined}>繁</IonText>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("lessonpractice.drawer.chinese_characters")}</IonLabel>
              <IonToggle checked slot="end" placeholder={undefined}></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("lessonpractice.drawer.pinyin")}</IonLabel>
              <IonToggle checked slot="end" placeholder={undefined}></IonToggle>
            </IonItem>
            {/* <IonItem class="ion-no-padding">
              <IonLabel>Translation</IonLabel>
              <IonToggle slot="end"></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding">
              <IonLabel>Font size</IonLabel>
              <IonImg src="./assets/images/Fontdown.png" />
              <IonImg src="./assets/images/Fontup.png" />
            </IonItem> */}
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("lessonpractice.drawer.playback_speed")}</IonLabel>
              <div className='leftright'><a href='#'>&lt;</a> 1.0 <a href='#'>&gt;</a></div>
            </IonItem>
            {/* <IonItem class="ion-no-padding">
              <IonLabel>Bookmark</IonLabel>
              <IonIcon color='primary' icon={bookmark} />
            </IonItem> */}
          </IonList>
        </IonRow>
      </Drawer>

    </IonPage>
  );
};

export default React.memo(Lessonpractice);