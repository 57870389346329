import React, { useState } from 'react';
import { IonContent, IonPage, IonHeader, IonToolbar, IonIcon, IonImg, IonTitle, IonRow, IonCol, IonGrid, IonButton, IonList, IonItem, IonLabel, IonInput, IonText, useIonLoading, useIonToast } from '@ionic/react';
import './Forgotpassresetpass.scss';
import { lockClosed, close } from 'ionicons/icons';
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css';
import { CONFIG } from '../App';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from "@hookform/error-message";
import { RouteComponentProps } from 'react-router';
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface OwnProps extends RouteComponentProps { }

interface ForgotpassresetpassProps extends OwnProps { }

const Forgotpassresetpass: React.FC<ForgotpassresetpassProps> = () => {
  let url: string = CONFIG.API_ENDPOINT;//live

  const { t } = useTranslation();

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();

  const { handleSubmit, control, formState: { errors } } = useForm({});

  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealPwdconf, setIsRevealPwdconf] = useState(false);

  const resetpassword = async (item: any) => {

    if (item.pwd != item.confpwd) {
      presenttost({
        message: 'Passwords does not match',
        duration: 5000,
        position: 'bottom'
      });
      return
    }
    present({
      message: '',
      cssClass: 'custom-spinner',
      //duration: 3000
    })
    var email = localStorage.getItem('email');
    var encodedString = btoa(item.pwd);

    const data = { email: email, password: encodedString }
    //  debugger
    try {
      const response = await fetch(url + '/account/resetpassword', {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      })
      if (!response.ok) {
        throw Error(response.statusText)
      }
      const json = await response.json()
      //debugger
      //console.log(json)
      dismiss()


      presenttost({
        message: json.Message,
        duration: 1500,
        position: 'bottom'
      });

      if (json.StatusCode == 1) {
        submit()
      }
    } catch (error) {

      presenttost({
        message: 'Please try again later',
        duration: 1500,
        position: 'bottom'
      });
      dismiss()
      console.error(error)

    }
  }


  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <div className='close'><IonIcon onClick={onClose} icon={close} placeholder={undefined} /></div>
            <div className='icon'><IonIcon icon={lockClosed} placeholder={undefined} /></div>
            <p>{t("forgotpassresetpass.submit.pass_cngd_succ")}</p>
            <div className='react-confirm-alert-button-group'>
              <IonButton className="alertcancel w-150" routerLink="/login" placeholder={undefined}>{t("forgotpassresetpass.submit.continue")}</IonButton>
            </div>
          </div>
        );
      }
   
    });
  };

  const togglePasswordVisiblity = () => {

    setIsRevealPwd(isRevealPwd ? false : true);
  };
  const togglePasswordVisiblityconf = () => {
    

    setIsRevealPwdconf(isRevealPwdconf ? false : true);
  };


  return (

    <IonPage className='blue-bg' id="login-page" placeholder={undefined}>
      <IonHeader className='bluescreen' placeholder={undefined}>
        <IonToolbar color='primary' placeholder={undefined}>
          <IonTitle>{t("forgotpassresetpass.reset_password")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent placeholder={undefined}>
        <form onSubmit={handleSubmit(data => resetpassword(data))}>
          <IonGrid className='main-box' placeholder={undefined}>
            <IonList className="userform" placeholder={undefined}>

              <IonItem color='white' className='subtitle' placeholder={undefined}>
                <IonLabel placeholder={undefined}>
                  <IonText className='nowrap' no-margin placeholder={undefined}>{t("forgotpassresetpass.enter_new_password")}</IonText>
                </IonLabel>
              </IonItem>

              <IonCol placeholder={undefined}>
                <IonItem className='tooltiparea forminput' color='white' placeholder={undefined}>
                  <IonLabel placeholder={undefined}>
                    <IonText no-margin placeholder={undefined}>{t("forgotpassresetpass.new_password")} {isRevealPwd}</IonText>
                  </IonLabel>
                  <div className='labeltitle'>
                    {/* <IonIcon icon={informationCircleOutline} /> */}
                    <IonImg src="./assets/images/bang-in-circle.png" alt="" placeholder={undefined} />
                    <div className="tooltip">{t("forgotpassresetpass.password_tooltip")}</div>
                  </div>
                </IonItem>
                <div className='iconwithinput'>
                  {/* <IonIcon icon={lockClosed} /> */}
                  <IonImg src="./assets/images/lock.png" alt="" placeholder={undefined} />
                  {/* <IonInput name="pwd" onIonChange={(e: any) => setPwd(e.target.value)} value={pwd} type={isRevealPwd ? "text" : "password"} required></IonInput>
                <IonIcon onClick={() => togglePasswordVisiblity()} icon={isRevealPwd ? eye : eyeOffOutline} /> */}

                    <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <IonInput type={isRevealPwd ? "text" : "password"} onBlur={onBlur}
                      onIonChange={onChange}
                      value={value} placeholder={undefined}                    />
                    )}
                    control={control}
                    name="pwd"
                    rules={{
                      required: t("forgotpassresetpass.required"),
                      // pattern: {
                      //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      //   message: "invalid pwd address"
                      // }
                    }}
                  />
                   <IonImg onClick={() => togglePasswordVisiblity()} src={isRevealPwd ? '/assets/images/showpassword.png' : './assets/images/hidepassword.png'} alt="" placeholder={undefined} />
                  {/* <IonIcon onClick={() => togglePasswordVisiblity()} icon={isRevealPwd ? eye : eyeOffOutline} /> */}
                </div>
              </IonCol>
              <ErrorMessage
                errors={errors}
                name="pwd"
                as={<div className='inputerror' />}
              />
              <IonCol placeholder={undefined}>
                <IonLabel placeholder={undefined}>{t("forgotpassresetpass.confirm_new_password")}</IonLabel>
                <div className='iconwithinput'>
                  {/* <IonIcon icon={lockClosed} /> */}
                  <IonImg src="./assets/images/lock.png" alt="" placeholder={undefined} />
                  {/* <IonInput name="confpwd" onIonChange={(e: any) => confsetPwd(e.target.value)} value={confpwd} type={isRevealPwdconf ? "text" : "password"} required></IonInput> */}
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <IonInput type={isRevealPwdconf ? "text" : "password"} onBlur={onBlur}
                      onIonChange={onChange}
                      value={value} placeholder={undefined}                    />
                    )}
                    control={control}
                    name="confpwd"
                    rules={{
                      required: t("forgotpassresetpass.required"),
                      // pattern: {
                      //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      //   message: "invalid confpwd address"
                      // }
                    }}
                  />
                      <IonImg onClick={() => togglePasswordVisiblityconf()} src={isRevealPwdconf ? '/assets/images/showpassword.png' : './assets/images/hidepassword.png'} alt="" placeholder={undefined} />
                  {/* <IonIcon onClick={() => togglePasswordVisiblityconf()} icon={isRevealPwdconf ? eye : eyeOffOutline} /> */}
                </div>
              </IonCol>
              <ErrorMessage
                errors={errors}
                name="confpwd"
                as={<div className='inputerror' />}
              />

            </IonList>

            <IonRow placeholder={undefined}>
              <IonCol size="12" placeholder={undefined}>
                <IonButton color="white" type="submit" className='whitebtn' expand='block' placeholder={undefined}>{t("forgotpassresetpass.reset")}</IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
        <div className="fixpagination">
          <div className="swiper-pagination swiper-pagination-bullets">
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Forgotpassresetpass);