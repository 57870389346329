import React from 'react';
import { IonHeader, IonToolbar, IonButtons, IonButton, IonContent, IonAccordionGroup, IonAccordion, IonItem, IonLabel, IonIcon, IonPage, IonTitle, useIonLoading, useIonToast, useIonViewWillEnter } from '@ionic/react';
import './FAQs.scss';
import { chevronBack } from 'ionicons/icons';
import { CONFIG } from '../App';
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface FAQsProps { }

const FAQs: React.FC<FAQsProps> = () => {
  let url: string = CONFIG.API_ENDPOINT;//live

  const { t } = useTranslation();

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any>({})
  let token = ''
  let resfreshtoken = ''

  useIonViewWillEnter(() => {
    let currentUser = JSON.parse(localStorage.getItem('alltoken') || '{}');
    if (currentUser) {
      token = currentUser.JwtToken
      resfreshtoken = currentUser.RefreshToken
    }
    GetFaqs()
  })

  const GetFaqs = async () => {
    present({
      message: '',
      cssClass: 'custom-spinner',
      duration: 1000
    })

    try {

      const response = await fetch(url + '/account/GetFaqs', {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token
        },
        method: "GET",
      })


      const json = await response.json()
      console.log('Faqs', json)


      dismiss()
      if (json.StatusCode == 1) {

        setitems(json.Result)
        //debugger

      } else if (json.StatusCode == 2) {
        refreshtoken()
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: 'bottom'
        });
      }


    } catch (error) {
      console.error(error)
      dismiss()
    }
  }

  const refreshtoken = async () => {
    present({
      message: '',
      cssClass: 'custom-spinner',
      //duration: 3000
    })
    const data = { refreshToken: resfreshtoken }
    try {
      const response = await fetch(url + '/account/refreshtoken', {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      })
      if (!response.ok) {
        throw Error(response.statusText)
      }
      const json = await response.json()
      console.log(json)

      if (json.StatusCode == 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken
        }
        localStorage.setItem('alltoken', JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: 'bottom'
        });
      }

      dismiss()

    } catch (error) {
      console.error(error)
      dismiss()
    }
  }
  return (
    <IonPage className='intro' placeholder={undefined}>
      <IonHeader className='ion-text-center' placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <IonButtons slot="start" placeholder={undefined}>
            <IonButton href='/tabs/settings' color='primary' placeholder={undefined}>
              <IonIcon icon={chevronBack} placeholder={undefined} />
            </IonButton>
          </IonButtons>
          <IonTitle color='primary' className='font20 ion-no-padding' placeholder={undefined}><span>{t("faqs.faqs")}</span></IonTitle>
          <IonButtons slot="end" placeholder={undefined}>
            {/* <IonButton color='primary'>
              <IonIcon icon={ellipsisVertical} />
            </IonButton> */}
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className='listing' placeholder={undefined}>

        {itemlist.length > 0 ?
          <IonAccordionGroup className='accordiongroup' placeholder={undefined}>
            {itemlist.map((items, index) => {
              return (
                <IonAccordion key={index} value={index} placeholder={undefined}>
                  <IonItem slot="header" color="white" placeholder={undefined}>
                    <IonLabel placeholder={undefined}>{items.Title}</IonLabel>
                  </IonItem>
                  <div className='subaccordion' slot="content">
                    {items.Description}
                  </div>
                </IonAccordion>
              )
            })}
          </IonAccordionGroup> : ''
        }
      </IonContent>

    </IonPage>
  );
};

export default React.memo(FAQs);