import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonToggle,
  IonCol,
  IonText,
  IonCard,
  IonCardContent,
  IonRow,
  IonPage,
  useIonViewWillEnter,
  useIonLoading,
  useIonToast,
  IonSlides,
  IonSlide,
  IonImg,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./E5sResultDetails.scss";
import Drawer from "react-bottom-drawer";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { CONFIG } from "../App";
import HanziWriter from "hanzi-writer";
import ReactAudioPlayer from "react-audio-player";
import useMediaRecorder from "@wmik/use-media-recorder";
import { json } from "stream/consumers";
import { string } from "yup";
import "swiper/swiper.min.css";
import "@ionic/react/css/ionic-swiper.css";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import { RouteComponentProps, useHistory } from "react-router";
import { chevronBack, ellipsisVertical } from "ionicons/icons";
interface OwnProps extends RouteComponentProps {}
function Player({ srcBlob }: { srcBlob: any }) {
  //console.log('srcBlob', srcBlob);
  if (!srcBlob) {
    return null;
  } else {
    return <audio src={URL.createObjectURL(srcBlob)} controls />;
  }
}
interface MyObject {
  id: number;
  name: string;
  type: "e5s";
  pagecouter: number;
  totalque: number;
  back: number;
}
interface E5sProps {
  itemE5s: any;
  onObjectChange: (object: MyObject) => void;
  lastSlide: any;
}
const E5sResultDetails: React.FC<E5sProps> = (
  props: E5sProps,
  { history, isSuccess, isWrong }
) => {
  let url: string = CONFIG.API_ENDPOINT; //live
  const { t } = useTranslation();
  const themeData: any = localStorage.getItem("itemlist");
  //const tDataCon = JSON.parse(themeData);
  const [allexcieslist, setAllExcies] = React.useState<any[]>([]);
  // const [isApicall, setAPIcall] = useState<boolean | undefined>(true);
  const [itemE5slist, setE5stems] = React.useState<any[]>([]);
  const [itemlist, setitems] = React.useState<any[]>([]);
  let tmscorefinal: any;
  const [isVisible, setIsVisible] = React.useState(false);
  const openDrawer = React.useCallback(() => setIsVisible(true), []);
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [showLoading, setShowLoading] = useState(false);
  const [showResults, setShowResults] = React.useState(1);
  const [progress, setProgress] = useState(0);
  const [isApicall, setApicall] = useState(0);
  const [length, setLength] = React.useState(-1);
  //CompareSentences
  const [sentence1, setSentence1] = useState("");
  const [sentence2, setSentence2] = useState("");
  const words1 = sentence1.split(" ");
  const words2 = sentence2.split(" ");
  const [showImage, setShowImage] = useState(false);
  const [myanswer, setmyanswer] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [ansIndex, setAnsSlideIndex] = useState(0);
  // DnD Start
  // Sample data
  const initialList1 = ["請", "問", "您", "想", "喝", "什", "麼", "？"];
  const initialList2 = [];
  const [list1, setList1] = useState<any>(initialList1);
  const [list2, setList2] = useState<any>(initialList2);
  // DnD End
  const notMatchingWords = words1
    .filter((word) => !words2.includes(word))
    .concat(words2.filter((word) => !words1.includes(word)));
  //end
  const spanRef = useRef(null);
  let lastValue: any;
  let token = "";
  let resfreshtoken = "";
  let pagecouter: any = 0;
  let totalque: any = 0;
  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);

  const historyss = useHistory<{
    themeArray: string;
    themeId: string;
    lessonsId: string;
    index: string;
    lessonname: string;
    themename: string;
    levelId: string;
    level: string;
    levname: string;
    backpage: string;
    pagename: string;
    backpageValue: any;
  }>();
  const themeArray = historyss.location.state
    ? historyss.location.state.themeArray
    : "";
  const itemArray: any = themeArray;
  const themeId = historyss.location.state
    ? historyss.location.state.themeId
    : "";
  const lessonsId = historyss.location.state
    ? historyss.location.state.lessonsId
    : "";
  const indexs = historyss.location.state ? historyss.location.state.index : "";
  const lessonname = historyss.location.state
    ? historyss.location.state.lessonname
    : "";
  const themename = historyss.location.state
    ? historyss.location.state.themename
    : "";
  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";
  const levelId = historyss.location.state
    ? historyss.location.state.levelId
    : "";
  const level = historyss.location.state ? historyss.location.state.level : "";
  const backpage = historyss.location.state
    ? historyss.location.state.backpage
    : "";
  const pagename = historyss.location.state
    ? historyss.location.state.pagename
    : "";
    const backpageValue = historyss.location.state
    ? historyss.location.state.backpageValue
    : "";
  useIonViewWillEnter(() => {
    getExercise();
    setLength(1);
    console.log("length nw", itemE5slist.length);
    slidesRef.current?.getActiveIndex().then((index) => {
      console.log("index", index + 1);
      console.log("length", itemE5slist.length);
      if (itemE5slist.length == index + 1) {
        console.log("Done");
        // setLength(-1)
        // saveContent()
        // handleChange()
      }
    });
    setShowResults(1);
  });
  const getExercise = async () => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 1000,
    });
    console.log(lessonsId);
    try {
      const response = await fetch(
        url +
          "/Book/GetExerciseResult?lessonGuid=" +
          lessonsId +
          "&type=" +
          pagename,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        }
      );
      const json = await response.json();
      //console.log('GetExercise ', json)
      // debugger
      dismiss();
      if (json.StatusCode == 1) {
        // setitems(json.Result.E4ev1s)
        // setLengthQue(json.Result.length)
        console.log("GetExercise", json.Result);
        setAllExcies(json.Result);
        setitems(json.Result);
        setE5stems(json.Result);
        // setAPIcall(false);
        //New Code
        // setE1stems(json.Result.E1s);
        //Exercise E1s  4 images and 1 text logic set
        // let tmpoptione1s: any = [];
        // let resulteE1s = json.Result.E1s;
        // resulteE1s.forEach((axq: any[], indexq: number) => {
        //   // console.log('Option', result[indexq].Word1)
        //   let qq = [
        //     {
        //       Word: resulteE1s[indexq].ImageUrl1,
        //       questionno: 1,
        //     },
        //     {
        //       Word: resulteE1s[indexq].ImageUrl2,
        //       questionno: 2,
        //     },
        //     {
        //       Word: resulteE1s[indexq].ImageUrl3,
        //       questionno: 3,
        //     },
        //     {
        //       Word: resulteE1s[indexq].ImageUrl4,
        //       questionno: 4,
        //     },
        //   ];
        //   const random_o = qq
        //     .map((a) => ({ sort: Math.random(), value: a }))
        //     .sort((a, b) => a.sort - b.sort)
        //     .map((a) => a.value);
        //   console.log("random", qq);
        //   let final = {
        //     EId: resulteE1s[indexq].EId,
        //     //Ev_guid: resulteE1s[indexq].Ev_guid,
        //     TemplateCode: resulteE1s[indexq].TemplateCode,
        //     PageId: resulteE1s[indexq].PageId,
        //     BookId: resulteE1s[indexq].BookId,
        //     Options: random_o,
        //     Question:
        //       resulteE1s[indexq].WordCh + " " + resulteE1s[indexq].WordPy,
        //     WordCh: resulteE1s[indexq].WordCh,
        //     WordPy: resulteE1s[indexq].WordPy,
        //     answer: resulteE1s[indexq].CorrectImgUrl,
        //     score: false,
        //   };
        //   tmpoptione1s.push(final);
        // });
        // setitems(tmpoptione1s);
        // setE1stems(tmpoptione1s);
        // console.log("this.itemlist", itemlist);
        // End E1s
        //Exercise E2s 1 images and 4 text logic set
        // let tmpoptione2s: any = [];
        // let resulteE2s = json.Result.E2s;
        // resulteE2s.forEach((axq: any[], indexq: number) => {
        //   // console.log('Option', result[indexq].Word1)
        //   let qq = [
        //     {
        //       Word: resulteE2s[indexq].WordCh1,
        //       WordPy: resulteE2s[indexq].WordPy1,
        //       questionno: 1,
        //     },
        //     {
        //       Word: resulteE2s[indexq].WordCh2,
        //       WordPy: resulteE2s[indexq].WordPy2,
        //       questionno: 2,
        //     },
        //     {
        //       Word: resulteE2s[indexq].WordCh3,
        //       WordPy: resulteE2s[indexq].WordPy3,
        //       questionno: 3,
        //     },
        //     {
        //       Word: resulteE2s[indexq].WordCh4,
        //       WordPy: resulteE2s[indexq].WordPy4,
        //       questionno: 4,
        //     },
        //   ];
        //   const random_b = qq
        //     .map((a) => ({ sort: Math.random(), value: a }))
        //     .sort((a, b) => a.sort - b.sort)
        //     .map((a) => a.value);
        //   console.log("random", qq);
        //   let final = {
        //     EId: resulteE2s[indexq].EId,
        //     //Ev_guid: resulteE1s[indexq].Ev_guid,
        //     TemplateCode: resulteE2s[indexq].TemplateCode,
        //     PageId: resulteE2s[indexq].PageId,
        //     BookId: resulteE2s[indexq].BookId,
        //     Options: random_b,
        //     Question: resulteE2s[indexq].ImageUrl,
        //     // WordCh: resulteE2s[indexq].WordCh,
        //     // WordPy: resulteE2s[indexq].WordPy,
        //     answer: resulteE2s[indexq].CorrectCh,
        //     score: false,
        //   };
        //   tmpoptione2s.push(final);
        // });
        // // setitems(tmpoptione2s);
        // setE2stems(tmpoptione2s);
        // console.log("this.itemlist", tmpoptione2s);
        // End E2s
        //Exercise E4s 1 audio and 4 text logic set
        // let tmpoptione4s: any = [];
        // let resulteE4s = json.Result.E4s;
        // resulteE4s.forEach((axq: any[], indexq: number) => {
        //   // console.log('Option', result[indexq].Word1)
        //   let qq = [
        //     {
        //       Word: resulteE4s[indexq].WordCh1,
        //       WordPy: resulteE4s[indexq].WordPy1,
        //       questionno: 1,
        //     },
        //     {
        //       Word: resulteE4s[indexq].WordCh2,
        //       WordPy: resulteE4s[indexq].WordPy2,
        //       questionno: 2,
        //     },
        //     {
        //       Word: resulteE4s[indexq].WordCh3,
        //       WordPy: resulteE4s[indexq].WordPy3,
        //       questionno: 3,
        //     },
        //     {
        //       Word: resulteE4s[indexq].WordCh4,
        //       WordPy: resulteE4s[indexq].WordPy4,
        //       questionno: 4,
        //     },
        //   ];
        //   const random_c = qq
        //     .map((a) => ({ sort: Math.random(), value: a }))
        //     .sort((a, b) => a.sort - b.sort)
        //     .map((a) => a.value);
        //   console.log("random", qq);
        //   let final = {
        //     EId: resulteE4s[indexq].EId,
        //     //Ev_guid: resulteE1s[indexq].Ev_guid,
        //     TemplateCode: resulteE4s[indexq].TemplateCode,
        //     PageId: resulteE4s[indexq].PageId,
        //     BookId: resulteE4s[indexq].BookId,
        //     Options: random_c,
        //     Question: resulteE4s[indexq].AudioUrl,
        //     // WordCh: resulteE2s[indexq].WordCh,
        //     // WordPy: resulteE2s[indexq].WordPy,
        //     answer: resulteE4s[indexq].CorrectCh,
        //     score: false,
        //   };
        //   tmpoptione4s.push(final);
        // });
        // setitems(tmpoptione4s);
        // setE4stems(tmpoptione4s);
        // console.log("this.itemlist", tmpoptione4s);
        // End E2s
        //Old Code
        // setLengthQue(3);
        //Logic E4ev1s
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      // console.log(json)
      if (json.StatusCode == 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  function handleChange(back) {
    // const myObject: MyObject = {
    //   id: 1,
    //   name: "My Object",
    //   type: "e5s",
    //   pagecouter: pagecouter,
    //   totalque: itemE5slist.length,
    //   back: back,
    // };
    // props.onObjectChange(myObject);
  }
  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  const options = {
    keyboard: true,
  };
  const onDataAvailable = (data) => {
    // Update your progress bar using the data.size property
    const progress = setProgress((prevProgress) => prevProgress + data.size);
  };
  // const { startRecording, stopRecording } = useMediaRecorder({ onDataAvailable });
  let { error, status, mediaBlob, stopRecording, startRecording } =
    useMediaRecorder({
      recordScreen: false,
      blobOptions: { type: "audio/webm" },
      mediaStreamConstraints: { audio: true, video: false },
      onDataAvailable,
    });
  const handleStartRecording = () => {
    setProgress(0);
    setApicall(0);
    setIsRecording(true); // Set isRecording to true when recording starts
    startRecording();
    console.log("Start");
  };
  const handleStopRecording = () => {
    debugger;
    stopRecording();
    setIsRecording(false); // Set isRecording to false when recording stops
    console.log(mediaBlob);
    console.log(error);
    console.log("Stop");
    // setE4EV1(false)
    // setE5EV1(false)
    // setE6EV1(true)
  };
  const handleSlideTouchEnd = (event: CustomEvent) => {
    console.log("Slide touch end", event.detail);
  };
  //dbaudio
  function handleCheckword() {
    debugger;
    const spanElement = document.querySelector(".dbaudio");
    if (spanElement) {
      console.log("sentence1", spanElement.textContent);
      if (spanElement.textContent) {
        // setSentence1('hello my testing')
        setSentence1(spanElement.textContent);
      }
      console.log("sentence2", sentence2);
    } else {
      console.log('No span element found with class "dbaudio"');
    }
  }
  function blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => {
        const base64String = reader.result as string;
        resolve(base64String.split(",")[1]);
      };
      reader.onerror = () => {
        reject("Failed to convert blob to base64");
      };
    });
  }
  const convertaudiototext = async (audiobasebase64: any) => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 3000,
    });
    let data = {
      base64String: audiobasebase64,
    };
    try {
      const response = await fetch(url + "/account/SpeechAudio", {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        body: JSON.stringify(data),
      });
      const json = await response.json();
      console.log(json);
      setmyanswer(json.result);
      // debugger
      if (json.statusCode == 1) {
        setSentence2("");
        setSentence2(json.result || "hello");
        handleCheckword();
        // status = 'idle'
      } else {
        // presenttost({
        //   message: json.Message,
        //   duration: 1500,
        //   position: 'bottom'
        // });
      }
      setApicall(1);
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const handleQuestionCheckClick = (
    items: any,
    index: any,
    indexq: any,
    type
  ) => {
    // console.log(type)
    //console.log('soundno ', items?.Question?.[0].soundno)
    //console.log('questionno', type)
    // if (items?.Question?.[0].soundno == type) {
    //   isSuccess = true
    //   isWrong = false
    //   setSuccessOption(isSuccess);
    //   setWrongOption(isWrong);
    //   setSelectedDiv(type)
    //   console.log('q1', isSuccess)
    //   if (!props.itemE6EV1[index].answer) {
    //     props.itemE6EV1[index].answer = type
    //     props.itemE6EV1[index].score = true
    //     tmpscore += tmpscore
    //   }
    // } else {
    //   isSuccess = false
    //   isWrong = true
    //   setRightAnsOption(true)
    //   setSuccessOption(isSuccess);
    //   setWrongOption(isWrong);
    //   setSelectedDiv(type)
    //   if (!props.itemE6EV1[index].answer) {
    //     props.itemE6EV1[index].answer = type
    //     props.itemE6EV1[index].score = false
    //   }
    // }
    // console.log('itemE6EV1', props.itemE6EV1)
    //console.log('isSuccess', isSuccess)
    //console.log('isWrong', isWrong)
  };
  const gotonextques = async (items: any, index: any, type) => {
    //1 for Understand and 2= dont understand
    console.log(items);
    // console.log(type)
    // console.log('soundno ', items?.Question?.[0].soundno)
    // console.log('questionno', type)
    // if (items?.Question?.[0].soundno == type) {
    //   isSuccess = true
    //   isWrong = false
    // } else {
    //   isSuccess = false
    //   isWrong = true
    // }
    // console.log(showResults)
    //console.log(itemlist)
    //console.log(itemlist[showResults - 1])
    //  let data = itemlist[showResults - 1];
    //debugger
    if (items) {
      // resetstate()
      setShowResults(index);
      // console.log('len ', showResults - 1)
      setLength(index);
      // ansques = data.QuestionId + ',' + type;
      // setQuestion([...quesdata, ansques]);
    }
  };
  useEffect(() => {
    if (mediaBlob) {
      present({
        message: "",
        cssClass: 'custom-spinner',
        duration: 3000,
      });
      debugger;
      //convertaudiototextlive(mediaBlob)
      blobToBase64(mediaBlob)
        .then((base64String) => {
          // console.log('base64String', base64String);
          if (status === "stopped" && isApicall == 0) {
            convertaudiototext(base64String);
            dismiss();
          }
          dismiss();
        })
        .catch((error) => {
          console.error(error);
          dismiss();
        });
      dismiss();
    }
    dismiss();
  }, [mediaBlob]);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowImage(true);
      // Calculate the index of the next slide
      if (mediaBlob === null && sentence2 === null) {
        const nextSlideIndex = activeSlideIndex + 1;
        setActiveSlideIndex(nextSlideIndex);
      }
      // Call the slideTo method to swipe to the next slide
      // slidesRef.current?.slideTo(nextSlideIndex);
    }, 3000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [activeSlideIndex, itemE5slist.length]);
  const handleSlideNextEnded = (event: CustomEvent<any>) => {
    console.log("Slide Next ended", event.detail);
    slidesRef.current?.getActiveIndex().then((index) => {
      console.log("index", index + 1);
      setLength(index + 1);
      console.log("length", itemE5slist.length);
    });
  };
  const handleSlidePrevEnded = (event: CustomEvent<any>) => {
    console.log("Slide Prev ended", event.detail);
    // setLength(length - 1)
    slidesRef.current?.getActiveIndex().then((index) => {
      console.log("index", index + 1);
      setLength(index - 1);
      console.log("length", itemE5slist.length);
    });
  };
  const handleSlideDidChanged = async (event: CustomEvent<any>) => {
    console.log("Slide Did ended", length);
    const swiper = await slidesRef.current?.getSwiper();
    if (swiper) {
      const swipeDirection = swiper.swipeDirection;
      const currentIndex = swiper.activeIndex;
      console.log("Swipe direction:", swipeDirection);
      if (swipeDirection == "prev") {
        //Back Login code
        setAnsSlideIndex(currentIndex);
        slidesRef.current?.getActiveIndex().then((index) => {
          console.log(index - 1);
          if (index - 1 === -1) {
            handleChange(1);
          }
        });
      } else {
        setAnsSlideIndex(currentIndex);
        slidesRef.current?.getActiveIndex().then((index) => {
          console.log("index", index + 1);
          //SaveExerciseQueAnswer(length);
          if (index + 1 == length) {
            console.log("Go next page");
            setLength(-1);
            // saveContent();
            handleChange(0);
          }
        });
      }
    }
  };


  let srcText = "";
  const test_main = (text, textpy) => {
    srcText = text;
    const characters1 = text.split("");
    const charPy = textpy.split(" ");
    debugger;
    const result = characters1.map((text, i) => ({
      Text: text,
      Piniyan: charPy[i] || null,
    }));
    // const fragments = result.map((word, index) => (
    //   <React.Fragment key={index}>
    //     <li className={index === 0 ? "active" : ""}>
    //       <span className={"ion-text-center dbaudio"} id="top-center">
    //         {word["Text"]}
    //       </span>
    //     </li>{" "}
    //   </React.Fragment>
    // ));
    const fragments = result.map((word, index) => (
      <React.Fragment key={index}>
        <li className={index === 0 ? "active" : ""}>
          <span className={"ion-text-center dbaudio"} id="top-center">
            {word["Text"]}
          </span>
          <p>{word["Piniyan"]}</p>
        </li>{" "}
      </React.Fragment>
    ));
    return fragments;
  };
  const test = (text, textpy, answer) => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 1000,
    });
    // const text123 = "hello";
    // const test = "請問您想";
    const characters1 = text.split("");
    const characters2 = answer.split("");
    //  const sentencepy = textpy.split("");
    const charPy = textpy.split(" ");
    const spChar = [
      "！",
      "(",
      "?",
      "!",
      "[",
      "，",
      "。",
      ";",
      "：",
      "”",
      "”",
      "'",
      "'",
      "？",
      "（",
      "）",
      "、",
      "]",
      ")",
    ];
    debugger;
    const result = characters1.map((text, i) => ({
      Text: text,
      Piniyan: charPy[i] || null,
      status: spChar.includes(text) ? true : characters2.includes(text),
    }));
    console.log(result);
    // score count start
    // const filteredChar1 = characters1.filter((char) => !spChar.includes(char));
    // const charLength: number = filteredChar1.length;
    // const charValue: number = 100 / charLength;
    // const rouCharValue: number = parseFloat(charValue.toFixed(0));
    // console.log(rouCharValue);
    // const filteredResult = filteredChar1.map((text, i) => ({
    //   Text: text,
    //   status: characters2.includes(text),
    // }));
    // const trueStatusLength: number = filteredResult.filter(
    //   (item) => item.status === true
    // ).length;
    // console.log(trueStatusLength);
    // const score: number = trueStatusLength * rouCharValue;
    // console.log(score);
    // tmscorefinal = score;
    // score count end
    dismiss();
    const fragments = result.map((word, index) => (
      <React.Fragment key={index}>
        <li className={index === 0 ? "active" : ""}>
          <span
            className={
              word["status"] === false
                ? "ion-text-center red"
                : "ion-text-center"
            }
            id="top-center">
            {word["Text"]}
          </span>
          <p className={word["status"] === false ? "wordpy red" : "wordpy"}>
            {word["Piniyan"]}
          </p>
        </li>{" "}
      </React.Fragment>
    ));
    return fragments;
  };
  // undefined
  const gobackpage = () => {
    // Check if historyss.location.state is defined
    if (historyss.location.state) {
      historyss.replace({
        pathname: "/tabs/home/Practiceresult",
        state: {
          themeArray: themeArray,
          themename: themename,
          themeId: themeId,
          lessonname: lessonname,
          index: indexs,
          lessonsId: lessonsId,
          levelId: levelId,
          level: level,
          levname: levname,
          backpage: historyss.location.state.backpage, // Access the backpage property safely
          pagename: "E5s",
          backpageValue: backpageValue,
        },
      });
    } else {
      // Handle the case when historyss.location.state is undefined
      console.error("historyss.location.state is undefined");
    }
  };
  return (
    <IonPage className="intro" placeholder={undefined}>
      <IonHeader className="ion-text-center dubbletitle" placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <div>
            <IonButtons slot="start" placeholder={undefined}>
              <IonButton color="primary" onClick={gobackpage} placeholder={undefined}>
                <IonIcon icon={chevronBack} placeholder={undefined} />
              </IonButton>
            </IonButtons>
            <IonTitle color="primary" className="font20 ion-no-padding" placeholder={undefined}>
              <span>
                {t("practicequestion.lesson")}{" "}
                {themeArray[indexs ? indexs : 0]?.number}:{" "}
                {t("practicequestion.practice")}
                <p className="ion-no-margin">{themename}</p>
              </span>
            </IonTitle>
            <IonButtons slot="end" placeholder={undefined}>
              {/* <IonButton onClick={openDrawer} color="primary">
                <IonIcon icon={ellipsisVertical} />
              </IonButton> */}
            </IonButtons>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className="listing" placeholder={undefined}>
        <IonSlides
          pager={false}
          scrollbar={true}
          options={{
            initialSlide: props.lastSlide === 1 ? itemE5slist.length - 1 : activeSlideIndex,
            options,
          }}
          onIonSlideTransitionEnd={handleSlideDidChanged}
          onIonSlideNextEnd={handleSlideNextEnded}
          onIonSlidePrevEnd={handleSlidePrevEnded}
          ref={slidesRef} placeholder={undefined}>
          {itemE5slist.map((items, index) => {
            return (
              <IonSlide placeholder={undefined}>
                {itemE5slist.map((itemse5, indexe5) => {
                  return (
                    <div
                      key={itemse5.EId}
                      style={{
                        display: showResults == indexe5 + 1 ? "block" : "none",
                      }}
                      className="audiorecord">
                      <IonList className="ion-no-padding bottomfix record" placeholder={undefined}>
                        <IonRow className="Practice ion-padding-horizontal" placeholder={undefined}>
                          <IonCol placeholder={undefined}>
                            <IonText placeholder={undefined}>{t("e5s.record_sentence")}</IonText>
                          </IonCol>
                          <IonCol
                            size="12"
                            className="test-audio ion-text-left whitebg" placeholder={undefined}>
                            <ReactAudioPlayer
                              className="String"
                              controls
                              controlsList="nodownload noplaybackrate">
                              <source
                                src={itemse5?.AudioUrl}
                                type="audio/mpeg"></source>
                            </ReactAudioPlayer>
                          </IonCol>
                        </IonRow>
                        <IonRow className="answer ion-padding-horizontal" placeholder={undefined}>
                          <IonCol size="12" placeholder={undefined}>
                            <IonCard className="select" placeholder={undefined}>
                              <IonCardContent className="Lessonpracticeaudio" placeholder={undefined}>
                                <ul className="lession-icon">
                                  {/* {!sentence2
                                    ? test_main(itemse5.WordCh, itemse5.WordPy)
                                    : ""}
                                  {sentence2
                                    ? test(itemse5.WordCh, itemse5.WordPy)
                                    : ""} */}
                                  {test(itemse5.WordCh, itemse5.WordPy,itemse5.Answer)}
                                </ul>
                              </IonCardContent>
                            </IonCard>
                          </IonCol>
                        </IonRow>
                      </IonList>
                      <IonList className="ion-text-center ion-padding-horizontal" placeholder={undefined}>
                        <IonRow placeholder={undefined}>
                          <IonCol size="12" placeholder={undefined}>
                            {showImage && (
                              <IonImg
                                className="slide"
                                src="./assets/images/swipe-left-right.gif" placeholder={undefined}                              />
                            )}
                          </IonCol>
                          <IonCol size="12" className="back-forward" placeholder={undefined}>
                            <div className="audio-record">
                              {/* {status == "stopped" ? (
                                <Player srcBlob={mediaBlob} />
                              ) : (
                                ""
                              )} */}
                              {/* {status} */}
                              {/* {status === 'stopped' || status === 'idle' ?
                                <span onClick={(e: any) => handleStartRecording()}><IonImg src="./assets/images/micCircle.svg" /></span> : ''}
                              {status == 'recording' ?
                                <span onClick={(e: any) => handleStopRecording()} ><IonImg src="./assets/images/stop.svg" /></span> : ''} */}
                              {/* <span><IonIcon icon={micCircleSharp} /></span> */}
                              {/* <span
                                onClick={(e: any) => {
                                  isRecording
                                    ? handleStopRecording()
                                    : handleStartRecording();
                                }}>
                                <IonImg
                                  src={`./assets/images/${
                                    isRecording ? "stop" : "micCircle"
                                  }.svg`}
                                />
                              </span> */}
                              {/* <p>{t("e5s.press_to_record")}</p> */}
                            </div>
                          </IonCol>
                          <IonCol size="12" placeholder={undefined}>
                            {/* <IonButton expand="block" onClick={() => gotonextques(itemse5, showResults + 1, 1)}>Next</IonButton> */}
                          </IonCol>
                        </IonRow>
                      </IonList>
                    </div>
                  );
                })}
              </IonSlide>
            );
          })}
        </IonSlides>
      </IonContent>
      <Drawer
        className="bottompopup skateInBottom"
        isVisible={isVisible}
        onClose={onClose}>
        <IonRow placeholder={undefined}>
          <IonList className="list" placeholder={undefined}>
            {/* <IonItem class="ion-no-padding playbook">
              <div>
                <IonImg src="./assets/images/play.png" />
              </div>
              <div className="details ion-padding-start">
                <h4>Play book</h4>
                <span>Autoplay book audio</span>
              </div>
            </IonItem> */}
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("e5s.drawer.lesson_language")}</IonLabel>
              <div className="right-space">
                <IonText color="primary" placeholder={undefined}>簡</IonText>
                <IonText placeholder={undefined}>繁</IonText>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("e5s.drawer.chinese_characters")}</IonLabel>
              <IonToggle checked slot="end" placeholder={undefined}></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("e5s.drawer.pinyin")}</IonLabel>
              <IonToggle checked slot="end" placeholder={undefined}></IonToggle>
            </IonItem>
            {/* <IonItem class="ion-no-padding">
              <IonLabel>Translation</IonLabel>
              <IonToggle slot="end"></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding">
              <IonLabel>Font size</IonLabel>
              <IonImg src="./assets/images/Fontdown.png" />
              <IonImg src="./assets/images/Fontup.png" />
            </IonItem> */}
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("e5s.drawer.playback_speed")}</IonLabel>
              <div className="leftright">
                <a href="#">&lt;</a> 1.0 <a href="#">&gt;</a>
              </div>
            </IonItem>
            {/* <IonItem class="ion-no-padding">
              <IonLabel>Bookmark</IonLabel>
              <IonIcon color='primary' icon={bookmark} />
            </IonItem> */}
          </IonList>
        </IonRow>
      </Drawer>
    </IonPage>
  );
};
export default React.memo(E5sResultDetails);
