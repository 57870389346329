import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonItem,
  IonLabel,
  IonToggle,
  IonContent,
  IonText,
  IonList,
  IonCol,
  IonRow,
  IonIcon,
  IonPage,
  IonTitle,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
} from "@ionic/react";
import "./Dialogue.scss";
import {
  chevronBack,
  ellipsisVertical,
  playBack,
  playForward,
} from "ionicons/icons";
import Drawer from "react-bottom-drawer";
import ReactAudioPlayer from "react-audio-player";
import { CONFIG } from "../App";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface OwnProps extends RouteComponentProps {
  id: string;
}

interface DialogueProps extends OwnProps {}

const Dialogue: React.FC<DialogueProps> = ({ history }) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const openDrawer = React.useCallback(() => setIsVisible(true), []);

  let url: string = CONFIG.API_ENDPOINT; //live

  const { t } = useTranslation();

  // const params = useParams<{id: string}>();
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any[]>([]);

  const historyss = useHistory<{
    dialogueId: string;
    themeId: string;
    themename: string;
    levelId: string;
    level: string;
    levname: string;
    backpage: string;
    title: string;
  }>();
  const dialogueId = historyss.location.state
    ? historyss.location.state.dialogueId
    : "";
  const themeIddb = historyss.location.state
    ? historyss.location.state.themeId
    : "";
  const themename = historyss.location.state
    ? historyss.location.state.themename
    : "";
  debugger;
  const levelId = historyss.location.state
    ? historyss.location.state.levelId
    : "";
  const level = historyss.location.state ? historyss.location.state.level : "";
  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";
  const backpage = historyss.location.state
    ? historyss.location.state.backpage
    : "";

  const title = historyss.location.state ? historyss.location.state.title : "";
  debugger;
  const [index, setIndex] = useState(1);
  const [displayedItems, setDisplayedItems] = useState<any[]>([]);
  const ionListRef = useRef<HTMLIonListElement>(null);

  let token = "";
  let resfreshtoken = "";
  useIonViewWillEnter(() => {
    console.log("useIonViewWillEnter");

    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }

    let pagecode1 = "Practice";
    localStorage.setItem("pagecode1", JSON.stringify(pagecode1));

    let pagecode2 = "Read again";
    localStorage.setItem("pagecode2", JSON.stringify(pagecode2));
    // getdialogue()
  });

  const scrollToBottom = () => {
    if (ionListRef.current) {
      const scrollHeight = ionListRef.current.scrollHeight;
      const clientHeight = ionListRef.current.clientHeight;
      ionListRef.current.scrollTop = scrollHeight - clientHeight;
    }
  };

  useEffect(() => {
    // Scroll to bottom after a short delay to ensure the DOM has updated
    const scrollTimeout = setTimeout(() => {
      scrollToBottom();
    }, 100); // You can adjust the delay as needed

    // Clear the timeout if the component is unmounted or when displayedItems change
    return () => {
      clearTimeout(scrollTimeout);
    };
  }, [displayedItems]); // Trigger the effect whenever displayedItems changes

  const handleClickNext = () => {
    if (index < itemlist.length) {
      // setDisplayedItems([...displayedItems, itemlist[index]]);
      setDisplayedItems((prevItems) => [...prevItems, itemlist[index]]);
      setIndex((prevIndex) => prevIndex + 1);
      scrollToBottom();
    }

    if (index === itemlist.length) {
      history.replace({
        pathname: "/tabs/home/Dialoguedone",
        state: {
          themeId: themeIddb,
          themename: themename,
          levelId: levelId,
          level: level,
          levname: levname,
          dialogueId: dialogueId,
        },
      });
    }
  };

  const handleClickPrev = () => {
    if (index > 1) {
      setDisplayedItems((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems.splice(-1, 1); // Remove the last item from the array
        return updatedItems;
      });
      setIndex((prevIndex) => prevIndex - 1);
    }
  };

  const textset_tc = (data_tc, data_py) => {
    //console.log(data_tc)
    //debugger
    const html_tc = data_tc;
    const html_py = data_py;

    const range = document.createRange();
    const fragment_tc = range.createContextualFragment(html_tc);
    const fragment_py = range.createContextualFragment(html_py);

    const textreturn_tc = fragment_tc.textContent;
    const textreturn_py = fragment_py.textContent;

    const text_tc = textreturn_tc;
    const text_py = textreturn_py;
    const fragmentsa: any = [];
    if (text_tc && text_py) {
      const outputArray = text_tc.split(/(?![，。;：””''！？（）、])/g);

      const words_tc = outputArray;
      const words_py = text_py.split(" ");

      if (words_tc.length === words_py.length) {
        for (let i = 0; i < words_tc.length; i++) {
          const wordtc = words_tc[i] as never;
          let data = {
            words_tc: wordtc,
            words_py: "",
          };
          fragmentsa.push(data);
        }

        for (let i = 0; i < words_py.length; i++) {
          const wordpy = words_py[i] as never;
          if (wordpy !== "" && wordpy !== " " && wordpy !== undefined) {
            fragmentsa[i]["words_py"] = wordpy ? wordpy : "";
          } else {
            fragmentsa[i]["words_py"] = "";
          }
        }

        const fragments = fragmentsa.map((word, index) => (
          <React.Fragment key={index}>
            <li>
              <span className="ion-text-center" id="top-center">
                {word["words_tc"]}
              </span>
              <p className="wordpy">{word["words_py"]}</p>
            </li>{" "}
          </React.Fragment>
        ));

        //const fragments = ''

        return fragments;
      } else {
        const arr1 = words_tc;
        const arr2 = words_py;

        const maxLength = Math.max(arr1.length, arr2.length);
        const paddedArr1 = arr1.concat(Array(maxLength - arr1.length).fill(""));
        const paddedArr2 = arr2.concat(Array(maxLength - arr2.length).fill(""));

        for (let i = 0; i < paddedArr1.length; i++) {
          const wordtc = paddedArr1[i] as never;
          let data = {
            words_tc: wordtc,
            words_py: "",
          };
          fragmentsa.push(data);
        }

        for (let i = 0; i < paddedArr2.length; i++) {
          const wordpy = paddedArr2[i] as never;
          if (wordpy !== "" && wordpy !== " " && wordpy !== undefined) {
            fragmentsa[i]["words_py"] = wordpy ? wordpy : "";
          } else {
            fragmentsa[i]["words_py"] = "";
          }
        }

        const fragments = fragmentsa.map((word, index) => (
          <React.Fragment key={index}>
            <li>
              <span className="ion-text-center" id="top-center">
                {word["words_tc"]}
              </span>
              <p className="wordpy">{word["words_py"]}</p>
            </li>{" "}
          </React.Fragment>
        ));

        //const fragments = ''

        return fragments;
      }
    } else if (text_tc) {
      const words_tc = text_tc.split("");

      for (let i = 0; i < words_tc.length; i++) {
        const word = words_tc[i] as never;
        let data = {
          words_tc: word,
          words_py: "",
        };
        fragmentsa.push(data);
      }

      const fragments = fragmentsa.map((word, index) => (
        <React.Fragment key={index}>
          <li>
            <span className="ion-text-center" id="top-center">
              {word["words_tc"]}
            </span>
          </li>{" "}
        </React.Fragment>
      ));

      return fragments;
    } else if (text_py) {
      const words_py = text_py.split(" ");
    }
    // console.log('spans', fragments)
    //const htmls = { __html: spans.join('') };
  };

  const back = () => {
    history.replace({
      pathname: "/tabs/home/Themesports",
      state: {
        themeId: themeIddb,
        themename: themename,
        levelId: levelId,
        level: level,
        levname: levname,
      },
    });
  };
  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);

  useEffect(() => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 1000,
    });

    console.log("dialogueId. ", dialogueId);

    const fetchData = async () => {
      try {
        const response = await fetch(
          url + "/Book/GetDialogueQueAns?dialogueId=" + dialogueId,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            method: "GET",
          }
        );

        const json = await response.json();

        console.log(json);

        dismiss();

        if (json.StatusCode === 1) {
          setitems(json.Result);
          setDisplayedItems([json.Result[0]]);

          handleClickNext();
        } else if (json.StatusCode === 2) {
          // refreshtoken()
        } else {
          presenttost({
            message: json.Message,
            duration: 1500,
            position: "bottom",
          });
        }
      } catch (error) {
        console.error(error);
        dismiss();
      }
    };

    fetchData();
  }, []);

  return (
    <IonPage className="intro" placeholder={undefined}>
      <IonHeader className="ion-text-center dubbletitle" placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <div>
            <IonButtons slot="start" placeholder={undefined}>
              <IonButton color="primary" onClick={back} placeholder={undefined}>
                <IonIcon icon={chevronBack} placeholder={undefined} />
              </IonButton>
            </IonButtons>
            <IonTitle color="primary" className="font20 ion-no-padding" placeholder={undefined}>
              <span>
                {t("dialogue.dialogue")}<p className="ion-no-margin">{title}</p>
              </span>
            </IonTitle>
            <IonButtons slot="end" placeholder={undefined}>
              <IonButton onClick={openDrawer} color="primary" placeholder={undefined}>
                <IonIcon icon={ellipsisVertical} placeholder={undefined} />
              </IonButton>
            </IonButtons>
          </div>
        </IonToolbar>
      </IonHeader>

      <IonContent className="listing" placeholder={undefined}>
        <IonList
          ref={ionListRef}
          className="leftrightspace ion-padding-horizontal dialogue" placeholder={undefined}>
          <IonRow placeholder={undefined}>
            {itemlist && itemlist.length > 0 ? (
              <>
                {displayedItems.map((items, index) => {
                  return (
                    <IonCol
                      className={items.Type === "B" ? "play" : ""}
                      size="12"
                      key={index} placeholder={undefined}>
                      <div className="user">
                        {items.Type === "A" ? (
                          <div className="usercircle">{items.Type}</div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="dialoguebox">
                        <ul className="lession-icon">
                          {/* <li><span>你</span>Nǐ</li>
                      <li><span>们</span>mén</li>
                      <li><span>做</span>zuò</li>
                      <li><span>做</span>dàn</li>
                      <li><span>蛋</span>gāo</li> */}
                          {textset_tc(items.Title_Chinese, items.Title_Pinyin)}
                        </ul>
                        <p>{items.Title_English}</p>
                        <div className="test-audio ion-text-left player">
                          <ReactAudioPlayer
                            className="String"
                            controls
                            controlsList="nodownload noplaybackrate"
                          >
                            <source
                              src="/assets/audio.mp3"
                              type="audio/mpeg"
                            ></source>
                          </ReactAudioPlayer>
                        </div>
                      </div>
                      <div className="user">
                        {items.Type === "B" ? (
                          <div className="usercircle">{items.Type}</div>
                        ) : (
                          ""
                        )}
                      </div>
                    </IonCol>
                  );
                })}
              </>
            ) : (
              <p></p>
            )}
            {/* <IonCol size='12' className='play'>
              <div className='user'>
                <div className='usercircle'></div>
              </div>
              <div className='dialoguebox'>
                <ul className='lession-icon'>
                  <li><span>你</span>Nǐ</li>
                  <li><span>们</span>mén</li>
                  <li><span>做</span>zuò</li>
                  <li><span>做</span>dàn</li>
                  <li><span>蛋</span>gāo</li>
                </ul>
                <p>Hello, Chen laoshi!</p>
                <div className='test-audio ion-text-left player'>
                  <ReactAudioPlayer className='String' controls controlsList="nodownload noplaybackrate">
                    <source src="/assets/audio.mp3" type="audio/mpeg"></source>
                  </ReactAudioPlayer>
                </div>
              </div>
              <div className='user'>
              </div>
            </IonCol> */}
          </IonRow>
        </IonList>
        <IonList className="ion-text-center" placeholder={undefined}>
          <IonRow placeholder={undefined}>
            <IonCol size="12" className="back-forward" placeholder={undefined}>
              <div>
                {index === 1 ? (
                  <a>
                    <IonIcon icon={playBack} placeholder={undefined} />
                  </a>
                ) : (
                  <a onClick={() => handleClickPrev()} className="select-arrow">
                    <IonIcon icon={playBack} placeholder={undefined} />
                  </a>
                )}

                <a onClick={() => handleClickNext()} className="select-arrow">
                  <IonIcon icon={playForward} placeholder={undefined} />
                </a>
                {/* <a href='tabs/home/Dialoguedone' className=''><IonIcon icon={playBack} /></a>
                <a href='tabs/home/Dialoguedone' className='select-arrow'><IonIcon icon={playForward} /></a> */}
              </div>
            </IonCol>
          </IonRow>
        </IonList>
      </IonContent>

      <Drawer
        className="bottompopup skateInBottom"
        isVisible={isVisible}
        onClose={onClose}
      >
        <IonRow placeholder={undefined}>
          <IonList className="list" placeholder={undefined}>
            {/* <IonItem class="ion-no-padding playbook">
            <div>
              <IonImg src="./assets/images/play.png" />
            </div>
            <div className="details ion-padding-start">
              <h4>Play book</h4>
              <span>Autoplay book audio</span>
            </div>
          </IonItem> */}
            {/* <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("dialogue.drawer.lesson_language")}</IonLabel>
              <div className="right-space">
                <IonText color="primary" placeholder={undefined}>簡</IonText>
                <IonText placeholder={undefined}>繁</IonText>
              </div>
            </IonItem> */}
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("dialogue.drawer.chinese_characters")}</IonLabel>
              <IonToggle checked slot="end" placeholder={undefined}></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("dialogue.drawer.pinyin")}</IonLabel>
              <IonToggle checked slot="end" placeholder={undefined}></IonToggle>
            </IonItem>
            {/* <IonItem class="ion-no-padding">
            <IonLabel>Translation</IonLabel>
            <IonToggle slot="end"></IonToggle>
          </IonItem>
          <IonItem class="ion-no-padding">
            <IonLabel>Font size</IonLabel>
            <IonImg src="./assets/images/Fontdown.png" />
            <IonImg src="./assets/images/Fontup.png" />
          </IonItem> */}
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("dialogue.drawer.playback_speed")}</IonLabel>
              <div className="leftright">
                <a href="#">&lt;</a> 1.0 <a href="#">&gt;</a>
              </div>
            </IonItem>
            {/* <IonItem class="ion-no-padding">
            <IonLabel>Bookmark</IonLabel>
            <IonIcon color='primary' icon={bookmark} />
          </IonItem> */}
          </IonList>
        </IonRow>
      </Drawer>
    </IonPage>
  );
};

export default React.memo(Dialogue);
