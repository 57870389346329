import React, { useEffect, useState } from 'react';
import { IonHeader, IonToolbar, IonButtons, IonContent, IonPage, IonTitle, IonImg, IonIcon, IonList, IonCol, IonText, IonCard, IonCardContent, IonButton, isPlatform } from '@ionic/react';
import './Subscribe.scss';
import { chevronBack, arrowForward, close } from 'ionicons/icons';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
// import { InAppPurchase2 as iap, IAPProduct, InAppPurchase2 } from "@ionic-native/in-app-purchase-2";


interface SubscribeProps { }
// const registerItems = () => {
//   iap.register({
//     id: "Subscription ID",
//     type: iap.PAID_SUBSCRIPTION,
//   });

//   iap.refresh();
// };
// const handlePurchase = async (id) => {
//   iap.order("Subscription ID").then(
//     (p) => {
//       // Processing purchase
//     },
//     (e) => {
//       // Purchase error
//     }
//   );
// };
// const handleEvents = () => {
//   iap.when("product").cancelled(() => {
//     // The user cancelled the purchase
//   });

//   iap.when("product").error(() => {
//     // An error occured
//   });

//   iap.when("product").initiated(() => {
//     // The user clicked on the purchase button and the purchase is now process
//   });

//   iap.when("product").approved((product) => {
//     // The purchase was a success
//     product.verify();
//   }).verified((product) => {
//     // Purchase verified
//     product.finish();
//   });
// };



const Subscribe: React.FC<SubscribeProps> = () => {

  const { t } = useTranslation();

  // const [productPrice, setPrice] = useState('')
  // const [product, setProduct] = useState([]) as any
  // const productId = 'com.test.test'
  // useEffect(() => {
  //   const init = async () => {
  //     await initInAppPurchase();
  //   }
  //   init();

  // }, []);
  // //if on an ios or android device, then get product info 
  // const initInAppPurchase = () => {
  //   if ((isPlatform('ios')) || (isPlatform('android'))) {
  //     //Test 1
  //     InAppPurchase2.register({
  //       id: 'your_subscription_id',
  //       type: 'subs'
  //     });
  //     InAppPurchase2.when(productId).approved((product) => {
  //       // Handle the approved subscription
  //     });
    
  //     InAppPurchase2.when(productId).updated((product) => {
  //       // Handle the updated subscription
  //     });
    
  //     InAppPurchase2.when(productId).cancelled((product) => {
  //       // Handle the cancelled subscription
  //     });
    
  //     InAppPurchase2.when(productId).owned((product) => {
  //       // Handle the owned subscription
  //     });
    
  //     InAppPurchase2.when(productId).error((error) => {
  //       // Handle any errors
  //     });

  //     //TEst 2
  //     iap.verbosity = iap.DEBUG;

  //     iap.register({
  //       id: "com.test.test",
  //       alias: "Test",
  //       type: iap.NON_CONSUMABLE
  //     });

  //     iap.ready(() => {
  //       let product = iap.get('Test');
  //       setPrice(product.price)
  //       setProduct(product)
  //     })

  //     iap.refresh();
  //   }
  // }
  // //if user clicks purchase button 
  // const purchaseProduct = () => {
  //   if (product.owned) {
  //     alert('Product already owned, click restore button instead!')
  //   } else {
  //     iap.order('Test').then(() => {
  //       iap.when("com.test.test").approved((p: IAPProduct) => {
  //         //store product 
  //         p.verify();
  //         p.finish();
  //       });
  //     })
  //     iap.refresh();
  //   }
  // }

  // //if user clicks retore or promo code button 
  // const restore = () => {
  //   iap.when("com.test.test").owned((p: IAPProduct) => {
  //     if (product.owned) {
  //       //store product 
  //     } else {
  //       alert("You have not purchased this product before.")
  //     }
  //   });
  //   iap.refresh();
  // }

  // const purchaseSubscription = async () => {
  //   try {
  //     const purchase = await InAppPurchase2.order('your_subscription_id');
  //     // Handle the purchase order result
  //   } catch (error) {
  //     // Handle the purchase error
  //   }
  // };

  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <div className='close'><IonIcon onClick={onClose} icon={close} placeholder={undefined} /></div>
            <div className='icon'><IonImg className='select' src="./assets/images/bigmessage.png" alt="" placeholder={undefined} /></div>
            <p className='popuppadding'>{t("subscribe.submit.cnfrm_sub")}</p>
            <div className='react-confirm-alert-button-group'>
              <IonButton className="alertcancel w-150" placeholder={undefined}>{t("subscribe.submit.subscribe_btn")}</IonButton>
            </div>
          </div>
        );
      }
      /*confirmAlert({
        title: 'Confirm to submit',
        message: 'You will not get your suggested level if you skip the test, are you sure?',
        buttons: [
          {
            label: 'Yes',
           // onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
           // onClick: () => alert('Click No')
          }
        ]*/
    });
  };
  const gotoskip = async () => {
    submit()
    // await setHasSeenTutorial(true);
    // await setMenuEnabled(true);
    //history.push('/pretesttwo', { direction: 'none' });

  };
  return (
    <IonPage className='intro' placeholder={undefined}>
      <IonHeader className='ion-text-center' placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <IonButtons slot="start" placeholder={undefined}>
            <IonButton href='/tabs/profile' color='primary' placeholder={undefined}>
              <IonIcon icon={chevronBack} placeholder={undefined} />
            </IonButton>
          </IonButtons>
          <IonTitle color='primary' className='font20 ion-no-padding' placeholder={undefined}><span>{t("subscribe.subscribe")}</span></IonTitle>
          <IonButtons slot="end" placeholder={undefined}>
            {/* <IonButton color='success'>
              <IonIcon icon={ellipsisVertical} />
            </IonButton> */}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='listing' placeholder={undefined}>
        <IonList className="ion-padding-horizontal adjust Subscription" placeholder={undefined}>
          <IonCol placeholder={undefined}>
            <IonText placeholder={undefined}>{t("subscribe.mmx_pro")}</IonText>
          </IonCol>
          <IonCard className='subscription' placeholder={undefined}>
            <IonCardContent class="ion-no-padding" placeholder={undefined}>
              <ul className='icon'>
                <li><IonImg src="./assets/images/arrowIcon.png" alt="" placeholder={undefined} />{t("subscribe.unlimited_access")}</li>
                <li><IonImg src="./assets/images/arrowIcon.png" alt="" placeholder={undefined} />{t("subscribe.technical_support")}</li>
                <li><IonImg src="./assets/images/arrowIcon.png" alt="" placeholder={undefined} />{t("subscribe.what")}</li>
              </ul>
            </IonCardContent>
          </IonCard>

          <IonCol placeholder={undefined}>
            <IonText placeholder={undefined}>{t("subscribe.pricing")}</IonText>
          </IonCol>
          <IonCard className='subscription' placeholder={undefined}>
            <IonCardContent class="ion-no-padding" placeholder={undefined}>
              <ul className='list'>
                <li>{t("subscribe.monthly_billing")}</li>
                <li>{t("subscribe.annually_billed")}</li>
              </ul>
              <IonButton onClick={gotoskip} placeholder={undefined}>{t("subscribe.subscribe_now_btn")}<IonIcon className='ion-float-right' icon={arrowForward} placeholder={undefined} /></IonButton>
            </IonCardContent>
          </IonCard>
          {/* <IonCard className="homeCards">
            <IonButton onClick={purchaseProduct}>Buy for {productPrice}</IonButton>
            <IonButton onClick={restore}>Restore</IonButton>
            <IonButton onClick={restore}>Promo code</IonButton>
          </IonCard> */}
        </IonList>
      </IonContent>

    </IonPage>
  );
};

export default React.memo(Subscribe);