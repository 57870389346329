import React from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonCard,
  IonImg,
  IonCardContent,
  IonList,
  IonCol,
  IonItem,
  IonLabel,
  IonRow,
  IonIcon,
  IonPage,
  IonTitle,
  useIonToast,
  useIonLoading,
  useIonViewWillEnter,
} from "@ionic/react";
import "./Dialogueresult.scss";
import { chevronBack } from "ionicons/icons";
import Drawer from "react-bottom-drawer";

import { Share } from "@capacitor/share";
import { Directory, Filesystem } from "@capacitor/filesystem";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Capacitor } from "@capacitor/core";
import { RouteComponentProps, useHistory } from "react-router";
import { CONFIG } from "../App";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface OwnProps extends RouteComponentProps {}

interface DialogueresultProps extends OwnProps {}

const Dialogueresult: React.FC<DialogueresultProps> = ({ history }) => {

  const { t } = useTranslation();

  const themeData: any = localStorage.getItem("itemlist");
 // const tDataCon = JSON.parse(themeData);
  const historyss = useHistory<{
    themeArray:any;
    themeId: string;
    lessonsId: string;
    index: string;
    lessonname: string;
    themename: string;
    levelId: string;
    level: string;
    levname: string;
    bookId: string;
    backpage: string;
    title: string;
    dialogueId: string;
    role: string;
    allrole: string;
    rolename: string;
    videoUrl: string;
  }>();
  const themeArray = historyss.location.state
  ? historyss.location.state.themeArray
  : "";
  console.log(themeArray);
  
  const themeId = historyss.location.state
    ? historyss.location.state.themeId
    : "";
  const lessonsId = historyss.location.state
    ? historyss.location.state.lessonsId
    : "";
  const index = historyss.location.state ? historyss.location.state.index : "";
  const lessonname = historyss.location.state
    ? historyss.location.state.lessonname
    : "";
  const dialogueId = historyss.location.state
    ? historyss.location.state.dialogueId
    : "";

  const bookId = historyss.location.state
    ? historyss.location.state.bookId
    : "";

  const videoUrl = historyss.location.state
    ? historyss.location.state.videoUrl
    : "";
  const themename = historyss.location.state
    ? historyss.location.state.themename
    : "";
  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";
  const levelId = historyss.location.state
    ? historyss.location.state.levelId
    : "";
  const level = historyss.location.state ? historyss.location.state.level : "";
  const backpage = historyss.location.state
    ? historyss.location.state.backpage
    : "";
  const title = historyss.location.state ? historyss.location.state.title : "";
  const role = historyss.location.state ? historyss.location.state.role : "";
  const allrole = historyss.location.state
    ? historyss.location.state.allrole
    : "";
  const rolename = historyss.location.state
    ? historyss.location.state.rolename
    : "";

  const [isVisible, setIsVisible] = React.useState(false);
  const openDrawer = React.useCallback(() => setIsVisible(true), []);
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [FileToaster] = useIonToast();
  const [itemprofilelist, setprofileitems] = React.useState<any>({});

  const [itemresultlist, setresultitems] = React.useState<any[]>([]);
  const [RoleAslist, setRoleAs] = React.useState<any[]>([]);
  const [RoleBslist, setRoleBs] = React.useState<any[]>([]);

  let url: string = CONFIG.API_ENDPOINT; //live
  let token = "";
  let resfreshtoken = "";

  useIonViewWillEnter(() => {
    console.log(role);
    console.log(rolename);
    console.log(allrole);
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    GetResult();
    GetProfie();
  });

  const onClose = React.useCallback(() => {
    setIsVisible(false);
    // console.log("Close");
  }, []);

  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      console.log(json);

      if (json.StatusCode === 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const GetResult = async () => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 1000,
    });

    try {
      const response = await fetch(
        url + "/Book/GetDialogueResult?dialogueId=" + dialogueId,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        }
      );

      const json = await response.json();
      console.log("GetDialogueResult", json);
      debugger;

      dismiss();

      if (json.StatusCode === 1) {
        setresultitems(json.Result);
        //debugger
        setRoleAs(json.Result.RoleAs);
        setRoleBs(json.Result.RoleBs);
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const GetProfie = async () => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 1000,
    });

    try {
      const response = await fetch(url + "/User/GetProfile", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      const json = await response.json();
      console.log("Profice", json);

      dismiss();

      if (json.StatusCode === 1) {
        setprofileitems(json.Result);
        //debugger
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  // const shareButton = async () => {
  //   FileToaster({
  //     message: 'Which format do you want to share?',
  //     duration: 10000,
  //     position: 'top',
  //     layout: 'stacked',
  //     cssClass: 'custom-toast',
  //     buttons: [
  //       {
  //         text: 'Share Text',
  //         handler: shareGenerateTXT
  //       }, {
  //         text: 'Share Image',
  //         handler: shareGenerateIMG
  //       },
  //     ],
  //   });
  //   onClose()
  // }

  // const shareGenerateTXT = async () => {
  //   const div = document.getElementById('content') as HTMLElement;
  //   const textContent = div ? (div.textContent || div.innerText || '') : '';
  //   console.log(textContent);
  //   const formattedText = textContent.replace(/(%|\/0|:|ing)/g, '$1\n');
  //   console.log(formattedText);
  //   try {
  //     await Share.share({
  //       title: 'Share Result',
  //       text: formattedText,
  //       dialogTitle: 'Share via',
  //     });
  //   } catch (error) {
  //     console.error('Error sharing:', error);
  //   }
  // };

  const shareGenerateIMG = async () => {
    const hiddenContent = document.getElementById("hidden-content");
    if (hiddenContent) {
      hiddenContent.classList.remove("hidden-content");
      const element = document.getElementById("content") as HTMLElement;
      if (element) {
        html2canvas(element).then(async (canvas) => {
          const imageDataUrl = canvas.toDataURL();
          await Filesystem.requestPermissions();
          const imagePath = await Filesystem.writeFile({
            data: imageDataUrl,
            directory: Directory.Cache,
            path: "Result.png",
          });
          // console.log("Image saved to:", imagePath);
          let imageResult = await Filesystem.getUri({
            directory: Directory.Cache,
            path: "Result.png",
          });
          let imageLink = Capacitor.convertFileSrc(imageResult.uri);
          // console.log(imageLink);
          Share.share({
            title: "Result",
            text: "Result",
            url: imageResult.uri,
          })
            .then(() => console.log("Successful share"))
            .catch((error) => console.log("Error sharing ::: ", error));
        });
        onClose();
      } else {
        console.error("Element not found. Make sure the selector is correct.");
      }
    } else {
      console.error("Element not found. Make sure the selector is correct.");
    }
  };

  const generateIMG = async () => {
    const hiddenContent = document.getElementById("hidden-content");
    if (hiddenContent) {
      hiddenContent.classList.remove("hidden-content");
      const element = document.getElementById("content") as HTMLElement;
      if (element) {
        html2canvas(element).then(async (canvas) => {
          const imageDataUrl = canvas.toDataURL();
          // console.log(imageDataUrl);
          await Filesystem.requestPermissions();
          const imagePath = await Filesystem.writeFile({
            data: imageDataUrl,
            directory: Directory.ExternalStorage,
            path: "Download/Result.png",
          });
          // console.log("Image saved to:", imagePath);
          if (imagePath !== null) {
            FileToaster({
              message:
                "Image save successfully at Location:Download/Result.png",
              duration: 10000,
              position: "top",
              cssClass: "custom-toast",
              buttons: [{ text: "Ok", handler: () => onClose() }],
            });
          }
        });
        onClose();
      } else {
        console.error("Element not found. Make sure the selector is correct.");
      }
    } else {
      console.error("Element not found. Make sure the selector is correct.");
    }
  };

  const generatePDF = async () => {
    const hiddenContent = document.getElementById("hidden-content");
    if (hiddenContent) {
      hiddenContent.classList.remove("hidden-content");
      const element = document.getElementById("content") as HTMLElement;
      if (element) {
        html2canvas(element).then(async (canvas) => {
          const imgWidth = canvas.width;
          const imgHeight = canvas.height;
          const doc = new jsPDF({ unit: "px", format: [imgWidth, imgHeight] });
          const imageDataUrl = canvas.toDataURL();
          doc.addImage(imageDataUrl, "PNG", 0, 0, imgWidth, imgHeight);
          await Filesystem.requestPermissions();
          const filePath = await Filesystem.writeFile({
            path: "Download/Result.pdf",
            data: doc.output("datauristring"),
            directory: Directory.ExternalStorage,
          });
          // console.log("PDF saved to:", filePath);
          if (filePath !== null) {
            FileToaster({
              message:
                "PDF saved successfully at Location: Download/Result.pdf",
              duration: 10000,
              position: "top",
              cssClass: "custom-toast",
              buttons: [{ text: "Ok", handler: () => onClose() }],
            });
          }
        });
        onClose();
      } else {
        console.error("Element not found. Make sure the selector is correct.");
      }
    } else {
      console.error("Element not found. Make sure the selector is correct.");
    }
  };

  const textset_tc = (data_tc, data_py) => {
    debugger;
    //console.log(data_tc)
    //debugger
    const html_tc = data_tc;
    const html_py = data_py;

    const range = document.createRange();
    const fragment_tc = range.createContextualFragment(html_tc);
    const fragment_py = range.createContextualFragment(html_py);

    const textreturn_tc = fragment_tc.textContent;
    const textreturn_py = fragment_py.textContent;

    const text_tc = textreturn_tc;
    const text_py = textreturn_py;
    const fragmentsa: any = [];
    // console.log('text', text_tc)
    if (text_tc && text_py) {
      //console.log('words_tc_tmp',words_tc_tmp)
      // let words_tc =  text_tc.split('');

      const inputString = "我说英语和印地语，你呢？";
      //const outputArray1 = inputString.split(/(?!，)/g);
      //const outputArray = text_tc.split(/(?![？，。])/g);
      const outputArray = text_tc.split(/(?![，。;：””''！？（）、])/g);

      const words_tc = outputArray;
      const words_py = text_py.split(" ");

      // console.log(outputArray);
      // console.log(words_py);
      // let words_tc: any = text_tc.replace(/(?!^)(?![。，]$)/gu, ' ').split(' ');
      //  console.log('words_tc - words_py', words_tc.length + ' - ' + words_py.length)

      if (words_tc.length === words_py.length) {
        for (let i = 0; i < words_tc.length; i++) {
          const wordtc = words_tc[i] as never;
          let data = {
            words_tc: wordtc,
            words_py: "",
          };
          fragmentsa.push(data);
        }

        for (let i = 0; i < words_py.length; i++) {
          const wordpy = words_py[i] as never;
          if (wordpy !== "" && wordpy !== " " && wordpy !== undefined) {
            fragmentsa[i]["words_py"] = wordpy ? wordpy : "";
          } else {
            fragmentsa[i]["words_py"] = "";
          }
        }

        const fragments = fragmentsa.map((word, index) => (
          <React.Fragment key={index}>
            <li>
              <span className="ion-text-center" id="top-center">
                {word["words_tc"]}
              </span>
              <p className="wordpy">{word["words_py"]}</p>
            </li>{" "}
          </React.Fragment>
        ));

        //const fragments = ''

        return fragments;
      } else {
        const arr1 = words_tc;
        const arr2 = words_py;

        const maxLength = Math.max(arr1.length, arr2.length);
        const paddedArr1 = arr1.concat(Array(maxLength - arr1.length).fill(""));
        const paddedArr2 = arr2.concat(Array(maxLength - arr2.length).fill(""));

        for (let i = 0; i < paddedArr1.length; i++) {
          const wordtc = paddedArr1[i] as never;
          let data = {
            words_tc: wordtc,
            words_py: "",
          };
          fragmentsa.push(data);
        }

        for (let i = 0; i < paddedArr2.length; i++) {
          const wordpy = paddedArr2[i] as never;
          if (wordpy !== "" && wordpy !== " " && wordpy !== undefined) {
            fragmentsa[i]["words_py"] = wordpy ? wordpy : "";
          } else {
            fragmentsa[i]["words_py"] = "";
          }
        }

        const fragments = fragmentsa.map((word, index) => (
          <React.Fragment key={index}>
            <li>
              <span className="ion-text-center" id="top-center">
                {word["words_tc"]}
              </span>
              <p className="wordpy">{word["words_py"]}</p>
            </li>{" "}
          </React.Fragment>
        ));

        //const fragments = ''

        return fragments;
      }
    } else if (text_tc) {
      const words_tc = text_tc.split("");

      for (let i = 0; i < words_tc.length; i++) {
        const word = words_tc[i] as never;
        let data = {
          words_tc: word,
          words_py: "",
        };
        fragmentsa.push(data);
      }

      const fragments = fragmentsa.map((word, index) => (
        <React.Fragment key={index}>
          <li>
            <span className="ion-text-center" id="top-center">
              {word["words_tc"]}
            </span>
          </li>{" "}
        </React.Fragment>
      ));

      return fragments;
    } else if (text_py) {
      const words_py = text_py.split(" ");
    }
    // console.log('spans', fragments)
    //const htmls = { __html: spans.join('') };
  };

  const test = (ContentTc, ContentPy, Answer) => {
    const sentence1 = ContentTc;
    const sentence2 = Answer ? Answer : "";
    const sentencepy = ContentPy;

    const characters1 = sentence1.split("");
    const characters2 = sentence2.split("");
    const charPy = sentencepy.split(" ");

    const spChar = [
      "！",
      "(",
      "?",
      "!",
      "[",
      "，",
      "。",
      ";",
      "：",
      "”",
      "”",
      "'",
      "'",
      "？",
      "（",
      "）",
      "、",
      "]",
      ")",
    ];

    charPy.forEach((py, i) => {
      if (spChar.includes(characters1[i])) {
        charPy.splice(i, 0, " "); // Insert space at index i
      }
    });

    const result = characters1.map((text, i) => ({
      Text: text,
      Piniyan: charPy[i] || null,
      status: spChar.includes(text) ? true : characters2.includes(text),
    }));

    console.log(result);

    const fragments = result.map((word, index) => (
      <React.Fragment key={index}>
        <li className={index === 0 ? "active" : ""}>
          <span
            className={
              word["status"] === false
                ? "ion-text-center red"
                : "ion-text-center"
            }
            id="top-center"
          >
            {word["Text"]}
          </span>
          <p className={word["status"] === false ? "wordpy red" : "wordpy"}>
            {word["Piniyan"]}
          </p>
        </li>{" "}
      </React.Fragment>
    ));
    return fragments;
  };

  const back = () => {
    history.replace({
      pathname: "/tabs/home/Dialoguedone",
      state: {
        themeArray:themeArray,
        videoUrl: videoUrl,
        index: index,
        themeId: themeId,
        themename: themename,
        levelId: levelId,
        level: level,
        levname: levname,
        dialogueId: dialogueId,
        role: role,
        rolename: rolename,
        allrole: allrole,
        backpage: backpage,
      },
    });
  };

  return (
    <IonPage className="intro" placeholder={undefined}>
      <IonHeader className="ion-text-center dubbletitle" placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <div>
            <IonButtons slot="start" placeholder={undefined}>
              <IonButton color="primary" onClick={back} placeholder={undefined}>
                <IonIcon icon={chevronBack} placeholder={undefined} />
              </IonButton>
            </IonButtons>
            <IonTitle color="primary" className="font20 ion-no-padding" placeholder={undefined}>
              <span>
              {t("dialogueresult.dialogue")} : {t("dialogueresult.practice")}<p className="ion-no-margin">{themeArray[index]?.ContentEn}</p>
              </span>
            </IonTitle>
            <IonButtons slot="end" placeholder={undefined}>
              <IonButton onClick={openDrawer} color="primary" placeholder={undefined}>
                <IonImg src="./assets/images/share.png" alt="" placeholder={undefined} />
              </IonButton>
            </IonButtons>
          </div>
        </IonToolbar>
      </IonHeader>
      {/*  ion-padding-horizontal */}
      <IonContent className="listing" placeholder={undefined}>
        <div has-bouncing="true" className="psdviewer" id="content">
          <IonRow
            class="blue-bg pdfhedding"
            id="hidden-content"
            className="hidden-content" placeholder={undefined}>
            <IonCol size="12" placeholder={undefined}>
              <span>
                <h3>{t("dialogueresult.lesson_report")}</h3>
                <p className="pdflavel">{t("dialogueresult.level")} {level}</p>
                <p className="ion-no-margin">
                  {themename}: {t("dialogueresult.lesson")} {themeArray[index? index :  0 ]?.number}
                </p>
                <p className="ion-no-margin themename">{lessonname}</p>
              </span>
              <span className="logoarea">
                <img src="./assets/images/pdfMMXlogo.png" alt="" />
                <p className="ion-no-margin username">
                  <img src="./assets/images/pdfusericon.png" alt="" />
                  {itemprofilelist?.Name}
                </p>
              </span>
            </IonCol>
          </IonRow>
          <IonRow className="count" placeholder={undefined}>
            <IonCol size="12" placeholder={undefined}>
              <IonCard placeholder={undefined}>
                <IonCardContent class="ion-no-padding" placeholder={undefined}>
                  <IonRow placeholder={undefined}>
                    <IonCol size="6" placeholder={undefined}>
                      <h5>{t("dialogueresult.overall_score")}</h5>
                      <div>
                        <h1>74%</h1>
                      </div>
                    </IonCol>
                    <IonCol size="6" className="ion-text-right bookfont" placeholder={undefined}>
                    {t("dialogueresult.completed_date")}:
                      <br />
                      DD/MM/YY
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonList className="ion-no-padding dialogueresult" placeholder={undefined}>
            {RoleAslist && RoleAslist.length > 0 ? (
              <>
                <IonRow className="answer" placeholder={undefined}>
                  <IonCol size="12" placeholder={undefined}>
                    <div className="alignment">
                      <div className="roundecircle"></div>
                      <div className="role">{RoleAslist[0]?.Role}</div>
                    </div>
                  </IonCol>

                  {RoleAslist.map((items, index) => {
                    return (
                      <IonCol size="12" key={index} placeholder={undefined}>
                        <IonCard placeholder={undefined}>
                          <IonCardContent class="ion-no-padding" placeholder={undefined}>
                            <div className="listingbox">
                              <ul className="lession-icon">
                                {/* {textset_tc(items.ContentTc, items.ContentPy)} */}
                                {test(
                                  items?.ContentTc,
                                  items?.ContentPy,
                                  items?.Answer
                                )}
                              </ul>
                            </div>
                          </IonCardContent>
                        </IonCard>
                      </IonCol>
                    );
                  })}
                </IonRow>
              </>
            ) : (
              <p></p>
            )}

            {RoleBslist && RoleBslist.length > 0 ? (
              <>
                <IonRow className="answer" placeholder={undefined}>
                  <IonCol size="12" placeholder={undefined}>
                    <div className="alignment">
                      <div className="roundecircle"></div>
                      <div className="role">{RoleBslist[0]?.Role}</div>
                    </div>
                  </IonCol>

                  {RoleBslist.map((items, index) => {
                    return (
                      <IonCol size="12" key={index} placeholder={undefined}>
                        <IonCard placeholder={undefined}>
                          <IonCardContent class="ion-no-padding" placeholder={undefined}>
                            <div className="listingbox">
                              <ul className="lession-icon">
                                {/* {textset_tc(items.ContentTc, items.ContentPy)} */}
                                {test(
                                  items?.ContentTc,
                                  items?.ContentPy,
                                  items?.Answer
                                )}
                              </ul>
                            </div>
                          </IonCardContent>
                        </IonCard>
                      </IonCol>
                    );
                  })}
                </IonRow>
              </>
            ) : (
              <p></p>
            )}

            {/* <IonRow className='lession-details'>
            <IonCol size='12' className='ion-padding-bottom ion-text-left'>
              <IonIcon color='primary' icon={play} />
              <div className='ion-padding-start ion-padding-bottom'>
                <ul className='lession-icon'>
                  <li>Nǐ<span>你</span></li>
                  <li>mén<span>们</span></li>
                  <li>zuò<span>做</span></li>
                  <li>dàn<span>做</span></li>
                  <li>gāo<span>蛋</span></li>
                </ul>
              </div>
            </IonCol>
          </IonRow> */}
          </IonList>
        </div>
      </IonContent>
      <Drawer
        className="bottompopup skateInBottom bottompopupresult"
        isVisible={isVisible}
        onClose={onClose}
      >
        <IonRow placeholder={undefined}>
          <IonList className="list" placeholder={undefined}>
            <IonItem class="ion-no-padding" onClick={shareGenerateIMG} placeholder={undefined}>
              {/* <IonIcon icon={share} /> */}
              <IonImg src="./assets/images/editShare.png" alt="" placeholder={undefined} />
              <IonLabel placeholder={undefined}>{t("dialogueresult.drawer.share_result")}</IonLabel>
            </IonItem>
            <IonItem class="ion-no-padding" onClick={generateIMG} placeholder={undefined}>
              {/* <IonIcon icon={image} /> */}
              <IonImg src="./assets/images/Chooseimage.png" alt="" placeholder={undefined} />
              <IonLabel placeholder={undefined}>{t("dialogueresult.drawer.save_as_photo")}</IonLabel>
            </IonItem>
            <IonItem class="ion-no-padding" onClick={generatePDF} placeholder={undefined}>
              {/* <IonIcon icon={download} /> */}
              <IonImg src="./assets/images/download.png" alt="" placeholder={undefined} />
              <IonLabel placeholder={undefined}>{t("dialogueresult.drawer.save_as_pdf")}</IonLabel>
            </IonItem>
          </IonList>
        </IonRow>
      </Drawer>
    </IonPage>
  );
};

export default React.memo(Dialogueresult);
