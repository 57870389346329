import React from 'react';
import { IonHeader, IonToolbar, IonButtons, IonButton, IonImg, IonContent, IonPage, IonTitle, IonIcon, IonList, IonCol, IonText, IonCard, IonCardContent } from '@ionic/react';
import './Notificationbell.scss';
import { chevronBack, chevronForward } from 'ionicons/icons';

interface MysubscriptionProps { }

const Mysubscription: React.FC<MysubscriptionProps> = () => {

  return (
    <IonPage className='intro' placeholder={undefined}>
      <IonHeader className='ion-text-center' placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <IonButtons slot="start" placeholder={undefined}>
            <IonButton href='/tabs/home' color='primary' placeholder={undefined}>
              <IonIcon icon={chevronBack} placeholder={undefined} />
            </IonButton>
          </IonButtons>
          <IonTitle color='primary' className='font20 ion-no-padding' placeholder={undefined}><span>Notifications</span></IonTitle>
          <IonButtons slot="end" placeholder={undefined}>
            <IonButton color='primary' placeholder={undefined}>
              {/* <IonIcon icon={notificationsOutline} /> */}
              <IonImg src="./assets/images/bell.svg" alt="" placeholder={undefined} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='listing' placeholder={undefined}>
        <IonList className="ion-padding-horizontal notificationbell" placeholder={undefined}>

          <IonCol placeholder={undefined}>
            <IonText placeholder={undefined}>Today</IonText>
          </IonCol>

          <IonCard className='mysubscription bell unread' placeholder={undefined}>
            <IonCardContent class="ion-no-padding" placeholder={undefined}>
              <IonText placeholder={undefined}>Application version 1.3 available</IonText>
              <IonIcon color='primary' className='ion-float-right' icon={chevronForward} placeholder={undefined} />
            </IonCardContent>
          </IonCard>
          <IonCard className='mysubscription bell' placeholder={undefined}>
            <IonCardContent class="ion-no-padding" placeholder={undefined}>
              <IonText placeholder={undefined}>Refer a friend and get 10% off</IonText>
              <IonIcon color='primary' className='ion-float-right' icon={chevronForward} placeholder={undefined} />
            </IonCardContent>
          </IonCard>

          <IonCol className='dataspace' placeholder={undefined}>
            <IonText placeholder={undefined}>9 February 2023</IonText>
          </IonCol>

          <IonCard className='mysubscription bell' placeholder={undefined}>
            <IonCardContent class="ion-no-padding" placeholder={undefined}>
              <IonText placeholder={undefined}>Application version 1.2 available</IonText>
              <IonIcon color='primary' className='ion-float-right' icon={chevronForward} placeholder={undefined} />
            </IonCardContent>
          </IonCard>

        </IonList>
    </IonContent>

  </IonPage>
  );
};

export default React.memo(Mysubscription);