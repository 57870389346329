import React, { useEffect, useRef, useState } from 'react';
import { IonContent, IonList, IonItem, IonLabel, IonToggle, IonCol, IonText, IonCard, IonCardContent, IonRow, IonPage, useIonViewWillEnter, useIonLoading, useIonToast, IonSlides, IonSlide, IonImg } from '@ionic/react';
import './E4ev1vocab.scss';
import Drawer from "react-bottom-drawer";
import ReactAudioPlayer from 'react-audio-player';
import { CONFIG } from '../App';
import { Swiper as SwiperCore, Pagination, Navigation, A11y } from 'swiper';
import { string } from 'yup';

interface MyObject {
  id: number;
  name: string;
  type: 'e4ev1',
  answ: true,
  pagecouter: 0,
  totalque: 0
}
interface E4ev1vocabProps { itemE4EV1: any; onObjectChange: (object: MyObject) => void; }


const E4ev1vocab: React.FC<E4ev1vocabProps> = (props: E4ev1vocabProps, { isSuccess, isWrong }) => {
  let url: string = CONFIG.API_ENDPOINT;//live

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [isVisible, setIsVisible] = React.useState(false);
  const openDrawer = React.useCallback(() => setIsVisible(true), []);

  const [showMessage, setShowMessage] = useState(false);
  const [myObject, setMyObject] = useState<MyObject>({ id: 0, name: '', answ: true, type: 'e4ev1', pagecouter: 0, totalque: 0 });
  const [length, setLength] = React.useState(-1)
  const [showResults, setShowResults] = React.useState(1)
  const [successOption, setSuccessOption] = useState<boolean | undefined>();
  const [wrongOption, setWrongOption] = useState<boolean | undefined>();
  const [setRightAns, setRightAnsOption] = useState<boolean | undefined>();
  const [selectedDiv, setSelectedDiv] = useState<any>(null);
  let [swiper, setSwiper] = useState<SwiperCore>();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [showImage, setShowImage] = useState(false);
  const [myanswer, setmyanswer] = useState('');

  const [pageRead, setpageRead] = useState(0);
  let finalanswer = {}
  let token = ''
  let resfreshtoken = ''
  let Ise4ev1: any = true
  let pagecouter: any = 0
  let totalque: any = 0
  let indexdbq: any = -1
  // let myanswer = ''
  function handleChange() {
    const myObject: MyObject = { id: 1, name: 'My Object', answ: Ise4ev1, type: 'e4ev1', pagecouter: pagecouter, totalque: props.itemE4EV1.length };
    props.onObjectChange(myObject);
  }
  useIonViewWillEnter(() => {
    setShowResults(1)
    setLength(1)
    console.log('itemE4EV1', props.itemE4EV1);
    console.log('length nw', props.itemE4EV1.length);
    setShowMessage(true);

  });

  SwiperCore.use([Navigation, Pagination, A11y]);
  const slidesRef = useRef<HTMLIonSlidesElement>(null);

  const options = {
    keyboard: true
  };
  const slideRef = useRef<HTMLIonSlidesElement>(null);

  const slideOpts = {
    initialSlide: 0,
    speed: 400
  };



  const handleSlideNextEnded = (event: CustomEvent<any>) => {
    console.log('Slide Next ended', event.detail);

    slidesRef.current?.getActiveIndex().then((index) => {
      console.log('Slide Next ended index', (index + 1));
      setLength((index + 1))
      resetstate()
      setShowResults((index + 1))
      // console.log('length', props.itemE4EV1.length);

    })
  }

  const handleSlidePrevEnded = (event: CustomEvent<any>) => {
    console.log('Slide Prev ended', event.detail);
    // setLength(length - 1)
    slidesRef.current?.getActiveIndex().then((index) => {
      //console.log('index', (index + 1));
      setLength((index - 1))
      // resetstate()
      // setShowResults((index-1))

    })
  }

  const handleSlideDidChanged = (event: CustomEvent<any>) => {
    console.log('Length', length);

    slidesRef.current?.getActiveIndex().then(async (index) => {
      console.log('index', (index + 1));
      debugger
      // if (slidesRef.current) {
      //   const ionSlides = await slidesRef.current.getSwiper();
      //   console.log('ionSlides.activeIndex',ionSlides.activeIndex);
      // }

      SaveExerciseQueAnswer(length)
      if ((index + 1) == length) {



        console.log("Go next page")
        setLength(-1)
        saveContent()
        handleChange()
      }
    })
  }




  const gotonextques = async (items: any, index: any, type) => {

    //1 for Understand and 2= dont understand
    console.log(items)

    // console.log(type)
    // console.log('soundno ', items?.Question?.[0].soundno)
    // console.log('questionno', type)

    if (items?.Question?.[0].soundno == type) {
      isSuccess = true
      isWrong = false
    } else {
      isSuccess = false
      isWrong = true
    }

    // console.log(showResults)
    //console.log(itemlist)
    //console.log(itemlist[showResults - 1])

    //  let data = itemlist[showResults - 1];
    //debugger
    if (items) {
      resetstate()
      setShowResults(index)
      // console.log('len ', showResults - 1)
      setLength(index)


      // ansques = data.QuestionId + ',' + type;
      // setQuestion([...quesdata, ansques]);


    }
  };
  let tmpscore = 0

  const handleQuestionCheckClick = (items: any, index: any, indexq: any, type, indexnew) => {
    // console.log(type)
    //console.log('soundno ', items?.Question?.[0].soundno)
    //console.log('questionno', type)
    debugger
    // indexdbq = index

    if (items?.Question?.[0].soundno == type) {
      isSuccess = true
      isWrong = false
      setSuccessOption(isSuccess);
      setWrongOption(isWrong);
      setSelectedDiv(type)
      console.log('q1', isSuccess)
      // myanswer = props.itemE4EV1[index].Options?.[indexnew].Word
      setmyanswer('')
      setmyanswer(props.itemE4EV1[index].Options?.[indexnew].Word)
      console.log('myanswer', myanswer)
      debugger
      if (!props.itemE4EV1[index].answer) {
        props.itemE4EV1[index].answer = type
        props.itemE4EV1[index].score = true
        tmpscore += tmpscore

      }

    } else {
      isSuccess = false
      isWrong = true
      setRightAnsOption(true)
      setSuccessOption(isSuccess);
      setWrongOption(isWrong);
      setSelectedDiv(type)
      setmyanswer('')
      setmyanswer(props.itemE4EV1[index].Options?.[indexnew].Word)
      console.log('myanswer', myanswer)
      if (!props.itemE4EV1[index].answer) {
        props.itemE4EV1[index].answer = type
        props.itemE4EV1[index].score = false

      }

    }

    // console.log('result', result)
    //console.log('isSuccess', isSuccess)


    //console.log('isWrong', isWrong)
  };

  const resetstate = () => {
    setSuccessOption(false);
    setWrongOption(false);
    setSelectedDiv(false)
    setRightAnsOption(false)

  }
  useEffect(() => {
    // console.log(quesdata);

    //console.log('String', quesdata.join('#'))

    // console.log('showResults', showResults)
    //  console.log('finalindex', length)
    // console.log('lengthque', lengthque)
    //console.log('teatemediaBlob', mediaBlob)


    if (length > props.itemE4EV1.length) {
      console.log('Done')
      setLength(-1)
      saveContent()
      handleChange()
    }
    const timeoutId = setTimeout(() => {
      setShowImage(true);
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };

    //debugger


  });

  const saveContent = async () => {
    let currentUser = JSON.parse(localStorage.getItem('alltoken') || '{}');
    if (currentUser) {
      token = currentUser.JwtToken
      resfreshtoken = currentUser.RefreshToken
    }
    // present({
    //   message: '',
    // })

    let anw = {};
    let tmscore = 0;
    pagecouter = 0
    for (let index = 0; index < props.itemE4EV1.length; index++) {
      const element = props.itemE4EV1[index];
      const propName = ("q" + (index + 1)).toString();
      const propValue = element.answer;


      anw[propName] = (propValue).toString();
      finalanswer = anw
      if (element.score) {
        tmscore += element.score
      }

    }
    console.log('tmpscore', tmscore)
    console.log('finalanswer', finalanswer)
    debugger
    const stringWithBackslashes = JSON.stringify(finalanswer);
    const stringWithoutBackslashes = stringWithBackslashes.replace(/\\/g, "");
    console.log(stringWithoutBackslashes); // Output: {"q1":"2","q2":"4","q3":"4"}

    const myArray = Object.entries(finalanswer);
    console.log(myArray);
    for (let k = 0; k < myArray.length; k++) {
      const element = myArray[k][1];
      if (element != '') {
        pagecouter++
      }

    }
    console.log(pagecouter);

    debugger
    const data = {
      "contentPageCode": props.itemE4EV1[0].TemplateCode,
      "type": "exercise-score",
      "bookId": props.itemE4EV1[0].Book_id,
      "contentPageId": props.itemE4EV1[0].Page_id,
      "answer": stringWithoutBackslashes,
      "score": tmscore,
      "options": null,
      "pageRead": pagecouter
    }
    //dismiss()
    console.log(data)
    debugger

    try {
      const response = await fetch(url + '/Book/SaveContent', {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token
        },
        method: "POST",
      })

      const json = await response.json()
      console.log(json)
      //dismiss()
      debugger
      if (json.StatusCode == 1) {


      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: 'bottom'
        });
      }

      // dismiss()

    } catch (error) {
      console.error(error)
      // dismiss()
    }

  }

  const SaveExerciseQueAnswer = async (indexdb) => {
    debugger

    let currentUser = JSON.parse(localStorage.getItem('alltoken') || '{}');
    if (currentUser) {
      token = currentUser.JwtToken
      resfreshtoken = currentUser.RefreshToken
    }


    // let rigthans = '';
    // for (let index = 0; index < props.itemE4EV1.length; index++) {
    //   const element = props.itemE4EV1[index];

    //   for (let j = 0; j < element.Options.length; j++) {
    //     const elementans = element.Options[j];

    //     if (props.itemE4EV1[indexdb - 1]?.Question?.[0].soundno == elementans.questionno) {
    //       rigthans = elementans.Word;

    //     }
    //   }

    // }



    const array = props.itemE4EV1[indexdb - 1].Options

    const targetValue = props.itemE4EV1[indexdb - 1]?.Question?.[0].soundno;

    const foundValue = array.find((element) => element.questionno === targetValue);

    console.log(foundValue); // Output: 3
    debugger
    //console.log('rigthans', rigthans)
    console.log('myanswer11', myanswer)
    console.log('indexdb', indexdb)

    const data = {
      "ev_id": props.itemE4EV1[0].Ev_Id,
      "templateCode": props.itemE4EV1[indexdb - 1].TemplateCode,
      "question": props.itemE4EV1[indexdb - 1]?.Question?.[0].SoundUrl,
      "rightAnswer": foundValue.Word,
      "answer": myanswer
    }

    console.log('SaveExerciseQueAnswer', data)
    debugger

    try {
      const response = await fetch(url + '/Book/SaveExerciseQueAnswer', {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token
        },
        method: "POST",
      })

      const json = await response.json()
      console.log(json)
      //  debugger
      if (json.StatusCode == 1) {


      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: 'bottom'
        });
      }


    } catch (error) {
      console.error(error)
    }
  }


  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);

  return (
    <IonPage className='pages-space intro' placeholder={undefined}>

      <IonContent className='listing' placeholder={undefined}>
        <IonSlides
          pager={false}
          scrollbar={true}
          options={options}
          onIonSlideNextEnd={handleSlideNextEnded}
          onIonSlidePrevEnd={handleSlidePrevEnded}
          onIonSlideTransitionEnd={handleSlideDidChanged}
          ref={slidesRef} placeholder={undefined} >



          {props.itemE4EV1.map((items, index) => {
            return (
              <IonSlide placeholder={undefined}>
                <IonList key={index} className="ion-no-padding height100" placeholder={undefined}>
                  <IonRow className='Practice leftrightspace ion-padding-horizontal' placeholder={undefined}>
                    <IonCol placeholder={undefined}>
                      <IonText placeholder={undefined}>Choose the correct answer</IonText>
                    </IonCol>
                  </IonRow>

                  <IonRow className='Practice ion-padding-horizontal' placeholder={undefined}>
                    <IonCol size='12' placeholder={undefined}>
                      {/* {index + 1}
                      {items?.Question?.[0].SoundUrl} */}
                      {showMessage ?
                        <ReactAudioPlayer className='String' controls controlsList="nodownload noplaybackrate">
                          <source src={items?.Question?.[0].SoundUrl} type="audio/mpeg"></source>
                        </ReactAudioPlayer> : ''}
                    </IonCol>
                  </IonRow>
                  <IonRow className='answer ion-padding-horizontal' placeholder={undefined}>
                    {props.itemE4EV1?.[index].Options?.map((datav, indexnew) => {
                      return (
                        <IonCol size='6' key={indexnew} placeholder={undefined}>
                          <IonCard id={datav.questionno} style={{ borderColor: setRightAns && (items?.Question?.[0].soundno === datav.questionno) ? 'var(--ion-color-warning-shade)' : '' }} className={successOption && (selectedDiv === datav.questionno) ? 'answer-right' : wrongOption && (selectedDiv === datav.questionno) ? 'answer-wrong' : ''} onClick={() => handleQuestionCheckClick(items, index, showResults + 1, datav.questionno, indexnew)} placeholder={undefined} >
                            <IonCardContent class="ion-no-padding" placeholder={undefined}>
                              <div>
                                <IonText placeholder={undefined}>{datav.Word} </IonText>
                              </div>
                            </IonCardContent>
                          </IonCard>
                        </IonCol>
                      )
                    })}
                    <IonCol size='12' placeholder={undefined}>

                      {showImage && <IonImg className='slide' src="./assets/images/swipe-left-right.gif" placeholder={undefined} />}
                      {/* <IonButton expand="block" onClick={() => gotonextques(items, showResults + 1, 1)}>Next</IonButton> */}
                    </IonCol>
                  </IonRow>
                </IonList>
              </IonSlide>
            )
          })}
        </IonSlides>
      </IonContent>

      {/* <IonContent className='listing'>
        {props.itemE4EV1.map((items, index) => {
          return (
            <IonList key={index} style={{ display: showResults == index + 1 ? "block" : "none" }} className="ion-no-padding height100">
              <IonRow className='Practice ion-padding-horizontal'>
                <IonCol size='12'>
                  {showMessage ?
                    <ReactAudioPlayer className='String' controls controlsList="nodownload noplaybackrate">
                      <source src={items?.Question?.[0].SoundUrl} type="audio/mpeg"></source>
                    </ReactAudioPlayer> : ''}
                </IonCol>
              </IonRow>


              <IonRow className='answer ion-padding-horizontal'>
                {props.itemE4EV1?.[index].Options?.map((datav, indexnew) => {
                  return (
                    <IonCol size='6' key={indexnew}>
                      <IonCard id={datav.questionno} style={{ borderColor: setRightAns && (items?.Question?.[0].soundno === datav.questionno) ? 'var(--ion-color-warning-shade)' : '' }} className={successOption && (selectedDiv === datav.questionno) ? 'answer-right' : wrongOption && (selectedDiv === datav.questionno) ? 'answer-wrong' : ''} onClick={() => handleQuestionCheckClick(items, index, showResults + 1, datav.questionno)} >
                        <IonCardContent class="ion-no-padding">
                          <div>
                            <IonText>{datav.Word} </IonText>
                          </div>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                  )
                })}
                <IonCol size='12'>
                  <IonButton expand="block" onClick={() => gotonextques(items, showResults + 1, 1)}>Next</IonButton>
                </IonCol>
              </IonRow>
            </IonList>
          )
        })}
      </IonContent> */}

      <Drawer className='bottompopup skateInBottom' isVisible={isVisible} onClose={onClose}>
        <IonRow placeholder={undefined}>
          <IonList className='list' placeholder={undefined}>
            {/* <IonItem class="ion-no-padding playbook">
              <div>
                <IonImg src="./assets/images/play.png" />
              </div>
              <div className="details ion-padding-start">
                <h4>Play book</h4>
                <span>Autoplay book audio</span>
              </div>
            </IonItem> */}
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>Lesson language</IonLabel>
              <div className='right-space'>
                <IonText color='primary' placeholder={undefined}>簡</IonText>
                <IonText placeholder={undefined}>繁</IonText>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>Chinese characters</IonLabel>
              <IonToggle checked slot="end" placeholder={undefined}></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>Pinyin</IonLabel>
              <IonToggle checked slot="end" placeholder={undefined}></IonToggle>
            </IonItem>
            {/* <IonItem class="ion-no-padding">
              <IonLabel>Translation</IonLabel>
              <IonToggle slot="end"></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding">
              <IonLabel>Font size</IonLabel>
              <IonImg src="./assets/images/Fontdown.png" />
              <IonImg src="./assets/images/Fontup.png" />
            </IonItem> */}
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>Playback speed</IonLabel>
              <div className='leftright'><a href='#'>&lt;</a> 1.0 <a href='#'>&gt;</a></div>
            </IonItem>
            {/* <IonItem class="ion-no-padding">
              <IonLabel>Bookmark</IonLabel>
              <IonIcon color='primary' icon={bookmark} />
            </IonItem> */}
          </IonList>
        </IonRow>
      </Drawer>


    </IonPage>
  );
};

export default React.memo(E4ev1vocab);

