import React, { useEffect, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonText,
  IonCol,
  IonCard,
  IonCardContent,
  IonImg,
  IonList,
  IonIcon,
  IonPage,
  IonTitle,
  useIonViewWillEnter,
  useIonLoading,
  useIonToast,
  useIonViewDidEnter,
  IonSkeletonText,
} from "@ionic/react";
import "./Themesports.scss";
import {
  close,
  chevronBack,
  lockClosed,
  lockClosedOutline,
} from "ionicons/icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ProgressBar from "@ramonak/react-progress-bar";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import { CONFIG } from "../App";
import { array } from "yargs";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface OwnProps extends RouteComponentProps {
  id: string;
}

interface ThemesportsProps extends OwnProps {}

const Themesports: React.FC<ThemesportsProps> = ({ history, match }) => {
  let url: string = CONFIG.API_ENDPOINT; //live

  const { t } = useTranslation();

  // const params = useParams<{id: string}>();
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any[]>([]);

  const [itemdialoguesist, setDialoguesitems] = React.useState<any[]>([]);
  const [itemvideolist, setVideoitems] = React.useState<any[]>([]);
  const [themeguid, setthemeIddb] = React.useState<any>();
  const [bookmarkindex, setBookmarkindex] = React.useState<any>(-1);
  const historyss = useHistory<{
    themeArray: string;
    themeId: string;
    themename: string;
    levelId: string;
    level: string;
    levname: string;
    backpage: string;
    index: string;
  }>();

  const themeArray = historyss.location.state
    ? historyss.location.state.themeArray
    : "";

  const themeIddb = historyss.location.state
    ? historyss.location.state.themeId
    : "";
  console.log(themeIddb);

  // setthemeIddb(0)
  // setthemeIddb(themeIddb)
  const themename = historyss.location.state
    ? historyss.location.state.themename
    : "";

  const levelId = historyss.location.state
    ? historyss.location.state.levelId
    : "";
  const level = historyss.location.state ? historyss.location.state.level : "";
  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";
  // console.log("themeId1. ", themeIddb);
  const backpage = historyss.location.state
    ? historyss.location.state.backpage
    : "";

  const index = historyss.location.state ? historyss.location.state.index : "";
  // console.log("backpage. ", backpage);

  let token = "";
  let resfreshtoken = "";
  useIonViewDidEnter(() => {
    console.log("useIonViewDidEnter");
  });
  useIonViewWillEnter(() => {
    console.log("useIonViewWillEnter");
    //setMenuEnabled(false);
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    //  const history = useHistory<any>();
    //console.log(history)

    getthemes();
    // getVideoTutorials()
  });

  const getthemes = async () => {
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   duration: 1000,
    // });

    console.log("APIthemeId. ", themeIddb);
    try {
      const response = await fetch(
        url + "/Book/GetLessons?themeGuid=" + themeIddb,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        }
      );

      // if (!response.ok) {
      //   throw Error(response.statusText)
      // }
      const json = await response.json();
      console.log(json);

      if (json.StatusCode == 1) {
        for (let index = 0; index < json.Result.Lessons.length; index++) {
          const element = json.Result.Lessons[index];
        }
        let test: any = [];
        // const array1 = [{ BookId: 14335 }, { BookId: 14336 }];
        // const array2 = [{ BookId: 1111 }, { BookId: 2222 }, { BookId: 3333 }, { BookId: 4444 }];

        const array1 = json.Result.Lessons;
        const array2 = json.Result.Dialogues;

        const combinedArray = array1.concat(array2);
        const maxLength = Math.max(array1.length, array2.length);

        for (let i = 0; i < maxLength; i++) {
          // Your logic here
          if (array1[i]) {
            array1[i]["type"] = "lesson";
            array1[i]["number"] = i + 1;
            test.push(array1[i]);
          }
          if (array2[i]) {
            array2[i]["type"] = "dialogues";
            array2[i]["number"] = i + 1;
            test.push(array2[i]);
          }
        }

        console.log("test array", test);

        setitems(test);
        // setDialoguesitems(json.Result.Dialogues)
        // setVideoitems(json.Result.Videos)

        localStorage.setItem("itemlist", JSON.stringify(test));
        setitems(test || localStorage.getItem("itemlist"));
        // localStorage.setItem("itemdialoguesist",JSON.stringify(json.Result.Dialogues));
        // setDialoguesitems(json.Result.Dialogues || localStorage.getItem("itemdialoguesist"));
        // setVideoitems(json.Result.Videos);
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const getVideoTutorials = async () => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 1000,
    });

    try {
      const response = await fetch(
        url + "/User/GetVideoLessons?level=" + level,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        }
      );

      // if (!response.ok) {
      //   throw Error(response.statusText)
      // }
      const json = await response.json();
      console.log(json);

      dismiss();
      if (json.StatusCode == 1) {
        setVideoitems(json.Result);
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      console.log(json);

      if (json.StatusCode == 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const Subscribe = async () => {
    history.push("/tabs/Subscribe", { direction: "none" });
  };
  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <div className="close">
              <IonIcon onClick={onClose} icon={close} placeholder={undefined} />
            </div>
            <div className="imgview">
              <IonImg src="./assets/images/rocet.svg" placeholder={undefined} />
            </div>
            <p>{t("themesports.submit.level")} 5: Proficient</p>
            <p className="subtext popuppadding">
              {t("themesports.submit.desc")}
            </p>
          </div>
        );
      },
    });
  };

  const submit1 = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <div className="close">
              <IonIcon onClick={onClose} icon={close} placeholder={undefined} />
            </div>
            <div className="icon">
              <IonIcon icon={lockClosed} placeholder={undefined} />
            </div>
            <p>{t("themesports.submit1.sub_t_unlk")}</p>
            <div className="react-confirm-alert-button-group">
              <IonButton
                onClick={() => {
                  Subscribe();
                  onClose();
                }}
                className="alertcancel w-150" placeholder={undefined}>
                {t("themesports.submit1.subscribe")}
              </IonButton>
            </div>
          </div>
        );
      },
    });
  };

  const submit2 = (item, index) => {
    debugger;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <p className="mt0">{t("themesports.submit2.pick_role")}</p>
            <div className="react-confirm-alert-button-group">
              <div
                onClick={() => {
                  goDialoguepractice(item, 0, index);
                  onClose();
                }}
                className="usercircle">
                Crew
              </div>
              <div
                onClick={() => {
                  goDialoguepractice(item, 1, index);
                  onClose();
                }}
                className="usercircle">
                Pax
              </div>
            </div>
          </div>
        );
      },
    });
  };

  const gonextPage = (val, index) => {
    //history.push('/tabs/home/themesports/5');
    if (val.IsLocked == false) {
      history.replace({
        pathname: "/tabs/home/lesson",
        state: {
          themeArray: itemlist,
          themeId: themeIddb,
          lessonname: val.Title,
          index: index,
          lessonsId: val.BookGuid,
          bookId: val.Bookid,
          themename: themename,
          levelId: levelId,
          level: level,
          levname: levname,
          backpage: "Themesports",
          backpageValue: 1,
        },
      });
    }
  };

  const goDialoguepractice = (item, value, index) => {
    const str = item.Types;
    const trimmedStr = str.replace(/\s/g, "");
    console.log(trimmedStr); // Output: "HelloWorld"
    let string = trimmedStr;
    let one = "";
    let two = "";
    if (string) {
      let result = string.split(",");
      one = result[0];
      two = result[1];
    }

    history.replace({
      pathname: "/tabs/home/Dialoguepractice",
      state: {
        themeArray: itemlist,
        index: index,
        themeId: themeIddb,
        themename: themename,
        levelId: levelId,
        level: level,
        levname: levname,
        dialogueId: item.Id,
        role: value,
        rolename: value === 0 ? one : two,
        allrole: trimmedStr,
        backpage: "Themesports",
      },
    });
  };

  const goDialoguePage = (items, index) => {
    //  history.push('/tabs/home/Dialogue');

    // if (items.IsLocked == false) {
    //   history.replace({
    //     pathname: '/tabs/home/Dialogue',
    //     state: { themeId: themeIddb, dialogueId: items.Id, title: items.Title_English, themename: themename, levelId: levelId, level: level, levname: levname }
    //   })
    // }

    const stringSpac = items.Types; // Your string with spaces
    const stringWtot = stringSpac.replace(/\s/g, ""); // Remove spaces from the string
    console.log(stringWtot);
    if (items.IsLocked == false) {
      history.replace({
        pathname: "/tabs/home/Dialoguevideo",
        state: {
          themeArray: itemlist,
          themeId: themeIddb,
          index: index,
          dialogueId: items.Id,
          title: items.Title_English,
          themename: themename,
          levelId: levelId,
          level: level,
          levname: levname,
          videoUrl: items.VideoUrl,
          role: stringWtot,
          backpage: "Themesports",
        },
      });
    }
  };

  const gobackPageproficient = () => {
    //history.push('/tabs/home/themesports/5');
    //history.goBack()
    debugger;
    history.push({
      pathname: "/tabs/home/proficient",
      state: { levelId: levelId, level: level, levname: levname, index: index },
    });
    // localStorage.removeItem("itemlist");
  };

  const gotoPractice = (val, index) => {
    debugger;
    if (val.IsExistExercise == 1) {
      history.push({
        pathname: "/tabs/home/Practicequestion",
        state: {
          themeArray: itemlist,
          themeId: themeIddb,
          lessonname: val.Title,
          index: index,
          lessonsId: val.BookGuid,
          themename: themename,
          levelId: levelId,
          level: level,
          levname: levname,
          backpage: "Themesports",
          backpageValue: 1,
        },
      });
      history.go(0);
      // window.location.reload();
    } else {
      presenttost({
        message: "Exercise not available",
        duration: 1500,
        position: "bottom",
      });
    }
  };

  const savebookmark = (val, index, type) => {
    console.log(val);
    //BookGuid
    //IsFavourite
    if (itemlist[index].IsFavourite == 0) {
      itemlist[index].IsFavourite = 1;
      setBookmarkindex(index);
      saveContent(val, true, type);
    } else {
      itemlist[index].IsFavourite = 0;
      setBookmarkindex(-1);
      saveContent(val, false, type);
    }

    setitems([...itemlist]); // Use spread operator to create a new array reference

    console.log("itemlistfinal", itemlist);
  };

  // useEffect(() => {
  //   // This will run whenever myArray is updated
  //   console.log('itemlistfinal', itemlist)
  // }, [itemlist]);

  const saveContent = async (val, isFavourite, type) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    debugger;
    const data = {
      referenceId: type == 1 ? val.BookId : val.Id,
      isFavourite: isFavourite,
      typeId: type,
    };
    //dismiss()
    console.log(data);

    try {
      const response = await fetch(url + "/User/Favourite", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });

      const json = await response.json();
      console.log(json);
      //dismiss()

      if (json.StatusCode == 1) {
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };

  return (
    <IonPage className="intro" placeholder={undefined}>
      <IonHeader className="ion-text-center" placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <IonButtons slot="start" placeholder={undefined}>
            <IonButton color="primary" onClick={gobackPageproficient} placeholder={undefined}>
              <IonIcon icon={chevronBack} placeholder={undefined} />
            </IonButton>
          </IonButtons>
          <IonTitle color="primary" className="font20 ion-no-padding" placeholder={undefined}>
            <span>{t("themesports.themes")} {themename}</span>
          </IonTitle>
          <IonButtons slot="end" placeholder={undefined}>
            {/* onClick={submit} */}
            {/* <IonButton color="primary" placeholder={undefined}>
             <IonIcon icon={informationCircleOutline} />
              <IonImg
                className="info"
                src="./assets/images/bang-circle-blue.svg"
                alt="" placeholder={undefined}              />
            </IonButton> */}
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding-horizontal themepage" placeholder={undefined}>
        <IonList className="themes reports" placeholder={undefined}>
          {/* <IonCol>
            <IonText>Lessons</IonText>
          </IonCol> */}
            {itemlist.length == 0 ? (
            <>

              <IonCard placeholder={undefined}>
                <IonCardContent class="ion-no-padding" placeholder={undefined}>
                  <div className="lessongbox">
                    <div className="themelesson">
                      <IonSkeletonText animated placeholder={undefined} />

                    </div>
                    <div className="details">
                      <h4> <IonSkeletonText animated placeholder={undefined} /></h4>
                      <span className="gamename"> <IonSkeletonText animated placeholder={undefined} /></span>
                    </div>
                  </div>
                </IonCardContent>
              </IonCard>
              <IonCard placeholder={undefined}>
                <IonCardContent class="ion-no-padding" placeholder={undefined}>
                  <div className="lessongbox">
                    <div className="themelesson">
                      <IonSkeletonText animated placeholder={undefined} />

                    </div>
                    <div className="details">
                      <h4> <IonSkeletonText animated placeholder={undefined} /></h4>
                      <span className="gamename"> <IonSkeletonText animated placeholder={undefined} /></span>
                    </div>
                  </div>
                </IonCardContent>
              </IonCard>
              <IonCard placeholder={undefined}>
                <IonCardContent class="ion-no-padding" placeholder={undefined}>
                  <div className="lessongbox">
                    <div className="themelesson">
                      <IonSkeletonText animated placeholder={undefined} />

                    </div>
                    <div className="details">
                      <h4> <IonSkeletonText animated placeholder={undefined} /></h4>
                      <span className="gamename"> <IonSkeletonText animated placeholder={undefined} /></span>
                    </div>
                  </div>
                </IonCardContent>
              </IonCard>
              <IonCard placeholder={undefined}>
                <IonCardContent class="ion-no-padding" placeholder={undefined}>
                  <div className="lessongbox">
                    <div className="themelesson">
                      <IonSkeletonText animated placeholder={undefined} />

                    </div>
                    <div className="details">
                      <h4> <IonSkeletonText animated placeholder={undefined} /></h4>
                      <span className="gamename"> <IonSkeletonText animated placeholder={undefined} /></span>
                    </div>
                  </div>
                </IonCardContent>
              </IonCard>
              <IonCard placeholder={undefined}>
                <IonCardContent class="ion-no-padding" placeholder={undefined}>
                  <div className="lessongbox">
                    <div className="themelesson">
                      <IonSkeletonText animated placeholder={undefined} />

                    </div>
                    <div className="details">
                      <h4> <IonSkeletonText animated placeholder={undefined} /></h4>
                      <span className="gamename"> <IonSkeletonText animated placeholder={undefined} /></span>
                    </div>
                  </div>
                </IonCardContent>
              </IonCard>
            </>
          ) : (
            <>
          {itemlist.map((items, index) => {
            return (
              <div key={index}>
                {items.type === "lesson" ? (
                  <>
                    <IonCard placeholder={undefined}>
                      <IonCardContent class="ion-no-padding" placeholder={undefined}>
                        <div
                          className="lessongbox"
                          onClick={() => gonextPage(items, index)}>
                          <div className="themelesson">
                            <IonImg src={items.ImageUrl} placeholder={undefined} />
                          </div>
                          <div className="details">
                            {/* <h4>Lesson {index + 1} - {items.BookId} - {items.IsExistExercise}</h4> */}
                            <h4>{t("themesports.lesson")}{" "}{items.number} </h4>
                            <span className="gamename">{items.Title}</span>
                            {items.Progress !== 0 ? (
                              <div className="line">
                                <ProgressBar
                                  bgColor="var(--ion-color-primary)"
                                  height="9px"
                                  className="process"
                                  completed={items.Progress}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        {items.IsLocked === false ? (
                          <div className="icons">
                            <IonImg
                              onClick={() => savebookmark(items, index, 1)}
                              className="ion-padding-bottom bookmark"
                              src={items.IsFavourite
                                ? "./assets/images/Bookmarkfill.svg"
                                : "./assets/images/Bookmark.svg"}
                              alt="" placeholder={undefined}                            />
                            <IonImg
                              onClick={() => gotoPractice(items, index)}
                              className="pen"
                              src="./assets/images/pen.svg"
                              alt="" placeholder={undefined}                            />
                          </div>
                        ) : (
                          <div className="icons">
                            <IonIcon color="primary" icon={lockClosedOutline} placeholder={undefined} />
                          </div>
                        )}
                      </IonCardContent>
                    </IonCard>
                  </>
                ) : (
                  <>
                    <IonCard placeholder={undefined}>
                      <IonCardContent class="ion-no-padding" placeholder={undefined}>
                        <div
                          className="lessongbox"
                          onClick={() => goDialoguePage(items, index)}>
                          <div className="themelesson">
                            <IonImg
                                src={items.ImageUrl !== ""
                                  ? items.ImageUrl
                                  : "./assets/images/noimg.jpg"} placeholder={undefined}/>
                          </div>
                          <div className="details">
                            <h4 className="title">{items.Title}</h4>
                            <h4>{t("themesports.dialogue")}{" "}{items.number} </h4>
                            <span className="gamename">{items.ContentEn}</span>
                            <div className="line">
                              <ProgressBar
                                bgColor="var(--ion-color-primary)"
                                height="9px"
                                className="process"
                                completed={80}
                              />
                            </div>
                          </div>
                        </div>

                            {items.IsLocked === false ? (
                              <div className="icons">
                                <IonImg
                                  onClick={() => savebookmark(items, index, 4)}
                                  className="ion-padding-bottom bookmark"
                                  src={items.IsFavourite
                                    ? "./assets/images/Bookmarkfill.svg"
                                    : "./assets/images/Bookmark.svg"}
                                  alt="" placeholder={undefined} />
                                <IonImg
                                  onClick={() => {
                                    submit2(items, index);
                                  }}
                                  className="pen"
                                  src="./assets/images/pen.svg"
                                  alt="" placeholder={undefined} />
                              </div>
                            ) : (
                              <div className="icons">
                                <IonIcon color="primary" icon={lockClosedOutline} placeholder={undefined} />
                              </div>
                            )}
                          </IonCardContent>
                        </IonCard>
                      </>
                    )}
                  </div>
                );
              })}
              </>
          )}
        </IonList>

        {/* <IonList className="themes reports">
          <IonCol>
            <IonText>Dialogues</IonText>
          </IonCol>
          {itemdialoguesist.map((items, index) => {
            return (
              <IonCard key={index}>
                <IonCardContent class="ion-no-padding">
                  <div
                    className="lessongbox"
                    onClick={() => goDialoguePage(items)}
                  >
                    <div className="themelesson">
                      <IonImg
                        src={
                          items.ImageUrl != ""
                            ? items.ImageUrl
                            : "./assets/images/noimg.jpg"
                        }
                      />
                    </div>
                    <div className="details">
                      <h4>{items.Title}</h4>
                      <h4>Dialogue - {index + 1} </h4>
                      <span className="gamename">{items.Title_English}</span>
                      <div className="line">
                        <ProgressBar
                          bgColor="var(--ion-color-primary)"
                          height="9px"
                          className="process"
                          completed={80}
                        />
                      </div>
                    </div>
                  </div>

                  {items.IsLocked == false ? (
                    <div className="icons">
                      <IonImg
                        className="ion-padding-bottom bookmark"
                        src="./assets/images/Bookmark.svg"
                        alt=""
                      />
                      <IonImg
                        onClick={() => {
                          submit2(items);
                        }}
                        className="pen"
                        src="./assets/images/pen.svg"
                        alt=""
                      />
                    </div>
                  ) : (
                    <div className="icons">
                      <IonIcon color="primary" icon={lockClosedOutline} />
                    </div>
                  )}
                </IonCardContent>
              </IonCard>
            );
          })}

        </IonList> */}

        {/* <IonList className="themes reports">
          <IonCol>
            <IonText>Flashcards</IonText>
          </IonCol>
          <IonCard>
            <IonCardContent class="ion-no-padding">
              <div className='lessongbox'>
                <div className='themelesson'><IonImg src="./assets/images/unicone.jpg" /></div>
                <div className='details'>
                  <h4>Flashcards 1</h4>
                  <span className='gamename'>Sports</span>
                  <div className='line'>
                    <ProgressBar bgColor='var(--ion-color-primary)' height='9px' className='process' completed={90} />
                  </div>
                </div>
              </div>

              <div className='icons'>
                <IonImg className='ion-padding-bottom bookmark' src="./assets/images/Bookmark.svg" alt="" />
                <IonImg className="pen" src="./assets/images/pen.svg" alt="" />
              </div>
            </IonCardContent>
          </IonCard>
          <IonCard>
            <IonCardContent class="ion-no-padding">
              <div className='lessongbox'>
                <div className='themelesson'><IonImg src="./assets/images/girl.jpg" /></div>
                <div className='details'>
                  <h4>Flashcards 2</h4>
                  <span className='gamename'>Sports</span>
                  <div className='line'>
                    <ProgressBar bgColor='var(--ion-color-primary)' height='9px' className='process' completed={10} />
                  </div>
                </div>
              </div>

              <div className='icons'>
                <IonImg className='ion-padding-bottom bookmark' src="./assets/images/Bookmark.svg" alt="" />
                <IonImg className="pen" src="./assets/images/pen.svg" alt="" />
              </div>
            </IonCardContent>
          </IonCard>
        </IonList> */}

        {/* <IonList className="themes reports">
          <IonCol>
            <IonText>Video lessons</IonText>
          </IonCol>
          {itemvideolist.map((items, index) => {
            return (
              <IonCard key={index}>
                <IonCardContent class="ion-no-padding">
                  <div className='lessongbox'>
                    <div className='themelesson'><IonImg src={items.ImageUrl} /></div>
                    <div className='details'>
                      <h4>Video lesson - {index + 1}</h4>
                      <span className='gamename'>{items.Title}</span>
                      <div className='line'>
                        <ProgressBar bgColor='var(--ion-color-primary)' height='9px' className='process' completed={50} />
                      </div>
                    </div>
                  </div>

                  {items.IsLocked == false ?
                    <div className='icons'>
                      <IonImg className='ion-padding-bottom bookmark' src="./assets/images/Bookmark.svg" alt="" />
                      <IonImg className="pen" src="./assets/images/pen.svg" alt="" />
                    </div> : <div className='icons'>

                      <IonIcon color='primary' icon={lockClosedOutline} />

                    </div>}
                </IonCardContent>
              </IonCard>
            )
          })}

        </IonList> */}
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Themesports);
