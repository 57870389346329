import React, { useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonTextarea,
  IonToast,
} from "@ionic/react";
import { connect } from "../data/connect";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface OwnProps {}

interface DispatchProps {}

interface SupportProps extends OwnProps, DispatchProps {}

const Support: React.FC<SupportProps> = () => {
  const { t } = useTranslation();

  const [message, setMessage] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const send = (e: React.FormEvent) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (!message) {
      setMessageError(true);
    }
    if (message) {
      setMessage("");
      setShowToast(true);
    }
  };

  return (
    <IonPage id="support-page" placeholder={undefined}>
      <IonHeader placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <IonButtons slot="start" placeholder={undefined}>
            <IonMenuButton placeholder={undefined}></IonMenuButton>
          </IonButtons>
          <IonTitle placeholder={undefined}>{t("support.support")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent placeholder={undefined}>
        <div className="login-logo">
          <img src="assets/img/appicon.svg" alt="Ionic logo" />
        </div>

        <form noValidate onSubmit={send}>
          <IonList placeholder={undefined}>
            <IonItem placeholder={undefined}>
              <IonLabel position="stacked" color="primary" placeholder={undefined}>
                {t("support.enter_msg_lbl")}
              </IonLabel>
              <IonTextarea
                name="message"
                value={message}
                spellCheck={false}
                autocapitalize="off"
                rows={6}
                onIonChange={(e) => setMessage(e.detail.value!)}
                required placeholder={undefined}></IonTextarea>
            </IonItem>

            {formSubmitted && messageError && (
              <IonText color="danger" placeholder={undefined}>
                <p className="ion-padding-start">{t("support.required")}</p>
              </IonText>
            )}
          </IonList>

          <IonRow placeholder={undefined}>
            <IonCol placeholder={undefined}>
              <IonButton type="submit" expand="block" placeholder={undefined}>
              {t("support.submit_btn")}
              </IonButton>
            </IonCol>
          </IonRow>
        </form>
      </IonContent>

      <IonToast
        isOpen={showToast}
        duration={3000}
        message= {t("support.sucs_msg")}
        onDidDismiss={() => setShowToast(false)}
      />
    </IonPage>
  );
};
export default Support;
// export default connect<OwnProps, {}, DispatchProps>({
//   component: Support
// })
