import React, { useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonItem,
  IonImg,
  IonRow,
  IonGrid,
  IonText,
  IonCol,
  IonInput,
  IonList,
  IonIcon,
  IonButton,
  IonLabel,
  IonContent,
  IonPage,
  IonTitle,
  useIonToast,
  useIonLoading,
  useIonViewWillEnter,
} from "@ionic/react";
import "./Editprofile.scss";
import { chevronBack, close } from "ionicons/icons";
import Drawer from "react-bottom-drawer";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { CONFIG } from "../App";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { Filesystem, Directory } from "@capacitor/filesystem";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface EditprofileProps {}

const Editprofile: React.FC<EditprofileProps> = () => {
  let url: string = CONFIG.API_ENDPOINT; //live

  const { t } = useTranslation();

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any>({});
  const [inputValue, setInputValue] = useState("");
  const [imageBase64, setImageBase64] = useState<any>(null);

  let token = "";
  let resfreshtoken = "";
  let userdata;
  let username;
  const [isVisible, setIsVisible] = React.useState(false);
  const openDrawer = React.useCallback(() => setIsVisible(true), []);
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  //image uplload
  const [imageSrc, setImageSrc] = useState(null);
  // const handleImageUpload = (event) => {
  //   const file = event.target.files[0];

  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = () => {
  //     //   setImageSrc(reader.result);
  //   };
  //   reader.onerror = (error) => {
  //     console.log('Error: ', error);
  //   };
  // };

  useIonViewWillEnter(() => {
    //setMenuEnabled(false);
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser?.JwtToken;
      resfreshtoken = currentUser?.RefreshToken;
    }
    GetProfie();
  });

  let logindata = JSON.parse(localStorage.getItem("logininfo") || "{}");

  if (logindata) {
    userdata = logindata?.Result;
    username = userdata?.UserName;
  }
  const GetProfie = async () => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 2000,
    });

    try {
      const response = await fetch(url + "/User/GetProfile", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      const json = await response.json();
      console.log("Profice", json);

      dismiss();
      if (json.StatusCode === 1) {
        setitems(json.Result);
        console.log(itemlist.ProfileUrl);
        //debugger
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      console.log(json);

      if (json.StatusCode === 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <div className="close">
              <IonIcon onClick={onClose} icon={close} placeholder={undefined} />
            </div>
            <div className="icon">
              <IonImg src="./assets/images/bigTrash.png" alt="" placeholder={undefined} />
            </div>
            <p>{t("editprofile.submit.title")}</p>
            <div className="react-confirm-alert-button-group">
              <IonButton onClick={onClose} className="alertcancel" placeholder={undefined}>
              {t("editprofile.submit.cancel")}
              </IonButton>
              <IonButton className="alertsubmit" placeholder={undefined}>{t("editprofile.submit.remove")}</IonButton>
            </div>
          </div>
        );
      },
    });
  };
  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);

  const save = async () => {
    console.log(inputValue);
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser?.JwtToken;
      resfreshtoken = currentUser?.RefreshToken;
    }
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 2000,
    });
    const data = { name: inputValue, imageBase64: imageBase64 };
    try {
      const response = await fetch(url + "/User/SaveProfile", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      console.log(json);
      presenttost({
        message: json.Message,
        duration: 1500,
        position: "bottom",
      });
      GetProfie();
      if (json.StatusCode === 2) {
        refreshtoken();
      }
      dismiss();
      debugger;
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  async function handleImageUpload(sourceType: CameraSource) {
    try {
      // Take a photo or select from the gallery
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        source: sourceType,
      });

      // Get the file path of the image
      const fileUri: any = image.webPath;
      const fileName = new Date().getTime() + ".jpeg";
      const filePath = `uploads/${fileName}`;

      // Write the image to the filesystem
      const binaryData = await fetch(fileUri).then((r) => r.blob());

      // Convert image to Base64
      const base64Image = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(binaryData);
      });

      // Store the Base64 data in a variable
      const imageBase64: any = base64Image;
      setImageSrc(imageBase64);
      //remove data:image/jpeg;base64, portion from Base64
      var strImage = imageBase64.replace(/^data:image\/[a-z]+;base64,/, "");
      setImageBase64(strImage);
      onClose();
      const savedFile = await Filesystem.writeFile({
        path: filePath,
        data: strImage,
        directory: Directory.Data,
      });
      console.log("Image saved to:", savedFile.uri);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <IonPage className="intro" placeholder={undefined}>
      <IonHeader className="ion-text-center" placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <IonButtons slot="start" placeholder={undefined}>
            <IonButton href="/tabs/profile" color="primary" placeholder={undefined}>
              <IonIcon icon={chevronBack} placeholder={undefined} />
            </IonButton>
          </IonButtons>
          <IonTitle color="primary" className="font20 ion-no-padding" placeholder={undefined}>
            <span>{t("editprofile.edit_profile")}</span>
          </IonTitle>
          <IonButtons slot="end" placeholder={undefined}>
            {/* <IonButton color='primary'>
              <IonIcon icon={ellipsisVertical} />
            </IonButton> */}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      {/*<div>
        <input type="file" accept="image/*" onChange={handleImageUpload} />
        {imageSrc && <IonImg src={imageSrc} />}
          </div>*/}
      <IonContent className="listing" placeholder={undefined}>
        <IonGrid className="changepassword editprofile" placeholder={undefined}>
          <IonList placeholder={undefined}>
            <IonRow className="edit ion-text-center" placeholder={undefined}>
              <IonCol size="12" class="pro pointer" placeholder={undefined}>
                <div className="logo" onClick={openDrawer}>
                  {/* : "./assets/images/bang-circle-blue.svg" */}
                  {/* <IonImg src={imageSrc? imageSrc : itemlist.ProfileUrl } /> */}
                  <IonImg src={imageSrc ? imageSrc : itemlist.ProfileUrl} placeholder={undefined} />
                  {/* <IonImg src={itemlist.ProfileUrl ? itemlist?.ProfileUrl : './assets/images/bang-circle-blue.svg'} /> */}
                </div>
                <span>Pro</span>
              </IonCol>
              <IonCol size="12" className="ion-text-center" placeholder={undefined}>
                <h6 className="no-margin" onClick={openDrawer}>
                  <b>{t("editprofile.edit_picture")}</b>
                </h6>
              </IonCol>
            </IonRow>
            <IonItem className="inputwithicon tooltiparea" placeholder={undefined}>
              <IonLabel placeholder={undefined}>
                <IonText no-margin placeholder={undefined}>{t("editprofile.email")}</IonText>
              </IonLabel>
              <div className="labeltitle">
                {/* <IonIcon icon={informationCircleOutline} /> */}
                <IonImg
                  class="profileout"
                  src="./assets/images/bang-circle-blue.svg"
                  alt="" placeholder={undefined}                />
                <div className="tooltip">
                  {t("editprofile.registered_email_note")}
                </div>
              </div>
            </IonItem>
            <IonItem className="inputwithicon twoicon" placeholder={undefined}>
              {/* <IonIcon icon={mail} /> */}
              <IonImg
                class="profileout"
                src="./assets/images/darkmail.png"
                alt="" placeholder={undefined}              />
              <IonText color="primary" placeholder={undefined}>
                {itemlist?.Email ? itemlist.Email : userdata?.Email}
              </IonText>
            </IonItem>
            <IonItem className="inputwithicon tooltiparea" color="white" placeholder={undefined}>
              <IonLabel placeholder={undefined}>
                <IonText no-margin placeholder={undefined}>{t("editprofile.account")}</IonText>
              </IonLabel>
            </IonItem>
            <IonItem className="inputwithicon twoicon" placeholder={undefined}>
              {/* <IonIcon icon={calendarClearSharp} /> */}
              <IonImg
                class="profileout"
                src="./assets/images/calendar.png"
                alt="" placeholder={undefined}              />
              <IonText color="primary" placeholder={undefined}>{itemlist?.Account}</IonText>
            </IonItem>
            <IonItem className="inputwithicon tooltiparea" color="white" placeholder={undefined}>
              <IonLabel placeholder={undefined}>
                <IonText no-margin placeholder={undefined}>{t("editprofile.name")}</IonText>
              </IonLabel>
            </IonItem>
            <IonItem className="inputwithicon twoicon pr-0" placeholder={undefined}>
              {/* <IonIcon icon={person} /> */}
              <IonImg
                class="profileout"
                src="./assets/images/user-icon.png"
                alt="" placeholder={undefined}              />
              <IonInput
                name="username"
                value={inputValue ? inputValue : itemlist?.Name}
                onIonChange={handleInputChange}
                type="text"
                required placeholder={undefined}              ></IonInput>
            </IonItem>
          </IonList>
          <IonRow class="passwordbtn" placeholder={undefined}>
            <IonCol size="12" placeholder={undefined}>
              <IonButton color="primary" onClick={save} expand="block" placeholder={undefined}>
                {t("editprofile.save")}
              </IonButton>
            </IonCol>
          </IonRow>
          {/* <IonList>
          <IonItem className='inputwithicon tooltiparea' color='white'>
            <IonLabel>
                <IonText no-margin>Email</IonText>
            </IonLabel>
          </IonItem>
          <IonItem className='inputwithicon'>
              <IonIcon icon={mail} />
              <IonInput name="mail" type="text" required></IonInput>
          </IonItem>
          <IonItem className='inputwithicon tooltiparea' color='white'>
            <IonLabel>
                <IonText no-margin>Account</IonText>
            </IonLabel>
          </IonItem>
          <IonItem className='inputwithicon'>
              <IonIcon icon={basket} />
              <IonInput name="username" type="text" required></IonInput>
          </IonItem>
          <IonItem className='inputwithicon tooltiparea' color='white'>
            <IonLabel>
                <IonText no-margin>Name</IonText>
            </IonLabel>
          </IonItem>
          <IonItem className='inputwithicon'>
              <IonIcon icon={person} />
              <IonInput name="username" type="text" required></IonInput>
          </IonItem>
        </IonList> */}
        </IonGrid>
      </IonContent>

      <Drawer
        className="bottompopup skateInBottom bottompopupresult"
        isVisible={isVisible}
        onClose={onClose}
      >
        <IonRow placeholder={undefined}>
          <IonList className="list" placeholder={undefined}>
            <IonItem
              class="ion-no-padding"
              onClick={() => handleImageUpload(CameraSource.Camera)} placeholder={undefined}            >
              <IonImg src="./assets/images/camerawhite.png" alt="" placeholder={undefined} />
              <IonLabel placeholder={undefined}>{t("editprofile.drawer.take_photo")}</IonLabel>
            </IonItem>
            <IonItem
              class="ion-no-padding"
              onClick={() => handleImageUpload(CameraSource.Photos)} placeholder={undefined}            >
              <IonImg src="./assets/images/Chooseimage.png" alt="" placeholder={undefined} />
              <IonLabel placeholder={undefined}>{t("editprofile.drawer.choose_from_library")}</IonLabel>
            </IonItem>
            <IonItem onClick={submit} class="ion-no-padding" placeholder={undefined}>
              <IonImg src="./assets/images/Trash.png" alt="" placeholder={undefined} />
              <IonLabel placeholder={undefined}>{t("editprofile.drawer.remove_current_picture")}</IonLabel>
            </IonItem>
          </IonList>
        </IonRow>
      </Drawer>

      {/* <IonModal className='profilemodal' trigger="open-modal" initialBreakpoint={0.25} breakpoints={[0, 0.4, 1]}>
        <IonContent>
            <IonList className='list'>
              <IonItem class="ion-no-padding">
                <IonImg src="./assets/images/camerawhite.png" alt="" />
                <IonLabel>Take photo</IonLabel>
              </IonItem>
              <IonItem class="ion-no-padding">
                <IonImg src="./assets/images/Chooseimage.png" alt="" />
                <IonLabel>Choose from library</IonLabel>
              </IonItem>
              <IonItem onClick={submit} class="ion-no-padding">
                <IonImg src="./assets/images/Trash.png" alt="" />
                <IonLabel>Remove current picture</IonLabel>
              </IonItem>
            </IonList>
        </IonContent>
      </IonModal> */}
    </IonPage>
  );
};

export default React.memo(Editprofile);
