import React, { useEffect, useState } from 'react';
import {IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonImg, IonLabel, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import {Route, Redirect, useHistory } from 'react-router';
import ProgressPage from './Progress';
import ProfilePage from './Profile';
import SettingsPage from './Settings';
import Adjustmylevel from './Adjustmylevel';
import Currentlevel from './Currentlevel';
import Editprofile from './Editprofile';
import Leveldescriprion from './Leveldescriprion';
import Languages from './Languages';
import Notifications from './Notifications';
import FAQs from './FAQs';
import Privacypolicy from './Privacypolicy';
import Changepassword from './Changepassword';
import Mysubscription from './Mysubscription';
import Subscribe from './Subscribe';
import Themesports from './Themesports';
import Wittywizard from './Wittywizard';
import Proficient from './Proficient';
import Home from './Home';
import Lesson from './Lesson';
import Notificationbell from './Notificationbell';
import Detailedreports from './Detailedreports';
import Vocabularybank from './Vocabularybank';
import Starredvocabulary from './Starredvocabulary';
import Dialogue from './Dialogue';
import Dialoguedone from './Dialoguedone';
import Practicecompleted from './Practicecompleted';
import Lessonpractice from './Lessonpractice';
import Dialogueresult from './Dialogueresult';
import Practiceresult from './Practiceresult';
import Dragdroplesson from './Dragdroplesson';
import Lessoncompleted from './Lessoncompleted';
import Practicequestion from './Practicequestion';
import E7ev1 from './E7ev1';
import Practicevocab from './Practicevocab';
import E4ev1vocab from './E4ev1vocab';
import E5ev1vocab from './E5ev1vocab';
import E6ev1vocab from './E6ev1vocab';
import Vocabcompleted from './Vocabcompleted';
import Dialoguepractice from './Dialoguepractice';
import Dialoguevideo from './Dialoguevideo';
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import E1sResultDetails from './E1sResultDetails';
import E2sResultDetails from './E2sResultDetails';
import E3sResultDetails from './E3sResultDetails';
import E4sResultDetails from './E4sResultDetails';
import E5sResultDetails from './E5sResultDetails';
import E6sResultDetails from './E6sResultDetails';

interface MainTabsProps { }
const MainTabs: React.FC<MainTabsProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();

  // const [homeIcon,setHomeIcon] = useState<boolean>()
  // const [profileIcon,setProfileIcon] = useState<boolean>()
  // const [progressIcon,setProgressIcon] = useState<boolean>()
  // const [settingsIcon,setSettingsIcon] = useState<boolean>()

  // useIonViewWillEnter(() => {
  //   setHomeIcon(true)
  //   setProfileIcon(false)
  //   setProgressIcon(false)
  //   setSettingsIcon(false)
  // });

  // const navigateToHome = () => {
  //   history.replace('/tabs/home');
  //   setHomeIcon(true)
  //   setProfileIcon(false)
  //   setProgressIcon(false)
  //   setSettingsIcon(false)
  // };
  // const navigateToProfile= () => {
  //   history.replace('/tabs/profile');
  //   setHomeIcon(false)
  //   setProfileIcon(true)
  //   setProgressIcon(false)
  //   setSettingsIcon(false)
  // };
  // const navigateToProgress= () => {
  //   history.replace('/tabs/progress');
  //   setHomeIcon(false)
  //   setProfileIcon(false)
  //   setProgressIcon(true)
  //   setSettingsIcon(false)
  // };
  // const navigateToSettings= () => {
  //   history.replace('/tabs/settings');
  //   setHomeIcon(false)
  //   setProfileIcon(false)
  //   setProgressIcon(false)
  //   setSettingsIcon(true)
  // };

  // const navigateToHome = () => {
  //   history.replace('/tabs/home');
  // };
  // const navigateToProfile= () => {
  //   history.replace('/tabs/profile');
  // };
  // const navigateToProgress= () => {
  //   history.replace('/tabs/progress');
  // };
  // const navigateToSettings= () => {
  //   history.replace('/tabs/settings');
  // };

 // Retrieve the activeTab from localStorage on component initialization
  const initialActiveTab = localStorage.getItem('activeTab') || 'home';
  const [activeTab, setActiveTab] = useState(initialActiveTab);

  const navigateToTab = (tab: string) => {
    history.replace(`/tabs/${tab}`);
    setActiveTab(tab);
    // Save the activeTab in localStorage
    localStorage.setItem('activeTab', tab);
  };

  useEffect(() => {
    // Set the activeTab in localStorage whenever it changes
    localStorage.setItem('activeTab', activeTab);
  }, [activeTab]);

  useIonViewWillLeave(() => {
    // Clear localStorage when leaving the view
    localStorage.removeItem('activeTab');
  });
 
  return (
    <IonTabs>
      <IonRouterOutlet placeholder={undefined}>
        <Redirect exact path="/tabs" to="/tabs/home" />
        {/*
          Using the render method prop cuts down the number of renders your components will have due to route changes.
          Use the component prop when your component depends on the RouterComponentProps passed in automatically.
        */}
        <Route path="/tabs/home" component={Home} />
        {/* <Route path="/tabs/home" render={() => <Home />} exact={true} /> */}
      {/* <Route path="/tabs/progress" render={() => <ProgressPage/>} exact={true} /> */}
        <Route path="/tabs/progress" component={ProgressPage} exact={true} />
        {/* <Route path="/tabs/profile" render={() => <ProfilePage />} exact={true} /> */}
        <Route path="/tabs/profile" component={ProfilePage} />
        <Route path="/tabs/profile/editprofile" render={() => <Editprofile />} exact={true} />
        <Route path="/tabs/profile/currentlevel" render={() => <Currentlevel />} exact={true} />
        <Route path="/tabs/profile/leveldescriprion" render={() => <Leveldescriprion />} exact={true} />
        <Route path="/tabs/profile/adjustmylevel" render={() => <Adjustmylevel />} exact={true} />
        <Route path="/tabs/settings" render={() => <SettingsPage />} exact={true} />
        <Route path="/tabs/settings/Languages" component={Languages} />
        <Route path="/tabs/settings/Notifications" render={() => <Notifications />} exact={true} />
        <Route path="/tabs/settings/FAQs" render={() => <FAQs />} exact={true} />
        <Route path="/tabs/settings/Privacypolicy" render={() => <Privacypolicy />} exact={true} />
        <Route path="/tabs/settings/Changepassword" render={() => <Changepassword />} exact={true} />
        <Route path="/tabs/profile/Mysubscription" render={() => <Mysubscription />} exact={true} />
        <Route path="/tabs/profile/Subscribe" render={() => <Subscribe />} exact={true} />
        <Route path="/tabs/home/Themesports" component={Themesports} exact={true} />
        <Route path="/tabs/home/wittywizard" render={() => <Wittywizard />} exact={true} />
        <Route path="/tabs/home/proficient" component={Proficient} />
        {/* <Route path="/tabs/proficient" render={() => <Proficient />} exact={true} /> */}
        
        <Route path="/tabs/home/lesson" component={Lesson} />
        {/* <Route path="/tabs/lesson" render={() => <Lesson />} exact={true} /> */}
        <Route path="/tabs/home/Notificationbell" render={() => <Notificationbell />} exact={true} />
        <Route path="/tabs/progress/Detailedreports" component={Detailedreports} />
          {/* <Route path="/tabs/Detailedreports" render={() => <Detailedreports />} exact={true} /> */}
        {/* <Route path="/tabs/Vocabularybank" render={() => <Vocabularybank />} exact={true} /> */}
        <Route path="/tabs/home/Dialogue" component={Dialogue} />
        <Route path="/tabs/home/Dialoguevideo" component={Dialoguevideo} />
        <Route path="/tabs/home/Dialoguedone" component={Dialoguedone} exact={true} />
        <Route path="/tabs/home/Dialoguepractice" component={Dialoguepractice} />
        <Route path="/tabs/home/Lessoncompleted" component={Lessoncompleted} />
        <Route path="/tabs/home/Practicecompleted" component={Practicecompleted} />
        {/* <Route path="/tabs/home/Practicecompleted" component={Practicecompleted} exact={true} /> */}
        <Route path="/tabs/home/practicequestion" component={Practicequestion} />
       
        {/* <Route path="/tabs/Whilepressing1" render={() => <Whilepressing1 />} exact={true} /> */}
   
        <Route path="/tabs/Lessonpractice" render={() => <Lessonpractice />} exact={true} />
        {/* <Route path="/tabs/home/Dialogueresult" render={() => <Dialogueresult />} exact={true} /> */}
        <Route path="/tabs/home/Dialogueresult" component={Dialogueresult} exact={true} />
        <Route path="/tabs/home/Practiceresult" component={Practiceresult} />
        {/* <Route path="/tabs/home/Practiceresult" render={() => <Practiceresult />} exact={true} /> */}
        <Route path="/tabs/Dragdroplesson" render={() => <Dragdroplesson />} exact={true} />
        <Route path="/tabs/E7ev1" component={E7ev1} />
        <Route path="/tabs/progress/Vocabularybank" component={Vocabularybank} />
        <Route path="/tabs/E4ev1vocab" component={E4ev1vocab} />
        <Route path="/tabs/E5ev1vocab" component={E5ev1vocab} />
        <Route path="/tabs/E6ev1vocab" component={E6ev1vocab} />
        <Route path="/tabs/Vocabcompleted" component={Vocabcompleted} />
        <Route path="/tabs/progress/Starredvocabulary" component={Starredvocabulary}/>
        <Route path="/tabs/home/E1sResultDetails" component={E1sResultDetails} />
        <Route path="/tabs/home/E2sResultDetails" component={E2sResultDetails} />
        <Route path="/tabs/home/E3sResultDetails" component={E3sResultDetails} />
        <Route path="/tabs/home/E4sResultDetails" component={E4sResultDetails} />
        <Route path="/tabs/home/E5sResultDetails" component={E5sResultDetails} />
        <Route path="/tabs/home/E6sResultDetails" component={E6sResultDetails} />
        <Route path="/tabs/Practicevocab" component={Practicevocab} />
        
        {/*<Route path="/tabs/speakers" render={() => <SpeakerList />} exact={true} />
        <Route path="/tabs/speakers/:id" component={SpeakerDetail} exact={true} />
        <Route path="/tabs/speakers/sessions/:id" component={SessionDetail} />
        <Route path="/tabs/map" render={() => <MapView />} exact={true} />
        <Route path="/tabs/about" render={() => <About />} exact={true} /> */}
      </IonRouterOutlet>
      <IonTabBar slot="bottom" placeholder={undefined}>

        {/* href="/tabs/home"  */}
        {/* <IonTabButton tab="home" onClick={navigateToHome}>
          <IonImg src={homeIcon === true ? "./assets/images/home-fill.svg" : "./assets/images/home.svg"} placeholder={undefined}/>
          <IonLabel color='primary' placeholder={undefined}>{t("maintabs.home")}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="progress" onClick={navigateToProgress}>
          <IonImg src={progressIcon === true ? "./assets/images/progressmenu-fill.svg" : "./assets/images/progressmenu.svg"} placeholder={undefined}/>
          <IonLabel color='primary' placeholder={undefined}>{t("maintabs.progress")}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="profile" onClick={navigateToProfile}>
          <IonImg src={profileIcon === true ? "./assets/images/profile-fill.svg" : "./assets/images/profile.svg"} placeholder={undefined}/>
          <IonLabel color='primary' placeholder={undefined}>{t("maintabs.profile")}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="settings" onClick={navigateToSettings}>
          <IonImg src={settingsIcon === true ? "./assets/images/setting-fill.svg" : "./assets/images/setting.svg"} placeholder={undefined}/>
          <IonLabel color='primary' placeholder={undefined}>{t("maintabs.settings")}</IonLabel>
        </IonTabButton> */}

        <IonTabButton tab="home" onClick={() => navigateToTab('home')} selected={activeTab === 'home'}>
          <IonImg src={`./assets/images/${activeTab === 'home' ? 'home-fill' : 'home'}.svg`} placeholder={undefined}/>
          <IonLabel placeholder={undefined}>{t("maintabs.home")}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="progress" onClick={() => navigateToTab('progress')} selected={activeTab === 'progress'}>
          <IonImg src={`./assets/images/${activeTab === 'progress' ? 'progressmenu-fill' : 'progressmenu'}.svg`} placeholder={undefined}/>
          <IonLabel placeholder={undefined}>{t("maintabs.progress")}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="profile" onClick={() => navigateToTab('profile')} selected={activeTab === 'profile'}>
          <IonImg src={`./assets/images/${activeTab === 'profile' ? 'profile-fill' : 'profile'}.svg`} placeholder={undefined}/>
          <IonLabel placeholder={undefined}>{t("maintabs.profile")}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="settings" onClick={() => navigateToTab('settings')} selected={activeTab === 'settings'}>
          <IonImg src={`./assets/images/${activeTab === 'settings' ? 'setting-fill' : 'setting'}.svg`} placeholder={undefined}/>
          <IonLabel placeholder={undefined}>{t("maintabs.settings")}</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};
export default MainTabs;