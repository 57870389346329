import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IonContent, IonList, IonItem, IonLabel, IonToggle, IonCol, IonText, IonCard, IonCardContent, IonRow, IonIcon, IonPage, useIonViewWillEnter, useIonLoading, useIonToast, IonSlides, IonSlide, IonImg } from '@ionic/react';
import './E5ev1vocab.scss';
import Drawer from "react-bottom-drawer";
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css';
import { CONFIG } from '../App';
import HanziWriter from 'hanzi-writer';
import ReactAudioPlayer from 'react-audio-player';
import useMediaRecorder from '@wmik/use-media-recorder';
import { RouteComponentProps, useHistory } from 'react-router';
import { json } from 'stream/consumers';
import { string } from 'yup';
// import Whilepressing1 from './E6ev1';
import 'swiper/swiper.min.css';
import '@ionic/react/css/ionic-swiper.css';

function Player({ srcBlob }: { srcBlob: any }) {
  //console.log('srcBlob', srcBlob);
  if (!srcBlob) {
    return null;
  } else {
    return <audio src={URL.createObjectURL(srcBlob)} controls />;
  }
}
interface MyObject {
  id: number;
  name: string;
  type: 'e5ev1',
  pagecouter: number,
  totalque: number
}
interface E5ev1vocabProps { itemE5EV1: any; onObjectChange: (object: MyObject) => void; }

const E5ev1vocab: React.FC<E5ev1vocabProps> = (props: E5ev1vocabProps) => {
  let url: string = CONFIG.API_ENDPOINT;//live

  const [isVisible, setIsVisible] = React.useState(false);
  const openDrawer = React.useCallback(() => setIsVisible(true), []);
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [showLoading, setShowLoading] = useState(false);

  const [showResults, setShowResults] = React.useState(1)
  const [progress, setProgress] = useState(0);
  const [isApicall, setApicall] = useState(0);
  const [length, setLength] = React.useState(-1)
  //CompareSentences
  const [sentence1, setSentence1] = useState('');
  const [sentence2, setSentence2] = useState('');

  const words1 = sentence1.split(' ');
  const words2 = sentence2.split(' ');
  const [showImage, setShowImage] = useState(false);
  const [myanswer, setmyanswer] = useState('');

  const notMatchingWords = words1.filter(word => !words2.includes(word))
    .concat(words2.filter(word => !words1.includes(word)));
  //end
  const spanRef = useRef(null);
  let token = ''
  let resfreshtoken = ''
  let pagecouter: any = 0
  let totalque: any = 0
  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);
  useIonViewWillEnter(() => {
    setLength(1)
    console.log('length nw', props.itemE5EV1.length);
    slidesRef.current?.getActiveIndex().then((index) => {
      console.log('index', (index + 1));
      console.log('length', props.itemE5EV1.length);
      if (props.itemE5EV1.length == (index + 1)) {
        console.log('Done')
        // setLength(-1)
        // saveContent()
        // handleChange()
      }
    })

    setShowResults(1)
  });
  function handleChange() {
    const myObject: MyObject = { id: 1, name: 'My Object', type: 'e5ev1', pagecouter: pagecouter, totalque: props.itemE5EV1.length };
    props.onObjectChange(myObject);
  }
  const slidesRef = useRef<HTMLIonSlidesElement>(null);

  const options = {
    keyboard: true
  };
  const onDataAvailable = (data) => {
    // Update your progress bar using the data.size property
    const progress =

      setProgress(prevProgress => prevProgress + data.size);
  };

  // const { startRecording, stopRecording } = useMediaRecorder({ onDataAvailable });

  let { error, status, mediaBlob, stopRecording, startRecording } =
    useMediaRecorder({
      recordScreen: false,
      blobOptions: { type: 'audio/webm' },
      mediaStreamConstraints: { audio: true, video: false },
      onDataAvailable
    });

  const handleStartRecording = () => {
    setProgress(0);
    setApicall(0);
    startRecording();
  };

  const handleStopRecording = () => {
    stopRecording();

    // setE4EV1(false)
    // setE5EV1(false)
    // setE6EV1(true)
  };

  const handleSlideTouchEnd = (event: CustomEvent) => {
    console.log('Slide touch end', event.detail);
  };

  //dbaudio
  function handleCheckword() {
    const spanElement = document.querySelector('.dbaudio');

    if (spanElement) {
      console.log('sentence1', spanElement.textContent);
      if (spanElement.textContent) {
        // setSentence1('hello my testing')
        setSentence1(spanElement.textContent)
      }

      console.log('sentence2', sentence2);
    } else {
      console.log('No span element found with class "dbaudio"');
    }
  }

  function blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => {
        const base64String = reader.result as string;
        resolve(base64String.split(',')[1]);
      };
      reader.onerror = () => {
        reject('Failed to convert blob to base64');
      };
    });
  }



  const convertaudiototext = async (audiobasebase64: any) => {

    present({
      message: '',
      cssClass: 'custom-spinner',
      duration: 1000
    })
    let data = {
      "base64String": audiobasebase64
    }

    try {
      const response = await fetch(url + '/account/SpeechAudio', {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        body: JSON.stringify(data)
      })
      const json = await response.json()

      //console.log(json)
      // debugger

      if (json.statusCode == 1) {
        setSentence2('')
        setSentence2(json.result)
        handleCheckword()
        // status = 'idle'
      } else {
        // presenttost({
        //   message: json.Message,
        //   duration: 1500,
        //   position: 'bottom'
        // });
      }
      setApicall(1)
      dismiss()

    } catch (error) {
      console.error(error)
      dismiss()
    }
  }

  const handleQuestionCheckClick = (items: any, index: any, indexq: any, type) => {
    // console.log(type)
    //console.log('soundno ', items?.Question?.[0].soundno)
    //console.log('questionno', type)

    // if (items?.Question?.[0].soundno == type) {
    //   isSuccess = true
    //   isWrong = false
    //   setSuccessOption(isSuccess);
    //   setWrongOption(isWrong);
    //   setSelectedDiv(type)
    //   console.log('q1', isSuccess)

    //   if (!props.itemE6EV1[index].answer) {
    //     props.itemE6EV1[index].answer = type
    //     props.itemE6EV1[index].score = true
    //     tmpscore += tmpscore
    //   }

    // } else {
    //   isSuccess = false
    //   isWrong = true
    //   setRightAnsOption(true)
    //   setSuccessOption(isSuccess);
    //   setWrongOption(isWrong);
    //   setSelectedDiv(type)
    //   if (!props.itemE6EV1[index].answer) {
    //     props.itemE6EV1[index].answer = type
    //     props.itemE6EV1[index].score = false
    //   }

    // }
    // console.log('itemE6EV1', props.itemE6EV1)
    //console.log('isSuccess', isSuccess)


    //console.log('isWrong', isWrong)
  };

  const gotonextques = async (items: any, index: any, type) => {

    //1 for Understand and 2= dont understand
    console.log(items)

    // console.log(type)
    // console.log('soundno ', items?.Question?.[0].soundno)
    // console.log('questionno', type)

    // if (items?.Question?.[0].soundno == type) {
    //   isSuccess = true
    //   isWrong = false
    // } else {
    //   isSuccess = false
    //   isWrong = true
    // }

    // console.log(showResults)
    //console.log(itemlist)
    //console.log(itemlist[showResults - 1])

    //  let data = itemlist[showResults - 1];
    //debugger
    if (items) {
      // resetstate()
      setShowResults(index)
      // console.log('len ', showResults - 1)
      setLength(index)


      // ansques = data.QuestionId + ',' + type;
      // setQuestion([...quesdata, ansques]);


    }
  };
  useEffect(() => {
    // console.log(quesdata);

    //console.log('String', quesdata.join('#'))

    // console.log('showResults', showResults)
    // console.log('finalindex', length)
    // console.log('lengthque', lengthque)
    //console.log('teatemediaBlob', mediaBlob)
    if (mediaBlob) {
      //convertaudiototextlive(mediaBlob)

      blobToBase64(mediaBlob)
        .then(base64String => {
          // console.log('base64String', base64String);
          if (status === 'stopped' && isApicall == 0) {
            convertaudiototext(base64String)
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
    // console.log('this.itemlist-Final', itemlist);


    // if (length > props.itemE5EV1.length) {
    //   console.log('Done')
    //   setLength(-1)
    //   saveContent()
    //   handleChange()
    // }
    const timeoutId = setTimeout(() => {
      setShowImage(true);
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };


  });

  const handleSlideNextEnded = (event: CustomEvent<any>) => {
    console.log('Slide Next ended', event.detail);

    slidesRef.current?.getActiveIndex().then((index) => {
      console.log('index', (index + 1));
      setLength((index + 1))
      console.log('length', props.itemE5EV1.length);

    })
  }

  const handleSlidePrevEnded = (event: CustomEvent<any>) => {
    console.log('Slide Prev ended', event.detail);
    // setLength(length - 1)
    slidesRef.current?.getActiveIndex().then((index) => {
      console.log('index', (index + 1));
      setLength((index - 1))
      console.log('length', props.itemE5EV1.length);

    })
  }


  const handleSlideDidChanged = (event: CustomEvent<any>) => {
    console.log('Slide Did ended', length);

    slidesRef.current?.getActiveIndex().then((index) => {
      console.log('index', (index + 1));

      SaveExerciseQueAnswer(length)
      if ((index + 1) == length) {
        console.log("Go next page")
        setLength(-1)
        saveContent()
        handleChange()
      }
    })
  }

  const SaveExerciseQueAnswer = async (length) => {
    let currentUser = JSON.parse(localStorage.getItem('alltoken') || '{}');
    if (currentUser) {
      token = currentUser.JwtToken
      resfreshtoken = currentUser.RefreshToken
    }


    // let rigthans = '';
    // for (let index = 0; index < props.itemE4EV1.length; index++) {
    //   const element = props.itemE4EV1[index];
    //   for (let j = 0; j < element.Options.length; j++) {
    //     const elementans = element.Options[j];

    //     if (props.itemE4EV1[0]?.Question?.[0].soundno == elementans.questionno) {
    //       rigthans = elementans.Word;

    //     }
    //   }

    // }
    // console.log('rigthans', rigthans)
    // console.log('myanswer11', myanswer)
    const spanElement = document.querySelector('.dbaudio');

    let ans = {
      srcCompare: spanElement ? spanElement.textContent : '',
      destCompare: sentence2
    }

    const stringWithBackslashes = JSON.stringify(ans);
    const stringWithoutBackslashes = stringWithBackslashes.replace(/\\/g, "");
    console.log(stringWithoutBackslashes); // Output: {"q1":"2","q2":"4","q3":"4"}
    const data = {
      "ev_id": props.itemE5EV1[length-1].Ev_Id,
      "templateCode": props.itemE5EV1[length-1].TemplateCode,
      "question": props.itemE5EV1[length-1]?.SoundUrl,
      "rightAnswer": stringWithoutBackslashes,
      "answer": myanswer
    }
    console.log(length)
    console.log('SaveExerciseQueAnswer_Audio', data)
    debugger

    try {
      const response = await fetch(url + '/Book/SaveExerciseQueAnswer', {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token
        },
        method: "POST",
      })

      const json = await response.json()
      console.log(json)
      debugger
      if (json.StatusCode == 1) {


      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: 'bottom'
        });
      }


    } catch (error) {
      console.error(error)
    }
  }


  const saveContent = async () => {
    let currentUser = JSON.parse(localStorage.getItem('alltoken') || '{}');
    if (currentUser) {
      token = currentUser.JwtToken
      resfreshtoken = currentUser.RefreshToken
    }
    // present({
    //   message: '',
    // })

    // let anw = {};
    // let tmscore = 0;
    // for (let index = 0; index < props.itemE5EV1.length; index++) {
    //   const element = props.itemE5EV1[index];
    //   const propName = ("q" + (index + 1)).toString();
    //   const propValue = element.answer;;

    //   anw[propName] = (propValue).toString();
    //   // finalanswer = anw
    //   if (element.score) { tmscore += element.score }

    // }
    // console.log('tmpscore', tmscore)
    pagecouter = 0
    const spanElement = document.querySelector('.dbaudio');

    let ans = {
      srcCompare: spanElement ? spanElement.textContent : '',
      destCompare: sentence2
    }

    const stringWithBackslashes = JSON.stringify(ans);
    const stringWithoutBackslashes = stringWithBackslashes.replace(/\\/g, "");
    console.log(stringWithoutBackslashes); // Output: {"q1":"2","q2":"4","q3":"4"}
    const myArray = Object.entries(ans);
    console.log(myArray);
    // for (let k = 0; k < myArray.length; k++) {
    //   const element = myArray[k][1];
    //   if (element !='') {
    //     pagecouter++
    //   }

    // }

    if (myArray[0][1]) {
      pagecouter = 1
    }
    console.log(pagecouter);

    const data = {
      "contentPageCode": props.itemE5EV1[0] ? props.itemE5EV1[0].TemplateCode : props.itemE5EV1[length - 1].TemplateCode,
      "type": "exercise-score",
      "bookId": props.itemE5EV1[0].Book_id,
      "contentPageId": props.itemE5EV1[0] ? props.itemE5EV1[0].Page_id : props.itemE5EV1[length - 1].Page_id,
      answer: stringWithoutBackslashes,
      "score": 0,
      "options": "",
      "pageRead": pagecouter
    }

    //dismiss()
    console.log(data)
    debugger

    try {
      const response = await fetch(url + '/Book/SaveContent', {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token
        },
        method: "POST",
      })

      const json = await response.json()
      console.log(json)
      //dismiss()
      debugger
      if (json.StatusCode == 1) {


      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: 'bottom'
        });
      }

      // dismiss()

    } catch (error) {
      console.error(error)
      // dismiss()
    }

  }
  return (
    <IonPage className='intro' placeholder={undefined}>


      <IonContent className='listing' placeholder={undefined}>
     


        <IonSlides
          pager={false}
          scrollbar={true}
          options={options}
          onIonSlideTransitionEnd={handleSlideDidChanged}
          onIonSlideNextEnd={handleSlideNextEnded}
          onIonSlidePrevEnd={handleSlidePrevEnded}
          ref={slidesRef} placeholder={undefined}>
          {props.itemE5EV1.map((items, index) => {
            return (
              <IonSlide placeholder={undefined}>
                {props.itemE5EV1.map((itemse5, indexe5) => {
                  return (
                    <div key={itemse5.Ev_Id} style={{ display: showResults == indexe5 + 1 ? "block" : "none" }} className='audiorecord'>
                      <IonList className="ion-no-padding bottomfix record" placeholder={undefined}>

                        <IonRow className='Practice ion-padding-horizontal' placeholder={undefined}>
                          <IonCol placeholder={undefined}>
                            <IonText placeholder={undefined}>Record the given Chinese sentence</IonText>
                          </IonCol>
                          <IonCol size="12" className='test-audio ion-text-left whitebg' placeholder={undefined}>
                            <ReactAudioPlayer className='String' controls controlsList="nodownload noplaybackrate">
                              <source src={itemse5?.SoundUrl} type="audio/mpeg"></source>
                            </ReactAudioPlayer>
                          </IonCol>
                        </IonRow>

                        <IonRow className='answer ion-padding-horizontal' placeholder={undefined}>
                          <IonCol size='12' placeholder={undefined}>
                            <IonCard className='select' placeholder={undefined}>
                              <IonCardContent className='Lessonpracticeaudio' placeholder={undefined}>
                                <ul className='lession-icon'>
                                  <li><span className='dbaudio' ref={spanRef} id={itemse5.Ev_Id} dangerouslySetInnerHTML={{ __html: `${itemse5.Word}` }}></span></li>
                                  {/* <li>{sentence2}</li> */}

                                  {sentence2 ? <li>
                                    {words1.map(word => (
                                      <span className={notMatchingWords.includes(word) ? 'red' : ''}>{word} </span>
                                    ))}
                                  </li> : ''}

                                </ul>
                              </IonCardContent>
                            </IonCard>
                          </IonCol>
                        </IonRow>
                        <IonCol size='12' placeholder={undefined}>
                          {showImage && <IonImg className='slide' src="./assets/images/swipe-left-right.gif" placeholder={undefined} />}

                        </IonCol>
                      </IonList>
                      <IonList className='ion-text-center audio-bottom ion-padding-horizontal' placeholder={undefined}>
                        <IonRow placeholder={undefined}>
                          <IonCol size='12' className='back-forward' placeholder={undefined}>
                            <div className='audio-record'>
                              {status == 'stopped' ?
                                <Player srcBlob={mediaBlob} /> : ''}
                              {/* {status} */}
                              {status === 'stopped' || status === 'idle' ?
                                <span onClick={(e: any) => handleStartRecording()}><IonImg src="./assets/images/micCircle.svg" placeholder={undefined} /></span> : ''}
                              {status == 'recording' ?
                                <span onClick={(e: any) => handleStopRecording()} ><IonImg src="./assets/images/stop.svg" placeholder={undefined} /></span> : ''}
                              {/* <span><IonIcon icon={micCircleSharp} /></span> */}
                              <p>Press to record.</p>
                            </div>
                          </IonCol>
                          {/* <IonCol size='12'>
                            <IonButton expand="block" onClick={() => gotonextques(itemse5, showResults + 1, 1)}>Next</IonButton>
                          </IonCol> */}
                        </IonRow>

                      </IonList>
                    </div>

                  )
                })}
              </IonSlide>
            )
          })}

        </IonSlides>

      </IonContent>
      <Drawer className='bottompopup skateInBottom' isVisible={isVisible} onClose={onClose}>
        <IonRow placeholder={undefined}>
          <IonList className='list' placeholder={undefined}>
            {/* <IonItem class="ion-no-padding playbook">
              <div>
                <IonImg src="./assets/images/play.png" />
              </div>
              <div className="details ion-padding-start">
                <h4>Play book</h4>
                <span>Autoplay book audio</span>
              </div>
            </IonItem> */}
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>Lesson language</IonLabel>
              <div className='right-space'>
                <IonText color='primary' placeholder={undefined}>簡</IonText>
                <IonText placeholder={undefined}>繁</IonText>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>Chinese characters</IonLabel>
              <IonToggle checked slot="end" placeholder={undefined}></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>Pinyin</IonLabel>
              <IonToggle checked slot="end" placeholder={undefined}></IonToggle>
            </IonItem>
            {/* <IonItem class="ion-no-padding">
              <IonLabel>Translation</IonLabel>
              <IonToggle slot="end"></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding">
              <IonLabel>Font size</IonLabel>
              <IonImg src="./assets/images/Fontdown.png" />
              <IonImg src="./assets/images/Fontup.png" />
            </IonItem> */}
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>Playback speed</IonLabel>
              <div className='leftright'><a href='#'>&lt;</a> 1.0 <a href='#'>&gt;</a></div>
            </IonItem>
            {/* <IonItem class="ion-no-padding">
              <IonLabel>Bookmark</IonLabel>
              <IonIcon color='primary' icon={bookmark} />
            </IonItem> */}
          </IonList>
        </IonRow>
      </Drawer>

    </IonPage>
  );
};

export default React.memo(E5ev1vocab);