import React, { useEffect, useRef, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonItem,
  IonLabel,
  IonToggle,
  IonContent,
  IonText,
  IonList,
  IonCol,
  IonRow,
  IonIcon,
  IonPage,
  IonTitle,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
  IonImg,
  IonSpinner,
} from "@ionic/react";
import "./Dialoguepractice.scss";
import {
  chevronBack,
  ellipsisVertical,
  playBack,
  playForward,
} from "ionicons/icons";
import Drawer from "react-bottom-drawer";
import ReactAudioPlayer from "react-audio-player";
import { CONFIG } from "../App";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import useMediaRecorder from "@wmik/use-media-recorder";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

function Player({ srcBlob }: { srcBlob: any }) {
  //console.log('srcBlob', srcBlob);
  if (!srcBlob) {
    return null;
  } else {
    return <audio src={URL.createObjectURL(srcBlob)} controls />;
  }
}

interface OwnProps extends RouteComponentProps {
  id: string;
}

interface DialoguepracticeProps extends OwnProps {}

const Dialoguepractice: React.FC<DialoguepracticeProps> = ({ history }) => {

  const { t } = useTranslation();

  const [isTextVisible, setTextVisibility] = useState(true);
  const [isVisible, setIsVisible] = React.useState(false);
  const [isVisibletc, setIsVisibleTc] = React.useState(false);
  const openDrawer = React.useCallback(() => setIsVisible(true), []);
  const openDrawertc = React.useCallback(() => setIsVisibleTc(true), []);
  let url: string = CONFIG.API_ENDPOINT; //live
  // const params = useParams<{id: string}>();
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any[]>([]);
  const [progress, setProgress] = useState(0);
  const [isApicall, setApicall] = useState(0);
  const [isrecST, setrecST] = useState(0);
  const [isrecordindex, setRecordindex] = useState(0);
  const [isdisplay, setdisplayindex] = useState("eldb_0");
  const [iscurrentindex, setCurrentindex] = useState(0);
  const [sentence1, setSentence1] = useState("");
  const [sentence2, setSentence2] = useState("");
  const [seconds, setSeconds] = useState(1);
  const [isRunning, setIsRunning] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);

  const historyss = useHistory<{
    themeArray:any;
    index: string;
    dialogueId: string;
    themeId: string;
    themename: string;
    levelId: string;
    level: string;
    levname: string;
    backpage: string;
    title: string;
    role: number;
    rolename: string;
    allrole: string;
    videoUrl: string;
  }>();
  const themeArray = historyss.location.state
  ? historyss.location.state.themeArray
  : "";
  console.log(themeArray);
  const [isRecording, setIsRecording] = useState(false);
  const ionListRef = useRef<HTMLIonListElement>(null);
  const index2 = historyss.location.state ? historyss.location.state.index : "";
  const dialogueId = historyss.location.state
    ? historyss.location.state.dialogueId
    : "";
  const themeIddb = historyss.location.state
    ? historyss.location.state.themeId
    : "";
  const themename = historyss.location.state
    ? historyss.location.state.themename
    : "";

  const videoUrl = historyss.location.state
    ? historyss.location.state.videoUrl
    : "";

  const levelId = historyss.location.state
    ? historyss.location.state.levelId
    : "";
  const level = historyss.location.state ? historyss.location.state.level : "";
  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";
  const backpage = historyss.location.state
    ? historyss.location.state.backpage
    : "";
  debugger;
  const title = historyss.location.state ? historyss.location.state.title : "";
  const role = historyss.location.state ? historyss.location.state.role : "";
  const allrole = historyss.location.state
    ? historyss.location.state.allrole
    : "";

  let string = allrole;
  let one = "";
  let two = "";
  if (string) {
    let result = string.split(",");
    one = result[0];
    two = result[1];
  }
  const rolename = historyss.location.state
    ? historyss.location.state.rolename
    : "";

  const [index, setIndex] = useState(1);
  const [numRecords, setNumRecords] = useState(1);
  const [displayedItems, setDisplayedItems] = useState<any[]>([]);
  const [currentRole, setCurrentRole] = useState(rolename); // Initialize with 'Crew' role
  const [isCrewToLeft, setIsCrewToLeft] = useState(true); // State to toggle Crew alignment
  const [showTextSetTc, setShowTextSetTc] = useState(true);
  const [answer, setAnswer] = useState<any>();
  const [textStatus, setStatus] = useState<any[]>([]);

  const words1 = sentence1.split(" ");
  const words2 = sentence2.split(" ");
  const notMatchingWords = words1
    .filter((word) => !words2.includes(word))
    .concat(words2.filter((word) => !words1.includes(word)));
  let token = "";
  let resfreshtoken = "";
  const [Position, setPosition] = useState<any>();
  useIonViewWillEnter(() => {
    // console.log('useIonViewWillEnter')
    console.log(role);
    console.log(rolename);
    console.log(allrole);
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    let pagecode1 = "See Result";
    let pagecode2 = "Practice again";
    localStorage.setItem("pagecode1", JSON.stringify(pagecode1));
    localStorage.setItem("pagecode2", JSON.stringify(pagecode2));
    // getdialogue()
  });

  const switchRole = () => {
    // setitems((prevItems) => {
    //   const updatedItems = prevItems.map((item) => {
    //     if (item.Type === one) {
    //       return { ...item, Type: two }; // Switch Crew to Pax
    //     } else if (item.Type === two) {
    //       return { ...item, Type: one }; // Switch Pax to Crew
    //     }
    //     return item;
    //   });
    //   return updatedItems;
    // });
    // setIsCrewToLeft((prevIsCrewToLeft) => !prevIsCrewToLeft); // Toggle alignment
    //Jignesh
    //pax to crew
    if (currentRole === two) {
      for (let i = 0; i < itemlist.length; i++) {
        const element = itemlist[i];

        if (currentRole === element["Type"]) {
          element["Type"] = one;
        } else {
          element["Type"] = two;
        }
      }
    }
    if (currentRole === one) {
      for (let i = 0; i < itemlist.length; i++) {
        const element = itemlist[i];
        if (currentRole === element["Type"]) {
          element["Type"] = two;
        } else {
          element["Type"] = one;
        }
      }
    }
    for (let i = 0; i < itemlist.length; i++) {
      debugger;
      itemlist[i]["displayvalue"] = false;
      itemlist[i]["DisplayEmpty"] = false;
    }
    setIsVisible(false);
    setIsVisibleTc(false);
    let testarray: any = [];
    testarray = itemlist;
    setNumRecords(1);
    setIndex(1);
    setDisplayedItems([]);
    setitems([]);
    setRecordindex(0);
    setCurrentRole(currentRole === one ? two : one);
    setitems(testarray);
    setDisplayedItems([testarray[0]]);
    console.log("json.Result", itemlist);
    // handleClickNext();
  };

  const scrollToBottom = () => {
    if (ionListRef.current) {
      const scrollHeight = ionListRef.current.scrollHeight;
      const clientHeight = ionListRef.current.clientHeight;
      ionListRef.current.scrollTop = scrollHeight - clientHeight;
    }
  };

  useEffect(() => {
    // Scroll to bottom after a short delay to ensure the DOM has updated
    const scrollTimeout = setTimeout(() => {
      scrollToBottom();
    }, 100); // You can adjust the delay as needed
    // Clear the timeout if the component is unmounted or when displayedItems change
    return () => {
      clearTimeout(scrollTimeout);
    };
  }, [index]); // Trigger the effect whenever displayedItems changes

  const handleClickNext = () => {
    // Iterate through each item and update the display value and position based on index
    itemlist.forEach((item, index) => {
      item.displayvalue = index % 2 === 0; // Set displayvalue to true for even indices and false for odd indices
      item.position = index % 2 === 0 ? "left" : "right"; // Set position to 'left' for even indices and 'right' for odd indices
      setPosition(item.position);
    });
    // Iterate through each item and update the display value and position based on index
    // itemlist.forEach((item, index) => {
    //   item.displayvalue = index % 2 === 0; // Set displayvalue to true for even indices and false for odd indices
    //   item.position = index % 2 === 0 ? "left" : "right"; // Set position to 'left' for even indices and 'right' for odd indices
    //   // console.log(item.displayvalue);
    //   console.log(item.position);
    // });
    console.log("itemlistlength", itemlist.length);
    // console.log('index',index)
    // console.log('isrecordindex',isrecordindex)
    console.log("numRecords", numRecords + 1);
    // for (let i = 0; i < itemlist.length; i++) {
    //   // const element = itemlist[isrecordindex];
    //   itemlist[isrecordindex]["displayvalue"] = false;
    // }
    if (index < itemlist.length) {
      setNumRecords(numRecords + 1);
      // setDisplayedItems([...displayedItems, itemlist[index]]);
      // setDisplayedItems(prevItems => [...prevItems, itemlist[index]]);
      // setDisplayedItems(prevItems => [...prevItems, itemlist[index+1]]);
      setIndex((prevIndex) => prevIndex + 1);
      scrollToBottom();
    }
    // if (numRecords+1 === itemlist.length) {
    //   //alert("s")
    //   setIsVisibleTc(true);
    //   // history.replace({
    //   //   pathname: '/tabs/home/Dialoguedone',
    //   //   state: { themeId: themeIddb, themename: themename, levelId: levelId, level: level, levname: levname, dialogueId: dialogueId }
    //   // })

    // }
  };

  const handleClickNext_New = (newindex) => {
    console.log("handleClickNext_New");
    if (index < itemlist.length) {
      setNumRecords(numRecords + 1);
      setIndex((prevIndex) => prevIndex + 1);
      scrollToBottom();
    }
    for (let i = 0; i < itemlist.length; i++) {
      debugger;
      console.log("index = i", newindex + 1 + "==" + i);
      for (let i = 0; i < itemlist.length; i++) {
        if (newindex + 1 >= i) {
          // Code to execute if iscurrentindex is greater than i
          //console.log("iscurrentindex is greater than", i);
        } else {
          itemlist[i]["displayvalue"] = false;
          // Code to execute if iscurrentindex is less than or equal to i
          //console.log("iscurrentindex is less than or equal to", i);
        }
      }
      // if (iscurrentindex > i) {
      //   itemlist[i]["displayvalue"] = false;
      // }
      // console.log("Updated22", itemlist);
    }
    console.log("Updated1", itemlist);
    if (numRecords + 1 === itemlist.length) {
      setIsVisibleTc(true);
    }
  };

  const endPractice = () => {
    history.replace({
      pathname: "/tabs/home/Dialoguedone",
      state: {
        themeArray:themeArray,
        videoUrl: videoUrl,
        index: index2,
        themeId: themeIddb,
        themename: themename,
        levelId: levelId,
        level: level,
        levname: levname,
        dialogueId: dialogueId,
        allrole: one + "," + two,
        rolename: rolename,
        role: role,
        backpage: backpage,
      },
    });
    let pagecode1 = "See Result";
    let pagecode2 = "Practice again";
    localStorage.setItem("pagecode1", JSON.stringify(pagecode1));
    localStorage.setItem("pagecode2", JSON.stringify(pagecode2));
  };

  const handleClickPrev = () => {
    if (index > 1) {
      setDisplayedItems((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems.splice(-1, 1); // Remove the last item from the array
        return updatedItems;
      });
      setIndex((prevIndex) => prevIndex - 1);
    }
  };

  const getdialogue = async () => {};

  const textset_tc = (data_tc, data_py) => {
    //console.log(data_tc)
    //debugger
    const html_tc = data_tc;
    const html_py = data_py;

    const range = document.createRange();
    const fragment_tc = range.createContextualFragment(html_tc);
    const fragment_py = range.createContextualFragment(html_py);

    const textreturn_tc = fragment_tc.textContent;
    const textreturn_py = fragment_py.textContent;

    const text_tc = textreturn_tc;
    const text_py = textreturn_py;
    const fragmentsa: any = [];

    if (text_tc && text_py) {
      const outputArray = text_tc.split(/(?![，。;：””''！？（）、])/g);

      const words_tc = outputArray;
      const words_py = text_py.split(" ");

      if (words_tc.length === words_py.length) {
        for (let i = 0; i < words_tc.length; i++) {
          const wordtc = words_tc[i] as never;
          let data = {
            words_tc: wordtc,
            words_py: "",
          };
          fragmentsa.push(data);
        }
        for (let i = 0; i < words_py.length; i++) {
          const wordpy = words_py[i] as never;
          if (wordpy !== "" && wordpy !== " " && wordpy !== undefined) {
            fragmentsa[i]["words_py"] = wordpy ? wordpy : "";
          } else {
            fragmentsa[i]["words_py"] = "";
          }
        }
        const fragments = fragmentsa.map((word, index) => (
          <React.Fragment key={index}>
            <li>
              <span className="ion-text-center dbaudio" id="top-center">
                {word["words_tc"]}
              </span>
              <p className="wordpy">{word["words_py"]}</p>
            </li>{" "}
          </React.Fragment>
        ));
        //const fragments = ''
        return fragments;
      } else {
        const arr1 = words_tc;
        const arr2 = words_py;

        const maxLength = Math.max(arr1.length, arr2.length);
        const paddedArr1 = arr1.concat(Array(maxLength - arr1.length).fill(""));
        const paddedArr2 = arr2.concat(Array(maxLength - arr2.length).fill(""));

        for (let i = 0; i < paddedArr1.length; i++) {
          const wordtc = paddedArr1[i] as never;
          let data = {
            words_tc: wordtc,
            words_py: "",
          };
          fragmentsa.push(data);
        }
        for (let i = 0; i < paddedArr2.length; i++) {
          const wordpy = paddedArr2[i] as never;
          if (wordpy !== "" && wordpy !== " " && wordpy !== undefined) {
            fragmentsa[i]["words_py"] = wordpy ? wordpy : "";
          } else {
            fragmentsa[i]["words_py"] = "";
          }
        }
        const fragments = fragmentsa.map((word, index) => (
          <React.Fragment key={index}>
            <li>
              <span className="ion-text-center" id="top-center">
                {word["words_tc"]}
              </span>
              <p className="wordpy">{word["words_py"]}</p>
            </li>{" "}
          </React.Fragment>
        ));
        //const fragments = ''
        return fragments;
      }
    } else if (text_tc) {
      const words_tc = text_tc.split("");
      for (let i = 0; i < words_tc.length; i++) {
        const word = words_tc[i] as never;
        let data = {
          words_tc: word,
          words_py: "",
        };
        fragmentsa.push(data);
      }
      const fragments = fragmentsa.map((word, index) => (
        <React.Fragment key={index}>
          <li>
            <span className="ion-text-center" id="top-center">
              {word["words_tc"]}
            </span>
          </li>{" "}
        </React.Fragment>
      ));
      return fragments;
    } else if (text_py) {
      const words_py = text_py.split(" ");
    }
    // console.log('spans', fragments)
    //const htmls = { __html: spans.join('') };
  };

  const textset_tc_check1 = (data_tc, data_py, RightAnswer, indexs) => {
    console.log("numRecords_test ", numRecords);
    console.log("indexs_test ", indexs);
    return textset_tc_check1(data_tc, data_py, RightAnswer, indexs);
    // if (numRecords - 2 == indexs + 1) {
    //   return textset_tc_check1(data_tc, data_py, RightAnswer, indexs);
    // } else {
    //   return;
    // }
  };

  const test = (ContentTc, ContentPy, dbanswer, indexs) => {
    const sentence1 = ContentTc;
    const sentence2 = dbanswer ? dbanswer : "";
    const sentencepy = ContentPy;
    // const sentence1 = "您好！";
    // const sentence2 = "好歡迎登機";
    // const sentencepy = "hǎo!";

    const characters1 = sentence1.split("");
    const characters2 = sentence2.split("");
    const charPy = sentencepy.split(" ");

    const spChar = [
      "！",
      "(",
      "?",
      "!",
      "[",
      "，",
      "。",
      ";",
      "：",
      "”",
      "”",
      "'",
      "'",
      "？",
      "（",
      "）",
      "、",
      "]",
      ")",
    ];

    charPy.forEach((py, i) => {
      if (spChar.includes(characters1[i])) {
        charPy.splice(i, 0, " "); // Insert space at index i
      }
    });

    const result = characters1.map((text, i) => ({
      Text: text,
      Piniyan: charPy[i],
      // VoiceText: characters2[i] || null,
      status: spChar.includes(text) ? true : characters2.includes(text),
    }));

    console.log(result);
    const fragments = result.map((word, index) => (
      <React.Fragment key={index}>
        <li className={index === 0 ? "active" : ""}>
          <span
            className={
              word["status"] === false
                ? "ion-text-center red"
                : "ion-text-center"
            }
            id="top-center"
          >
            {word["Text"]}
          </span>
          <p className={word["status"] === false ? "wordpy red" : "wordpy"}>
            {word["Piniyan"]}
          </p>
        </li>{" "}
      </React.Fragment>
    ));
    return fragments;
  };

  const back = () => {
    console.log("themeIddb", themeIddb);
    history.replace({
      pathname: "/tabs/home/" + backpage,
      state: {
        themeArray:themeArray,
        videoUrl: videoUrl,
        dialogueId: dialogueId,
        index: index2,
        themeId: themeIddb,
        themename: themename,
        levelId: levelId,
        level: level,
        levname: levname,
        role: role,
        rolename: rolename,
        allrole: one + "," + two,
      },
    });
    history.go(0);
    // window.location.reload();
    let pagecode1 = "Practice";
    localStorage.setItem("pagecode1", JSON.stringify(pagecode1));
    let pagecode2 = "Read again";
    localStorage.setItem("pagecode2", JSON.stringify(pagecode2));
  };
  const onClose = React.useCallback(() => {
    setIsVisible(false);
    setIsVisibleTc(true);
  }, []);

  //Audio
  const onDataAvailable = (data) => {
    // Update your progress bar using the data.size property
    const progress = setProgress((prevProgress) => prevProgress + data.size);
  };

  // const { startRecording, stopRecording } = useMediaRecorder({ onDataAvailable });

  let { error, status, mediaBlob, stopRecording, startRecording } =
    useMediaRecorder({
      recordScreen: false,
      blobOptions: { type: "audio/webm" },
      mediaStreamConstraints: { audio: true, video: false },
      onDataAvailable,
    });

  const handleStartRecording = () => {
    setSeconds(0)
    setIsRunning(true);
    setProgress(0);
    setApicall(0);
    startRecording();
    let leng = isrecST + 1;
    setrecST(leng);
    setIsRecording(true); // Set isRecording to true when recording starts
    scrollToBottom();
  };

  const handleStopRecording = () => {
    setIsRunning(false);  
    handleClickNext();
    // setInterval(function(){  
    //   handleClickNext();
    // }, 2000);  
    // handleClickNext_New(index);
    stopRecording();
    console.log("mediaBlob ", mediaBlob);
    setIsRecording(false); // Set isRecording to false when recording stops
    setIsSpinner(true)
    scrollToBottom();
    console.log(status);
    console.log(mediaBlob);
    if (error) {
      console.log(error);
    }
    debugger;
    if (numRecords + 1 === itemlist.length) {
      debugger;
      // alert("s")
      setIsVisibleTc(true);
      console.log("dialogue call");

      // history.replace({
      //   pathname: '/tabs/home/Dialoguedone',
      //   state: { themeId: themeIddb, themename: themename, levelId: levelId, level: level, levname: levname, dialogueId: dialogueId }
      // })
    }
  };
  //endu

  const convertaudiototext = async (audiobasebase64: any) => {
    // present({
    //   message: "",
    //   duration: 1000
    // });
    // let data = {
    //   "base64String": audiobasebase64
    // }
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    //  console.log(isrecordindex)
    let index = isrecordindex === 0 ? 0 : isrecordindex - 1;
    debugger;
    let data = {
      answerId:
        itemlist[index].Id === 1 ? itemlist[index].Id : itemlist[index].Id + 1,
      base64String: audiobasebase64,
      // role: rolename,
      role: rolename,
    };
    try {
      //Book/SaveDialogueAnswer
      const response = await fetch(url + "/Book/SaveDialogueAnswer", {
        // const response = await fetch(url + '/account/SpeechAudio', {
        // headers: {
        //   "Content-Type": "application/json",
        //   "Access-Control-Allow-Origin": "*",
        // },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
        body: JSON.stringify(data),
      });
      const json = await response.json();
      // dismiss();
      console.log(json);
      debugger;

      for (let i = 0; i < itemlist.length; i++) {
        itemlist[isrecordindex]["dbanswer"] = json.Result.Answer;
      }

      debugger;
      let iddb = "eldb_" + index;
      let idst = "elst_" + index;
      setdisplayindex(iddb);
      setCurrentindex(index);
      setRecordindex(isrecordindex + 2);
      handleClickNext_New(index);
      if (json.StatusCode === 1) {
        // setSentence2("");
        setSentence2(json.Result.Answer ? json.Result.Answer : "abxx");
        setIsSpinner(false)
        handleCheckword();

        // status = 'idle'
      } else {
        // presenttost({
        //   message: json.Message,
        //   duration: 1500,
        //   position: 'bottom'
        // });
      }
      setApicall(1);
      // dismiss();
      setTextVisibility(true);
    } catch (error) {
      console.error(error);
      // dismiss();
    }
    debugger;
    // for (let i = 0; i < itemlist.length; i++) {
    //   if (index > i) {
    //     itemlist[i]["displayvalue"] = false;
    //   }
    // }
    // console.log("Updated", itemlist);
  };

  function handleCheckword() {
    // present({
    //   message: "",
    //   duration: 1000,
    // });
    //handleClickNext_New();
    const spanElement = document.querySelector(".dbaudio");
    // debugger;
    if (spanElement) {
      //console.log('sentence1', spanElement.textContent);
      if (spanElement.textContent) {
        // setSentence1('hello my testing')
        setSentence1(itemlist[index - 1].ContentTc);
      }
      //  console.log('sentence1', sentence1);
      //  console.log('sentence2', sentence2);
    } else {
      console.log('No span element found with class "dbaudio"');
    }
  }

  function blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => {
        const base64String = reader.result as string;
        resolve(base64String.split(",")[1]);
      };
      reader.onerror = () => {
        reject("Failed to convert blob to base64");
      };
    });
  }

  useEffect(() => {
    // This callback will be called whenever `value` changes
    // console.log('Value changed: ', mediaBlob);
    if (mediaBlob) {
      // console.log(mediaBlob)
      //convertaudiototextlive(mediaBlob)
      blobToBase64(mediaBlob)
        .then((base64String) => {
          // console.log('base64String', base64String);
          if (status === "stopped" && isApicall === 0) {
            convertaudiototext(base64String);
            setTextVisibility(false);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [mediaBlob]);

  useEffect(() => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 1000,
    });
    // console.log('dialogueId. ', dialogueId);
    const fetchData = async () => {
      try {
        const response = await fetch(
          url + "/Book/GetDialogueQueAns?dialogueId=" + dialogueId,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            method: "GET",
          }
        );
        const json = await response.json();
        console.log(json);
        dismiss();
        setIndex(1);
        setDisplayedItems([]);
        setitems([]);
        if (json.StatusCode === 1) {
          debugger;
          for (let i = 0; i < json.Result.length; i++) {
            const element = json.Result[i];
            element["DisplayEmpty"] = false;
          }
          if (rolename === two) {
            for (let i = 0; i < json.Result.length; i++) {
              const element = json.Result[i];

              if (rolename === element["Type"]) {
                element["Type"] = one;
              } else {
                element["Type"] = two;
              }
            }
          }
          setitems(json.Result);
          setDisplayedItems([json.Result[0]]);
          console.log("json.Result", json.Result);
          debugger;
          handleClickNext();
        } else if (json.StatusCode === 2) {
          // refreshtoken()
        } else {
          presenttost({
            message: json.Message,
            duration: 1500,
            position: "bottom",
          });
        }
      } catch (error) {
        console.error(error);
        dismiss();
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    let timer;

    if (isRunning) {
      timer = setInterval(() => {
        if (seconds < 60) {
          setSeconds((prevSeconds) => prevSeconds + 1);
        } else {
          clearInterval(timer);
          setIsRunning(false);
          handleStopRecording()
        }
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isRunning, seconds]);

  const percentage = (seconds / 60) * 100;

  return (
    <IonPage className="intro" placeholder={undefined}>
      <IonHeader className="ion-text-center dubbletitle" placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <div>
            <IonButtons slot="start" placeholder={undefined}>
              <IonButton color="primary" onClick={back} placeholder={undefined}>
                <IonIcon icon={chevronBack} placeholder={undefined} />
              </IonButton>
            </IonButtons>
            <IonTitle color="primary" className="font20 ion-no-padding" placeholder={undefined}>
              <span>
                {t("dialoguepractice.dialogue")} : {t("dialoguepractice.practice")} <p className="ion-no-margin">{themeArray[index2]?.ContentEn}</p>
              </span>
            </IonTitle>
            <IonButtons slot="end" placeholder={undefined}>
              <IonButton onClick={openDrawer} color="primary" placeholder={undefined}>
                <IonIcon icon={ellipsisVertical} placeholder={undefined} />
              </IonButton>
            </IonButtons>
          </div>
        </IonToolbar>
      </IonHeader>

      <IonContent className="listing" placeholder={undefined}>
        <IonList
          ref={ionListRef}
          className="leftrightspace ion-padding-horizontal dialogue" placeholder={undefined}>
          <IonRow placeholder={undefined}>
            {itemlist && itemlist.length > 0 ? (
              <>
                {itemlist.slice(0, numRecords).map((items, indexs) => {
                  return (
                    <IonCol
                      className={items?.Type === one ? "play" : ""}
                      size="12"
                      key={indexs} placeholder={undefined}>
                      <div className="user">
                        {items?.Type === one ? (
                          <div className="usercircle">{items?.Type}</div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="dialoguebox">
                        <ul className="lession-icon">
                          {/* {itemlist[index].Title_Chinese} */}
                          {/* {items?.displayvalue} */}

                          <span id={`eldb_${indexs}`}>
                            {items?.displayvalue !== true
                              ? /* (items?.displayvalue !== true &&
                                items?.DisplayEmpty !== false) ||
                              ((items?.displayvalue === false ||
                                items?.displayvalue === undefined ||
                                items?.displayvalue === "") &&
                                items?.DisplayEmpty === false) */
                                textset_tc(items?.ContentTc, items?.ContentPy)
                              : ""}
                          </span>
                          <span id={`elst_${indexs}`}>
                            {isTextVisible && (
                              <>
                                {items?.displayvalue === true 
                                  && sentence2
                                  ? test(
                                      items?.ContentTc,
                                      items?.ContentPy,
                                      items?.dbanswer,
                                      indexs
                                    )
                                : ""}
                              </>
                            )}
                          </span>
                          {/* <span id={`elst_${indexs}`}>
                            {(items.displayvalue ==true) ?
                              <>
                                {sentence2 ? <li>
                                  {words1.map(word => (
                                    <span className={notMatchingWords.includes(word) ? 'red' : ''}>{word} </span>
                                  ))}

                                </li> : ''}
                              </> :
                              ''}
                          </span> */}

                          {/* {isrecordindex} */}
                        </ul>

                        {items?.displayvalue}
                        {/* {items?.dbanswer} */}
                        {/* {items.Type} */}
                        {/* {Position} */}
                        {/* <p>{items.Title_English} {isrecST} =  {index}  ={indexs}</p> */}
                        <p>{items.ContentEn}</p>
                        {items?.Type === one && isrecST === indexs ? (
                          <div className="ion-text-left audiowave">
                            {isRecording ? (
                              <IonImg
                                className="wave"
                                src="./assets/images/wave.svg" placeholder={undefined}/>
                            ) : (
                              <div>
                                <IonImg
                                    className="mic"
                                    src="./assets/images/mic.png" placeholder={undefined}/>
                                <span>{t("dialoguepractice.record_sentence")}</span>
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* <div className="dialoguebox">
                        <ul className="lession-icon">
                          <span id={`elst_${indexs}`}>{test()}</span>
                        </ul>
                      </div> */}

                      <div className="user">
                        {items?.Type === two ? (
                          <div className="usercircle">{items?.Type}</div>
                        ) : (
                          ""
                        )}
                      </div>
                    </IonCol>
                  );
                })}
              </>
            ) : (
              <p></p>
            )}
          </IonRow>
        </IonList>
        <IonList className="ion-text-center audio-bottom" placeholder={undefined}>
          <IonRow placeholder={undefined}>
            <IonCol size="12" className="back-forward" placeholder={undefined}>
              {/* <div>
                <button onClick={() => test()}>Test</button>
              </div> */}
              <div className="audio-record">
                {/* {status == 'stopped' ?
                  <Player srcBlob={mediaBlob} /> : ''} */}
                {/* {status} */}
                {/* {status === 'stopped' || status === 'idle' ?
                  <span onClick={(e: any) => handleStartRecording()}><IonImg src="./assets/images/micCircle.svg" /></span> : ''}
                {status === 'recording' ?
                  <span onClick={(e: any) => handleStopRecording()} ><IonImg src="./assets/images/stop.svg" /></span> : ''} */}
                {/* <span><IonIcon icon={micCircleSharp} /></span> */}
                {/* <span onClick={(e: any) => { isRecording ? handleStopRecording() : handleStartRecording() }} >
                  <IonImg src={`./assets/images/${isRecording ? "stop" : "micCircle"}.svg`} placeholder={undefined}/>
                  <div style={{ position: "absolute",  left: "0",right: "0", top: "-15px", margin: "0 auto", width: "150px" }}>
                    {isRecording ?
                    <CircularProgressbar
                      value={percentage}
                      // text={`${seconds}s`}
                      styles={buildStyles({
                        pathTransition: 'none',
                        // textSize: '16px',
                        // textColor: '#fff',
                        trailColor: 'var(--ion-color-warning-contrast-rgb)',
                        pathColor: 'var(--ion-color-primary)',
                      })}
                    />:""}
                  </div>
                </span>
                <p>{t("dialoguepractice.press_to_record")}</p> */}
                {!isSpinner?<>
                  <span onClick={(e: any) => { isRecording ? handleStopRecording() : handleStartRecording(); }} >
                    <IonImg src={`./assets/images/${isRecording ? "stop" : "micCircle"}.svg`} placeholder={undefined}/>
                      <div style={{ position: "absolute",  left: "0",right: "0", top: "-15px", margin: "0 auto", width: "150px" }}>
                                  {isRecording ?
                                  <CircularProgressbar
                                    value={percentage}
                                    // text={`${seconds}s`}
                                    styles={buildStyles({
                                      pathTransition: 'none',
                                      // textSize: '16px',
                                      // textColor: '#fff',
                                      trailColor: 'var(--ion-color-warning-contrastimage.png-rgb)',
                                      pathColor: 'var(--ion-color-primary)',
                                    })}
                                  />:""}
                                </div>
                              </span><p>{t("e5s.press_to_record")}</p></>
                              :
                              <IonSpinner name="crescent" style={{height:"70px",width:"70px",color:"var(--ion-color-warning)"}} />}
              </div>
            </IonCol>
          </IonRow>

          {/* <IonRow>
            <IonCol size='12' className='back-forward'>
              <div>
                {index == 1 ? <a ><IonIcon icon={playBack} /></a> : <a onClick={() => handleClickPrev()} className='select-arrow'><IonIcon icon={playBack} /></a>}
                <a onClick={() => handleClickNext()} className='select-arrow'><IonIcon icon={playForward} /></a>
              </div>
            </IonCol>
          </IonRow> */}
        </IonList>
      </IonContent>

      <Drawer
        className="bottompopup skateInBottom"
        isVisible={isVisible}
        onClose={onClose}
      >
        <IonRow placeholder={undefined}>
          <IonList className="list" placeholder={undefined}>
            {/* <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("dialoguepractice.drawer.lesson_language")}</IonLabel>
              <div className="right-space">
                <IonText color="primary" placeholder={undefined}>簡</IonText>
                <IonText placeholder={undefined}>繁</IonText>
              </div>
            </IonItem> */}
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("dialoguepractice.drawer.chinese_characters")}</IonLabel>
              <IonToggle checked slot="end" placeholder={undefined}></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("dialoguepractice.drawer.pinyin")}</IonLabel>
              <IonToggle checked slot="end" placeholder={undefined}></IonToggle>
            </IonItem>
            {/* <IonItem class="ion-no-padding">
            <IonLabel>Translation</IonLabel>
            <IonToggle slot="end"></IonToggle>
          </IonItem>
          <IonItem class="ion-no-padding">
            <IonLabel>Font size</IonLabel>
            <IonImg src="./assets/images/Fontdown.png" />
            <IonImg src="./assets/images/Fontup.png" />
          </IonItem> */}
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("dialoguepractice.drawer.playback_speed")}</IonLabel>
              <div className="leftright">
                <a href="#">&lt;</a> 1.0 <a href="#">&gt;</a>
              </div>
            </IonItem>
            {/* <IonItem class="ion-no-padding">
            <IonLabel>Bookmark</IonLabel>
            <IonIcon color='primary' icon={bookmark} />
          </IonItem> */}
          </IonList>
        </IonRow>
      </Drawer>

      <Drawer
        className="bottompopup switch skateInBottom"
        isVisible={isVisibletc}
        onClose={onClose}
      >
        <IonRow placeholder={undefined}>
          <IonList className="list Leisue" placeholder={undefined}>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <div className="mainpart">
                <IonButton
                  color="primary"
                  type="submit"
                  className="whitebtn"
                  expand="block"
                  onClick={switchRole} // Call the switchRole function on button click
                  placeholder={undefined}                  >
                  <IonImg className="wave" src="./assets/images/switch.png" placeholder={undefined} />{" "}
                  {t("dialoguepractice.drawer2.switch_role")}
                </IonButton>
                <IonButton
                  color="white"
                  type="submit"
                  className="whitebtn"
                  expand="block"
                  onClick={endPractice} placeholder={undefined}>
                  {t("dialoguepractice.drawer2.end_practice")}
                </IonButton>
              </div>
            </IonItem>
          </IonList>
        </IonRow>
      </Drawer>
    </IonPage>
  );
};

export default React.memo(Dialoguepractice);
