import React from 'react';
import { IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonImg, IonList, IonItem, IonLabel, IonContent, IonPage, IonTitle } from '@ionic/react';
import './Currentlevel.scss';
import { chevronBack, chevronForward } from 'ionicons/icons';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface CurrentlevelProps { }

const Currentlevel: React.FC<CurrentlevelProps> = () => {

  const { t } = useTranslation();

  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <div className='imgview'><IonImg src="./assets/images/goal.png" placeholder={undefined} /></div>
            <p className='popuppadding'>{t("currentlevel.submit.confirm_redo_test")}</p>
            <div className='react-confirm-alert-button-group'>
              <IonButton onClick={onClose} className="alertcancel" placeholder={undefined}>{t("currentlevel.submit.cancel")}</IonButton>
              <IonButton className="alertsubmit" placeholder={undefined}>{t("currentlevel.submit.redo")}</IonButton>
            </div>
          </div>
        );
      }
    });
  };
  return (
    <IonPage className='intro' placeholder={undefined}>
      <IonHeader className='ion-text-center' placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <IonButtons slot="start" placeholder={undefined}>
            <IonButton href='/tabs/profile' color='primary' placeholder={undefined}>
              <IonIcon icon={chevronBack} placeholder={undefined} />
            </IonButton>
          </IonButtons>
          <IonTitle color='primary' className='font20 ion-no-padding' placeholder={undefined}><span>{t("currentlevel.cur_level")}</span></IonTitle>
          <IonButtons slot="end" placeholder={undefined}>
            {/* <IonButton color='primary'>
              <IonIcon icon={ellipsisVertical} />
            </IonButton> */}
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className='listing' placeholder={undefined}>
        <IonList className='list selectlevels' placeholder={undefined}>
          <IonItem class="ion-no-padding" href='/tabs/profile/leveldescriprion' placeholder={undefined}>
            <IonLabel placeholder={undefined}>{t("currentlevel.level_desc")}</IonLabel>
            <IonIcon color='primary' icon={chevronForward} placeholder={undefined} />
          </IonItem>
          <IonItem class="ion-no-padding" href='/tabs/profile/adjustmylevel' placeholder={undefined}>
            <IonLabel placeholder={undefined}>{t("currentlevel.adj_level")}</IonLabel>
            <IonIcon color='primary' icon={chevronForward} placeholder={undefined} />
          </IonItem>
          <IonItem onClick={submit} class="ion-no-padding pointer" placeholder={undefined}>
            <IonLabel placeholder={undefined}>{t("currentlevel.redo_pro_test")}</IonLabel>
            <IonIcon color='primary' icon={chevronForward} placeholder={undefined} />
          </IonItem>
        </IonList>
    </IonContent>

</IonPage>
  );
};

export default React.memo(Currentlevel);