import React, { useEffect, useState } from 'react';
import { IonHeader, IonToolbar, IonContent, IonList, IonItem, IonToggle, IonLabel, IonIcon, IonPage, IonTitle, useIonLoading, useIonToast, useIonViewWillEnter } from '@ionic/react';
import './Settings.scss';
import { chevronForward } from 'ionicons/icons';
import { CONFIG } from '../App';
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface SettingsProps { }

const Settings: React.FC<SettingsProps> = () => {
  let url: string = CONFIG.API_ENDPOINT;//live

  const { t } = useTranslation();

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any[]>([])
  const [isapicall, setApiCall] = useState(false);

  let token = ''
  let resfreshtoken = ''
  let rap

  const handleToggleChangePy = async (event: CustomEvent) => {
    for (var key in itemlist) {
      //console.log("User " + itemlist[key] + " is #" + key); // "User john is #234"
      if (key == 'IsPinyin') {
        itemlist['IsPinyin'] = itemlist['IsPinyin'] == false ? true : false
      }
    }
    console.log('ddd1', itemlist)
    setApiCall(false)

    saveSetting()

  };
  const handleToggleChangeTc = async (event: CustomEvent) => {

    for (var key in itemlist) {
      if (key == 'IsTranslation') {
        itemlist['IsTranslation'] = itemlist['IsTranslation'] == false ? true : false
      }
    }
    console.log('ddd2', itemlist)
    saveSetting()

  };
  const handleToggleChangeAp = async (event: CustomEvent) => {

    for (var key in itemlist) {
      if (key == 'IsAutoPlay') {
        console.log(itemlist['IsAutoPlay'])
        itemlist['IsAutoPlay'] = itemlist['IsAutoPlay'] == false ? true : false
      }
    }
    console.log('ddd3', itemlist)
    saveSetting()
  };


  useIonViewWillEnter(() => {
    //setMenuEnabled(false);
    let currentUser = JSON.parse(localStorage.getItem('alltoken') || '{}');
    if (currentUser) {
      token = currentUser.JwtToken
      resfreshtoken = currentUser.RefreshToken
    }
  });


  const getSettings = async () => {
    present({
      message: '',
      cssClass: 'custom-spinner',
      duration: 1000
    })

    try {
      const response = await fetch(url + '/User/GetSetting', {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token
        },
        method: "GET",
      })

      const json = await response.json()
      console.log('Setting', json)

      dismiss()
      if (json.StatusCode == 1) {
        // set flag to true after initial settings have been loaded

        setitems(json.Result)


      } else if (json.StatusCode == 2) {
        refreshtoken()
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: 'bottom'
        });
      }


    } catch (error) {
      console.error(error)
      dismiss()
    }
  }

  const saveSetting = async () => {
    let currentUser = JSON.parse(localStorage.getItem('alltoken') || '{}');
    if (currentUser) {
      token = currentUser.JwtToken
      resfreshtoken = currentUser.RefreshToken
    }
    // present({
    //   message: '',
    //   //duration: 3000
    // })


    const data = {
      "isPinyin": itemlist['IsPinyin'],
      "isTranslation": itemlist['IsTranslation'],
      "isAutoPlay": itemlist['IsAutoPlay']
    }
    console.log(data);
    if (!isapicall) {
      try {
        const response = await fetch(url + '/User/SaveSetting', {
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
          },
          method: "POST",
        })

        const json = await response.json()
        console.log(json)
        if (json.StatusCode == 1) {


        } else {
          presenttost({
            message: json.Message,
            duration: 1500,
            position: 'bottom'
          });
        }

        dismiss()

      } catch (error) {
        console.error(error)
      }
    }

  }


  const refreshtoken = async () => {
    present({
      message: '',
      cssClass: 'custom-spinner',
      //duration: 3000
    })
    const data = { refreshToken: resfreshtoken }
    try {
      const response = await fetch(url + '/account/refreshtoken', {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      })
      if (!response.ok) {
        throw Error(response.statusText)
      }
      const json = await response.json()
      console.log(json)

      if (json.StatusCode == 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken
        }
        localStorage.setItem('alltoken', JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: 'bottom'
        });
      }

      dismiss()

    } catch (error) {
      console.error(error)
      dismiss()
    }
  }

  useEffect(() => {
    setApiCall(false)

    const getSettings = async () => {
      let currentUser = JSON.parse(localStorage.getItem('alltoken') || '{}');
      if (currentUser) {
        token = currentUser.JwtToken
        resfreshtoken = currentUser.RefreshToken
      }
      present({
        message: '',
        cssClass: 'custom-spinner',
        duration: 1000
      })
      setApiCall(true)

      try {
        const response = await fetch(url + '/User/GetSetting', {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
          },
          method: "GET",
        })

        const json = await response.json()
        console.log('Setting', json)

        dismiss()
        if (json.StatusCode == 1) {
          // set flag to true after initial settings have been loaded

          setitems(json.Result)


          // setitems(json.Result)
        } else if (json.StatusCode == 2) {
          refreshtoken()
        } else {
          presenttost({
            message: json.Message,
            duration: 1500,
            position: 'bottom'
          });
        }


      } catch (error) {
        console.error(error)
        dismiss()
      }
    }
    getSettings();
  }, []);

  return (
    <IonPage className='intro' placeholder={undefined}>
      <IonHeader className='ion-text-center' placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <IonTitle color='primary' className='font20' placeholder={undefined}><span>{t("settings.settings")}</span></IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className='listing spacingset' placeholder={undefined}>
        <IonList className='list' placeholder={undefined}>
          <IonItem class="ion-no-padding" href='/tabs/settings/Languages' placeholder={undefined}>
            <IonLabel placeholder={undefined}>{t("settings.languages")}</IonLabel>
            <IonIcon color='primary' className="rightpadding" icon={chevronForward} placeholder={undefined} />
          </IonItem>
          <IonItem class="ion-no-padding" placeholder={undefined}>
            <IonLabel placeholder={undefined}>{t("settings.pinyin")}</IonLabel>
            <IonToggle checked={itemlist['IsPinyin']} onIonChange={handleToggleChangePy} slot="end" placeholder={undefined} />

            {/* <IonToggle checked slot="end"></IonToggle> */}
          </IonItem>
          <IonItem class="ion-no-padding" placeholder={undefined}>
            <IonLabel placeholder={undefined}>{t("settings.translation")}</IonLabel>
            {/* <IonToggle slot="end"></IonToggle> */}
            <IonToggle checked={itemlist['IsTranslation']} onIonChange={handleToggleChangeTc} slot="end" placeholder={undefined} />

          </IonItem>
          <IonItem class="ion-no-padding" placeholder={undefined}>
            <IonLabel placeholder={undefined}>{t("settings.autoplay")}</IonLabel>
            {/* <IonToggle slot="end"></IonToggle> */}
            <IonToggle checked={itemlist['IsAutoPlay']} onIonChange={handleToggleChangeAp} slot="end" placeholder={undefined} />

          </IonItem>
          <IonItem class="ion-no-padding" href='/tabs/settings/Notifications' placeholder={undefined}>
            <IonLabel placeholder={undefined}>{t("settings.noti")}</IonLabel>
            <IonIcon color='primary' className="rightpadding" icon={chevronForward} placeholder={undefined} />
          </IonItem>
          <IonItem class="ion-no-padding" href='/tabs/settings/FAQs' placeholder={undefined}>
            <IonLabel placeholder={undefined}>{t("settings.freq_askd_que")}</IonLabel>
            <IonIcon color='primary' className="rightpadding" icon={chevronForward} placeholder={undefined} />
          </IonItem>
          <IonItem class="ion-no-padding" href='/tabs/settings/Privacypolicy' placeholder={undefined}>
            <IonLabel placeholder={undefined}>{t("settings.privcy_plcy")}</IonLabel>
            <IonIcon color='primary' className="rightpadding" icon={chevronForward} placeholder={undefined} />
          </IonItem>
          <IonItem class="ion-no-padding" href='/tabs/settings/Changepassword' placeholder={undefined}>
            <IonLabel placeholder={undefined}>{t("settings.chng_pass")}</IonLabel>
            <IonIcon color='primary' className="rightpadding" icon={chevronForward} placeholder={undefined} />
          </IonItem>
        </IonList>
      </IonContent>

    </IonPage>
  );
};

export default React.memo(Settings);