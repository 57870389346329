import React, { useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonList,
  IonCol,
  IonText,
  IonCard,
  IonCardContent,
  IonImg,
  IonIcon,
  IonPage,
  IonTitle,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
  IonCardHeader,
  IonRow,
} from "@ionic/react";
import "./Progress.scss";
import { arrowForwardCircleOutline, close } from "ionicons/icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ProgressBar from "@ramonak/react-progress-bar";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { CONFIG } from "../App";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import { RouteComponentProps, useHistory } from "react-router";
// import { RouteComponentProps, useHistory, useLocation } from "react-router";

// interface OwnProps extends RouteComponentProps {
//   id: string;
// }
interface OwnProps extends RouteComponentProps {
  id: string;
}

interface ProgressProps extends OwnProps {}

const Progress: React.FC<ProgressProps> = ({ history }) => {
  let url: string = CONFIG.API_ENDPOINT; //live

  const { t } = useTranslation();

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitemsprog] = React.useState<any>([]);
  const [itemlevellist, setitemslevel] = React.useState<any>([]);
  const [itemlistremcount, setitemsRemCount] = React.useState<any>([]);
  const [activeText, setActiveText] = useState("On Going");
  const [itemlogin, setLogindata] = React.useState<any>({});
  const [itemDetailReport, setDetailReport] = React.useState<any>({});
  const [bookmarkindex, setBookmarkindex] = React.useState<any>(-1);
  const [Reading, setReading] = React.useState<any>({});
  const [Writing, setWriting] = React.useState<any>({});
  const [Listening, setListening] = React.useState<any>({});
  const [Speaking, setSpeaking] = React.useState<any>({});

  const historyss = useHistory<{
    themeArray: string;
    themeId: string;
    themename: string;
    levelId: string;
    level: string;
    levname: string;
    backpage: string;
    index: string;
  }>();

  const themeArray = historyss.location.state
    ? historyss.location.state.themeArray
    : "";

  const themeIddb = historyss.location.state
    ? historyss.location.state.themeId
    : "";
  // setthemeIddb(0)
  // setthemeIddb(themeIddb)
  const themename = historyss.location.state
    ? historyss.location.state.themename
    : "";

  const levelId = historyss.location.state
    ? historyss.location.state.levelId
    : "";
  const level = historyss.location.state ? historyss.location.state.level : "";
  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";
  // console.log("themeId1. ", themeIddb);
  const backpage = historyss.location.state
    ? historyss.location.state.backpage
    : "";

  const index = historyss.location.state ? historyss.location.state.index : "";
  // console.log("backpage. ", backpage);

  let token = "";
  let resfreshtoken = "";
  let userdata: any;
  useIonViewWillEnter(() => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }

    GetMyLessons();
    GetRemainBooksCount();
    getUserLevel();
    GetDetailReport();
    let logindata = JSON.parse(localStorage.getItem("logininfo") || "{}");
    //userdata = logindata.Result
    setLogindata(logindata.Result);
    // debugger
  });
  const handleClick = (text) => {
    setActiveText(text);
  };
  const savebookmark = (val, index, type, value) => {
    console.log(val);

    //BookGuid
    //IsFavourite
    // if (value === 1) {
    //   if (itemlist.Ongoings[index].IsFavourite === 0) {
    //     // debugger;
    //     itemlist.Ongoings[index].IsFavourite = 1;
    //     setBookmarkindex(index);
    //     saveContent(val, true, type);
    //   } else {
    //     // debugger;
    //     itemlist.Ongoings[index].IsFavourite = 0;
    //     setBookmarkindex(-1);
    //     saveContent(val, false, type);
    //   }
    // } else if (value === 2) {
    //   if (itemlist.Completeds[index].IsFavourite === 0) {
    //     // debugger;
    //     itemlist.Completeds[index].IsFavourite = 1;
    //     setBookmarkindex(index);
    //     saveContent(val, true, type);
    //   } else {
    //     // debugger;
    //     itemlist.Completeds[index].IsFavourite = 0;
    //     setBookmarkindex(-1);
    //     saveContent(val, false, type);
    //   }
    // } else if (value === 3) {
    //   if (itemlist.Bookmarkeds[index].IsFavourite === 0) {
    //     // debugger;
    //     itemlist.Bookmarkeds[index].IsFavourite = 1;
    //     setBookmarkindex(index);
    //     saveContent(val, true, type);
    //   } else {
    //     // debugger;
    //     itemlist.Bookmarkeds[index].IsFavourite = 0;
    //     setBookmarkindex(-1);
    //     saveContent(val, false, type);
    //   }
    // }

    // setitemsprog((prevItemlist) => {
    //   const updatedItemlist = { ...prevItemlist }; // Make a copy of the previous state
    //   // Update the necessary properties in updatedItemlist based on the logic

    //   console.log("itemlistfinal", updatedItemlist); // Log the updated state

    //   return updatedItemlist; // Return the updated state
    // });
    
    setitemsprog((prevItemList) => {
      const newItemList = { ...prevItemList };
  
      if (value === 1) {
        const item = newItemList.Ongoings[index];
        item.IsFavourite = !item.IsFavourite;
        saveContent(val, item.IsFavourite, type);
  
        if (item.IsFavourite) {
          // If bookmarked, move from Ongoings to Bookmarkeds
          newItemList.Bookmarkeds.push(item);
          // newItemList.Ongoings.splice(index, 1);
        } else {
          // If unbookmarked, move based on Progress
          if (item.Progress === 100) {
            newItemList.Completeds.push(item);
          } else {
            newItemList.Ongoings.push(item);
          }
        }
      } else if (value === 2) {
        const item = newItemList.Completeds[index];
        item.IsFavourite = !item.IsFavourite;
        saveContent(val, item.IsFavourite, type);
  
        if (item.IsFavourite) {
          // If bookmarked, move from Completeds to Bookmarkeds
          newItemList.Bookmarkeds.push(item);
          // newItemList.Completeds.splice(index, 1);
        } else {
          // If unbookmarked, move to Ongoings
          newItemList.Ongoings.push(item);
        }
      } else if (value === 3) {
        const item = newItemList.Bookmarkeds[index];
        item.IsFavourite = !item.IsFavourite;
        saveContent(val, item.IsFavourite, type);
  
        if (!item.IsFavourite) {
          // If unbookmarked, move based on Progress
          // if (item.Progress === 100) {
          //   newItemList.Completeds.push(item);
          // } else {
          //   newItemList.Ongoings.push(item);
          // }
          newItemList.Bookmarkeds.splice(index, 1);
        }
      }
  
      return newItemList;
    });
  
    console.log("itemlistfinal", itemlist);
    
    
    // setitemsprog((prevItemList) => {
      //   const newItemList = { ...prevItemList };
      
    //   if (value === 1) {
    //     const item = newItemList.Ongoings[index];
    //     item.IsFavourite = item.IsFavourite === false ? true : false;
    //     saveContent(val, item.IsFavourite, type);
    //   } else if (value === 2) {
    //     const item = newItemList.Completeds[index];
    //     item.IsFavourite = item.IsFavourite === false ? true : false;
    //     saveContent(val, item.IsFavourite, type);
    //   } else if (value === 3) {
    //     const item = newItemList.Bookmarkeds[index];
    //     item.IsFavourite = item.IsFavourite === false ? true : false;
    //     saveContent(val, item.IsFavourite, type);
    //   }

    //   return newItemList;
    // });

    // console.log("itemlistfinal", itemlist);
  };

  const saveContent = async (val, isFavourite, type) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    debugger;
    const data = {
      referenceId: type == 1 ? val.BookId : val.Id,
      isFavourite: isFavourite,
      typeId: type,
    };
    //dismiss()
    console.log(data);

    try {
      const response = await fetch(url + "/User/Favourite", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });

      const json = await response.json();
      console.log(json);
      //dismiss()

      if (json.StatusCode == 1) {
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };

  const gotoPractice = (val, index) => {
    debugger;
    if (val.IsExistExercise == 1) {
      history.push({
        pathname: "/tabs/home/Practicequestion",
        state: {
          themeArray: val,
          themeId: themeIddb,
          lessonname: val.Title,
          index: index,
          lessonsId: val.BookGuid,
          themename: themename,
          levelId: levelId,
          level: level,
          levname: levname,
          backpage: "progress",
        },
      });
      history.go(0);
      // window.location.reload();
    } else {
      presenttost({
        message: "Exercise not available",
        duration: 1500,
        position: "bottom",
      });
    }
  };

  const getUserLevel = async () => {
    // present({
    //   message: "",
    //   duration: 1000,
    // });

    try {
      const response = await fetch(url + "/User/GetUserLevel", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      // if (!response.ok) {
      //   throw Error(response.statusText)
      // }
      const json = await response.json();
      console.log(json);
      debugger;

      dismiss();
      if (json.StatusCode === 1) {
        setitemslevel(json.Result);
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const GetMyLessons = async () => {
    // present({
    //   message: "",
    //   duration: 1000,
    // });

    try {
      const response = await fetch(url + "/Book/GetMyLessons", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      const json = await response.json();
      console.log("GetMyLessons", json);

      dismiss();
      if (json.StatusCode === 1) {
        setitemsprog(json.Result);
        //debugger
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const GetRemainBooksCount = async () => {
    // present({
    //   message: "",
    //   duration: 1000,
    // });

    try {
      const response = await fetch(url + "/Book/GetRemainBooksCount", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      const json = await response.json();
      console.log("GetRemainBooksCount", json);

      dismiss();
      if (json.StatusCode === 1) {
        setitemsRemCount(json.Result.RemainBooksCount);
        //debugger
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const GetDetailReport = async () => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 1000,
    });

    try {
      const response = await fetch(url + "/Book/GetDetailReport", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      const json = await response.json();
      console.log("GetDetailReport", json);

      dismiss();
      if (json.StatusCode === 1) {
        setDetailReport(json.Result);
        if (itemDetailReport !== null) {
          setReading(itemDetailReport.Reading || 0);
          setWriting(itemDetailReport.Writing || 0);
          setListening(itemDetailReport.Listening || 0);
          setSpeaking(itemDetailReport.Speaking || 0);
        }
        //debugger
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      console.log(json);

      if (json.StatusCode === 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <div className="close">
              <IonIcon onClick={onClose} icon={close} placeholder={undefined} />
            </div>
            <div className="imgview">
              <IonImg
                src="./assets/images/bicycle.svg"
                placeholder={undefined}
              />
            </div>
            <p>{t("progress.submit.level")} 2: Advanced Beginner</p>
            <p className="subtext">{t("progress.submit.msg")}</p>
          </div>
        );
      },
    });
  };

  const gonextPage = (val, index) => {
    //history.push('/tabs/home/themesports/5');
      history.replace({
        pathname: "/tabs/home/lesson",
        state: {
          themeArray: itemlist,
          themeId: val.ThemeGuid,
          lessonname: val.Title,
          index: index,
          lessonsId: val.BookGuid,
          bookId: val.Bookid,
          themename: themename,
          levelId: levelId,
          level: level,
          levname: levname,
          backpage: "progress",
          backpageValue: 2,
        },
      });
  };

  return (
    <IonPage className="intro" id="progress-page" placeholder={undefined}>
      <IonHeader className="ion-text-center" placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <IonTitle
            color="primary"
            className="font20 ion-no-padding"
            placeholder={undefined}
          >
            <span>{t("progress.progress")}</span>
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding-horizontal" placeholder={undefined}>
        <IonList
          className="themes ion-no-padding mylessons ion-margin-top"
          placeholder={undefined}
        >
          <IonCol placeholder={undefined}>
            <IonText placeholder={undefined}>
              {itemlogin?.UserName}, {t("progress.cur_lvl_txt")}
            </IonText>
          </IonCol>
        </IonList>

        <IonList className="PadT0 reports" placeholder={undefined}>
          <IonCard className="adjectcard progresscard" placeholder={undefined}>
            <IonCardContent class="ion-no-padding" placeholder={undefined}>
              <CircularProgressbar
                strokeWidth={10}
                text={itemlevellist.Level}
                value={50}
                styles={{
                  background: {
                    fill: "var(--ion-color-primary-tint)",
                    textAlign: "center",
                  },
                  path: { stroke: "var(--ion-color-primary)" },
                  trail: { stroke: "var(--ion-color-primary-tint)" },
                  text: {
                    fill: "var(--ion-color-primary)",
                    textAlign: "center",
                    position: "absolute",
                    verticalAlign: "middle",
                    lineHeight: "50",
                    fontSize: "52px",
                  },
                }}
              />
              {/* <div className="lessons_score donut-chart">
                <div className="slice one"></div>
                <div className="slice two"></div>
                <div className="chart-center"><span>2</span></div>
              </div> */}
              <div className="name">
                <h5>{itemlevellist.Name}</h5>
                <span>
                  {itemlistremcount} {t("progress.more_lsns")}{" "}
                </span>
              </div>
              {/* <IonIcon onClick={submit} color='primary' icon={informationCircleOutline} /> */}
              <IonImg
                className="info"
                onClick={submit}
                src="./assets/images/bang-circle-blue.svg"
                alt=""
                placeholder={undefined}
              />
            </IonCardContent>
          </IonCard>
        </IonList>

        <IonList
          className="themes ion-no-padding mylessons ion-margin-top"
          placeholder={undefined}
        >
          <IonCol placeholder={undefined}>
            <IonText placeholder={undefined}>{t("progress.re_over")}</IonText>
          </IonCol>
        </IonList>

        <IonList className="PadT0 progresscard reports" placeholder={undefined}>
          {/* <IonCard className="adjectcard">
            <IonCardContent class="ion-no-padding alignment">
              <div className="lessons_score donut-chart-number">204</div>
              <div className="name">
                <h5>{t("progress.vocb_lrnt")}</h5>
                <span>{t("progress.sub_txt")}</span>
              </div>
              <IonImg src="./assets/images/rightarrow.png" alt="" />
              <IonIcon color='primary' icon={arrowForwardCircleOutline} />
            </IonCardContent>
          </IonCard> */}

          <IonCard className="highlightarea" placeholder={undefined}>
            <IonCardHeader placeholder={undefined}>
              <IonRow className="progress-details" placeholder={undefined}>
                <IonCol
                  size="5"
                  className="ion-text-center"
                  placeholder={undefined}
                >
                  <img width="170" src="./assets/images/progress-round.png" />
                  {/* <div className="progress-bar-1">
                    <CircularProgressbar
                      strokeWidth={8}
                      value={Reading}
                      counterClockwise
                      styles={{
                        background: { fill: "var(--ion-color-primary-contrast-rgb)", textAlign: "center" },
                        path: { stroke: "var(--ion-color-primary)" },
                        trail: { stroke: "var(--ion-color-primary-contrast-rgb)" },
                      }}
                    />
                  </div>

                  <div className="progress-bar-2">
                    <CircularProgressbar
                      strokeWidth={10}
                      value={Writing}
                      counterClockwise
                      styles={{
                        background: { fill: "var(--ion-color-primary-contrast-rgb)", textAlign: "center" },
                        path: { stroke: "var(--ion-color-primary)" },
                        trail: { stroke: "var(--ion-color-primary-contrast-rgb)" },
                      }}
                    />
                  </div>

                  <div className="progress-bar-3">
                    <CircularProgressbar
                      strokeWidth={12}
                      value={Listening}
                      counterClockwise
                      styles={{
                        background: { fill: "var(--ion-color-primary-contrast-rgb)", textAlign: "center" },
                        path: { stroke: "var(--ion-color-primary)" },
                        trail: { stroke: "var(--ion-color-primary-contrast-rgb)" },
                      }}
                    />
                  </div>

                  <div className="progress-bar-4">
                    <CircularProgressbar
                      strokeWidth={14}
                      value={Speaking}
                      counterClockwise
                      styles={{
                        background: { fill: "var(--ion-color-primary-contrast-rgb)", textAlign: "center" },
                        path: { stroke: "var(--ion-color-primary)" },
                        trail: { stroke: "var(--ion-color-primary-contrast-rgb)" },
                      }}
                    />
                  </div> */}
                </IonCol>
                <IonCol size="7" placeholder={undefined}>
                  <ul>
                    <li>
                      <img src="./assets/images/colorReader.png" />{" "}
                      {t("progress.prgrs_dtil.reading")}
                    </li>
                    <li>
                      <img src="./assets/images/colorWriting.png" />{" "}
                      {t("progress.prgrs_dtil.writing")}
                    </li>
                    <li>
                      <img src="./assets/images/listening.png" />{" "}
                      {t("progress.prgrs_dtil.listening")}
                    </li>
                    <li>
                      <img src="./assets/images/speaking.png" />{" "}
                      {t("progress.prgrs_dtil.speaking")}
                    </li>
                  </ul>
                </IonCol>
              </IonRow>
            </IonCardHeader>
            <IonCardContent class="ion-no-padding" placeholder={undefined}>
              <IonRow placeholder={undefined}>
                <IonCol
                  size="12"
                  className="bank reports"
                  placeholder={undefined}
                >
                  <a href="/tabs/progress/Detailedreports">
                    <div>
                      <img alt="Bank" src="./assets/images/progress.png" />
                      <h5>{t("progress.dtil_rprt")}</h5>
                    </div>
                    <IonIcon
                      color="primary"
                      icon={arrowForwardCircleOutline}
                      placeholder={undefined}
                    />
                  </a>
                </IonCol>
              </IonRow>
            </IonCardContent>
          </IonCard>

          {/* <IonCard className="bank">
            <IonCardContent class="ion-no-padding">
              <a href="/tabs/progress/Vocabularybank">
                <IonIcon color='primary' icon={home} />
                <img alt="Bank" src="./assets/images/bank.png" />
                <h5>{t("progress.vocb_bnk")}</h5>
              </a>
            </IonCardContent>
          </IonCard> */}
        </IonList>

        <IonList className="themes reports mylessons" placeholder={undefined}>
          <IonCol placeholder={undefined}>
            <IonText placeholder={undefined}>{t("progress.my_lsns")}</IonText>
          </IonCol>

          <IonCol className="data-selection" placeholder={undefined}>
            <IonText
              className={activeText === "On Going" ? "select active" : ""}
              onClick={() => handleClick("On Going")}
              placeholder={undefined}
            >
              {t("progress.on_going")}
            </IonText>
            <IonText
              className={activeText === "Completed" ? "select active" : ""}
              onClick={() => handleClick("Completed")}
              placeholder={undefined}
            >
              {t("progress.completed")}
            </IonText>
            <IonText
              className={activeText === "Bookmarked" ? "select active" : ""}
              onClick={() => handleClick("Bookmarked")}
              placeholder={undefined}
            >
              {t("progress.bookmarked")}
            </IonText>
          </IonCol>

          {activeText === "On Going" ? (
            <div>
              {itemlist?.Ongoings?.length > 0 ? (
                itemlist?.Ongoings?.map((items, index) => {
                  return (
                    <IonCard key={index} placeholder={undefined}>
                      <IonCardContent
                        class="ion-no-padding"
                        placeholder={undefined}
                      >
                        <div className="lessongbox" onClick={() => gonextPage(items, index)}>
                          <div className="themelesson">
                            <IonImg
                              src={items.ImageUrl}
                              alt=""
                              placeholder={undefined}
                            />
                          </div>
                          <div className="details">
                            <h4>
                              {items.Theme}:{" "}{t("progress.lesson")}{" "}{index + 1}
                            </h4>
                            <span>{items.Title}</span>
                            <small>{t("progress.level")} 1</small>
                            {items.Progress !== 0 ? (
                              <div className="line">
                                <ProgressBar
                                  bgColor="var(--ion-color-primary)"
                                  height="9px"
                                  className="process"
                                  completed={items.Progress}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="icons">
                          <IonImg
                            onClick={() => savebookmark(items, index, 1, 1)}
                            className="ion-padding-bottom bookmark"
                            src={items.IsFavourite
                              ? "./assets/images/Bookmarkfill.svg"
                              : "./assets/images/Bookmark.svg"}
                            alt="" placeholder={undefined}                          />
                          <IonImg
                            onClick={() => gotoPractice(items, index)}
                            className="pen"
                            src="./assets/images/pen.svg"
                            alt="" placeholder={undefined}                          />
                        </div>
                      </IonCardContent>
                    </IonCard>
                  );
                })
              ) : (
                <p className="ion-text-center">{t("progress.no_dta_avilbl")}</p>
              )}
            </div>
          ) : (
            ""
          )}
          {activeText === "Completed" ? (
            <div>
              {itemlist?.Completeds?.length > 0 ? (
                itemlist?.Completeds?.map((items, index) => {
                  return (
                    <IonCard key={index}  placeholder={undefined}>
                      <IonCardContent
                        class="ion-no-padding"
                        placeholder={undefined}
                      >
                        <div className="lessongbox" onClick={() => gonextPage(items, index)}>
                          <div className="themelesson">
                            <IonImg
                              src={items.ImageUrl}
                              alt=""
                              placeholder={undefined}
                            />
                          </div>
                          <div className="details">
                            <h4>
                              {items.Theme}:{" "}{t("progress.lesson")}{" "}{index + 1}
                            </h4>
                            <span>{items.Title}</span>
                            <small>
                              {t("progress.level")} {items.Level}
                            </small>
                            {items.Progress !== 0 ? (
                              <div className="line">
                                <ProgressBar
                                  bgColor="var(--ion-color-primary)"
                                  height="9px"
                                  className="process"
                                  completed={items.Progress}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="icons">
                          <IonImg
                            onClick={() => savebookmark(items, index, 1, 2)}
                            className="ion-padding-bottom bookmark"
                            src={
                              items.IsFavourite
                                ? "./assets/images/Bookmarkfill.svg"
                                : "./assets/images/Bookmark.svg"
                            }
                            alt=""
                            placeholder={undefined}
                          />
                          <IonImg
                            onClick={() => gotoPractice(items, index)}
                            className="pen"
                            src="./assets/images/pen.svg"
                            alt=""
                            placeholder={undefined}
                          />
                        </div>
                      </IonCardContent>
                    </IonCard>
                  );
                })
              ) : (
                <p className="ion-text-center">{t("progress.no_dta_avilbl")}</p>
              )}
            </div>
          ) : (
            ""
          )}
          {activeText === "Bookmarked" ? (
            <div>
              {itemlist?.Bookmarkeds?.length > 0 ? (
                itemlist?.Bookmarkeds?.map((items, index) => {
                  return (
                    <IonCard key={index}  placeholder={undefined}>
                      <IonCardContent
                        class="ion-no-padding"
                        placeholder={undefined}
                      >
                        <div className="lessongbox" onClick={() => gonextPage(items, index)}>
                          <div className="themelesson">
                            <IonImg
                              src={items.ImageUrl}
                              alt=""
                              placeholder={undefined}
                            />
                          </div>
                          <div className="details">
                            <h4>
                              {items.Theme}:{" "}{t("progress.lesson")}{" "}{index + 1}
                            </h4>
                            <span>{items.Title}</span>
                            <small>{t("progress.level")} 1</small>
                            {items.Progress !== 0 ? (
                              <div className="line">
                                <ProgressBar
                                  bgColor="var(--ion-color-primary)"
                                  height="9px"
                                  className="process"
                                  completed={items.Progress}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="icons">
                          <IonImg
                            onClick={() => savebookmark(items, index, 1, 3)}
                            className="ion-padding-bottom bookmark"
                            src={
                              items.IsFavourite
                                ? "./assets/images/Bookmarkfill.svg"
                                : "./assets/images/Bookmark.svg"
                            }
                            alt=""
                            placeholder={undefined}
                          />
                          <IonImg
                            onClick={() => gotoPractice(items, index)}
                            className="pen"
                            src="./assets/images/pen.svg"
                            alt=""
                            placeholder={undefined}
                          />
                        </div>
                      </IonCardContent>
                    </IonCard>
                  );
                })
              ) : (
                <p className="ion-text-center">{t("progress.no_dta_avilbl")}</p>
              )}
            </div>
          ) : (
            ""
          )}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Progress);
