import React, { useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonSearchbar,
  IonButtons,
  IonButton,
  IonList,
  IonCol,
  IonItem,
  IonText,
  IonCard,
  IonImg,
  IonCardContent,
  IonContent,
  IonRow,
  IonIcon,
  IonPage,
  IonTitle,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
  IonLoading,
} from "@ionic/react";
import "./Vocabularybank.scss";
import {
  chevronBack,
  chevronForward,
  close,
  playCircle,
  search,
} from "ionicons/icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { CONFIG } from "../App";
import { RouteComponentProps, useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface OwnProps extends RouteComponentProps {}

interface VocabularybankProps extends OwnProps {}

const Vocabularybank: React.FC<VocabularybankProps> = ({ history }) => {
  let url: string = CONFIG.API_ENDPOINT; //live

  const { t } = useTranslation();

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [isVisible, setIsVisible] = React.useState(false);
  const [itemlist, setitems] = React.useState<any>([]);
  const [activeText, setActiveText] = useState("Level1");
  const [activeSearch, setActiveSearch] = useState(false);
  const [activeDetails, setActiveDetails] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [itemlevellist, setlevelitems] = React.useState<any>({});

  let token = "";
  let resfreshtoken = "";

  useIonViewWillEnter(() => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    handleClickResult("");
    GetLevels();
  });

  const GetLevels = async () => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 1000,
    });

    try {
      const response = await fetch(url + "/User/GetUserLevel", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      const json = await response.json();
      console.log("GetLevels", json);

      dismiss();
      if (json.StatusCode == 1) {
        setlevelitems(json.Result);
        //debugger
      } else if (json.StatusCode == 2) {
        //refreshtoken()
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const handleClickResult = async (word) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 1000,
    });

    try {
      const response = await fetch(
        url + "/User/GetVocabularyBank?word=" + word,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        }
      );

      const json = await response.json();
      console.log("GetVocabularyBank", json);

      dismiss();
      if (json.StatusCode == 1) {
        setitems(json.Result);
        //debugger
      } else if (json.StatusCode == 2) {
        //  refreshtoken()
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const handleClick = (text) => {
    setActiveText(text);
    setActiveSearch(false);
    setActiveDetails(false);
  };
  const handleClickvoocab = (item, level) => {
    setActiveDetails(true);
  };

  const searchhs = () => {
    if (activeSearch == true || activeDetails == true) {
      setActiveSearch(false);
      setActiveDetails(false);
    } else {
      setActiveSearch(true);
      history.replace({
        pathname: "/tabs/progress",
      });
    }
  };
  const handleSearch = (event: CustomEvent) => {
    const searchTerm = event.detail.value;
    // setShowLoader(true);

    // Perform search logic here
    console.log(`Performing search for: ${searchTerm}`);
    handleClickResult(searchTerm);
    //  setShowLoader(false);
  };
  let levid;
  const gotoprecvocab = () => {
    if (activeText === "Level1") {
      levid = 1;
    } else if (activeText === "Level2") {
      levid = 2;
    } else if (activeText === "Level3") {
      levid = 3;
    } else if (activeText === "Level4") {
      levid = 4;
    } else if (activeText === "Level5") {
      levid = 5;
    }

    history.replace({
      pathname: "/tabs/Practicevocab",
      state: { levelIdtype: levid },
    });
  };

  const Starredvocabulary = () => {
    if (activeText === "Level1") {
      levid = 1;
    } else if (activeText === "Level2") {
      levid = 2;
    } else if (activeText === "Level3") {
      levid = 3;
    } else if (activeText === "Level4") {
      levid = 4;
    } else if (activeText === "Level5") {
      levid = 5;
    }
    console.log("levid", levid);
    history.replace({
      pathname: "/tabs/progress/Starredvocabulary",
      state: { levelIdtype: levid },
    });
  };

  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <div className="close">
              <IonIcon onClick={onClose} icon={close} placeholder={undefined} />
            </div>
            <div className="icon">
              <IonImg src="./assets/images/message-square.png" alt="" placeholder={undefined} />
            </div>
            <p>Vocabulary to revise</p>
            <p className="subtext popuppadding">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiu.
            </p>
          </div>
        );
      },
    });
  };

  return (
    <IonPage className="intro" placeholder={undefined}>
      <IonHeader className="ion-text-center" placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          {activeSearch == true || activeDetails == true ? (
            <IonButtons onClick={searchhs} slot="start" placeholder={undefined}>
              <IonButton color="primary" placeholder={undefined}>
                <IonIcon icon={chevronBack} placeholder={undefined} />
              </IonButton>
            </IonButtons>
          ) : (
            <IonButtons onClick={searchhs} slot="start" placeholder={undefined}>
              <IonButton color="primary" placeholder={undefined}>
                <IonIcon icon={chevronBack} placeholder={undefined} />
              </IonButton>
            </IonButtons>
          )}
          {activeSearch == false ? (
            <IonTitle color="primary" className="font20 ion-no-padding" placeholder={undefined}>
              <span>{t("vocabularybank.vocbank")}</span>
            </IonTitle>
          ) : (
            ""
          )}
          {activeSearch ? (
            <IonSearchbar onIonChange={handleSearch} placeholder={undefined}></IonSearchbar>
          ) : (
            ""
          )}

          {activeSearch == false ? (
            <IonButtons onClick={searchhs} slot="end" placeholder={undefined}>
              <IonButton color="primary" placeholder={undefined}>
                <IonIcon icon={search} placeholder={undefined} />
              </IonButton>
            </IonButtons>
          ) : (
            ""
          )}
        </IonToolbar>
      </IonHeader>

      <IonContent className="listing ion-padding-horizontal" placeholder={undefined}>
        {activeSearch == false && activeDetails == false ? (
          <IonList className="themes" placeholder={undefined}>
            <IonCol className="data-selection Detailed" placeholder={undefined}>
              {1 <= itemlevellist["Level"] ? (
                <IonText
                  className={activeText === "Level1" ? "select " : ""}
                  onClick={() => handleClick("Level1")} placeholder={undefined}>
                  {t("vocabularybank.lvl1")}
                </IonText>
              ) : (
                ""
              )}
              {2 <= itemlevellist["Level"] ? (
                <IonText
                  className={activeText === "Level2" ? "select " : ""}
                  onClick={() => handleClick("Level2")} placeholder={undefined}>
                  {t("vocabularybank.lvl2")}
                </IonText>
              ) : (
                ""
              )}
              {3 <= itemlevellist["Level"] ? (
                <IonText
                  className={activeText === "Level3" ? "select " : ""}
                  onClick={() => handleClick("Level3")} placeholder={undefined}>
                  {t("vocabularybank.lvl3")}
                </IonText>
              ) : (
                ""
              )}
              {4 <= itemlevellist["Level"] ? (
                <IonText
                  className={activeText === "Level4" ? "select " : ""}
                  onClick={() => handleClick("Level4")} placeholder={undefined}>
                  {t("vocabularybank.lvl4")}
                </IonText>
              ) : (
                ""
              )}
              {5 <= itemlevellist["Level"] ? (
                <IonText
                  className={activeText === "Level5" ? "select " : ""}
                  onClick={() => handleClick("Level5")} placeholder={undefined}>
                  {t("vocabularybank.lvl5")}
                </IonText>
              ) : (
                ""
              )}
            </IonCol>
          </IonList>
        ) : (
          ""
        )}

        {activeSearch == false && activeDetails == false ? (
          <IonRow className="count Vocabularypage" placeholder={undefined}>
            <IonCol size="6" placeholder={undefined}>
              <IonCard placeholder={undefined}>
                <IonCardContent class="ion-no-padding" placeholder={undefined}>
                  <h5>{t("vocabularybank.vocbLrnt")}</h5>
                  <div>
                    <h1>105/115</h1>
                  </div>
                </IonCardContent>
              </IonCard>
            </IonCol>

            <IonCol size="6" placeholder={undefined}>
              <IonCard placeholder={undefined}>
                <IonCardContent class="ion-no-padding" placeholder={undefined}>
                  <h5>{t("vocabularybank.vocbRvis")}</h5>
                  <div>
                    <h1>24</h1>
                    {/* <IonIcon onClick={submit} color='primary' icon={informationCircleOutline} /> */}
                    <IonImg
                      onClick={submit}
                      src="./assets/images/bang-circle-blue.svg"
                      alt="" placeholder={undefined}                    />
                  </div>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        ) : (
          ""
        )}

        {activeSearch == false && activeDetails == false ? (
          <IonList className="vocabulary" placeholder={undefined}>
            <IonCard onClick={gotoprecvocab} className="mysubscription starred" placeholder={undefined}>
              <IonCardContent class="ion-no-padding" placeholder={undefined}>
                <IonText placeholder={undefined}>
                  <IonImg
                    src="./assets/images/pen.svg"
                    className="ion-margin-end pen"
                    alt="" placeholder={undefined}                  />
                  {t("vocabularybank.pracLvl")}&nbsp;
                  {activeText === "Level1"
                    ? 1
                    : activeText === "Level2"
                    ? 2
                    : activeText === "Level3"
                    ? 3
                    : activeText === "Level4"
                    ? 4
                    : activeText === "Level5"
                    ? 5
                    : ""}{" "}
                  {t("vocabularybank.vocabulary")}
                  {/* <IonIcon color='primary' className='ion-float-left ion-padding-end' icon={pencilSharp} /> */}
                </IonText>
              </IonCardContent>
            </IonCard>

            <IonCard
              className="mysubscription starred"
              onClick={Starredvocabulary} placeholder={undefined}>
              <IonCardContent class="ion-no-padding" placeholder={undefined}>
                <IonText placeholder={undefined}>
                  <IonImg
                    src="./assets/images/star.svg"
                    className="ion-margin-end star"
                    alt="" placeholder={undefined}                  />{" "}
                  {t("vocabularybank.lvl")}&nbsp;
                  {activeText === "Level1"
                    ? 1
                    : activeText === "Level2"
                    ? 2
                    : activeText === "Level3"
                    ? 3
                    : activeText === "Level4"
                    ? 4
                    : activeText === "Level5"
                    ? 5
                    : ""}
                  {t("vocabularybank.stredVocb")}
                </IonText>
              </IonCardContent>
            </IonCard>

            {activeText === "Level1" ? (
              <div className="w-100">
                {itemlist?.Level1s?.map((items, index) => {
                  return (
                    <IonCard
                      key={index}
                      onClick={() => handleClickvoocab(items, "Level1")}
                      className="mysubscription bell" placeholder={undefined}>
                      <IonCardContent class="ion-no-padding" placeholder={undefined}>
                        <IonText placeholder={undefined}>
                          <span>{items.Word}</span> {items.Pinyin}
                        </IonText>
                        <IonIcon
                          color="primary"
                          className="ion-float-right"
                          icon={chevronForward} placeholder={undefined}/>
                      </IonCardContent>
                    </IonCard>
                  );
                })}
              </div>
            ) : (
              ""
            )}

            {activeText === "Level2" ? (
              <div className="w-100">
                {itemlist?.Level2s?.map((items, index) => {
                  return (
                    <IonCard
                      key={index}
                      onClick={() => handleClickvoocab(items, "Level2")}
                      className="mysubscription bell" placeholder={undefined}>
                      <IonCardContent class="ion-no-padding" placeholder={undefined}>
                        <IonText placeholder={undefined}>
                          <span>{items.Word}</span> {items.Pinyin}
                        </IonText>
                        <IonIcon
                          color="primary"
                          className="ion-float-right"
                          icon={chevronForward} placeholder={undefined}/>
                      </IonCardContent>
                    </IonCard>
                  );
                })}
              </div>
            ) : (
              ""
            )}
            {activeText === "Level3" ? (
              <div className="w-100">
                {itemlist?.Level3s?.map((items, index) => {
                  return (
                    <IonCard
                      key={index}
                      onClick={() => handleClickvoocab(items, "Level3")}
                      className="mysubscription bell" placeholder={undefined}>
                      <IonCardContent class="ion-no-padding" placeholder={undefined}>
                        <IonText placeholder={undefined}>
                          <span>{items.Word}</span> {items.Pinyin}
                        </IonText>
                        <IonIcon
                          color="primary"
                          className="ion-float-right"
                          icon={chevronForward} placeholder={undefined}/>
                      </IonCardContent>
                    </IonCard>
                  );
                })}
              </div>
            ) : (
              ""
            )}

            {activeText === "Level4" ? (
              <div className="w-100">
                {itemlist?.Level4s?.map((items, index) => {
                  return (
                    <IonCard
                      key={index}
                      onClick={() => handleClickvoocab(items, "Level4")}
                      className="mysubscription bell" placeholder={undefined}>
                      <IonCardContent class="ion-no-padding" placeholder={undefined}>
                        <IonText placeholder={undefined}>
                          <span>{items.Word}</span> {items.Pinyin}
                        </IonText>
                        <IonIcon
                          color="primary"
                          className="ion-float-right"
                          icon={chevronForward} placeholder={undefined}/>
                      </IonCardContent>
                    </IonCard>
                  );
                })}
              </div>
            ) : (
              ""
            )}

            {activeText === "Level5" ? (
              <div className="w-100">
                {itemlist?.Level5s?.map((items, index) => {
                  return (
                    <IonCard
                      key={index}
                      onClick={() => handleClickvoocab(items, "Level5")}
                      className="mysubscription bell" placeholder={undefined}>
                      <IonCardContent class="ion-no-padding" placeholder={undefined}>
                        <IonText placeholder={undefined}>
                          <span>{items.Word}</span> {items.Pinyin}
                        </IonText>
                        <IonIcon
                          color="primary"
                          className="ion-float-right"
                          icon={chevronForward} placeholder={undefined}/>
                      </IonCardContent>
                    </IonCard>
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </IonList>
        ) : (
          ""
        )}

        {/* Search area */}
        {activeSearch == true && activeDetails == false ? (
          <div className="w-100">
            {itemlist?.Level1s.length > 0 ? (
              <div className="w-100">
                <IonList className="themes" placeholder={undefined}>
                  <IonCol className="data-selection Detailed" placeholder={undefined}>
                    <IonText className="select" placeholder={undefined}>{t("vocabularybank.lvl1")}</IonText>
                  </IonCol>
                </IonList>
                <IonList className="ion-no-padding vocabulary" placeholder={undefined}>
                  <div className="w-100">
                    {itemlist?.Level1s?.map((items, index) => {
                      return (
                        <IonCard className="mysubscription bell" placeholder={undefined}>
                          <IonCardContent class="ion-no-padding" placeholder={undefined}>
                            <IonText placeholder={undefined}>
                              <span>{items.Word}</span> {items.Pinyin}
                            </IonText>
                            <IonIcon
                              color="primary"
                              className="ion-float-right"
                              icon={chevronForward} placeholder={undefined}/>
                          </IonCardContent>
                        </IonCard>
                      );
                    })}
                  </div>
                </IonList>
              </div>
            ) : (
              ""
            )}
            {itemlist?.Level2s.length > 0 ? (
              <div className="w-100">
                <IonList className="themes" placeholder={undefined}>
                  <IonCol className="data-selection Detailed" placeholder={undefined}>
                    <IonText className="select" placeholder={undefined}>{t("vocabularybank.lvl2")}</IonText>
                  </IonCol>
                </IonList>
                <IonList className="ion-no-padding vocabulary" placeholder={undefined}>
                  <div className="w-100">
                    {itemlist?.Level2s?.map((items, index) => {
                      return (
                        <IonCard key={index} className="mysubscription bell" placeholder={undefined}>
                          <IonCardContent class="ion-no-padding" placeholder={undefined}>
                            <IonText placeholder={undefined}>
                              <span>{items.Word}</span> {items.Pinyin}
                            </IonText>
                            <IonIcon
                              color="primary"
                              className="ion-float-right"
                              icon={chevronForward} placeholder={undefined}/>
                          </IonCardContent>
                        </IonCard>
                      );
                    })}
                  </div>
                </IonList>
              </div>
            ) : (
              ""
            )}

            {itemlist?.Level3s.length > 0 ? (
              <div className="w-100">
                <IonList className="themes" placeholder={undefined}>
                  <IonCol className="data-selection Detailed" placeholder={undefined}>
                    <IonText className="select" placeholder={undefined}>{t("vocabularybank.lvl3")}</IonText>
                  </IonCol>
                </IonList>
                <IonList className="ion-no-padding vocabulary" placeholder={undefined}>
                  <div className="w-100">
                    {itemlist?.Level3s?.map((items, index) => {
                      return (
                        <IonCard key={index} className="mysubscription bell" placeholder={undefined}>
                          <IonCardContent class="ion-no-padding" placeholder={undefined}>
                            <IonText placeholder={undefined}>
                              <span>{items.Word}</span> {items.Pinyin}
                            </IonText>
                            <IonIcon
                              color="primary"
                              className="ion-float-right"
                              icon={chevronForward} placeholder={undefined}/>
                          </IonCardContent>
                        </IonCard>
                      );
                    })}
                  </div>
                </IonList>
              </div>
            ) : (
              ""
            )}

            {itemlist?.Level4s.length > 0 ? (
              <div className="w-100">
                <IonList className="themes" placeholder={undefined}>
                  <IonCol className="data-selection Detailed" placeholder={undefined}>
                    <IonText className="select" placeholder={undefined}>{t("vocabularybank.lvl4")}</IonText>
                  </IonCol>
                </IonList>
                <IonList className="ion-no-padding vocabulary" placeholder={undefined}>
                  <div className="w-100">
                    {itemlist?.Level4s?.map((items, index) => {
                      return (
                        <IonCard key={index} className="mysubscription bell" placeholder={undefined}>
                          <IonCardContent class="ion-no-padding" placeholder={undefined}>
                            <IonText placeholder={undefined}>
                              <span>{items.Word}</span> {items.Pinyin}
                            </IonText>
                            <IonIcon
                              color="primary"
                              className="ion-float-right"
                              icon={chevronForward} placeholder={undefined}/>
                          </IonCardContent>
                        </IonCard>
                      );
                    })}
                  </div>
                </IonList>
              </div>
            ) : (
              ""
            )}

            {itemlist?.Level5s.length > 0 ? (
              <div className="w-100">
                <IonList className="themes" placeholder={undefined}>
                  <IonCol className="data-selection Detailed" placeholder={undefined}>
                    <IonText className="select" placeholder={undefined}>{t("vocabularybank.lvl5")}</IonText>
                  </IonCol>
                </IonList>
                <IonList className="ion-no-padding vocabulary" placeholder={undefined}>
                  <div className="w-100">
                    {itemlist?.Level5s?.map((items, index) => {
                      return (
                        <IonCard key={index} className="mysubscription bell" placeholder={undefined}>
                          <IonCardContent class="ion-no-padding" placeholder={undefined}>
                            <IonText placeholder={undefined}>
                              <span>{items.Word}</span> {items.Pinyin}
                            </IonText>
                            <IonIcon
                              color="primary"
                              className="ion-float-right"
                              icon={chevronForward} placeholder={undefined}                            />
                          </IonCardContent>
                        </IonCard>
                      );
                    })}
                  </div>
                </IonList>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {/* End Search area */}

        {/* Details area */}
        {activeSearch == false && activeDetails == true ? (
          <div className="w-100 detailpage">
            <IonRow className="Practice count" placeholder={undefined}>
              <IonCol size="12" class="ion-no-padding" placeholder={undefined}>
                <IonCard className="count" placeholder={undefined}>
                  <IonCardContent class="ion-no-padding" placeholder={undefined}>
                    <IonRow placeholder={undefined}>
                      <IonCol size="12" placeholder={undefined}>
                        <div className="mainsection">
                          <div>
                            <span>蛋糕</span>
                            <IonIcon color="warning" icon={playCircle} placeholder={undefined} />
                          </div>
                          <IonImg src="./assets/images/star.svg" placeholder={undefined} />
                        </div>
                        <div className="mainsubtitle">
                          Pinyin <span>Chuàng yì</span>
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
                <IonCard className="scorebox" placeholder={undefined}>
                  <IonRow className="" placeholder={undefined}>
                    <IonCol size="12" className="ion-no-padding" placeholder={undefined}>
                      <div className="search">
                        <div className="more-example">
                          <div>
                            <span>Class of words:</span>
                            Adjective, noun
                          </div>
                        </div>
                        <div className="more-example">
                          <div>
                            <span>Meaning:</span>
                            Creative, creativity
                          </div>
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonCard>
              </IonCol>
            </IonRow>
            <IonRow placeholder={undefined}>
              <IonList className="list Leisue" placeholder={undefined}>
                <IonItem class="ion-no-padding playbook" placeholder={undefined}>
                  <div className="moredata">Sample sentences in lessons</div>
                </IonItem>
                <IonItem class="ion-no-padding" placeholder={undefined}>
                  <div className="mainpart">
                    <div className="more-example">
                      <div>
                        <span>Leisue: Lesson 2</span>
                        Making a Cake
                      </div>
                      <IonImg src="./assets/images/rightarrow.png" placeholder={undefined} />
                    </div>
                    <ul>
                      <li>
                        <IonImg src="./assets/images/bigReader.png" placeholder={undefined} />{" "}
                        <span>做蛋糕时可以发挥创意。</span>
                      </li>
                      <li>
                        <IonImg src="./assets/images/Pinyin.png" placeholder={undefined} />{" "}
                        <span>zuò dàngāo shí kěyǐ fāhuī chuàngyì</span>
                      </li>
                      <li>
                        <IonImg src="./assets/images/transliteration.png" placeholder={undefined} /> You
                        can use your creativity when making cakes.
                      </li>
                    </ul>
                  </div>
                </IonItem>
                <IonItem class="ion-no-padding" placeholder={undefined}>
                  <div className="mainpart">
                    <div className="more-example">
                      <div>
                        <span>Leisue: Lesson 2</span>
                        Making a Cake
                      </div>
                      <IonImg src="./assets/images/rightarrow.png" placeholder={undefined} />
                    </div>
                    <ul>
                      <li>
                        <IonImg src="./assets/images/bigReader.png" placeholder={undefined} />{" "}
                        <span>做蛋糕时可以发挥创意。</span>
                      </li>
                      <li>
                        <IonImg src="./assets/images/Pinyin.png" placeholder={undefined} />{" "}
                        <span>zuò dàngāo shí kěyǐ fāhuī chuàngyì</span>
                      </li>
                      <li>
                        <IonImg src="./assets/images/transliteration.png" placeholder={undefined} /> You
                        can use your creativity when making cakes.
                      </li>
                    </ul>
                  </div>
                </IonItem>
              </IonList>
            </IonRow>
          </div>
        ) : (
          ""
        )}
        {/* End Details area */}
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Vocabularybank);
