import React from 'react';
import { IonHeader, IonToolbar, IonButtons, IonButton, IonContent, IonItem, IonLabel, IonToggle, IonList, IonCol, IonText, IonRow, IonCard, IonCardContent, IonIcon, IonPage, IonTitle } from '@ionic/react';
import './Dragdroplesson.scss';
import { chevronBack, ellipsisVertical } from 'ionicons/icons';
import Drawer from "react-bottom-drawer";
import ReactAudioPlayer from 'react-audio-player';

interface LessonpracticeProps { }

const Lessonpractice: React.FC<LessonpracticeProps> = () => {
  const [isVisible, setIsVisible] = React.useState(false);
  const openDrawer = React.useCallback(() => setIsVisible(true), []);

  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);
  return (
    <IonPage className='intro' placeholder={undefined}>
      <IonHeader className='ion-text-center dubbletitle' placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <div>
            <IonButtons slot="start" placeholder={undefined}>
              <IonButton color='primary' placeholder={undefined}>
                <IonIcon icon={chevronBack} placeholder={undefined} />
              </IonButton>
            </IonButtons>
            <IonTitle color='primary' className='font20 ion-no-padding' placeholder={undefined}><span>Lesson 23: Practice <p className='ion-no-margin'>Making a Cake</p></span></IonTitle>
            <IonButtons slot="end" placeholder={undefined}>
              <IonButton onClick={openDrawer} color='primary' placeholder={undefined}>
                <IonIcon icon={ellipsisVertical} placeholder={undefined} />
              </IonButton>
            </IonButtons>
          </div>
        </IonToolbar>
      </IonHeader>

      <IonContent className='listing' placeholder={undefined}>
        <IonList className="ion-no-padding bottomfix" placeholder={undefined}>

          <IonRow className='Practice leftrightspace ion-padding-horizontal' placeholder={undefined}>
            <IonCol placeholder={undefined}>
              <IonText placeholder={undefined}>Choose the correct answer</IonText>
            </IonCol>
            <IonCol size="12" className='test-audio ion-text-left whitebg' placeholder={undefined}>
              <ReactAudioPlayer className='String' controls controlsList="nodownload noplaybackrate">
                <source src="/assets/audio.mp3" type="audio/mpeg"></source>
              </ReactAudioPlayer>
              {/* <audio controls src="/media/cc0-audio/t-rex-roar.mp3"></audio> */}
              {/* <div className='audio'></div> */}
            </IonCol>
          </IonRow>
          
          <IonRow className='answer single Practiceresultanswer leftrightspace ion-padding-horizontal' placeholder={undefined}>
            <IonCol size='12' className='borderbottom' placeholder={undefined}>
              <IonCard className='answer-right' placeholder={undefined}>
                <IonCardContent class="ion-no-padding" placeholder={undefined}>
                  <ul className='lession-icon'>
                    <li><span>小明</span>dàngāo</li>
                  </ul>
                </IonCardContent>
              </IonCard>
              <IonCard className='answer-wrong' placeholder={undefined}>
                <IonCardContent class="ion-no-padding" placeholder={undefined}>
                  <ul className='lession-icon'>
                    <li><span>是</span>shì</li>
                  </ul>
                </IonCardContent>
              </IonCard>
              <IonCard placeholder={undefined}>
                <IonCardContent class="ion-no-padding" placeholder={undefined}>
                  <ul className='lession-icon'>
                    <li><span>生日</span>shēngrì</li>
                  </ul>
                </IonCardContent>
              </IonCard>
              <IonCard placeholder={undefined}>
                <IonCardContent class="ion-no-padding" placeholder={undefined}>
                  <ul className='lession-icon'>
                    <li><span>的</span>de</li>
                  </ul>
                </IonCardContent>
              </IonCard>
              <IonCard placeholder={undefined}>
                <IonCardContent class="ion-no-padding" placeholder={undefined}>
                  <ul className='lession-icon'>
                    <li><span>今天</span>jīntiān</li>
                  </ul>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonList>

        <IonList className='ion-text-center' placeholder={undefined}>
          <IonRow className='answer single Practiceresultanswer mt0 leftrightspace ion-padding-horizontal' placeholder={undefined}>
            <IonCol size='12' placeholder={undefined}>
              <IonCard placeholder={undefined}>
                <IonCardContent class="ion-no-padding" placeholder={undefined}>
                  <ul className='lession-icon'>
                    <li><span>小明</span>dàngāo</li>
                  </ul>
                </IonCardContent>
              </IonCard>
              <IonCard placeholder={undefined}>
                <IonCardContent class="ion-no-padding" placeholder={undefined}>
                  <ul className='lession-icon'>
                    <li><span>是</span>shì</li>
                  </ul>
                </IonCardContent>
              </IonCard>
              <IonCard placeholder={undefined}>
                <IonCardContent class="ion-no-padding" placeholder={undefined}>
                  <ul className='lession-icon'>
                    <li><span>生日</span>shēngrì</li>
                  </ul>
                </IonCardContent>
              </IonCard>
              <IonCard placeholder={undefined}>
                <IonCardContent class="ion-no-padding" placeholder={undefined}>
                  <ul className='lession-icon'>
                    <li><span>的</span>de</li>
                  </ul>
                </IonCardContent>
              </IonCard>
              <IonCard placeholder={undefined}>
                <IonCardContent class="ion-no-padding" placeholder={undefined}>
                  <ul className='lession-icon'>
                    <li><span>今天</span>jīntiān</li>
                  </ul>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonList>
    </IonContent>

    <Drawer className='bottompopup skateInBottom' isVisible={isVisible} onClose={onClose}>
      <IonRow placeholder={undefined}>
        <IonList className='list' placeholder={undefined}>
          {/* <IonItem class="ion-no-padding playbook">
            <div>
              <IonImg src="./assets/images/play.png" />
            </div>
            <div className="details ion-padding-start">
              <h4>Play book</h4>
              <span>Autoplay book audio</span>
            </div>
          </IonItem> */}
          <IonItem class="ion-no-padding" placeholder={undefined}>
            <IonLabel placeholder={undefined}>Lesson language</IonLabel>
            <div className='right-space'>
              <IonText color='primary' placeholder={undefined}>簡</IonText>
              <IonText placeholder={undefined}>繁</IonText>
            </div>
          </IonItem>
          <IonItem class="ion-no-padding" placeholder={undefined}>
            <IonLabel placeholder={undefined}>Chinese characters</IonLabel>
            <IonToggle checked slot="end" placeholder={undefined}></IonToggle>
          </IonItem>
          <IonItem class="ion-no-padding" placeholder={undefined}>
            <IonLabel placeholder={undefined}>Pinyin</IonLabel>
            <IonToggle checked slot="end" placeholder={undefined}></IonToggle>
          </IonItem>
          {/* <IonItem class="ion-no-padding">
            <IonLabel>Translation</IonLabel>
            <IonToggle slot="end"></IonToggle>
          </IonItem>
          <IonItem class="ion-no-padding">
            <IonLabel>Font size</IonLabel>
            <IonImg src="./assets/images/Fontdown.png" />
            <IonImg src="./assets/images/Fontup.png" />
          </IonItem> */}
          <IonItem class="ion-no-padding" placeholder={undefined}>
            <IonLabel placeholder={undefined}>Playback speed</IonLabel>
            <div className='leftright'><a href='#'>&lt;</a> 1.0 <a href='#'>&gt;</a></div>
          </IonItem>
          {/* <IonItem class="ion-no-padding">
            <IonLabel>Bookmark</IonLabel>
            <IonIcon color='primary' icon={bookmark} />
          </IonItem> */}
        </IonList>
      </IonRow>
    </Drawer>

</IonPage>
  );
};

export default React.memo(Lessonpractice);