import React, { useEffect, useRef, useState } from "react";
import {
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonToggle,
  IonCol,
  IonText,
  IonCard,
  IonCardContent,
  IonRow,
  IonPage,
  IonImg,
  useIonViewWillEnter,
  useIonLoading,
  useIonToast,
  IonSlides,
  IonSlide,
  IonSkeletonText,
} from "@ionic/react";
import "./E1s.scss";
import Drawer from "react-bottom-drawer";
import { CONFIG } from "../App";
import "swiper/swiper.min.css";
import "@ionic/react/css/ionic-swiper.css";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface MyObject {
  id: number;
  name: string;
  type: "e1s";
  pagecouter: number;
  totalque: number;
  back:number;
}

interface E1sProps {
  itemE1s: any;
  onObjectChange: (object: MyObject) => void;
  lastSlide : any;
  cheChar:any;
  piniyin:any;
}

const E1s: React.FC<E1sProps> = (props: E1sProps, { isSuccess, isWrong }) => {
  let url: string = CONFIG.API_ENDPOINT; //live

  const { t } = useTranslation();

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [isVisible, setIsVisible] = React.useState(false);
  const openDrawer = React.useCallback(() => setIsVisible(true), []);
  const [length, setLength] = React.useState(-1);
  const [showResults, setShowResults] = React.useState(1);
  const [successOption, setSuccessOption] = useState<boolean | undefined>();
  const [wrongOption, setWrongOption] = useState<boolean | undefined>();
  const [setRightAns, setRightAnsOption] = useState<boolean | undefined>();
  const [selectedDiv, setSelectedDiv] = useState<any>(null);
  const [showImage, setShowImage] = useState(false);
  const [myanswer, setmyanswer] = useState("");
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);
  const [correctAnswer, setCorrectAnswer] = useState<string | null>(null);
  const [isCorrect, setIsCorrect] = useState<boolean | null>(null);
  const [correctAnswerIndex, setCorrectAnswerIndex] = useState<number | null>(null);
  const [correctAnswerIndices, setCorrectAnswerIndices] = useState<number[]>([]);
  const [correctAnswerUser, setCorrectAnswerUser] = useState<any>([]);
  const [backSlideIndex, setbackSlideIndex] = useState(-1);
  const [ansIndex, setAnsSlideIndex] = useState(0);
  let lastValue : any;
  let finalanswer = {};
  let token = "";
  let resfreshtoken = "";
  let pagecouter: any = 0;
  let totalque: any = 0;
  function handleChange(back) {
    const myObject: MyObject = {
      id: 1,
      name: "My Object",
      type: "e1s",
      pagecouter: pagecouter,
      back:back,
      totalque: props.itemE1s.length,
    };
    props.onObjectChange(myObject);
  }
  const handleImageLoad = () => {
    setIsLoading(false);
  };
  useIonViewWillEnter(() => {
    setLength(1);
    setShowResults(1);
  });
  const slidesRef = useRef<HTMLIonSlidesElement>(null);

  const options = {
    keyboard: true,
  };
  const handleSlideNextEnded = (event: CustomEvent<any>) => {
    console.log("Slide Next ended", event.detail);

    slidesRef.current?.getActiveIndex().then((index) => {
      console.log("index", index + 1);
      setLength(index + 1);
      console.log("length", props.itemE1s.length);
    });
  };

  const handleSlidePrevEnded = (event: CustomEvent<any>) => {
    console.log("Slide Prev ended", event.detail);
    // setLength(length - 1)
    slidesRef.current?.getActiveIndex().then((index) => {
      console.log("index", index + 1);
      setLength(index - 1);
      console.log("length", props.itemE1s.length);
    });
  };

  const handleSlideDidChanged = async (event: CustomEvent<any>) => {
    console.log("Slide Did ended", length);
    //debugger
    const swiper = await slidesRef.current?.getSwiper();
    if (swiper) {
      const swipeDirection = swiper.swipeDirection;
      const currentIndex = swiper.activeIndex;
      console.log('Swipe direction:', swipeDirection);
      console.log('Current index:', currentIndex);
      console.log('ANS Current index:', ansIndex);

      if (swipeDirection == "prev") {
        //Back Login code
        setAnsSlideIndex(currentIndex)
        //Back Login code
        let index = backSlideIndex == -1 ? -2 : 0
     ///   setbackSlideIndex(index);
        if (currentIndex == 0) {
          setbackSlideIndex(-1);
        }
        if (backSlideIndex == -1) {
          console.log("Go Back Final page");
        }
        console.log("backSlideIndex", backSlideIndex);
      } else {
        setAnsSlideIndex(currentIndex)
        setbackSlideIndex(0);
        slidesRef.current?.getActiveIndex().then((index) => {
          console.log("index", index + 1);
          console.log('length',length)
          debugger
          
          SaveExerciseQueAnswer(currentIndex, index);
          if (index + 1 == length) {
            console.log("Go next page");
            setLength(-1);
            saveContent();
            handleChange(0);
          }
        });
      }
    }


  };
  let tmpscore = 0;
  const handleQuestionCheckClick = (
    items: any,
    index: any,
    indexq: any,
    type,
    indexnew
  ) => {
    // console.log(type)
    //console.log('soundno ', items?.Question?.[0].soundno)
    //console.log('questionno', type)

    if (items?.Question?.[0].soundno == type) {
      isSuccess = true;
      isWrong = false;
      setSuccessOption(isSuccess);
      setWrongOption(isWrong);
      setSelectedDiv(type);
      console.log("q1", isSuccess);
      setmyanswer("");
      setmyanswer(props.itemE1s[index].Options?.[indexnew].Choice);
      console.log("myanswer", myanswer);
      //debugger
      if (!props.itemE1s[index].answer) {
        props.itemE1s[index].answer = type;
        props.itemE1s[index].score = true;
        tmpscore += tmpscore;
        // setSum(sum + sum);
      }
    } else {
      isSuccess = false;
      isWrong = true;

      setRightAnsOption(true);
      setSuccessOption(isSuccess);
      setWrongOption(isWrong);
      setSelectedDiv(type);
      setmyanswer("");
      setmyanswer(props.itemE1s[index].Options?.[indexnew].Choice);
      console.log("myanswer", myanswer);
      //debugger
      if (!props.itemE1s[index].answer) {
        props.itemE1s[index].answer = type;
        props.itemE1s[index].score = false;
      }
    }
    console.log("itemE1s", props.itemE1s);
    //console.log('isSuccess', isSuccess)
    //console.log('isWrong', isWrong)
  };

  const handleAnswerSelection = (
    selectedOption: string,
    correctAnswer: string,
    index: number
  ) => {
    setSelectedAnswer(selectedOption);
    setCorrectAnswer(correctAnswer);
    if (selectedOption === correctAnswer) {
      debugger;

      setIsCorrect(true);
      setRightAnsOption(true);

      setmyanswer("")
      setmyanswer(selectedOption);
      const pushValue = (selectedOption) => {
        setCorrectAnswerUser(prevValue => [...prevValue, selectedOption]);
      };
      pushValue(selectedOption)

    } else {
      debugger
      setmyanswer("")
      setmyanswer(selectedOption);
      setIsCorrect(false);
      setRightAnsOption(false);
      // setCorrectAnswerIndex(index); // Set the correct answer index
    }

    // Set the correct answer index
    const correctIndex = props.itemE1s[index].Options.findIndex(
      (option) => option.Word === correctAnswer
    );
    setCorrectAnswerIndex(correctIndex);

    setCorrectAnswerIndices((prevIndices) => {
      const newIndices = [...prevIndices];
      newIndices[index] = correctIndex;
      return newIndices;
    });

    // Update the state or perform other actions as needed
    // Example: props.itemE1s[index].answer = selectedOption;
  };

  const gotonextques = async (items: any, index: any, type) => {
    //1 for Understand and 2= dont understand
    console.log(items);

    // console.log(type)
    // console.log('soundno ', items?.Question?.[0].soundno)
    // console.log('questionno', type)

    if (items?.Question?.[0].soundno == type) {
      isSuccess = true;
      isWrong = false;
    } else {
      isSuccess = false;
      isWrong = true;
    }

    // console.log(showResults)
    //console.log(itemlist)
    //console.log(itemlist[showResults - 1])

    //  let data = itemlist[showResults - 1];
    //debugger
    if (items) {
      //
      setShowResults(index);
      // console.log('len ', showResults - 1)
      setLength(index);
      resetstate();

      // ansques = data.QuestionId + ',' + type;
      // setQuestion([...quesdata, ansques]);
    }
  };

  const resetstate = () => {
    setSuccessOption(false);
    setWrongOption(false);
    setSelectedDiv(false);
    setRightAnsOption(false);
  };
  useEffect(() => {
    // console.log(quesdata);

    //console.log('String', quesdata.join('#'))

    // console.log('showResults', showResults)
    // console.log('finalindex', length)
    // console.log('lengthque', lengthque)
    //console.log('teatemediaBlob', mediaBlob)

    if (length > props.itemE1s.length) {
      console.log("Done");
      setLength(-1);
      saveContent();
      handleChange(0);
    }

    //debugger
    const timeoutId = setTimeout(() => {
      setShowImage(true);
      const nextSlideIndex = activeSlideIndex + 1;
      setActiveSlideIndex(nextSlideIndex);
      // Call the slideTo method to swipe to the next slide
      // slidesRef.current?.slideTo(nextSlideIndex);
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [activeSlideIndex, props.itemE1s.length]);
  const SaveExerciseQueAnswer = async (indexdb, index) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }


    const data = {
      eId: props.itemE1s[ansIndex].EId,
      eType: props.itemE1s[ansIndex].TemplateCode,
      //question: props.itemE2s[indexdb - 1]?.Question?.[0].ImageUrl,
      //rightAnswer: foundValue.Choice,
      rearrageAnswer: "", //only for rearrange exercise otherwise blank
      answer: myanswer,
      writingScore: 0
    };


    console.log("SaveExerciseQueAnswer", data);
    //debugger

    try {
      const response = await fetch(url + "/Book/SaveExerciseQueAnswer", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });

      const json = await response.json();
      console.log(json);
      // debugger
      if (json.StatusCode == 1) {
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const saveContent = async () => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    // present({
    //   message: '',
    // })
    debugger
    let anw = {};
    let tmscore = 0;
    pagecouter = 0;
    for (let index = 0; index < props.itemE1s.length; index++) {
      const element = props.itemE1s[index];
      const propName = ("q" + (index + 1)).toString();
      const propValue = element.answer;

      anw[propName] = propValue.toString();
      finalanswer = anw;
      if (element.score) {
        tmscore += element.score;
      }
    }
    debugger
    console.log("tmpscore E1", tmscore);
    console.log("finalanswer E1", finalanswer);

    const stringWithBackslashes = JSON.stringify(finalanswer);
    const stringWithoutBackslashes = stringWithBackslashes.replace(/\\/g, "");
    console.log(stringWithoutBackslashes); // Output: {"q1":"2","q2":"4","q3":"4"}
    const myArray = Object.entries(finalanswer);
    console.log(myArray);
    //debugger
    for (let k = 0; k < myArray.length; k++) {
      const element = myArray[k][1];
      if (element != "") {
        pagecouter++;
      }
    }
    console.log(pagecouter);

    console.log(correctAnswerUser);
    debugger
    // debugger
    const data = {
      contentPageCode: props.itemE1s[0].TemplateCode,
      type: "exercise-score",
      bookId: props.itemE1s[0].BookId,
      contentPageId: props.itemE1s[0].PageId,
      answer: stringWithoutBackslashes,
      score: correctAnswerUser.length,
      options: null,
      pageRead: pagecouter,
    };
    //dismiss()
    console.log(data);
    //debugger

    try {
      const response = await fetch(url + "/Book/SaveContent", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });

      const json = await response.json();
      console.log(json);
      //dismiss()
      //  debugger
      if (json.StatusCode == 1) {
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };

  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
    }, 1000); // Delay in milliseconds (e.g. 2000ms = 2 seconds)

    return () => clearTimeout(timer);
  }, []);
  return (
    <IonPage className="intro" placeholder={undefined}>
      <IonContent className="listing" placeholder={undefined}>
        <IonSlides
          pager={false}
          scrollbar={true}
          options={{
            initialSlide: props.lastSlide === 1 ? props.itemE1s.length - 1 : activeSlideIndex,
            options,
          }}
          onIonSlideTransitionEnd={handleSlideDidChanged}
          onIonSlideNextEnd={handleSlideNextEnded}
          onIonSlidePrevEnd={handleSlidePrevEnded}
          ref={slidesRef} placeholder={undefined}>
          {props.itemE1s.map((items, index) => {
            return (
              <IonSlide placeholder={undefined}>
                <IonList key={index} className="ion-no-padding height100" placeholder={undefined}>
                  <IonRow className="Practice leftrightspace ion-padding-horizontal" placeholder={undefined}>
                    <IonCol placeholder={undefined}>
                      <IonText placeholder={undefined}>{t("e1s.which_picture_matches_chinese")}</IonText>
                    </IonCol>
                  </IonRow>

                  <IonRow className="bigbox leftrightspace ion-padding-horizontal" placeholder={undefined}>
                    <IonCol
                      size="12"
                      className="ion-no-padding ion-text-center" placeholder={undefined}>
                      <ul className="lession-icon">
                        <li>
                          <span>{props.cheChar&&(items.WordCh)}</span>
                          {props.piniyin&&(items.WordPy)}
                        </li>
                        {/* <li><span>糕</span>gāo</li> */}
                      </ul>
                    </IonCol>
                  </IonRow>

                  <IonRow className="answer leftrightspace datakey ion-padding-horizontal" placeholder={undefined}>
                    {props.itemE1s?.[index].Options?.map((datav, indexnew) => (
                      <IonCol size="6" key={indexnew} placeholder={undefined}>
                        <IonCard
                          id={datav.questionno}
                          style={{
                            borderColor: isCorrect !== null &&
                              isCorrect &&
                              datav.Word === selectedAnswer
                              ? "var(--ion-color-success)" // Green outline for the correct answer
                              : correctAnswerIndices[index] !== null &&
                                indexnew === correctAnswerIndices[index]
                                ? "var(--ion-color-success)" // Green outline for the correct answer when wrong is selected
                                : isCorrect !== null &&
                                  !isCorrect &&
                                  datav.Word === selectedAnswer
                                  ? "var(--ion-color-danger)" // Red outline for the wrong answer
                                  : "",
                          }}
                          onClick={() => handleAnswerSelection(
                            datav.Word,
                            items.answer,
                            index
                          )} placeholder={undefined}>
                          <IonCardContent class="ion-no-padding imageview" placeholder={undefined}>
                            <div className="min80">
                              {showImage ? (
                                <IonImg
                                  src={datav.Word}
                                  onLoad={handleImageLoad} placeholder={undefined}/>
                              ) : (
                                <IonSkeletonText
                                    animated
                                    style={{ width: "100%", height: "138px", margin: "0px" }} placeholder={undefined}/>
                              )}
                            </div>
                          </IonCardContent>
                        </IonCard>
                      </IonCol>
                    ))}

                    {/* <IonCol size='12'>
                      <IonButton expand="block" onClick={() => gotonextques(items, showResults + 1, 1)}>Next</IonButton>
                    </IonCol> */}
                    <IonCol size="12" placeholder={undefined}>
                      {showImage && (
                        <IonImg
                          className="slide"
                          src="./assets/images/swipe-left-right.gif" placeholder={undefined}/>
                      )}
                    </IonCol>
                  </IonRow>
                </IonList>
              </IonSlide>
            );
          })}
        </IonSlides>
      </IonContent>

      {/* <Drawer
        className="bottompopup skateInBottom"
        isVisible={isVisible}
        onClose={onClose}
      >
        <IonRow placeholder={undefined}>
          <IonList className="list" placeholder={undefined}>
            <IonItem class="ion-no-padding playbook">
              <div>
                <IonImg src="./assets/images/play.png" />
              </div>
              <div className="details ion-padding-start">
                <h4>Play book</h4>
                <span>Autoplay book audio</span>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("e1s.drawer.lesson_language")}</IonLabel>
              <div className="right-space">
                <IonText color="primary" placeholder={undefined}>簡</IonText>
                <IonText placeholder={undefined}>繁</IonText>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("e1s.drawer.chinese_characters")}</IonLabel>
              <IonToggle checked slot="end" placeholder={undefined}></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("e1s.drawer.pinyin")}</IonLabel>
              <IonToggle checked slot="end" placeholder={undefined}></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding">
              <IonLabel>Translation</IonLabel>
              <IonToggle slot="end"></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding">
              <IonLabel>Font size</IonLabel>
              <IonImg src="./assets/images/Fontdown.png" />
              <IonImg src="./assets/images/Fontup.png" />
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("e1s.drawer.playback_speed")}</IonLabel>
              <div className="leftright">
                <a href="#">&lt;</a> 1.0 <a href="#">&gt;</a>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding">
              <IonLabel>Bookmark</IonLabel>
              <IonIcon color='primary' icon={bookmark} />
            </IonItem>
          </IonList>
        </IonRow>
      </Drawer> */}
    </IonPage>
  );
};

export default React.memo(E1s);
