import React from "react";
import {
  IonHeader,
  IonToolbar,
  IonItem,
  IonImg,
  IonRow,
  IonCol,
  IonList,
  IonIcon,
  IonButton,
  IonLabel,
  IonContent,
  IonPage,
  IonTitle,
  useIonViewWillEnter,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import "./Profile.scss";
import { chevronForward } from "ionicons/icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { RouteComponentProps } from "react-router";
import { CONFIG } from "../App";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface OwnProps extends RouteComponentProps {}

interface ProfileProps extends OwnProps {}

const Profile: React.FC<ProfileProps> = ({ history }) => {
  let url: string = CONFIG.API_ENDPOINT; //live

  const { t } = useTranslation();

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any>({});
  const [itemlevellist, setlevelitems] = React.useState<any>({});

  let token = "";
  let resfreshtoken = "";
  let userdata;
  let username;

  useIonViewWillEnter(() => {
    //setMenuEnabled(false);
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser?.JwtToken;
      resfreshtoken = currentUser?.RefreshToken;
    }
    GetProfie();
    GetLevels();
  });
  let logindata = JSON.parse(localStorage.getItem("logininfo") || "{}");

  if (logindata) {
    userdata = logindata?.Result;
    username = userdata?.UserName;
  }
  //conformation popup code
  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <div className="icon">
              <IonImg src="./assets/images/bigsignout.png" alt="" placeholder={undefined} />
            </div>
            <p className="popuppadding">{t("profile.submit.msg")}</p>
            <div className="react-confirm-alert-button-group">
              <IonButton onClick={onClose} className="alertcancel" placeholder={undefined}>
              {t("profile.submit.cancel")}
              </IonButton>
              <IonButton
                onClick={() => {
                  onSignout();
                  onClose();
                } }
                className="alertsubmit" placeholder={undefined}>
                {t("profile.submit.sgn_out")}
              </IonButton>
            </div>
          </div>
        );
      },
    });
  };

  const GetProfie = async () => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 1000,
    });

    try {
      const response = await fetch(url + "/User/GetProfile", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      const json = await response.json();
      console.log("Profice", json);

      dismiss();
      if (json.StatusCode == 1) {
        setitems(json.Result);
        //debugger
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const GetLevels = async () => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 1000,
    });

    try {
      const response = await fetch(url + "/User/GetUserLevel", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      const json = await response.json();
      console.log("GetLevels", json);

      dismiss();
      if (json.StatusCode == 1) {
        setlevelitems(json.Result);
        //debugger
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      console.log(json);

      if (json.StatusCode == 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const onSignout = () => {
    localStorage.clear();
    history.push("/login", { direction: "none" });
  };

  return (
    <IonPage className="intro" placeholder={undefined}>
      <IonHeader className="ion-text-center" placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <IonTitle color="primary" className="font20" placeholder={undefined}>
            <span>{t("profile.my_pro")}</span>
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className="listing spacingset" placeholder={undefined}>
        <IonRow className="edit ion-text-center" placeholder={undefined}>
          <IonCol size="12" class="pro" placeholder={undefined}>
            {/* {itemlist.ProfileUrl} */}
            <div className="logo">
              <IonImg
                src={itemlist?.ProfileUrl
                  ? itemlist?.ProfileUrl
                  : "./assets/images/bang-circle-blue.svg"} placeholder={undefined}              />
            </div>
            <span>{t("profile.pro")}</span>
          </IonCol>
          <IonCol size="12" className="ion-text-center" placeholder={undefined}>
            <h4 className="no-margin">
              {itemlist?.Name ? itemlist.Name : userdata.username}
            </h4>
            <h6 className="no-margin">
              {itemlist?.Email ? itemlist.Email : userdata?.Email}
            </h6>
          </IonCol>
          <IonCol size="12" placeholder={undefined}>
            <IonButton href="/tabs/profile/editprofile" shape="round" placeholder={undefined}>
              {t("profile.edt_pro")}
            </IonButton>
          </IonCol>
        </IonRow>
        <IonList className="list prolist" placeholder={undefined}>
          <IonItem class="ion-no-padding" href="/tabs/profile/currentlevel" placeholder={undefined}>
            <IonLabel placeholder={undefined}>
              {t("profile.crnt_level")}
              <span className="sublevel">
                {t("profile.level")} {itemlevellist["Level"]}
              </span>
            </IonLabel>
            <IonIcon color="primary" icon={chevronForward} placeholder={undefined} />
          </IonItem>
          {/* <IonItem class="ion-no-padding" href="/tabs/profile/Subscribe" placeholder={undefined}>
            <IonLabel placeholder={undefined}>{t("profile.subscribe")}</IonLabel>
            <IonIcon color="primary" icon={chevronForward} placeholder={undefined} />
          </IonItem>
          <IonItem class="ion-no-padding" href="/tabs/profile/Mysubscription" placeholder={undefined}>
            <IonLabel placeholder={undefined}>{t("profile.mng_sub")}</IonLabel>
            <IonIcon color="primary" icon={chevronForward} placeholder={undefined} />
          </IonItem> */}
          <IonItem onClick={submit} class="ion-no-padding pointer" placeholder={undefined}>
            <IonLabel placeholder={undefined}>{t("profile.sgn_out")}</IonLabel>
            {/* <IonIcon color='primary' icon={logOutOutline} /> */}
            <IonImg src="./assets/images/Signout.png" alt="" placeholder={undefined} />
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Profile);
