import React, { useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonGrid,
  IonItem,
  IonIcon,
  IonImg,
  IonText,
  IonLabel,
  IonInput,
  IonList,
  IonContent,
  IonPage,
  IonTitle,
  IonButton,
  IonCol,
  IonRow,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
} from "@ionic/react";
import "./Changepassword.scss";
import { chevronBack } from "ionicons/icons";
import { CONFIG } from "../App";
import { Controller, useForm } from "react-hook-form";
import { lockClosed, close } from "ionicons/icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ErrorMessage } from "@hookform/error-message";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface ChangepasswordProps {}

const Changepassword: React.FC<ChangepasswordProps> = () => {
  let url: string = CONFIG.API_ENDPOINT; //live

  const { t } = useTranslation();

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({});

  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealPwdconf, setIsRevealPwdconf] = useState(false);
  let token = "";
  let resfreshtoken = "";

  useIonViewWillEnter(() => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
  });

  const resetpassword = async (item: any) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    if (item.pwd != item.confpwd) {
      presenttost({
        message: "Passwords does not match",
        duration: 5000,
        position: "bottom",
      });
      return;
    }
    present({
      message: "",
      cssClass: 'custom-spinner',
      //duration: 3000
    });
    var email = localStorage.getItem("email");
    var oldPwdencodedString = btoa(item.oldPassword);
    var pwdencodedString = btoa(item.pwd);
    // var oldPwdencodedString = item.oldPassword;
    // var pwdencodedString =item.pwd;

    const data = {
      oldPassword: oldPwdencodedString,
      newPassword: pwdencodedString,
    };
    //  debugger
    try {
      const response = await fetch(url + "/User/ChangePassword", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      //debugger
      //console.log(json)
      dismiss();

      // presenttost({
      //   message: json.Message,
      //   duration: 1500,
      //   position: 'bottom'
      // });

      if (json.StatusCode == 1) {
        submit();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      presenttost({
        message: "Please try again later",
        duration: 1500,
        position: "bottom",
      });
      dismiss();
      console.error(error);
    }
  };

  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <div className="close">
              <IonIcon onClick={onClose} icon={close} placeholder={undefined} />
            </div>
            <div className="icon">
              <IonIcon icon={lockClosed} placeholder={undefined} />
            </div>
            <p>{t("changepassword.submit.pass_success")}</p>
            <div className="react-confirm-alert-button-group">
              <IonButton className="alertcancel w-150" routerLink="/login" placeholder={undefined}>
                {t("changepassword.submit.continue")}
              </IonButton>
            </div>
          </div>
        );
      },
    });
  };

  const togglePasswordVisiblity = () => {
    setIsRevealPwd(isRevealPwd ? false : true);
  };
  const togglePasswordVisiblityconf = () => {
    setIsRevealPwdconf(isRevealPwdconf ? false : true);
  };
  return (
    <IonPage className="intro" placeholder={undefined}>
      <IonHeader className="ion-text-center" placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <IonButtons slot="start" placeholder={undefined}>
            <IonButton href="/tabs/settings" color="primary" placeholder={undefined}>
              <IonIcon icon={chevronBack} placeholder={undefined} />
            </IonButton>
          </IonButtons>
          <IonTitle color="primary" className="font20 ion-no-padding" placeholder={undefined}>
            {t("changepassword.change_password")}
          </IonTitle>
          <IonButtons slot="end" placeholder={undefined}>
            {/* <IonButton color='primary'>
              <IonIcon icon={ellipsisVertical} />
            </IonButton> */}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent placeholder={undefined}>
        <form onSubmit={handleSubmit((data) => resetpassword(data))}>
          <IonGrid className="changepassword" placeholder={undefined}>
            <IonList placeholder={undefined}>
              <IonItem className="subtitle" placeholder={undefined}>
                <IonLabel placeholder={undefined}>
                  <IonText className="nowrap" no-margin placeholder={undefined}>
                    {t("changepassword.password_desc")}
                  </IonText>
                </IonLabel>
              </IonItem>

              {/* current password strart */}

              <IonItem
                className="tooltiparea inputwithicon forminput"
                color="white" placeholder={undefined}>
                <IonLabel placeholder={undefined}>
                  <IonText no-margin placeholder={undefined}>{t("changepassword.cur_pass")}</IonText>
                </IonLabel>
                <div className="labeltitle">
                  <IonImg src="./assets/images/bang-in-circle.png" alt="" placeholder={undefined} />
                  <div className="tooltip">{t("changepassword.char_desc")}</div>
                </div>
              </IonItem>

              <IonItem className="inputwithicon iconwithinput twoicon" placeholder={undefined}>
                <IonImg src="./assets/images/bang-in-circle.png" alt="" placeholder={undefined} />
                <IonImg src="./assets/images/lockdark.png" alt="" placeholder={undefined} />
                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <IonInput
                      type={isRevealPwd ? "text" : "password"}
                      onBlur={onBlur}
                      onIonChange={onChange}
                      value={value} placeholder={undefined}/>
                  )}
                  control={control}
                  name="oldPassword"
                  rules={{
                    required: t("changepassword.required"),
                  }}
                />
                <IonImg
                  onClick={() => togglePasswordVisiblity()}
                  src={isRevealPwd
                    ? "/assets/images/seepassword-dark.png"
                    : "./assets/images/hidepassword-dark.png"}
                  alt="" placeholder={undefined}/>
              </IonItem>
              <ErrorMessage
                errors={errors}
                name="oldPassword"
                as={<div className="inputerror inputwithicon" />}
              />

              {/* current password end */}

              {/* new password strart */}

              <IonItem
                className="tooltiparea inputwithicon forminput"
                color="black" placeholder={undefined}>
                <IonLabel placeholder={undefined}>
                  <IonText no-margin placeholder={undefined}>{t("changepassword.new_pass")}</IonText>
                </IonLabel>
                <div className="labeltitle">
                  <IonImg src="./assets/images/bang-in-circle.png" alt="" placeholder={undefined} />
                  <div className="tooltip">{t("changepassword.char_desc")}</div>
                </div>
              </IonItem>
              <IonItem className="inputwithicon iconwithinput twoicon" placeholder={undefined}>
                <IonImg src="./assets/images/bang-in-circle.png" alt="" placeholder={undefined} />
                <IonImg src="./assets/images/lockdark.png" alt="" placeholder={undefined} />
                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <IonInput
                      type={isRevealPwd ? "text" : "password"}
                      onBlur={onBlur}
                      onIonChange={onChange}
                      value={value} placeholder={undefined}/>
                  )}
                  control={control}
                  name="pwd"
                  rules={{
                    required: t("changepassword.required"),
                  }}
                />
                <IonImg
                  onClick={() => togglePasswordVisiblity()}
                  src={isRevealPwd
                    ? "/assets/images/seepassword-dark.png"
                    : "./assets/images/hidepassword-dark.png"}
                  alt="" placeholder={undefined}/>
              </IonItem>
              <ErrorMessage
                errors={errors}
                name="pwd"
                as={<div className="inputerror inputwithicon" />}
              />

              {/* new password end */}

              {/* confirm new password strart */}

              <IonItem
                className="tooltiparea inputwithicon forminput"
                color="black" placeholder={undefined}>
                <IonLabel placeholder={undefined}>
                  <IonText no-margin placeholder={undefined}>
                    {t("changepassword.cnfm_new_pass")}
                  </IonText>
                </IonLabel>
                <div className="labeltitle">
                  <IonImg src="./assets/images/bang-in-circle.png" alt="" placeholder={undefined} />
                  <div className="tooltip">{t("changepassword.char_desc")}</div>
                </div>
              </IonItem>
              <IonItem className="inputwithicon iconwithinput twoicon" placeholder={undefined}>
                <IonImg src="./assets/images/bang-in-circle.png" alt="" placeholder={undefined} />
                <IonImg src="./assets/images/lockdark.png" alt="" placeholder={undefined} />
                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <IonInput
                      type={isRevealPwd ? "text" : "password"}
                      onBlur={onBlur}
                      onIonChange={onChange}
                      value={value} placeholder={undefined}/>
                  )}
                  control={control}
                  name="confpwd"
                  rules={{
                    required: t("changepassword.required"),
                  }}
                />
                <IonImg
                  onClick={() => togglePasswordVisiblity()}
                  src={isRevealPwd
                    ? "/assets/images/seepassword-dark.png"
                    : "./assets/images/hidepassword-dark.png"}
                  alt="" placeholder={undefined}/>
              </IonItem>
              <ErrorMessage
                errors={errors}
                name="confpwd"
                as={<div className="inputerror inputwithicon" />}
              />

              {/* confirm new password end */}

              {/* <IonCol>
                <IonLabel>Confirm New Password</IonLabel>
                <div className='iconwithinput'>
                  <IonImg src="./assets/images/lock.png" alt="" />
                
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <IonInput type={isRevealPwdconf ? "text" : "password"} onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                      />
                    )}
                    control={control}
                    name="confpwd"
                    rules={{
                      required: "This is a required field",
                    
                    }}
                  />
                  <IonImg onClick={() => togglePasswordVisiblityconf()} src={isRevealPwdconf ? '/assets/images/showpassword.png' : './assets/images/hidepassword.png'} alt="" />
                
                </div>
              </IonCol>
              <ErrorMessage
                errors={errors}
                name="confpwd"
                as={<div className='inputerror' />}
              /> */}
            </IonList>

            <IonRow className="passwordbtn" placeholder={undefined}>
              <IonCol size="12" placeholder={undefined}>
                <IonButton
                  color="primary"
                  type="submit"
                  className="whitebtn"
                  expand="block" placeholder={undefined}>
                  {t("changepassword.reset")}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>

        {/* <form>
          <IonGrid className="changepassword">
            <IonList>

              <IonItem className='subtitle'>
                <IonLabel>
                  <IonText className='nowrap' no-margin>Your password must be at least 9 characters long and should include uppercase letter and at least one special characters (!@#$).</IonText>
                </IonLabel>
              </IonItem>

              <IonItem className='inputwithicon tooltiparea' color='white'>
                <IonLabel>
                  <IonText no-margin>Current Password</IonText>
                </IonLabel>
              </IonItem>
              <IonItem className='inputwithicon twoicon'>
                <IonImg src="./assets/images/lockdark.png" alt="" />
                <IonInput name="username" type="password" required></IonInput>
                <IonIcon icon={eye} />
              </IonItem>

              <IonItem className='inputwithicon tooltiparea' color='white'>
                <IonLabel>
                  <IonText no-margin>New Password</IonText>
                </IonLabel>
              </IonItem>
              <IonItem className='inputwithicon twoicon'>
                <IonImg src="./assets/images/lockdark.png" alt="" />
                <IonInput name="username" type="password" required></IonInput>
                <IonIcon icon={eyeOffOutline} />
              </IonItem>

              <IonItem className='inputwithicon tooltiparea' color='white'>
                <IonLabel>
                  <IonText no-margin>Confirm New Password</IonText>
                </IonLabel>
              </IonItem>
              <IonItem className='inputwithicon twoicon'>
                <IonImg src="./assets/images/lockdark.png" alt="" />
                <IonInput name="username" type="password" required></IonInput>
                <IonIcon icon={eyeOffOutline} />
              </IonItem>

            </IonList>

            <IonRow class='passwordbtn'>
              <IonCol size="12">
                <IonButton color="primary" routerLink="" expand='block'>Save</IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form> */}
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Changepassword);
