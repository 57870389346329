import React from "react";
import {
  IonPage,
  IonContent,
  IonList,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonButton,
} from "@ionic/react";
import "./StartingLogReg.scss";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface StartingLogRegProps {}

const StartingLogReg: React.FC<StartingLogRegProps> = () => {
  const { t } = useTranslation();

  return (
    <IonPage className="joinnow" placeholder={undefined}>
      <IonContent fullscreen placeholder={undefined}>
        <IonList className="joinpage" placeholder={undefined}>
          <IonGrid placeholder={undefined}>
            <IonRow className="join-screenlogo" placeholder={undefined}>
              <IonCol size="12" placeholder={undefined}>
                <IonImg src="./assets/images/MMX-logo.svg" placeholder={undefined} />
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonGrid className="bg-box" placeholder={undefined}>
            <div className="middle">
              <IonRow placeholder={undefined}>
                <IonCol size="12" placeholder={undefined}>
                  <h4 className="ion-text-center">
                    {t("startinglogreg.join_now")}
                    <br />
                    {t("startinglogreg.learning")}
                  </h4>
                </IonCol>
                <IonCol size="12" className="ion-text-center" placeholder={undefined}>
                  <IonButton
                    routerLink="/signup"
                    color="white"
                    className="whitebtn"
                    expand="full" placeholder={undefined}>
                    {t("startinglogreg.reg_btn")}
                  </IonButton>
                </IonCol>
                <IonCol size="12" className="ion-text-center" placeholder={undefined}>
                  <IonButton
                    routerLink="/login"
                    className="blueborder"
                    color="primary"
                    expand="full" placeholder={undefined}>
                    {t("startinglogreg.sign_in_btn")}
                  </IonButton>
                </IonCol>
              </IonRow>
            </div>
          </IonGrid>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(StartingLogReg);
