import React, { useEffect, useRef, useState } from "react";
import {
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonToggle,
  IonCol,
  IonText,
  IonCard,
  IonCardContent,
  IonRow,
  IonPage,
  useIonViewWillEnter,
  useIonLoading,
  useIonToast,
  IonSlides,
  IonSlide,
  IonImg,
} from "@ionic/react";
import "./E4s.scss";
import Drawer from "react-bottom-drawer";
import ReactAudioPlayer from "react-audio-player";
import { CONFIG } from "../App";
import { Swiper as SwiperCore, Pagination, Navigation, A11y } from "swiper";
import { string } from "yup";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface MyObject {
  id: number;
  name: string;
  type: "e4s";
  answ: true;
  pagecouter: 0;
  totalque: 0;
  back: number;
}
interface E4sProps {
  itemE4s: any;
  onObjectChange: (object: MyObject) => void;
  lastSlide: any;
  cheChar:any;
  piniyin:any;
}

const E4s: React.FC<E4sProps> = (props: E4sProps, { isSuccess, isWrong }) => {
  let url: string = CONFIG.API_ENDPOINT; //live

  const { t } = useTranslation();

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [isVisible, setIsVisible] = React.useState(false);
  const openDrawer = React.useCallback(() => setIsVisible(true), []);

  const [showMessage, setShowMessage] = useState(false);
  const [myObject, setMyObject] = useState<MyObject>({
    id: 0,
    name: "",
    answ: true,
    type: "e4s",
    pagecouter: 0,
    totalque: 0,
    back: 0,
  });
  const [length, setLength] = React.useState(-1);
  const [showResults, setShowResults] = React.useState(1);
  const [successOption, setSuccessOption] = useState<boolean | undefined>();
  const [wrongOption, setWrongOption] = useState<boolean | undefined>();
  const [setRightAns, setRightAnsOption] = useState<boolean | undefined>();
  const [selectedDiv, setSelectedDiv] = useState<any>(null);
  let [swiper, setSwiper] = useState<SwiperCore>();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [showImage, setShowImage] = useState(false);
  const [myanswer, setmyanswer] = useState("");
  const [pageRead, setpageRead] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);
  const [correctAnswer, setCorrectAnswer] = useState<string | null>(null);
  const [isCorrect, setIsCorrect] = useState<boolean | null>(null);
  const [correctAnswerIndex, setCorrectAnswerIndex] = useState<number | null>(
    null
  );
  const [correctAnswerIndices, setCorrectAnswerIndices] = useState<number[]>(
    []
  );
  const [correctAnswerUser, setCorrectAnswerUser] = useState<any>([]);
  const [ansIndex, setAnsSlideIndex] = useState(0);
  let lastValue: any;
  let finalanswer = {};
  let token = "";
  let resfreshtoken = "";
  let Ise4ev1: any = true;
  let pagecouter: any = 0;
  let totalque: any = 0;
  let indexdbq: any = -1;
  // let myanswer = ''
  function handleChange(back) {
    const myObject: MyObject = {
      id: 1,
      name: "My Object",
      answ: Ise4ev1,
      type: "e4s",
      pagecouter: pagecouter,
      back: back,
      totalque: props.itemE4s.length,
    };
    props.onObjectChange(myObject);
  }
  useIonViewWillEnter(() => {
    setShowResults(1);
    setLength(1);
    console.log("itemE4s", props.itemE4s);
    console.log("length nw", props.itemE4s.length);
    debugger;
    setShowMessage(true);
  });

  SwiperCore.use([Navigation, Pagination, A11y]);
  const slidesRef = useRef<HTMLIonSlidesElement>(null);

  const options = {
    keyboard: true,
  };
  const slideRef = useRef<HTMLIonSlidesElement>(null);

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
  };

  const handleSlideNextEnded = (event: CustomEvent<any>) => {
    console.log("Slide Next ended", event.detail);

    slidesRef.current?.getActiveIndex().then((index) => {
      console.log("Slide Next ended index", index + 1);
      setLength(index + 1);
      resetstate();
      setShowResults(index + 1);
      // console.log('length', props.itemE4s.length);
    });
  };

  const handleSlidePrevEnded = (event: CustomEvent<any>) => {
    console.log("Slide Prev ended", event.detail);
    // setLength(length - 1)
    slidesRef.current?.getActiveIndex().then((index) => {
      //console.log('index', (index + 1));
      setLength(index - 1);
      // resetstate()
      // setShowResults((index-1))
      if (index === 0) {
        handleChange(1);
        console.log("Call Done");
      }
    });
  };

  const handleSlideDidChanged = async (event: CustomEvent<any>) => {
    console.log("Length", length);
    const swiper = await slidesRef.current?.getSwiper();
    if (swiper) {
      const swipeDirection = swiper.swipeDirection;
      const currentIndex = swiper.activeIndex;
      console.log("Swipe direction:", swipeDirection);

      if (swipeDirection == "prev") {
        //Back Login code
        setAnsSlideIndex(currentIndex);
        slidesRef.current?.getActiveIndex().then((index) => {
          console.log(index - 1);
          if (index - 1 === -1) {
            handleChange(1);
          }
        });
      } else {
        setAnsSlideIndex(currentIndex);
        slidesRef.current?.getActiveIndex().then(async (index) => {
          console.log("index", index + 1);
          debugger;
          // if (slidesRef.current) {
          //   const ionSlides = await slidesRef.current.getSwiper();
          //   console.log('ionSlides.activeIndex',ionSlides.activeIndex);
          // }

          SaveExerciseQueAnswer(length);
          if (index + 1 == length) {
            console.log("Go next page");
            setLength(-1);
            saveContent();
            handleChange(0);
          }
        });
      }
    }
  };

  const gotonextques = async (items: any, index: any, type) => {
    //1 for Understand and 2= dont understand
    console.log(items);

    // console.log(type)
    // console.log('soundno ', items?.Question?.[0].soundno)
    // console.log('questionno', type)

    if (items?.Question?.[0].soundno == type) {
      isSuccess = true;
      isWrong = false;
    } else {
      isSuccess = false;
      isWrong = true;
    }

    // console.log(showResults)
    //console.log(itemlist)
    //console.log(itemlist[showResults - 1])

    //  let data = itemlist[showResults - 1];
    //debugger
    if (items) {
      resetstate();
      setShowResults(index);
      // console.log('len ', showResults - 1)
      setLength(index);

      // ansques = data.QuestionId + ',' + type;
      // setQuestion([...quesdata, ansques]);
    }
  };
  let tmpscore = 0;

  const handleQuestionCheckClick = (
    items: any,
    index: any,
    indexq: any,
    type,
    indexnew
  ) => {
    // console.log(type)
    //console.log('soundno ', items?.Question?.[0].soundno)
    //console.log('questionno', type)
    debugger;
    // indexdbq = index

    if (items?.Question?.[0].soundno == type) {
      isSuccess = true;
      isWrong = false;
      setSuccessOption(isSuccess);
      setWrongOption(isWrong);
      setSelectedDiv(type);
      console.log("q1", isSuccess);
      // myanswer = props.itemE4s[index].Options?.[indexnew].Word
      setmyanswer("");
      setmyanswer(props.itemE4s[index].Options?.[indexnew].Word);
      console.log("myanswer", myanswer);
      debugger;
      if (!props.itemE4s[index].answer) {
        props.itemE4s[index].answer = type;
        props.itemE4s[index].score = true;
        tmpscore += tmpscore;
      }
    } else {
      isSuccess = false;
      isWrong = true;
      setRightAnsOption(true);
      setSuccessOption(isSuccess);
      setWrongOption(isWrong);
      setSelectedDiv(type);
      setmyanswer("");
      setmyanswer(props.itemE4s[index].Options?.[indexnew].Word);
      console.log("myanswer", myanswer);
      if (!props.itemE4s[index].answer) {
        props.itemE4s[index].answer = type;
        props.itemE4s[index].score = false;
      }
    }

    // console.log('result', result)
    //console.log('isSuccess', isSuccess)

    //console.log('isWrong', isWrong)
  };

  const handleAnswerSelection = (
    selectedOption: string,
    correctAnswer: string,
    index: number
  ) => {
    setSelectedAnswer(selectedOption);
    setCorrectAnswer(correctAnswer);
    if (selectedOption === correctAnswer) {
      debugger;
      setIsCorrect(true);
      setRightAnsOption(true);

      setmyanswer("");
      setmyanswer(selectedOption);
      const pushValue = (selectedOption) => {
        setCorrectAnswerUser((prevValue) => [...prevValue, selectedOption]);
      };
      pushValue(selectedOption);
    } else {
      setIsCorrect(false);
      setRightAnsOption(false);

      setmyanswer("");
      setmyanswer(selectedOption);
      // setCorrectAnswerIndex(index); // Set the correct answer index
    }

    // Set the correct answer index
    const correctIndex = props.itemE4s[index].Options.findIndex(
      (option) => option.Word === correctAnswer
    );
    setCorrectAnswerIndex(correctIndex);

    setCorrectAnswerIndices((prevIndices) => {
      const newIndices = [...prevIndices];
      newIndices[index] = correctIndex;
      return newIndices;
    });

    // Update the state or perform other actions as needed
    // Example: props.itemE1s[index].answer = selectedOption;
  };

  const resetstate = () => {
    setSuccessOption(false);
    setWrongOption(false);
    setSelectedDiv(false);
    setRightAnsOption(false);
  };
  useEffect(() => {
    // console.log(quesdata);

    //console.log('String', quesdata.join('#'))

    // console.log('showResults', showResults)
    //  console.log('finalindex', length)
    // console.log('lengthque', lengthque)
    //console.log('teatemediaBlob', mediaBlob)

    if (length > props.itemE4s.length) {
      console.log("Done");
      setLength(-1);
      saveContent();
      handleChange(0);
    }
    const timeoutId = setTimeout(() => {
      setShowImage(true);
      // Calculate the index of the next slide
      const nextSlideIndex = activeSlideIndex + 1;
      setActiveSlideIndex(nextSlideIndex);
      // Call the slideTo method to swipe to the next slide
      // slidesRef.current?.slideTo(nextSlideIndex);
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };

    //debugger
  }, [activeSlideIndex, props.itemE4s.length]);

  const saveContent = async () => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    // present({
    //   message: '',
    // })

    let anw = {};
    let tmscore = 0;
    pagecouter = 0;
    for (let index = 0; index < props.itemE4s.length; index++) {
      const element = props.itemE4s[index];
      const propName = ("q" + (index + 1)).toString();
      const propValue = element.answer;

      anw[propName] = propValue.toString();
      finalanswer = anw;
      if (element.score) {
        tmscore += element.score;
      }
    }
    console.log("tmpscore", tmscore);
    console.log("finalanswer", finalanswer);
    debugger;
    const stringWithBackslashes = JSON.stringify(finalanswer);
    const stringWithoutBackslashes = stringWithBackslashes.replace(/\\/g, "");
    console.log(stringWithoutBackslashes); // Output: {"q1":"2","q2":"4","q3":"4"}

    const myArray = Object.entries(finalanswer);
    console.log(myArray);
    for (let k = 0; k < myArray.length; k++) {
      const element = myArray[k][1];
      if (element != "") {
        pagecouter++;
      }
    }
    console.log(pagecouter);

    debugger;
    const data = {
      contentPageCode: props.itemE4s[0].TemplateCode,
      type: "exercise-score",
      bookId: props.itemE4s[0].BookId,
      contentPageId: props.itemE4s[0].PageId,
      answer: stringWithoutBackslashes,
      score: correctAnswerUser.length,
      options: null,
      pageRead: pagecouter,
    };
    //dismiss()
    console.log(data);
    debugger;

    try {
      const response = await fetch(url + "/Book/SaveContent", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });

      const json = await response.json();
      console.log(json);
      //dismiss()
      debugger;
      if (json.StatusCode == 1) {
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };

  const SaveExerciseQueAnswer = async (indexdb) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }

    //console.log('rigthans', rigthans)
    console.log("myanswer11", myanswer);
    console.log("indexdb", indexdb);

    const data = {
      eId: props.itemE4s[ansIndex].EId,
      eType: props.itemE4s[ansIndex].TemplateCode,
      //question: props.itemE2s[indexdb - 1]?.Question?.[0].ImageUrl,
      //rightAnswer: foundValue.Choice,
      rearrageAnswer: "", //only for rearrange exercise otherwise blank
      answer: myanswer,
      writingScore: 0,
    };
    console.log("SaveExerciseQueAnswer", data);
    debugger;

    try {
      const response = await fetch(url + "/Book/SaveExerciseQueAnswer", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });

      const json = await response.json();
      console.log(json);
      //  debugger
      if (json.StatusCode == 1) {
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);

  return (
    <IonPage className="pages-space intro" placeholder={undefined}>
      <IonContent className="listing" placeholder={undefined}>
        <IonSlides
          pager={false}
          scrollbar={true}
          options={{
            initialSlide: props.lastSlide === 1
              ? props.itemE4s.length - 1
              : activeSlideIndex,
            options,
          }}
          onIonSlideNextEnd={handleSlideNextEnded}
          onIonSlidePrevEnd={handleSlidePrevEnded}
          onIonSlideTransitionEnd={handleSlideDidChanged}
          ref={slidesRef} // initialSlide={activeSlideIndex}
          placeholder={undefined}        >
          {props.itemE4s.map((items, index) => {
            return (
              <IonSlide placeholder={undefined}>
                <IonList key={index} className="ion-no-padding height100" placeholder={undefined}>
                  <IonRow className="Practice leftrightspace ion-padding-horizontal" placeholder={undefined}>
                    <IonCol placeholder={undefined}>
                      <IonText placeholder={undefined}>{t("e4s.choose_correct_answer")}</IonText>
                    </IonCol>
                  </IonRow>

                  <IonRow className="Practice spacing ion-padding-horizontal" placeholder={undefined}>
                    <IonCol size="12" placeholder={undefined}>
                      {/* {index + 1}
                      {items?.Question?.[0].SoundUrl} */}
                      {showMessage ? (
                        <ReactAudioPlayer
                          className="String"
                          controls
                          controlsList="nodownload noplaybackrate"
                        >
                          <source
                            src={items?.Question}
                            type="audio/mpeg"
                          ></source>
                        </ReactAudioPlayer>
                      ) : (
                        ""
                      )}
                    </IonCol>
                  </IonRow>
                  <IonRow className="answer spacing ion-padding-horizontal" placeholder={undefined}>
                    {props.itemE4s[index]?.Options?.map((datav, indexnew) => {
                      return (
                        <IonCol size="6" key={indexnew} placeholder={undefined}>
                          {/* <p>{datav.Word}-{indexnew}</p> */}
                          <IonCard
                            id={datav.questionno}
                            style={{
                              borderColor: isCorrect !== null &&
                                isCorrect &&
                                datav.Word === selectedAnswer
                                ? "var(--ion-color-success)" // Green outline for the correct answer
                                : correctAnswerIndices[index] !== null &&
                                  indexnew === correctAnswerIndices[index]
                                  ? "var(--ion-color-success)" // Green outline for the correct answer when wrong is selected
                                  : isCorrect !== null &&
                                    !isCorrect &&
                                    datav.Word === selectedAnswer
                                    ? "var(--ion-color-danger)" // Red outline for the wrong answer
                                    : "",
                            }}
                            onClick={() => handleAnswerSelection(
                              datav.Word,
                              items.answer,
                              index
                            )} placeholder={undefined}>
                            <IonCardContent class="ion-no-padding" placeholder={undefined}>
                              <div className="min184">
                                <IonText placeholder={undefined}>{props.cheChar && (datav.Word)}</IonText>
                                <p>{props.piniyin && (datav.WordPy)}</p>
                              </div>
                            </IonCardContent>
                          </IonCard>
                        </IonCol>
                      );
                    })}
                    <IonCol size="12" placeholder={undefined}>
                      {showImage && (
                        <IonImg
                          className="slide"
                          src="./assets/images/swipe-left-right.gif" placeholder={undefined}/>
                      )}
                      {/* <IonButton expand="block" onClick={() => gotonextques(items, showResults + 1, 1)}>Next</IonButton> */}
                    </IonCol>
                  </IonRow>
                </IonList>
              </IonSlide>
            );
          })}
        </IonSlides>
      </IonContent>

      {/* <IonContent className='listing'>
        {props.itemE4s.map((items, index) => {
          return (
            <IonList key={index} style={{ display: showResults == index + 1 ? "block" : "none" }} className="ion-no-padding height100">
              <IonRow className='Practice ion-padding-horizontal'>
                <IonCol size='12'>
                  {showMessage ?
                    <ReactAudioPlayer className='String' controls controlsList="nodownload noplaybackrate">
                      <source src={items?.Question?.[0].SoundUrl} type="audio/mpeg"></source>
                    </ReactAudioPlayer> : ''}
                </IonCol>
              </IonRow>


              <IonRow className='answer ion-padding-horizontal'>
                {props.itemE4s?.[index].Options?.map((datav, indexnew) => {
                  return (
                    <IonCol size='6' key={indexnew}>
                      <IonCard id={datav.questionno} style={{ borderColor: setRightAns && (items?.Question?.[0].soundno === datav.questionno) ? var(--ion-color-warning-shade)' : '' }} className={successOption && (selectedDiv === datav.questionno) ? 'answer-right' : wrongOption && (selectedDiv === datav.questionno) ? 'answer-wrong' : ''} onClick={() => handleQuestionCheckClick(items, index, showResults + 1, datav.questionno)} >
                        <IonCardContent class="ion-no-padding">
                          <div>
                            <IonText>{datav.Word} </IonText>
                          </div>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                  )
                })}
                <IonCol size='12'>
                  <IonButton expand="block" onClick={() => gotonextques(items, showResults + 1, 1)}>Next</IonButton>
                </IonCol>
              </IonRow>
            </IonList>
          )
        })}
      </IonContent> */}

      <Drawer
        className="bottompopup skateInBottom"
        isVisible={isVisible}
        onClose={onClose}
      >
        <IonRow placeholder={undefined}>
          <IonList className="list" placeholder={undefined}>
            {/* <IonItem class="ion-no-padding playbook">
              <div>
                <IonImg src="./assets/images/play.png" />
              </div>
              <div className="details ion-padding-start">
                <h4>Play book</h4>
                <span>Autoplay book audio</span>
              </div>
            </IonItem> */}
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("e4s.drawer.lesson_language")}</IonLabel>
              <div className="right-space">
                <IonText color="primary" placeholder={undefined}>簡</IonText>
                <IonText placeholder={undefined}>繁</IonText>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("e4s.drawer.chinese_characters")}</IonLabel>
              <IonToggle checked slot="end" placeholder={undefined}></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("e4s.drawer.pinyin")}</IonLabel>
              <IonToggle checked slot="end" placeholder={undefined}></IonToggle>
            </IonItem>
            {/* <IonItem class="ion-no-padding">
              <IonLabel>Translation</IonLabel>
              <IonToggle slot="end"></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding">
              <IonLabel>Font size</IonLabel>
              <IonImg src="./assets/images/Fontdown.png" />
              <IonImg src="./assets/images/Fontup.png" />
            </IonItem> */}
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("e4s.drawer.playback_speed")}</IonLabel>
              <div className="leftright">
                <a href="#">&lt;</a> 1.0 <a href="#">&gt;</a>
              </div>
            </IonItem>
            {/* <IonItem class="ion-no-padding">
              <IonLabel>Bookmark</IonLabel>
              <IonIcon color='primary' icon={bookmark} />
            </IonItem> */}
          </IonList>
        </IonRow>
      </Drawer>
    </IonPage>
  );
};

export default React.memo(E4s);
