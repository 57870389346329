import React, { useEffect, useState } from 'react';
import { IonHeader, IonToolbar, IonButtons, IonButton, IonList, IonCol, IonText, IonCard, IonCardContent, IonContent, IonIcon, IonPage, IonTitle, useIonLoading, useIonToast, useIonViewWillEnter, IonLoading } from '@ionic/react';
import './Starredvocabulary.scss';
import { chevronBack, chevronForward, search } from 'ionicons/icons';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { CONFIG } from '../App';
import { RouteComponentProps, useHistory } from 'react-router';
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface OwnProps extends RouteComponentProps { };

interface StarredvocabularyProps extends OwnProps { };


const Starredvocabulary: React.FC<StarredvocabularyProps> = ({ history }) => {
  let url: string = CONFIG.API_ENDPOINT;//live

  const { t } = useTranslation();

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any>([])
  const [activeText, setActiveText] = useState('Level1');
  const [itemlevel, setlevel] = useState<any>(1)
  const [activeDetails, setActiveDetails] = useState(false);
  const historyss = useHistory<{ levelIdtype: string }>();

  const levelId = historyss.location.state ? historyss.location.state.levelIdtype : ''
  debugger

  let token = ''
  let resfreshtoken = ''


  useIonViewWillEnter(() => {
    setlevel(levelId)
    let levid;
    if (Number(levelId) === 1) {
      levid = 'Level1'
    } else if (Number(levelId) === 2) {
      levid = 'Level2'
    } else if (Number(levelId) === 3) {
      levid = 'Level3'
    } else if (Number(levelId) === 4) {
      levid = 'Level4'
    } else if (Number(levelId) === 5) {
      levid = 'Level5'
    }
    setActiveText(levid)
    //debugger
    // setlevelitems(levelId) 
    let currentUser = JSON.parse(localStorage.getItem('alltoken') || '{}');
    if (currentUser) {
      token = currentUser.JwtToken
      resfreshtoken = currentUser.RefreshToken
    }

    GetStarredVocubalary()
    // GetLevels()
  });

  const GetStarredVocubalary = async () => {
    let currentUser = JSON.parse(localStorage.getItem('alltoken') || '{}');
    if (currentUser) {
      token = currentUser.JwtToken
      resfreshtoken = currentUser.RefreshToken
    }
    present({
      message: '',
      cssClass: 'custom-spinner',
      duration: 1000
    })

    try {

      const response = await fetch(url + '/User/GetStarredVocubalary', {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token
        },
        method: "GET",
      })


      const json = await response.json()
      console.log('GetStarredVocubalary', json)


      dismiss()
      if (json.StatusCode == 1) {

        setitems(json.Result)
        //debugger

      } else if (json.StatusCode == 2) {
        //refreshtoken()
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: 'bottom'
        });
      }


    } catch (error) {
      console.error(error)
      dismiss()
    }
  }


  const handleClick = (text) => {
    setActiveText(text);
    // setActiveSearch(false)
    // setActiveDetails(false)
  }
  const handleClickvoocab = (item, level) => {
    setActiveDetails(true)
  }
  // useEffect(() => {
  //   setlevelitems(levelId)
  // },);

  return (
    <IonPage className='intro' placeholder={undefined}>
      <IonHeader className='ion-text-center' placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <IonButtons slot="start" placeholder={undefined}>
            <IonButton href='/tabs/progress/Vocabularybank' color='primary' placeholder={undefined}>
              <IonIcon icon={chevronBack} placeholder={undefined} />
            </IonButton>
          </IonButtons>
          <IonTitle color='primary' className='font20 ion-no-padding' placeholder={undefined}><span>{t("starredvocabulary.strd_vocb")}</span></IonTitle>
          <IonButtons slot="end" placeholder={undefined}>
            <IonButton color='success' placeholder={undefined}>
              <IonIcon icon={search} placeholder={undefined} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className='listing ion-padding-horizontal' placeholder={undefined}>
        <IonList className="themes" placeholder={undefined}>

          <IonCol className='data-selection Detailed' placeholder={undefined}>

            <IonText className={activeText === 'Level1' ? 'select ' : ''} onClick={() => handleClick('Level1')} placeholder={undefined}>{t("starredvocabulary.lvl_1")}</IonText>
            <IonText className={activeText === 'Level2' ? 'select ' : ''} onClick={() => handleClick('Level2')} placeholder={undefined}>{t("starredvocabulary.lvl_2")}</IonText>
            <IonText className={activeText === 'Level3' ? 'select ' : ''} onClick={() => handleClick('Level3')} placeholder={undefined}>{t("starredvocabulary.lvl_3")}</IonText>
            <IonText className={activeText === 'Level4' ? 'select ' : ''} onClick={() => handleClick('Level4')} placeholder={undefined}>{t("starredvocabulary.lvl_4")}</IonText>
            <IonText className={activeText === 'Level5' ? 'select ' : ''} onClick={() => handleClick('Level5')} placeholder={undefined}>{t("starredvocabulary.lvl_5")}</IonText>
          </IonCol>
        </IonList>

        <IonList className="vocabulary" placeholder={undefined}>

          <div></div>
          {activeText === 'Level1' ?

            <div className='w-100'>
              <p className='txtcenter'> {itemlist?.Level1s?.length > 0 ? '' : t("starredvocabulary.no_dta_fund")}</p>
              {
                itemlist?.Level1s?.map((items, index) => {
                  return (
                    <IonCard key={index} onClick={() => handleClickvoocab(items, 'Level1')} className='mysubscription bell' placeholder={undefined}>
                      <IonCardContent class="ion-no-padding" placeholder={undefined}>
                        <IonText placeholder={undefined}><span>{items.Word}</span> {(items.Pinyin)}</IonText>
                        <IonIcon color='primary' className='ion-float-right' icon={chevronForward} placeholder={undefined} />
                      </IonCardContent>
                    </IonCard>
                  )
                })
              }
            </div>
            : ''}

          {activeText === 'Level2' ?
            <div className='w-100'>
              <p className='txtcenter'> {itemlist?.Level2s?.length > 0 ? '' : t("starredvocabulary.no_dta_fund")}</p>

              {
                itemlist?.Level2s?.map((items, index) => {
                  return (
                    <IonCard key={index} onClick={() => handleClickvoocab(items, 'Level2')} className='mysubscription bell' placeholder={undefined}>
                      <IonCardContent class="ion-no-padding" placeholder={undefined}>
                        <IonText placeholder={undefined}><span>{items.Word}</span> {(items.Pinyin)}</IonText>
                        <IonIcon color='primary' className='ion-float-right' icon={chevronForward} placeholder={undefined} />
                      </IonCardContent>
                    </IonCard>
                  )
                })
              }
            </div>
            : ''}
          {activeText === 'Level3' ?
            <div className='w-100'>
              <p className='txtcenter'> {itemlist?.Level3s?.length > 0 ? '' : t("starredvocabulary.no_dta_fund")}</p>

              {
                itemlist?.Level3s?.map((items, index) => {
                  return (
                    <IonCard key={index} onClick={() => handleClickvoocab(items, 'Level3')} className='mysubscription bell' placeholder={undefined}>
                      <IonCardContent class="ion-no-padding" placeholder={undefined}>
                        <IonText placeholder={undefined}><span>{items.Word}</span> {(items.Pinyin)}</IonText>
                        <IonIcon color='primary' className='ion-float-right' icon={chevronForward} placeholder={undefined} />
                      </IonCardContent>
                    </IonCard>
                  )
                })
              }
            </div>
            : ''}

          {activeText === 'Level4' ?
            <div className='w-100'>
              <p className='txtcenter'> {itemlist?.Level4s?.length > 0 ? '' : t("starredvocabulary.no_dta_fund")}</p>

              {
                itemlist?.Level4s?.map((items, index) => {
                  return (
                    <IonCard key={index} onClick={() => handleClickvoocab(items, 'Level4')} className='mysubscription bell' placeholder={undefined}>
                      <IonCardContent class="ion-no-padding" placeholder={undefined}>
                        <IonText placeholder={undefined}><span>{items.Word}</span> {(items.Pinyin)}</IonText>
                        <IonIcon color='primary' className='ion-float-right' icon={chevronForward} placeholder={undefined} />
                      </IonCardContent>
                    </IonCard>
                  )
                })
              }
            </div>
            : ''}

          {activeText === 'Level5' ?
            <div className='w-100'>
              <p className='txtcenter'> {itemlist?.Level5s?.length > 0 ? '' : t("starredvocabulary.no_dta_fund")}</p>

              {
                itemlist?.Level5s?.map((items, index) => {
                  return (
                    <IonCard key={index} onClick={() => handleClickvoocab(items, 'Level5')} className='mysubscription bell' placeholder={undefined}>
                      <IonCardContent class="ion-no-padding" placeholder={undefined}>
                        <IonText placeholder={undefined}><span>{items.Word}</span> {(items.Pinyin)}</IonText>
                        <IonIcon color='primary' className='ion-float-right' icon={chevronForward} placeholder={undefined} />
                      </IonCardContent>
                    </IonCard>
                  )
                })
              }
            </div>
            : ''}


        </IonList>

      </IonContent>

    </IonPage>
  );
};

export default React.memo(Starredvocabulary);