import React, { useState } from "react";
import {
  IonContent,
  IonPage,
  IonHeader,
  IonTitle,
  IonImg,
  IonRow,
  IonCol,
  IonList,
  IonToolbar,
  IonButton,
} from "@ionic/react";
import { setHasSeenTutorial } from "../data/user/user.actions";
import "./Pretestone.scss";
import { RouteComponentProps } from "react-router";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {
  setHasSeenTutorial: typeof setHasSeenTutorial;
}

interface Pretestone extends OwnProps, DispatchProps {}

const Pretestone: React.FC<Pretestone> = ({ history }) => {

  const { t } = useTranslation();

  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <div className="icon">
              <IonImg src="./assets/images/info.png" alt="" placeholder={undefined} />
            </div>
            <p>
            {t("pretestone.submit.cfrm_skp_tst")}
            </p>
            <div className="react-confirm-alert-button-group">
              <IonButton onClick={onClose} className="alertcancel" placeholder={undefined}>
              {t("pretestone.submit.cancel")}
              </IonButton>
              <IonButton
                onClick={() => {
                  continuebtn();
                  onClose();
                } }
                className="alertsubmit" placeholder={undefined}>
                {t("pretestone.submit.continue")}
              </IonButton>
            </div>
          </div>
        );
      },

      /*title: 'Confirm to submit',
      message: 'You will not get your suggested level if you skip the test, are you sure?',
      buttons: [
        {
          label: 'Cancel',
          className: 'alertcancel',
         // onClick: () => alert('Click Yes')
        },
        {
          label: 'Continue',
          className: 'alertsubmit',
         // onClick: () => alert('Click No')
        },
      ]*/
    });
  };

  const gotoPretesttwo = async () => {
    // await setHasSeenTutorial(true);
    // await setMenuEnabled(true);
    history.push("/pretesttwo", { direction: "none" });
  };
  const continuebtn = async () => {
    history.push("/tabs/home", { direction: "none" });
  };

  const gotoskip = async () => {
    submit();
    // await setHasSeenTutorial(true);
    // await setMenuEnabled(true);
    //history.push('/pretesttwo', { direction: 'none' });
  };

  //    history.push('/tabs/home', { direction: 'none' });

  return (
    <IonPage className="pages-space" id="tutorial-page" placeholder={undefined}>
      <IonHeader className="ion-text-center" placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <IonTitle color="primary" placeholder={undefined}>
            <span>{t("pretestone.we_ar_almt_tr")}</span>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      {/* <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="end">
            {showSkip && <IonButton color='primary' onClick={startApp}>Skip</IonButton>}
          </IonButtons>
        </IonToolbar>
      </IonHeader> */}
      <IonContent fullscreen placeholder={undefined}>
        <IonList className="height100" placeholder={undefined}>
          <IonRow className="testpage" placeholder={undefined}>
            <IonCol size="12" class="testspace" placeholder={undefined}>
              <IonImg className="flag" src="./assets/images/test-flag.png" placeholder={undefined} />
            </IonCol>
            <IonCol size="12" className="ion-text-center" placeholder={undefined}>
              <p>{t("pretestone.nxt")}{t("pretestone.prof_tst")}</p>
              <p>
              {t("pretestone.do_tst_pers_exp")}
              </p>
            </IonCol>
          </IonRow>
          <IonRow class="skiptestpage" placeholder={undefined}>
            <IonCol size="12" className="ion-text-center" placeholder={undefined}>
              <IonButton
                onClick={gotoPretesttwo}
                className="nextbtn"
                color="primary" placeholder={undefined}>
                {t("pretestone.next")}
              </IonButton>
              <div className="skip ion-text-center">
                <a onClick={gotoskip}>{t("pretestone.skip_test")}</a>
              </div>
            </IonCol>
          </IonRow>
        </IonList>
        {/* <IonButton onClick={gotoPretesttwo} expand="block" fill="outline" shape="round">Go to Pre Test Two</IonButton> */}
      </IonContent>
    </IonPage>
  );
};

export default Pretestone;
// export default connect<OwnProps, {}, DispatchProps>({
//   mapDispatchToProps: ({
//     setHasSeenTutorial,
//     setMenuEnabled
//   }),
//   component: Beforewestart
// });
