import React, { useEffect, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonImg,
  IonItem,
  IonList,
  IonIcon,
  IonLabel,
  IonContent,
  IonPage,
  IonTitle,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
} from "@ionic/react";
import "./Languages.scss";
import { chevronForward, chevronBack } from "ionicons/icons";
import { CONFIG } from "../App";
import { json } from "stream/consumers";
import { RouteComponentProps } from "react-router";
import { useTranslation } from "react-i18next";
// import { setLanguage } from "./i18n/i18n"

interface OwnProps extends RouteComponentProps {}

interface LanguagesProps extends OwnProps {}

const Languages: React.FC<LanguagesProps> = ({ history }) => {
  let url: string = CONFIG.API_ENDPOINT; //live

  const { t,i18n } = useTranslation();

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any[]>([]);
  const [itemlistLang, setitemsLang] = React.useState<any[]>([]);

  let token = "";
  let resfreshtoken = "";
  let rap;

  const [IsLang, setIsLang] = useState(false);
  const [IsLessonLang, setIsLessonLang] = useState(false);
  const [IsTranLang, setIsTranLang] = useState(false);
  const [IsTranLangSim, setIsTranLangSim] = useState(true);
  useIonViewWillEnter(() => {
    setIsLang(true);
    setIsTranLang(false)
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    getLanguages();
  });

  const setIsLangue = (value: boolean, type) => {
    if (type == 1) {
      setIsLang(false);
      setIsLessonLang(true);
      setIsTranLang(false);
    } else {
      setIsLang(false);
      setIsLessonLang(false);
      setIsTranLang(true);
    }
    getSettings();
    getLanguages();
  };

  useEffect(() => {
    const getSettings = async () => {
      let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
      if (currentUser) {
        token = currentUser.JwtToken;
        resfreshtoken = currentUser.RefreshToken;
      }

      present({
        message: "",
        cssClass: 'custom-spinner',
        duration: 1000,
      });

      try {
        const response = await fetch(url + "/User/GetSetting", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        });

        const json = await response.json();
        console.log("Setting", json);

        dismiss();
        if (json.StatusCode == 1) {
          // set flag to true after initial settings have been loaded
          if (json.Result.LessonLanguage == "Chinese, Traditional") {
            setIsTranLangSim(true);
          } else {
            setIsTranLangSim(false);
          }

          // setToggleValueTc(json.Result.IsTranslation);
          // setToggleValuePy(json.Result.IsPinyin)
          // setToggleValueAp(json.Result.IsAutoPlay)
          // debugger
          // setSettingsLoaded(false);

          setitems(json.Result);
        } else if (json.StatusCode == 2) {
          refreshtoken();
        } else {
          presenttost({
            message: json.Message,
            duration: 1500,
            position: "bottom",
          });
        }
      } catch (error) {
        console.error(error);
        dismiss();
      }
    };
    getSettings();
  }, []);

  const getSettings = async () => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }

    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 1000,
    });

    try {
      const response = await fetch(url + "/User/GetSetting", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      const json = await response.json();
      console.log("Setting", json);

      dismiss();
      if (json.StatusCode == 1) {
        // set flag to true after initial settings have been loaded

        if (json.Result.LessonLanguage == "Chinese, Traditional") {
          setIsTranLangSim(false);
        } else {
          setIsTranLangSim(true);
        }

        // setToggleValueTc(json.Result.IsTranslation);
        // setToggleValuePy(json.Result.IsPinyin)
        // setToggleValueAp(json.Result.IsAutoPlay)
        // debugger
        // setSettingsLoaded(false);

        setitems(json.Result);
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const getLanguages = async () => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }

    // present({
    //   message: '',
    //   duration: 1000
    // })

    try {
      const response = await fetch(url + "/User/GetLanguages", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      const json = await response.json();
      console.log("GetLanguages", json);

      dismiss();
      if (json.StatusCode == 1) {
        setitemsLang(json.Result);
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const backspace = () => {
    getSettings();
    if (IsLang) {
      history.push("/tabs/settings", { direction: "none" });

      //setIsLangue(false,1)
    } else if (IsLessonLang) {
      setIsLang(true);
      setIsLessonLang(false);
      setIsTranLang(false);
    } else if (IsTranLang) {
      setIsLang(true);
      setIsLessonLang(false);
      setIsTranLang(false);
    }
  };
  const setIsLangueTrans = (value, type) => {
    if (type == 1) {
      setIsTranLangSim(true);
      saveLangue(value, true, -1);
    } else {
      setIsTranLangSim(false);
      saveLangue(value, true, -1);
    }
  };
  const saveLangue = async (val, isLesson, index) => {
    if (isLesson == false) {
      let tmp: any = [];
      for (let i = 0; i < itemlistLang.length; i++) {
        const element = itemlistLang[i];
        let data = {};
        if (index == i) {
          data = {
            Code: element.Code,
            IsSelected: true,
            Name: element.Name,
          };
          const languageCode = element.Code; // replace with the actual language code you want to set
          localStorage.setItem("language" , languageCode)
          i18n.changeLanguage(languageCode)
          // setLanguage(languageCode);
        } else {
          data = {
            Code: element.Code,
            IsSelected: false,
            Name: element.Name,
          };
        }

        tmp.push(data);
      }
      setitemsLang(tmp);

      console.log("tmp", tmp);
    }
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 3000,
    });
    console.log(itemlist);

    const data = {
      language: val,
      isLesson: isLesson,
    };
    try {
      const response = await fetch(url + "/User/SaveLanguage", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });

      const json = await response.json();
      console.log(json);

      dismiss();

      if (json.StatusCode == 1) {
        // history.push('/login');
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };

  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      console.log(json);

      if (json.StatusCode == 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  return (
    <IonPage className="intro" placeholder={undefined}>
      <IonHeader className="ion-text-center" placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <IonButtons slot="start" placeholder={undefined}>
            <IonButton onClick={() => backspace()} color="primary" placeholder={undefined}>
              <IonIcon icon={chevronBack} placeholder={undefined} />
            </IonButton>
          </IonButtons>
          <IonTitle color="primary" className="font20 ion-no-padding" placeholder={undefined}>
            <span>{t("languages.languages")}</span>
          </IonTitle>
          <IonButtons slot="end" placeholder={undefined}>
            {/* <IonButton color='success'>
              <IonIcon icon={ellipsisVertical} />
            </IonButton> */}
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="listing" placeholder={undefined}>
        {IsLang ? (
          <IonList className="list" placeholder={undefined}>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel onClick={() => setIsLangue(IsLessonLang, 1)} placeholder={undefined}>
                <h2>{t("languages.lesson_language")}</h2>
                <div>
                  <span>{itemlist["LessonLanguage"]} </span>
                  <IonIcon icon={chevronForward} placeholder={undefined} />
                </div>
              </IonLabel>
            </IonItem>

            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel onClick={() => setIsLangue(IsTranLang, 2)} placeholder={undefined}>
                <h2>{t("languages.translation_language")}</h2>
                <div>
                  <span>{itemlist["TranslateLanguage"]}</span>
                  <IonIcon icon={chevronForward} placeholder={undefined} />
                </div>
              </IonLabel>
            </IonItem>
          </IonList>
        ) : (
          ""
        )}

        {IsLessonLang ? (
          <IonList className="list engvschinese" placeholder={undefined}>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>
                <h2>{t("languages.lesson_language")}</h2>
              </IonLabel>
            </IonItem>

            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel
                onClick={() => setIsLangueTrans("Chinese, Simplified", 1)} placeholder={undefined}              >
                <h2>
                {t("languages.chinese_simplified")} <span className="chinese">简体中文</span>
                </h2>
                {IsTranLangSim ? (
                  <div>
                    <IonImg src="./assets/images/Icon.png" alt="" placeholder={undefined} />
                  </div>
                ) : (
                  ""
                )}
              </IonLabel>
            </IonItem>

            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel
                onClick={() => setIsLangueTrans("Chinese, Traditional", 2)} placeholder={undefined}              >
                <h2>
                {t("languages.chinese_traditional")} <span className="chinese">繁體中文</span>
                </h2>
                {!IsTranLangSim ? (
                  <div>
                    <IonImg src="./assets/images/Icon.png" alt="" placeholder={undefined} />
                  </div>
                ) : (
                  ""
                )}
              </IonLabel>
            </IonItem>
          </IonList>
        ) : (
          ""
        )}

        {IsTranLang ? (
          <IonList className="list engvschinese" placeholder={undefined}>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>
                <h2>{t("languages.translation_language")}</h2>
              </IonLabel>
            </IonItem>
            {/* <IonItem class="ion-no-padding">
              <IonLabel>
                <h2>Lesson language </h2>
                <div><span> Chinese, Simplified </span>
                  <IonIcon icon={chevronForward} /></div>
              </IonLabel>
            </IonItem> */}

            {itemlistLang.map((items, index) => {
              return (
                <IonItem key={index} class="ion-no-padding" placeholder={undefined}>
                  <IonLabel
                    onClick={() => saveLangue(items.Code, false, index)} placeholder={undefined}                  >
                    <h2>{items.Name}</h2>
                    {items.IsSelected ? (
                      <div>
                        <IonImg src="./assets/images/Icon.png" alt="" placeholder={undefined} />
                      </div>
                    ) : (
                      ""
                    )}
                  </IonLabel>
                </IonItem>
              );
            })}
          </IonList>
        ) : (
          ""
        )}

        {/* <IonItem>
          <IonLabel>
            <h2>Lesson language</h2>
          </IonLabel>
          <IonSelect multiple>
            <IonSelectOption key={1} value={1}>
              Chinese, Simplified 简体中文
            </IonSelectOption>
            <IonSelectOption key={2} value={2}>
              Chinese, Traditional 繁體中文
            </IonSelectOption>
          </IonSelect>
          <IonIcon icon={chevronForward} />
        </IonItem> */}
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Languages);
