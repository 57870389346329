import React, { useState } from "react";
import {
  IonContent,
  IonPage,
  IonHeader,
  IonTitle,
  IonImg,
  IonList,
  IonRow,
  IonCol,
  IonToolbar,
  IonButton,
  useIonViewWillEnter,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import { setMenuEnabled } from "../data/sessions/sessions.actions";
import { setHasSeenTutorial } from "../data/user/user.actions";
import "./Testdone.scss";
import { RouteComponentProps } from "react-router";
import { CONFIG } from "../App";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {
  setHasSeenTutorial: typeof setHasSeenTutorial;
  setMenuEnabled: typeof setMenuEnabled;
}

interface Testdone extends OwnProps, DispatchProps {}

const Testdone: React.FC<Testdone> = ({ history, setMenuEnabled }) => {
  let url: string = CONFIG.API_ENDPOINT; //live

  const { t } = useTranslation();

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any>({});
  let token = "";
  let resfreshtoken = "";
  useIonViewWillEnter(() => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    getUserLevel();
  });
  const getUserLevel = async () => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 1000,
    });

    try {
      const response = await fetch(url + "/User/GetUserLevel", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      // if (!response.ok) {
      //   throw Error(response.statusText)
      // }
      const json = await response.json();
      console.log(json);
      debugger;

      dismiss();
      if (json.StatusCode == 1) {
        setitems(json.Result);
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      console.log(json);

      if (json.StatusCode == 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const gotohome = async () => {
    // await setHasSeenTutorial(true);
    // await setMenuEnabled(true);
    history.push("/tabs/home", { direction: "none" });
  };

  //    history.push('/tabs/home', { direction: 'none' });

  return (
    <IonPage className="pages-space" id="tutorial-page" placeholder={undefined}>
      <IonHeader className="ion-text-center" placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <IonTitle color="primary" placeholder={undefined}>
            <span className="bookfont">
              {t("testdone.bsd_on_ur_ans")}
              <br />
              {t("testdone.we_thnk")}
            </span>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      {/* <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="end">
            {showSkip && <IonButton color='primary' onClick={startApp}>Skip</IonButton>}
          </IonButtons>
        </IonToolbar>
      </IonHeader> */}
      <IonContent fullscreen placeholder={undefined}>
        <IonList className="testpage height100 question" placeholder={undefined}>
          <IonRow className="content" placeholder={undefined}>
            <IonCol size="12" className="ion-text-center" placeholder={undefined}>
              <h4 className="level">{t("testdone.level")} {itemlist.Level}:</h4>
            </IonCol>
            <IonCol size="12" class="vector" placeholder={undefined}>
              {itemlist.Level === 1 && (
                <IonImg className="flag" src="./assets/images/shoeprint.svg" placeholder={undefined} />
              )}
              {itemlist.Level === 2 && (
                <IonImg className="flag" src="./assets/images/bicycle.svg" placeholder={undefined} />
              )}
              {itemlist.Level === 3 && (
                <IonImg className="flag" src="./assets/images/car.svg" placeholder={undefined} />
              )}
              {itemlist.Level === 4 && (
                <IonImg className="flag" src="./assets/images/plane.svg" placeholder={undefined} />
              )}
              {itemlist.Level === 5 && (
                <IonImg className="flag" src="./assets/images/rocet.svg" placeholder={undefined} />
              )}

              {/* <IonImg className='flag' src="./assets/images/bicycle.svg" /> */}
            </IonCol>
            <IonCol size="12" className="ion-text-center" placeholder={undefined}>
              <h1 className="advance">{itemlist.Name}</h1>
            </IonCol>
            <IonCol size="12" className="ion-text-center" placeholder={undefined}>
              <span className="normaltext">
              {t("testdone.desc")}
              </span>
            </IonCol>
          </IonRow>
          <IonRow class="skiptestpage" placeholder={undefined}>
            <IonCol size="12" className="ion-text-center startjourney" placeholder={undefined}>
              <IonButton onClick={gotohome} color="primary" placeholder={undefined}>
              {t("testdone.strt_ur_jorny")}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonList>

        {/* <IonButton onClick={gotohome} expand="block" fill="outline" shape="round">Go to Home</IonButton> */}
      </IonContent>
    </IonPage>
  );
};
export default Testdone;
// export default connect<OwnProps, {}, DispatchProps>({
//   mapDispatchToProps: ({
//     setHasSeenTutorial,
//     setMenuEnabled
//   }),
//   component: Beforewestart
// });
