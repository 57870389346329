import React, { useState, useRef, useEffect } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonList,
  IonCol,
  IonText,
  IonItem,
  IonLabel,
  IonCard,
  IonCardContent,
  IonRow,
  IonIcon,
  IonPage,
  IonTitle,
  IonImg,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
  IonProgressBar,
} from "@ionic/react";
import "./Practiceresult.scss";
import { chatboxEllipsesOutline, chevronBack, headset } from "ionicons/icons";
import Drawer from "react-bottom-drawer";
import { CONFIG } from "../App";
import { RouteComponentProps, useHistory } from "react-router";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ProgressBar from "@ramonak/react-progress-bar";
import { Share } from "@capacitor/share";
import { Directory, Filesystem } from "@capacitor/filesystem";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Capacitor } from "@capacitor/core";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
interface OwnProps extends RouteComponentProps {}
interface PracticeresultProps extends OwnProps {
  progress: number;
}
const Practiceresult: React.FC<PracticeresultProps> = ({
  progress,
  history,
}) => {
  const { t } = useTranslation();
  const themeData: any = localStorage.getItem("itemlist");
  //const tDataCon = JSON.parse(themeData);
  //console.log(tDataCon);
  const containerRef = useRef(null);
  const historyss = useHistory<{
    themeArray: string;
    themeId: string;
    lessonsId: string;
    index: string;
    lessonname: string;
    themename: string;
    levelId: string;
    level: string;
    levname: string;
    bookId: string;
    backpage: string;
    title: string;
    dialogueId: string;
    allrole: string;
    rolename: string;
    pagename: string;
    backpageValue: number;
  }>();
  const themeArray = historyss.location.state
    ? historyss.location.state.themeArray
    : "";
  const itemArray: any = themeArray;
  const themeId = historyss.location.state
    ? historyss.location.state.themeId
    : "";
  const lessonsId = historyss.location.state
    ? historyss.location.state.lessonsId
    : "";
  const indexs = historyss.location.state ? historyss.location.state.index : "";
  console.log(indexs);
  const lessonname = historyss.location.state
    ? historyss.location.state.lessonname
    : "";
  const bookId = historyss.location.state
    ? historyss.location.state.bookId
    : "";
  const themename = historyss.location.state
    ? historyss.location.state.themename
    : "";
  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";
  const levelId = historyss.location.state
    ? historyss.location.state.levelId
    : "";
  const level = historyss.location.state ? historyss.location.state.level : "";
  const backpage = historyss.location.state
    ? historyss.location.state.backpage
    : "";
  const dialogueId = historyss.location.state
    ? historyss.location.state.dialogueId
    : "";
  const rolename = historyss.location.state
    ? historyss.location.state.rolename
    : "";
  const allrole = historyss.location.state
    ? historyss.location.state.allrole
    : "";
  const pagename = historyss.location.state
    ? historyss.location.state.pagename
    : "";
    const backpageValue = historyss.location.state
    ? historyss.location.state.backpageValue
    : "";
  debugger;
  const [isVisible, setIsVisible] = React.useState(false);
  const openDrawer = React.useCallback(() => setIsVisible(true), []);
  const onClose = React.useCallback(() => {
    setIsVisible(false);
    console.log("Close");
  }, []);
  let url: string = CONFIG.API_ENDPOINT; //live
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any>([]);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [FileToaster] = useIonToast();
  const [sImage, setIMGval] = useState<any>([]);
  const [sText, setTXTval] = useState<any>([]);
  const [itemprofilelist, setprofileitems] = React.useState<any>({});
  const [Reading, setReading] = React.useState<any>({});
  const [Writing, setWriting] = React.useState<any>({});
  const [Listening, setListening] = React.useState<any>({});
  const [Speaking, setSpeaking] = React.useState<any>({});
  const [showDate, setShowDate] = useState(false);
  let token = "";
  let resfreshtoken = "";
  useIonViewWillEnter(() => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    GetResult(lessonsId);
    GetProfie();
  });
  const [sentence1, setSentence1] = useState("");
  const [sentence2, setSentence2] = useState("");
  const words1 = sentence1.split(" ");
  const words2 = sentence2.split(" ");
  const notMatchingWords = words1
    .filter((word) => !words2.includes(word))
    .concat(words2.filter((word) => !words1.includes(word)));
  const GetResult = async (lessonsId) => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 1000,
    });
    try {
      let lessonGuid = lessonsId;
      const response = await fetch(
        url + "/Book/GetProgress?lessonGuid=" + lessonGuid,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        }
      );
      const json = await response.json();
      console.log("GetProgress", json);
      dismiss();
      if (json.StatusCode === 1) {
        setitems(json.Result);
        console.log("test1", json["Result"]["Progress"]);
        //  console.log('test2',itemlist['Result']['Progress']);
        debugger;
        let restdata = json["Result"]["Progress"];
        if (restdata !== null) {
          setReading(restdata?.Reading || 0);
          setWriting(restdata?.Writing || 0);
          setListening(restdata?.Listening || 0);
          setSpeaking(restdata?.Speaking || 0);
        }
        if (itemlist["Progress"]?.CompleteDate !== 0 || null || undefined) {
          setShowDate(true);
        }
        //debugger
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const textset_tc = (data_tc, data_py, rightanswer) => {
    console.log("data_tc", data_tc);
    console.log("data_py", data_py);
    debugger;
    // const myArray = Object.entries(rightanswer);
    // console.log(myArray)
    console.log("rightanswer", JSON.parse(rightanswer));
    //debugger
    const html_tc = data_tc;
    const html_py = data_py;
    const range = document.createRange();
    const fragment_tc = range.createContextualFragment(html_tc);
    const fragment_py = range.createContextualFragment(html_py);
    const textreturn_tc = fragment_tc.textContent;
    const textreturn_py = fragment_py.textContent;
    const text_tc = textreturn_tc;
    const text_py = textreturn_py;
    const fragmentsa: any = [];
    // console.log('text', text_tc)
    if (text_tc && text_py) {
      const words_tc = text_tc.split("");
      const words_py = text_py.split(" ");
      //  console.log('words_tc - words_py', words_tc.length + ' - ' + words_py.length)
      if (words_tc.length === words_py.length) {
        for (let i = 0; i < words_tc.length; i++) {
          const wordtc = words_tc[i] as never;
          let data = {
            words_tc: wordtc,
            words_py: "",
          };
          fragmentsa.push(data);
        }
        for (let i = 0; i < words_py.length; i++) {
          const wordpy = words_py[i] as never;
          if (wordpy !== "" && wordpy !== " " && wordpy !== undefined) {
            fragmentsa[i]["words_py"] = wordpy ? wordpy : "";
          } else {
            fragmentsa[i]["words_py"] = "";
          }
        }
        const fragments = fragmentsa.map((word, index) => (
          <React.Fragment key={index}>
            <li
              onClick={() => handleClick(word, index)}
              className={index === activeIndex ? "active" : ""}
            >
              <span className="ion-text-center" id="top-center">
                {word["words_tc"]}
              </span>
              <p className="wordpy">{word["words_py"]}</p>
            </li>{" "}
          </React.Fragment>
        ));
        //const fragments = ''
        return fragments;
      } else {
        const arr1 = words_tc;
        const arr2 = words_py;
        const maxLength = Math.max(arr1.length, arr2.length);
        const paddedArr1 = arr1.concat(Array(maxLength - arr1.length).fill(""));
        const paddedArr2 = arr2.concat(Array(maxLength - arr2.length).fill(""));
        // console.log(paddedArr1); // [1, 2, 3, 0, 0]
        // console.log(paddedArr2); // [4, 5, 0, 0, 0]
        for (let i = 0; i < paddedArr1.length; i++) {
          const wordtc = paddedArr1[i] as never;
          let data = {
            words_tc: wordtc,
            words_py: "",
          };
          fragmentsa.push(data);
        }
        for (let i = 0; i < paddedArr2.length; i++) {
          const wordpy = paddedArr2[i] as never;
          if (wordpy !== "" && wordpy !== " " && wordpy !== undefined) {
            fragmentsa[i]["words_py"] = wordpy ? wordpy : "";
          } else {
            fragmentsa[i]["words_py"] = "";
          }
        }
        const fragments = fragmentsa.map((word, index) => (
          <React.Fragment key={index}>
            <li
              onClick={() => handleClick(word, index)}
              className={index === activeIndex ? "active" : ""}
            >
              <span className="ion-text-center" id="top-center">
                {word["words_tc"]}
              </span>
              <p className="wordpy">{word["words_py"]}</p>
            </li>{" "}
          </React.Fragment>
        ));
        // const fragments = ''
        return fragments;
      }
    } else if (text_tc) {
      const words_tc = text_tc.split("");
      for (let i = 0; i < words_tc.length; i++) {
        const word = words_tc[i] as never;
        let data = {
          words_tc: word,
          words_py: "",
        };
        fragmentsa.push(data);
      }
      const fragments = fragmentsa.map((word, index) => (
        <React.Fragment key={index}>
          <li
            onClick={() => handleClick(word, index)}
            className={index === activeIndex ? "active" : ""}
          >
            <span className="ion-text-center" id="top-center">
              {word["words_tc"]}
            </span>
          </li>{" "}
        </React.Fragment>
      ));
      return fragments;
    } else if (text_py) {
      const words_py = text_py.split(" ");
    }
    // console.log('spans', fragments)
    //const htmls = { __html: spans.join('') };
  };
  const handleClick = (word, index) => {
    setActiveIndex(index === activeIndex ? -1 : index);
    // document.addEventListener('click', handleDocumentClick);
    // checkdictionary(word.words_py)
  };
  const handleClickResult = async (data, index) => {
    //
    debugger;
    if (data.ExerciseType === "E1s" && data.TotalCount !== 0) {
      history.replace({
        pathname: "/tabs/home/E1sResultDetails",
        state: {
          themeArray: themeArray,
          // lessonGuid: lessonsId,
          type: data.ExerciseType,
          themename: themename,
          themeId: themeId,
          lessonname: lessonname,
          index: indexs,
          lessonsId: lessonsId,
          levelId: levelId,
          level: level,
          levname: levname,
          backpage: backpage,
          pagename: data.ExerciseType,
          backpageValue: backpageValue,
        },
      });
    } else if (data.ExerciseType === "E2s" && data.TotalCount !== 0) {
      history.replace({
        pathname: "/tabs/home/E2sResultDetails",
        state: {
          themeArray: themeArray,
          // lessonGuid: lessonsId,
          type: data.ExerciseType,
          themename: themename,
          themeId: themeId,
          lessonname: lessonname,
          index: indexs,
          lessonsId: lessonsId,
          levelId: levelId,
          level: level,
          levname: levname,
          backpage: backpage,
          pagename: data.ExerciseType,
          backpageValue: backpageValue,
        },
      });
    } else if (data.ExerciseType === "E3s" && data.TotalCount !== 0) {
      history.replace({
        pathname: "/tabs/home/E3sResultDetails",
        state: {
          themeArray: themeArray,
          // lessonGuid: lessonsId,
          type: data.ExerciseType,
          themename: themename,
          themeId: themeId,
          lessonname: lessonname,
          index: indexs,
          lessonsId: lessonsId,
          levelId: levelId,
          level: level,
          levname: levname,
          backpage: backpage,
          pagename: data.ExerciseType,
          backpageValue: backpageValue,
        },
      });
    } else if (data.ExerciseType === "E4s" && data.TotalCount !== 0) {
      history.replace({
        pathname: "/tabs/home/E4sResultDetails",
        state: {
          themeArray: themeArray,
          // lessonGuid: lessonsId,
          type: data.ExerciseType,
          themename: themename,
          themeId: themeId,
          lessonname: lessonname,
          index: indexs,
          lessonsId: lessonsId,
          levelId: levelId,
          level: level,
          levname: levname,
          backpage: backpage,
          pagename: data.ExerciseType,
          backpageValue: backpageValue,
        },
      });
    } else if (data.ExerciseType === "E5s" && data.TotalCount !== 0) {
      history.replace({
        pathname: "/tabs/home/E5sResultDetails",
        state: {
          themeArray: themeArray,
          // lessonGuid: lessonsId,
          type: data.ExerciseType,
          themename: themename,
          themeId: themeId,
          lessonname: lessonname,
          index: indexs,
          lessonsId: lessonsId,
          levelId: levelId,
          level: level,
          levname: levname,
          backpage: backpage,
          pagename: data.ExerciseType,
          backpageValue: backpageValue,
        },
      });
    } else if (data.ExerciseType === "E6s" && data.TotalCount !== 0) {
      history.replace({
        pathname: "/tabs/home/E6sResultDetails",
        state: {
          themeArray: themeArray,
          // lessonGuid: lessonsId,
          type: data.ExerciseType,
          themename: themename,
          themeId: themeId,
          lessonname: lessonname,
          index: indexs,
          lessonsId: lessonsId,
          levelId: levelId,
          level: level,
          levname: levname,
          backpage: backpage,
          pagename: data.ExerciseType,
          backpageValue: backpageValue,
        },
      });
    } else {
      presenttost({
        message: "No Results Found",
        duration: 1500,
        position: "bottom",
      });
    }
    console.log("data", data);
    // E5ev1ResultDetails
  };
  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      console.log(json);
      if (json.StatusCode === 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const GetProfie = async () => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 1000,
    });
    try {
      const response = await fetch(url + "/User/GetProfile", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });
      const json = await response.json();
      console.log("Profice", json);
      dismiss();
      if (json.StatusCode === 1) {
        setprofileitems(json.Result);
        //debugger
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  // const shareButton = async () => {
  //   FileToaster({
  //     message: 'Which format do you want to share?',
  //     duration: 10000,
  //     position: 'top',
  //     layout: 'stacked',
  //     cssClass: 'custom-toast',
  //     buttons: [
  //       {
  //         text: 'Share Text',
  //         handler: shareGenerateTXT
  //       }, {
  //         text: 'Share Image',
  //         handler: shareGenerateIMG
  //       },
  //     ],
  //   });
  //   onClose()
  // }
  // const shareGenerateTXT = async () => {
  //   const div = document.getElementById('content') as HTMLElement;
  //   const textContent = div ? (div.textContent || div.innerText || '') : '';
  //   console.log(textContent);
  //   const formattedText = textContent.replace(/(%|\/0|:|ing)/g, '$1\n');
  //   console.log(formattedText);
  //   try {
  //     await Share.share({
  //       title: 'Share Result',
  //       text: formattedText,
  //       dialogTitle: 'Share via',
  //     });
  //   } catch (error) {
  //     console.error('Error sharing:', error);
  //   }
  //   onClose();
  // };
  const shareGenerateIMG = async () => {
    const hiddenContent = document.getElementById("hidden-content");
    if (hiddenContent) {
      hiddenContent.classList.remove("hidden-content");
      const element = document.getElementById("content") as HTMLElement;
      if (element) {
        html2canvas(element).then(async (canvas) => {
          const imageDataUrl = canvas.toDataURL();
          await Filesystem.requestPermissions();
          const imagePath = await Filesystem.writeFile({
            data: imageDataUrl,
            directory: Directory.Cache,
            path: "Result.jpg",
          });
          console.log("Image saved to:", imagePath);
          let imageResult = await Filesystem.getUri({
            directory: Directory.Cache,
            path: "Result.jpg",
          });
          let imageLink = Capacitor.convertFileSrc(imageResult.uri);
          console.log(imageLink);
          Share.share({
            title: "Result",
            text: "Result",
            url: imageResult.uri,
          })
            .then(() => console.log("Successful share"))
            .catch((error) => console.log("Error sharing ::: ", error));
        });
        onClose();
      } else {
        console.error("Element not found. Make sure the selector is correct.");
      }
    } else {
      console.error("Element not found. Make sure the selector is correct.");
    }
  };
  const generateIMG = async () => {
    const hiddenContent = document.getElementById("hidden-content");
    if (hiddenContent) {
      hiddenContent.classList.remove("hidden-content");
      const element = document.getElementById("content") as HTMLElement;
      if (element) {
        html2canvas(element).then(async (canvas) => {
          const imageDataUrl = canvas.toDataURL();
          // console.log(imageDataUrl);
          await Filesystem.requestPermissions();
          const imagePath = await Filesystem.writeFile({
            data: imageDataUrl,
            directory: Directory.ExternalStorage,
            path: "Download/Result.jpg",
          });
          console.log("Image saved to:", imagePath);
          if (imagePath !== null) {
            FileToaster({
              message:
                "Image save successfully at Location:Download/Result.jpg",
              duration: 3000,
              position: "top",
              cssClass: "custom-toast",
              buttons: [{ text: "Ok", handler: () => onClose() }],
            });
          }
        });
        onClose();
      } else {
        console.error("Element not found. Make sure the selector is correct.");
      }
    } else {
      console.error("Element not found. Make sure the selector is correct.");
    }
  };
  const generatePDF = async () => {
    const hiddenContent = document.getElementById("hidden-content");
    if (hiddenContent) {
      hiddenContent.classList.remove("hidden-content");
      const element = document.getElementById("content") as HTMLElement;
      if (element) {
        html2canvas(element).then(async (canvas) => {
          const imgWidth = canvas.width;
          const imgHeight = canvas.height;
          const doc = new jsPDF({ unit: "px", format: [imgWidth, imgHeight] });
          const imageDataUrl = canvas.toDataURL();
          doc.addImage(imageDataUrl, "PNG", 0, 0, imgWidth, imgHeight);
          await Filesystem.requestPermissions();
          const filePath = await Filesystem.writeFile({
            path: "Download/Result.pdf",
            data: doc.output("datauristring"),
            directory: Directory.ExternalStorage,
          });
          console.log("PDF saved to:", filePath);
          if (filePath !== null) {
            FileToaster({
              message:
                "PDF saved successfully at Location: Download/Result.pdf",
              duration: 3000,
              position: "top",
              cssClass: "custom-toast",
              buttons: [{ text: "Ok", handler: () => onClose() }],
            });
          }
        });
        onClose();
      } else {
        console.error("Element not found. Make sure the selector is correct.");
      }
    } else {
      console.error("Element not found. Make sure the selector is correct.");
    }
  };
  const back = () => {
    history.replace({
      pathname: "/tabs/home/Practicecompleted",
      state: {
        themeArray: themeArray,
        index: indexs,
        themeId: themeId,
        lessonsId: lessonsId,
        themename: themename,
        levelId: levelId,
        level: level,
        levname: levname,
        dialogueId: dialogueId,
        rolename: rolename,
        allrole: allrole,
        backpage: backpage,
        backpageValue: backpageValue,
      },
    });
  };
  return (
    <IonPage className="intro" placeholder={undefined}>
      <IonHeader className="ion-text-center dubbletitle" placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <div>
            <IonButtons slot="start" placeholder={undefined}>
              <IonButton color="primary" onClick={back} placeholder={undefined}>
                <IonIcon icon={chevronBack} placeholder={undefined} />
              </IonButton>
            </IonButtons>
            <IonTitle color="primary" className="font20 ion-no-padding" placeholder={undefined}>
              <span>
                {t("practiceresult.practice_lesson")}{" "}
                {themeArray[indexs ? indexs : 0]?.number}
                <p className="ion-no-margin">{themename}</p>
              </span>
            </IonTitle>
            <IonButtons slot="end" placeholder={undefined}>
              <IonButton onClick={openDrawer} color="primary" placeholder={undefined}>
                {/* <IonIcon icon={cloudUploadOutline} /> */}
                <IonImg src="./assets/images/share.png" alt="" placeholder={undefined} />
              </IonButton>
            </IonButtons>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className="listing mxm" placeholder={undefined}>
        <div has-bouncing="true" className="psdviewer" id="content">
          <IonRow
            class="blue-bg pdfhedding"
            id="hidden-content"
            className="hidden-content" placeholder={undefined}>
            <IonCol size="12" placeholder={undefined}>
              <span>
                <h3>{t("practiceresult.lesson_report")}</h3>
                <p className="pdflavel">
                  {t("practiceresult.level")} {level}
                </p>
                <p className="ion-no-margin">
                  {themename}: {t("practiceresult.lesson")}{" "}
                  {themeArray[indexs ? indexs : 0]?.number}
                </p>
                <p className="ion-no-margin themename">{lessonname}</p>
              </span>
              <span className="logoarea">
                <img src="./assets/images/pdfMMXlogo.png" alt="" />
                <p className="ion-no-margin username">
                  <img src="./assets/images/pdfusericon.png" alt="" />
                  {itemprofilelist?.Name}
                </p>
              </span>
            </IonCol>
          </IonRow>
          <IonRow className="Practice count" placeholder={undefined}>
            <IonCol size="12" placeholder={undefined}>
              <IonCard className="count" placeholder={undefined}>
                <IonCardContent class="ion-no-padding" placeholder={undefined}>
                  <IonRow placeholder={undefined}>
                    <IonCol size="6" placeholder={undefined}>
                      <h5>{t("practiceresult.overall_score")} :</h5>
                      <div>
                        <h1>{itemlist["Progress"]?.OverAll || 0}%</h1>
                      </div>
                    </IonCol>
                    {showDate && (
                      <IonCol size="6" className="ion-text-right bookfont" placeholder={undefined}>
                        {t("practiceresult.completed_date")}:
                        <br />
                        {itemlist["Progress"]?.CompleteDate}
                      </IonCol>
                    )}
                  </IonRow>
                </IonCardContent>
              </IonCard>
              <IonCard className="scorebox" placeholder={undefined}>
                <IonRow className="" placeholder={undefined}>
                  <IonCol size="6" placeholder={undefined}>
                    <CircularProgressbar
                      strokeWidth={8}
                      text={`${Reading === 0 ? "--" : Reading}`}
                      value={Reading}
                      styles={{
                        background: { fill: "var(--ion-color-primary-contrast-rgb)", textAlign: "center" },
                        path: { stroke: "var(--ion-color-primary)" },
                        trail: { stroke: "var(--ion-color-primary-contrast-rgb)" },
                        text: {
                          fill: Reading === 0 ? "var(--ion-color-primary-contrast-rgb)" : "var(--ion-color-primary)",
                          textAlign: "center",
                          position: "absolute",
                          verticalAlign: "middle",
                          lineHeight: "50",
                          fontSize: "28px",
                        },
                      }}
                    />
                    <div>
                      <IonImg
                        src={Reading === 0
                          ? "./assets/images/Reader-light.png"
                          : "./assets/images/Reader.png"}
                        alt="" placeholder={undefined}                      />
                      <IonText
                        style={{ color: Reading === 0 ? "var(--ion-color-primary-contrast-rgb)" : "var(--ion-color-primary)" }} placeholder={undefined}>
                        {t("practiceresult.score.reading")}
                      </IonText>
                    </div>
                  </IonCol>
                  <IonCol size="6" placeholder={undefined}>
                    <CircularProgressbar
                      strokeWidth={8}
                      text={`${Writing === 0 ? "--" : Writing}`}
                      value={Writing}
                      styles={{
                        background: { fill: "var(--ion-color-primary-contrast-rgb)", textAlign: "center" },
                        path: { stroke: "var(--ion-color-primary)" },
                        trail: { stroke: "var(--ion-color-primary-contrast-rgb)" },
                        text: {
                          fill: Writing === 0 ? "var(--ion-color-primary-contrast-rgb)" : "var(--ion-color-primary)",
                          textAlign: "center",
                          position: "absolute",
                          verticalAlign: "middle",
                          lineHeight: "50",
                          fontSize: "28px",
                        },
                      }}
                    />
                    {/* <div className="round-chart">
                      <div className="chart-center">80</div>
                    </div> */}
                    <div>
                      <IonImg
                        src={Writing === 0
                          ? "./assets/images/Writing-light.png"
                          : "./assets/images/Writing.png"}
                        alt="" placeholder={undefined}                      />
                      <IonText
                        style={{ color: Writing === 0 ? "var(--ion-color-primary-contrast-rgb)" : "var(--ion-color-primary)" }} placeholder={undefined}>
                        {t("practiceresult.score.writing")}
                      </IonText>
                    </div>
                  </IonCol>
                  <IonCol size="6" placeholder={undefined}>
                    <CircularProgressbar
                      strokeWidth={8}
                      text={`${Listening === 0 ? "--" : Listening}`}
                      value={Listening}
                      styles={{
                        background: { fill: "var(--ion-color-primary-contrast-rgb)", textAlign: "center" },
                        path: { stroke: "var(--ion-color-primary)" },
                        trail: { stroke: "var(--ion-color-primary-contrast-rgb)" },
                        text: {
                          fill: Listening === 0 ? "var(--ion-color-primary-contrast-rgb)" : "var(--ion-color-primary)",
                          textAlign: "center",
                          position: "absolute",
                          verticalAlign: "middle",
                          lineHeight: "50",
                          fontSize: "28px",
                        },
                      }}
                    />
                    {/* <div className="round-chart">
                      <div className="chart-center">90</div>
                    </div> */}
                    <div>
                      {/* <IonIcon icon={headset} /> */}
                      <IonImg
                        src={Listening === 0
                          ? "./assets/images/Listening-light.png"
                          : "./assets/images/ListeningOrg.png"}
                        alt="" placeholder={undefined}                      />
                      <IonText
                        style={{
                          color: Listening === 0 ? "var(--ion-color-primary-contrast-rgb)" : "var(--ion-color-primary)",
                        }} placeholder={undefined}>
                        {t("practiceresult.score.listening")}
                      </IonText>
                    </div>
                  </IonCol>
                  <IonCol size="6" placeholder={undefined}>
                    <CircularProgressbar
                      strokeWidth={8}
                      text={`${Speaking === 0 ? "--" : Speaking}`}
                      value={Speaking}
                      styles={{
                        background: { fill: "var(--ion-color-primary-contrast-rgb)", textAlign: "center" },
                        path: { stroke: "var(--ion-color-primary)" },
                        trail: { stroke: "var(--ion-color-primary-contrast-rgb)" },
                        text: {
                          fill: Speaking === 0 ? "var(--ion-color-primary-contrast-rgb)" : "var(--ion-color-primary)",
                          textAlign: "center",
                          position: "absolute",
                          verticalAlign: "middle",
                          lineHeight: "50",
                          fontSize: "28px",
                        },
                      }}
                    />
                    {/* <div className="round-chart">
                      <div className="chart-center">80</div>
                    </div> */}
                    <div>
                      {/* <IonIcon icon={chatboxEllipsesOutline} /> */}
                      <IonImg
                        src={Speaking === 0
                          ? "./assets/images/Speaking-light.png"
                          : "./assets/images/SpeakingOrg.png"}
                        alt="" placeholder={undefined}/>
                      <IonText
                        style={{
                          color: Speaking === 0 ? "var(--ion-color-primary-contrast-rgb)" : "var(--ion-color-primary)",
                        }} placeholder={undefined}>
                        {t("practiceresult.score.speaking")}
                      </IonText>
                    </div>
                  </IonCol>
                </IonRow>
              </IonCard>
            </IonCol>
            <IonCol size="12" placeholder={undefined}>
              <IonCard className="count" placeholder={undefined}>
                <IonCardContent class="ion-no-padding" placeholder={undefined}>
                  <IonRow placeholder={undefined}>
                    <IonCol size="12" placeholder={undefined}>
                      <h5>{t("practiceresult.results_overview")}:</h5>
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
              <IonCard className="scorebox mnxview" placeholder={undefined}>
                <IonRow className="" placeholder={undefined}>
                  {itemlist["Types"]?.map((items, index) => {
                    return (
                      <IonCol
                        key={index}
                        size="6"
                        onClick={() => handleClickResult(items, index)} placeholder={undefined}>
                          {/* {index} */}
                        <div className="circleimg">
                          <CircularProgressbar
                            strokeWidth={8}
                            value={0}
                            styles={{
                              background: {
                                fill: "var(--ion-color-primary-contrast-rgb)",
                                textAlign: "center",
                              },
                              path: { stroke: "var(--ion-color-primary)" },
                              trail: { stroke: "var(--ion-color-primary-contrast-rgb)" },
                            }}
                          />
                          {items.ExerciseType === "E1s" ? (
                            <IonImg src="./assets/images/Reader.png" alt="" placeholder={undefined} />
                          ) : (
                            ""
                          )}
                          {items.ExerciseType === "E2s" ? (
                            <IonImg src="./assets/images/Reader.png" alt="" placeholder={undefined} />
                          ) : (
                            ""
                          )}
                          {items.ExerciseType === "E3s" ? (
                            <IonImg src="./assets/images/Writing.png" alt="" placeholder={undefined} />
                          ) : (
                            ""
                          )}
                          {items.ExerciseType === "E4s" ? (
                            <IonIcon icon={headset} placeholder={undefined} />
                          ) : (
                            ""
                          )}
                          {items.ExerciseType === "E5s" ? (
                            <IonIcon icon={chatboxEllipsesOutline} placeholder={undefined} />
                          ) : (
                            ""
                          )}
                          {items.ExerciseType === "E6s" ? (
                            <IonIcon icon={chatboxEllipsesOutline} placeholder={undefined} />
                          ) : (
                            ""
                          )}
                        </div>
                        <div>
                          {items.ExerciseType === "E1s" ? (
                            <IonText placeholder={undefined}>
                              {items?.TotalCount}/{items?.RightCount}
                            </IonText>
                          ) : (
                            ""
                          )}
                          {items.ExerciseType === "E2s" ? (
                            <IonText placeholder={undefined}>
                              {items?.TotalCount}/{items?.RightCount}
                            </IonText>
                          ) : (
                            ""
                          )}
                          {items.ExerciseType === "E3s" ? (
                            <IonText placeholder={undefined}>
                              {items?.TotalCount}/{items?.RightCount}
                            </IonText>
                          ) : (
                            ""
                          )}
                          {items.ExerciseType === "E4s" ? (
                            <IonText placeholder={undefined}>
                              {items.TotalCount}/{items.RightCount}
                            </IonText>
                          ) : (
                            ""
                          )}
                          {items.ExerciseType === "E5s" ? (
                            <IonText placeholder={undefined}>
                              {items.TotalCount}/{items.RightCount}
                            </IonText>
                          ) : (
                            ""
                          )}
                          {items.ExerciseType === "E6s" ? (
                            <IonText placeholder={undefined}>
                              {items.TotalCount}/{items.RightCount}
                            </IonText>
                          ) : (
                            ""
                          )}
                        </div>
                      </IonCol>
                    );
                  })}
                  {/* <IonCol size="6">
                    <div className="circleimg">
                      <CircularProgressbar
                        strokeWidth={8}
                        value={0}
                        styles={{
                          background: { fill: "var(--ion-color-primary-contrast-rgb)", textAlign: "center" },
                          path: { stroke: "var(--ion-color-primary)" },
                          trail: { stroke: "var(--ion-color-primary-contrast-rgb)" },
                        }}
                      />
                      <IonImg src="./assets/images/Writing.png" alt="" />
                    </div>
                    <div>
                      <IonText>0/0</IonText>
                    </div>
                  </IonCol> */}
                </IonRow>
              </IonCard>
            </IonCol>
          </IonRow>
        </div>
      </IonContent>
      <Drawer
        className="bottompopup skateInBottom bottompopupresult"
        isVisible={isVisible}
        onClose={onClose}
      >
        <IonRow placeholder={undefined}>
          <IonList className="list" placeholder={undefined}>
            <IonItem class="ion-no-padding" onClick={shareGenerateIMG} placeholder={undefined}>
              {/* <IonIcon icon={share} /> */}
              <IonImg src="./assets/images/editShare.png" alt="" placeholder={undefined} />
              <IonLabel placeholder={undefined}>{t("practiceresult.drawer.share_result")}</IonLabel>
            </IonItem>
            <IonItem class="ion-no-padding" onClick={generateIMG} placeholder={undefined}>
              {/* <IonIcon icon={image} /> */}
              <IonImg src="./assets/images/Chooseimage.png" alt="" placeholder={undefined} />
              <IonLabel placeholder={undefined}>{t("practiceresult.drawer.save_as_photo")}</IonLabel>
            </IonItem>
            <IonItem class="ion-no-padding" onClick={generatePDF} placeholder={undefined}>
              {/* <IonIcon icon={download} /> */}
              <IonImg src="./assets/images/download.png" alt="" placeholder={undefined} />
              <IonLabel placeholder={undefined}>{t("practiceresult.drawer.save_as_pdf")}</IonLabel>
            </IonItem>
          </IonList>
        </IonRow>
      </Drawer>
    </IonPage>
  );
};
export default React.memo(Practiceresult);
