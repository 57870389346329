import React, { useEffect, useRef, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonItem,
  IonLabel,
  IonButton,
  IonContent,
  IonText,
  IonList,
  IonToggle,
  IonCol,
  IonImg,
  IonRow,
  IonIcon,
  IonPage,
  IonTitle,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
  IonSlides,
  IonSlide,
  IonSkeletonText,
  getPlatforms,
} from "@ionic/react";
import "./Lesson.scss";
import {
  chevronBack,
  ellipsisVertical,
  bookmark,
  playCircle,
} from "ionicons/icons";
import Drawer from "react-bottom-drawer";
import { CONFIG } from "../App";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import ReactAudioPlayer from "react-audio-player";
import { Swiper as SwiperCore, Pagination, Navigation, A11y } from "swiper";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import CustomAudioPlayer from "./CustomAudioPlayer";

interface OwnProps extends RouteComponentProps {
  onHold?: () => void;
  onTap?: (event: any) => void;
  holdDuration?: number;
}
interface LessonProps extends OwnProps { }
const Lesson: React.FC<LessonProps> = ({
  history,
  onHold,
  onTap,
  holdDuration,
  ...rest
}) => {
  const platforms = getPlatforms();
  const themeData: any = localStorage.getItem("itemlist");
  debugger;
  const tDataCon = JSON.parse(themeData);
  let url: string = CONFIG.API_ENDPOINT; //live
  const { t } = useTranslation();
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any[]>([]);
  const [decitemlist, setdecitems] = React.useState<any[]>([]);
  const [showResults, setShowResults] = React.useState(1);
  const [length, setLength] = React.useState(-1);
  const [lengthque, setLengthQue] = React.useState(1);
  const [lengthnext, setnextLength] = React.useState(0);
  const [listDictionary, setDictionary] = React.useState<any[]>([]);
  const [showImage, setShowImage] = useState(false);
  //Setting
  const [fontSize, setFontSize] = useState(18);
  const [clickedUp, setClickedUp] = useState(false);
  const [clickedDown, setClickedDown] = useState(false);
  const [cheChar, setCheChar] = useState<any>(true);
  const [piniyin, setPiniyin] = useState<any>(true);
  const [isTransChecked, setIsTransChecked] = useState<any>(true);
  const [bookmarkindex, setBookmarkindex] = React.useState<any>(-1);
  const [playbackSpeed, setPlaybackSpeed] = useState(1.0);
  //End setting
  const historyss = useHistory<{
    themeArray: any;
    themeId: string;
    lessonsId: string;
    index: string;
    lessonname: string;
    themename: string;
    levelId: string;
    level: string;
    levname: string;
    bookId: string;
    backpage: string;
    backpageValue: number;
  }>();
  let themeArray = historyss.location.state
    ? historyss.location.state.themeArray
    : "";
  debugger
  const itemArray: any = themeArray;
  const themeId = historyss.location.state
    ? historyss.location.state.themeId
    : "";
  const lessonsId = historyss.location.state
    ? historyss.location.state.lessonsId
    : "";
  const index = historyss.location.state ? historyss.location.state.index : "";
  const lessonname = historyss.location.state
    ? historyss.location.state.lessonname
    : "";
  const bookId = historyss.location.state
    ? historyss.location.state.bookId
    : "";
  const themename = historyss.location.state
    ? historyss.location.state.themename
    : "";
  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";
  const levelId = historyss.location.state
    ? historyss.location.state.levelId
    : "";
  // console.log("themeId1. ", themeIddb);
  const backpage = historyss.location.state
    ? historyss.location.state.backpage
    : "";
  const backpageValue = historyss.location.state
    ? historyss.location.state.backpageValue
    : "";
  const level = historyss.location.state ? historyss.location.state.level : "";
  const [activeIndex, setActiveIndex] = useState(-1);
  const [activeIndexDc, setActiveIndexDC] = React.useState<any[]>([]);
  const audioRef = useRef();
  const [isVisible, setIsVisible] = React.useState(false);
  const [isVisibletc, setIsVisibleTc] = React.useState(false);
  const [platform, setPlatform] = React.useState<any>();
  const openDrawer = React.useCallback(() => setIsVisible(true), []);
  const openDrawertc = React.useCallback(() => setIsVisibleTc(true), []);
  const onClose = React.useCallback(() => {
    setIsVisible(false);
    setIsVisibleTc(false);
  }, []);
  const [getThemes, setThemes] = useState<any>()
  let allThemes: any[] = []
  let rap;
  let token = "";
  let resfreshtoken = "";
  let nextindex: any = 0;
  useIonViewWillEnter(() => {

    if (platforms.includes('ios')) {
      setPlatform(true)
      console.log("iOS");
    } else if (platforms.includes('android')) {
      setPlatform(false)
      console.log("Android");
    } else {
      setPlatform(false)
      console.log("Default Set");
    }

    // Disable the Android hardware back button
    document.addEventListener("ionBackButton", (e: any) => {
      e.detail.register(0, () => {
        // Do nothing to disable the back button
      });
    });
    //setMenuEnabled(false);
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    setShowImage(false);
    getthemes()
    debugger
    setLength(-1);
    setShowResults(1);
    //  const history = useHistory<any>();
    //console.log(history)
    getQuestions();
    GetSettings();
  });

  const getthemes = async () => {
    // present({
    //   message: "",
    //   duration: 1000,
    // });
    console.log("APIthemeId. ", themeId);
    try {
      const response = await fetch(
        url + "/Book/GetLessons?themeGuid=" + themeId,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        }
      );
      const json = await response.json();
      // console.log(json);
      if (json.StatusCode == 1) {
        debugger
        for (let index = 0; index < json.Result.Lessons.length; index++) {
          const element = json.Result.Lessons[index];
        }
        let test: any[] = [];
        const array1 = json.Result.Lessons;
        const array2 = json.Result.Dialogues;
        // const combinedArray = array1.concat(array2);
        const maxLength = Math.max(array1.length, array2.length);
        for (let i = 0; i < maxLength; i++) {
          // Your logic here
          if (array1[i]) {
            array1[i]["type"] = "lesson";
            array1[i]["number"] = i + 1;
            test.push(array1[i]);
          }
          if (array2[i]) {
            array2[i]["type"] = "dialogues";
            array2[i]["number"] = i + 1;
            test.push(array2[i]);
          }
        }
        localStorage.setItem("itemlist", JSON.stringify(test));
        console.log("test array", test);
        allThemes = test
        setThemes(test)
        // console.log(themeArray);        
        // console.log("Get Themes",getThemes);


      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      // dismiss();
    } catch (error) {
      console.error(error);
      // dismiss();
    }
  };

  //Silder
  SwiperCore.use([Navigation, Pagination, A11y]);
  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  const options = {
    keyboard: true,
  };
  const handleSlidePrevEnded = (event: CustomEvent<any>) => {
    //console.log('Slide Prev ended', event.detail);
    // setLength(length - 1)
    slidesRef.current?.getActiveIndex().then((index) => {
      //console.log('index', (index + 1));
      // setLength((index - 1))
      // resetstate()
      // setShowResults((index-1))
    });
  };
  const handleSlideNextEnded = (event: CustomEvent<any>) => {
    //console.log('Slide Next ended', event.detail);
    slidesRef.current?.getActiveIndex().then((index) => {
      //console.log('Slide Next ended index', (index + 1));
      //setLength((index + 1))
      // resetstate()
      if (showResults !== 0) {
        setShowResults(index + 1);
        setShowPopover(false);
        setActiveIndex(-1);
      }
      //debugger
      // console.log('length', props.itemE4EV1.length);
    });
  };
  const handleSlideDidChanged = (event: CustomEvent<any>) => {
    setPlaybackSpeed(1.0)
    // console.log('Length', length);
    // setActiveIndexDC([])
    slidesRef.current?.getActiveIndex().then(async (index) => {
      console.log("DidChanged index", index + 1);
      console.log("lengthnext", lengthnext);
      console.log("list_length", itemlist.length);
      setActiveIndexDC([])
      //console.log('getIndex', nextindex + '==' + itemlist.length)
      getDictionary(itemlist[index].ContentId);
      //debugger
      if (showResults !== 0) {
        setShowResults(index + 1);
        setLength(index);
        saveContent(itemlist, index + 1, 1);
        // if (slidesRef.current) {
        //   const ionSlides = await slidesRef.current.getSwiper();
        //   console.log('ionSlides.activeIndex',ionSlides.activeIndex);
        // }
        // SaveExerciseQueAnswer(length)
        if (itemlist.length === 1) {
          //  console.log('gotonextPage_handleSlideDidChanged')
          console.log("Go next page");
          setLength(-1);
          setActiveIndex(-1);
          setnextLength(0);
          gotonextPage();
          // saveContent()
          // handleChange()
        } else {
          if (itemlist.length < lengthnext) {
            //  console.log('gotonextPage_handleSlideDidChanged')
            console.log("Go next page");
            setLength(-1);
            setActiveIndex(-1);
            setnextLength(0);
            gotonextPage();
            // saveContent()
            // handleChange()
          }
        }
      }
    });
  };
  //End
  const getQuestions = async () => {
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   duration: 1000,
    // });
    try {
      const response = await fetch(
        url + "/Book/GetQuestions?lessonGuid=" + lessonsId,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        }
      );
      // if (!response.ok) {
      //   throw Error(response.statusText)
      // }
      const json = await response.json();
      console.log(json)
      // dismiss();
      if (json.StatusCode === 1) {
        setShowImage(true)
        setitems(json.Result);
        getDictionary(json.Result[0].ContentId);
        saveContent(json.Result, 1, 1);
        setLengthQue(json.Result.length);
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      // dismiss();
    }
  };
  const saveContent = async (val, index, type) => {
    debugger;
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    // present({
    //   message: '',
    //   //duration: 3000
    // })
    //console.log(itemlist);
    debugger;
    const data = {
      contentPageCode:
        type === 1 ? val[0].TemplateCode : itemlist[val].TemplateCode,
      type: "lesson-read",
      bookId: itemlist.length > 0 ? itemlist[0].BookId : val[0].BookId,
      contentPageId:
        type === 1 ? val[0].ContentPageId : itemlist[val].ContentPageId,
      answer: "",
      score: 0,
      options: "",
      pageRead: index,
    };
    debugger;
    try {
      const response = await fetch(url + "/Book/SaveContent", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      const json = await response.json();
      console.log("SaveContent", json);
      //dismiss()
      if (json.StatusCode === 1) {
        // history.push('/login');
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };
  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      //  console.log(json)
      if (json.StatusCode === 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  let ansques: any;
  const gotonextques = async (items: any, index: any, type) => {
    // console.log(rap)
    setShowPopover(false);
    //debugger
    // if (items) {
    //   setShowResults(index)
    //   setLength(index)
    //   saveContent(itemlist, index, 1)
    // }
  };
  useEffect(() => {
    // console.log('showResults', showResults)
    //console.log('finalindex', length + ' = ' + lengthque)
    // console.log('lengthque', lengthque)
    // debugger
    if (length === lengthque) {
      //debugger
      console.log("Done");
      // if (showResults !== 0) {
      //   console.log('gotonextPage_useEffect')
      //   setLength(-1)
      //   gotonextPage()
      // }
    }
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
    //debugger
  });
  const gotonextPage = () => {
    console.log("gotonextPage", length);
    if (length !== 0) {
      history.push({
        pathname: "/tabs/home/Lessoncompleted",
        state: {
          themeArray: itemArray,
          themeId: themeId,
          lessonname: lessonname,
          index: index,
          lessonsId: lessonsId,
          themename: themename,
          levelId: levelId,
          level: level,
          levname: levname,
          backpage: "lesson",
          backpageValue: backpageValue,
        },
      });
    }
  };
  const favouriteWord = async (val) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    // present({
    //   message: '',
    //   //duration: 3000
    // })

    const data = {
      referenceId: val.DictionaryId,
      isFavourite: val.IsFavourite === true ? false : true,
      typeId: 2,
    };
    // Update decitemlist with the new IsFavourite value
    const updatedDecItemList = decitemlist.map(item =>
      item.DictionaryId === val.DictionaryId
        ? { ...item, IsFavourite: !item.IsFavourite }
        : item
    );

    setdecitems(updatedDecItemList);
    debugger
    try {
      const response = await fetch(url + "/User/Favourite", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      const json = await response.json();
      //console.log('SaveContent', json)
      //dismiss()
      getQuestions();
      if (json.StatusCode === 1) {
        // Update decitemlist with the new IsFavourite value

        console.log(json);
        // history.push('/login');
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };
  const favouriteWord1 = async (val) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    // present({
    //   message: '',
    //   //duration: 3000
    // })
    console.log(val);
    const data = {
      referenceId: val.DictionaryId,
      isFavourite: val.IsFavourite === true ? false : true,
      typeId: 2,
    };
    try {
      const response = await fetch(url + "/User/Favourite", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      const json = await response.json();
      //console.log('SaveContent', json)
      //dismiss()
      getQuestions();
      if (json.StatusCode === 1) {
        // history.push('/login');
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };
  ///Desctiory
  const handleClick = (word, index, text_dc, html_tc) => {
    console.log(listDictionary);
    debugger
    const sentence = text_dc;
    const word_dc = sentence.split(' ');

    console.log('words', word_dc)
    const array1 = word_dc;
    const wordss = word.words_tc;
    const foundElement = array1.find(item => item.includes(wordss));

    if (foundElement) {
      //New
      const word_tc = html_tc.split('');
      const word_tc_dc = foundElement.split('');

      //const matchingWord = word_tc.find(word => word_tc_dc.includes(word));

      // const matchingIndex = word_tc.findIndex(word => word === matchingWord);
      const matchingIndices = word_tc
        .map((word, index) => word_tc_dc.includes(word) ? index : -1)
        .filter(index => index !== -1);

      setActiveIndexDC(matchingIndices)
    }



    debugger
    //console.log(`Word clicked: ${word}`);
    // console.log(word);
    //alert(`Word clicked: ${word}`);
    //debugger
    setActiveIndex(index === activeIndex ? -1 : index);
    document.addEventListener("click", handleDocumentClick);
    checkdictionary(foundElement);
  };
  const checkdictionary = async (words_py) => {
    //words_py
    setdecitems([]);
    // console.log(words_py);
    // debugger
    const filteredItems = listDictionary.filter((item) =>
      item["Word"].startsWith(words_py)
    );
    debugger
    if (filteredItems.length === 0) {
      setShowPopover(false);
      presenttost({
        message: "No results found",
        duration: 1500,
        position: "bottom",
      });
    } else {
      setdecitems(filteredItems);
      setShowPopover(false);
      setShowPopover(true);
    }
    //   console.log('decitemlist', decitemlist);
    //console.log(filteredItems);
  };
  const getDictionary = async (contentId) => {
    // present({
    //   message: "",
    //   duration: 1000,
    // });
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    // const chineseSymbol = word;
    // const encodedSymbol = encodeURIComponent(chineseSymbol);
    // const url = `https://example.com/path/${encodedSymbol}`;
    // console.log(url)
    try {
      const response = await fetch(
        url + "/User/Dictionary?contentId=" + contentId,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        }
      );
      const json = await response.json();
      console.log("Dictionary", json);
      // dismiss();
      if (json.StatusCode === 1) {
        setDictionary(json.Result);
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      // dismiss();
    }
  };
  //debugger
  const [showPopover, setShowPopover] = useState(false);
  const dismissPopover = () => {
    setActiveIndexDC([])
    setShowPopover(false);
    setActiveIndex(-1);
  };
  const textset_tc = (data_tc, data_py, data_dc) => {
    // console.log(data_dc)
    //debugger
    const html_tc = data_tc;
    const html_py = data_py;
    const html_dc = data_dc;
    const range = document.createRange();
    const fragment_tc = range.createContextualFragment(html_tc);
    const fragment_py = range.createContextualFragment(html_py);
    const fragment_dc = range.createContextualFragment(html_dc);
    const textreturn_tc = fragment_tc.textContent;
    const textreturn_py = fragment_py.textContent;
    const textreturn_dc = fragment_dc.textContent;
    const text_tc = textreturn_tc;
    const text_py = textreturn_py;
    const text_dc = textreturn_dc;
    const fragmentsa: any = [];
    // console.log("text_dc", text_dc)
    // console.log('text', text_tc)
    if (text_tc && text_py) {
      //console.log('words_tc_tmp',words_tc_tmp)
      // let words_tc =  text_tc.split('');
      //const inputString = "我说英语和印地语，你呢？";
      //const outputArray1 = inputString.split(/(?!，)/g);
      //const outputArray = text_tc.split(/(?![？，。])/g);
      const outputArray = text_tc.split(/(?![，。;：””''！？（）、])/g);
      const words_tc = outputArray;
      const words_py = text_py.split(" ");
      // console.log(outputArray);
      // console.log(words_py);
      // let words_tc: any = text_tc.replace(/(?!^)(?![。，]$)/gu, ' ').split(' ');
      //  console.log('words_tc - words_py', words_tc.length + ' - ' + words_py.length)
      if (words_tc.length === words_py.length) {
        // console.log("First Condition")
        for (let i = 0; i < words_tc.length; i++) {
          const wordtc = words_tc[i] as never;
          let data = {
            words_tc: wordtc,
            words_py: "",
          };
          fragmentsa.push(data);
        }
        for (let i = 0; i < words_py.length; i++) {
          const wordpy = words_py[i] as never;
          if (wordpy !== "" && wordpy !== " " && wordpy !== undefined) {
            fragmentsa[i]["words_py"] = wordpy ? wordpy : "";
          } else {
            fragmentsa[i]["words_py"] = "";
          }
        }
        const fragments = fragmentsa.map((word, index) => (
          <React.Fragment key={index}>
            <li
              onClick={() => handleClick(word, index, text_dc, html_tc)}
              className={index === activeIndex ? "active" : ""}
            >
              <div>
                {cheChar ? (
                  <span
                    style={{ fontSize: `${fontSize}px` }}
                    className={activeIndexDc.includes(index) ? "ion-text-center red" : "ion-text-center"}
                    id="top-center"
                  >
                    {word["words_tc"]}
                  </span>
                ) : (
                  ""
                )}
                {piniyin ? (
                  <p style={{ fontSize: `${cheChar === true && piniyin === true ? fontSize - 3 : fontSize}px` }}
                    className={activeIndexDc.includes(index) ? "wordpy red" : "wordpy"}
                  >
                    {word["words_py"]}
                  </p>
                ) : (
                  ""
                )}
              </div>

            </li>{" "}
          </React.Fragment>
        ));
        //const fragments = ''
        return fragments;
      } else {
        const arr1 = words_tc;
        const arr2 = words_py;
        const maxLength = Math.max(arr1?.length, arr2?.length);
        const paddedArr1 = arr1.concat(
          Array(maxLength - arr1?.length).fill("")
        );
        const paddedArr2 = arr2.concat(
          Array(maxLength - arr2?.length).fill("")
        );
        for (let i = 0; i < paddedArr1.length; i++) {
          const wordtc = paddedArr1[i] as never;
          let data = {
            words_tc: wordtc,
            words_py: "",
          };
          fragmentsa.push(data);
        }
        for (let i = 0; i < paddedArr2.length; i++) {
          const wordpy = paddedArr2[i] as never;
          if (wordpy !== "" && wordpy !== " " && wordpy !== undefined) {
            fragmentsa[i]["words_py"] = wordpy ? wordpy : "";
          } else {
            fragmentsa[i]["words_py"] = "";
          }
        }
        const fragments = fragmentsa.map((word, index) => (
          <React.Fragment key={index}>
            <li
              onClick={() => handleClick(word, index, text_dc, html_tc)}
              className={index === activeIndex ? "active" : ""}
            >
              <div>
                <span className="ion-text-center" id="top-center">
                  {word["words_tc"]}
                </span>
                <p className="wordpy">{word["words_py"]}</p>
              </div>
            </li>{" "}
          </React.Fragment>
        ));
        //const fragments = ''
        return fragments;
      }
    } else if (text_tc) {
      const words_tc = text_tc.split("");
      for (let i = 0; i < words_tc.length; i++) {
        const word = words_tc[i] as never;
        let data = {
          words_tc: word,
          words_py: "",
        };
        fragmentsa.push(data);
      }
      const fragments = fragmentsa.map((word, index) => (
        <React.Fragment key={index}>
          <li
            onClick={() => handleClick(word, index, text_dc, html_tc)}
            className={index === activeIndex ? "active" : ""}
          >
            <span className="ion-text-center" id="top-center">
              {word["words_tc"]}
            </span>
          </li>{" "}
        </React.Fragment>
      ));
      return fragments;
    } else if (text_py) {
      const words_py = text_py.split(" ");
    }
    // console.log('spans', fragments)
    //const htmls = { __html: spans.join('') };
  };
  const [divPosition, setDivPosition] = useState({ x: 0, y: 0 });
  const handleDocumentClick = (event: MouseEvent) => {
    setDivPosition({ x: event.clientX, y: event.clientY });
    // setShowDiv(true);
  };
  const getIndex = async (event: any) => {
    let index: number = 0;
    await event.target.getActiveIndex().then((value: any) => (index = value));
    nextindex = index;
    debugger;
    if (itemlist.length === index + 1) {
      nextindex = index + 2;
      setnextLength(nextindex);
    }
    // { console.log('getIndex', index + '==' + length) }
    // console.log("nextindex11111", nextindex);
    // if (itemlist.length ===index) {
    //   console.log('gotonextPage_handleSlideDidChanged')
    //   console.log("Go next page")
    //   setLength(-1)
    //   setActiveIndex(-1);
    //   gotonextPage()
    //   // saveContent()
    //   // handleChange()
    // }
  };
  const gobackPagethemesports = () => {
    //history.push('/tabs/home/themesports/5');
    // history.goForward()
    history.replace(
      {
        pathname: backpageValue === 2 ? "/tabs/progress" : (backpageValue === 0 ? "/tabs/home/proficient" : "/tabs/home/themesports"),
        state: {
          themeArray: themeArray,
          themeId: themeId,
          lessonname: lessonname,
          index: index,
          lessonsId: lessonsId,
          themename: themename,
          levelId: levelId,
          level: level,
          levname: levname,
          backpageValue: backpageValue,
        },
      },
      { direction: "back" }
    );
    // history.go(0);
    // window.location.reload();
  };
  //Setting Code
  const [IsTranLangSim, setIsTranLangSim] = useState<any>();
  const setIsLangueTrans = (value, type) => {
    if (type == 1) {
      setIsTranLangSim(true);
      saveLangue(value, true, -1);
    } else {
      setIsTranLangSim(false);
      saveLangue(value, true, -1);
    }
  };
  const saveLangue = async (val, isLesson, index) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 3000,
    });
    console.log(itemlist);

    const data = {
      language: val,
      isLesson: isLesson,
    };
    console.log(data);
    try {
      const response = await fetch(url + "/User/SaveLanguage", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });

      const json = await response.json();
      console.log(json);

      dismiss();

      if (json.StatusCode == 1) {
        // history.push('/login');
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };

  const fontsize = (data) => {

    if (data == 0) {
      if (fontSize > 18) {
        let font = fontSize - 3;
        setFontSize(font);
        setClickedDown(true);
      }
    } else if (data == 1) {
      if (fontSize < 24) {
        let font = fontSize + 3;
        setFontSize(font);
        setClickedUp(true);
      }
    }

    // Remove the 'clicked' class after a short delay (adjust as needed)
    setTimeout(() => {
      setClickedUp(false);
      setClickedDown(false);
    }, 300);
  };

  const SaveUserSettings = async (char: any, piniyin: any, trans: any) => {
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   duration: 1000,
    // });
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    const settings = {
      isPinyin: piniyin,
      isTranslation: trans,
      isChineseCharacter: char,
    };
    console.log(settings);
    try {
      const response = await fetch(url + "/User/SaveSetting", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
        body: JSON.stringify(settings),
      });
      const json = await response.json();
      console.log("SaveSetting Response", json);
      // dismiss();
      if (json.StatusCode === 1) {
        // Settings saved successfully
        // Handle the response as needed
      } else if (json.StatusCode === 2) {
        // Handle token refresh logic
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      // dismiss();
    }
  };

  const GetSettings = async () => {
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   duration: 1000,
    // });
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    try {
      const response = await fetch(url + `/User/GetSetting`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
          // Include other headers if needed for User/GetSetting
        },
      });
      const json = await response.json();
      console.log("Settings", json);
      // dismiss();
      if (json.StatusCode === 1) {
        console.log(json.Result);
        setCheChar(json.Result.IsChineseCharacter);
        setPiniyin(json.Result.IsPinyin);
        setIsTransChecked(json.Result.IsTranslation);
        setIsTranLangSim(json.Result.LessonLanguage === "Chinese, Simplified" === true)
      } else if (json.StatusCode === 2) {
      } else {
        // Handle other StatusCode values
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      // dismiss();
    }
  };

  const toggleChe = () => {
    setCheChar((prevCheChar) => {
      const newCheChar = !prevCheChar;
      console.log(newCheChar); // Log the new state (true or false)
      let newpiniyin: any
      if (newCheChar === false) {
        setPiniyin(true)
        newpiniyin = true
      } else {
        newpiniyin = null
      }
      SaveUserSettings(newCheChar, newpiniyin !== null ? newpiniyin : piniyin, isTransChecked); // Save the new state if needed
      return newCheChar;
    });
  };

  const togglepiniyin = () => {
    setPiniyin((prevPiniyin) => {
      const newPiniyin = !prevPiniyin;
      console.log(newPiniyin); // Log the new state (true or false)
      let newchechar: any
      if (newPiniyin === false) {
        setCheChar(true)
        newchechar = true
      } else {
        newchechar = null
      }
      SaveUserSettings(newchechar !== null ? newchechar : cheChar, newPiniyin, isTransChecked); // Save the new state if needed
      return newPiniyin;
    });
  };

  const translationkModeToggle = () => {
    setIsTransChecked((prevIsTransChecked) => {
      const newIsTransChecked = !prevIsTransChecked;
      console.log(newIsTransChecked); // Log the new state (true or false)
      SaveUserSettings(cheChar, piniyin, newIsTransChecked); // Save the new state if needed
      return newIsTransChecked;
    });
  };

  const savebookmark = (val, index) => {
    // Toggle the bookmark status of the current lesson
    themeArray[index].IsFavourite = !themeArray[index].IsFavourite;

    // Create a copy of the themeArray with the updated bookmark status
    const updatedThemeArray = [...themeArray];
    updatedThemeArray[index] = { ...themeArray[index] }; // Create a new object to trigger a state update

    if (themeArray[index].IsFavourite === 0) {
      // If the lesson is no longer a favorite, update the state
      setBookmarkindex(-1);
      saveFav(val, updatedThemeArray[index]?.IsFavourite);
    } else {
      // If the lesson is now a favorite, update the state
      setBookmarkindex(index);
      saveFav(val, updatedThemeArray[index]?.IsFavourite);
    }

    // Log the updated themeArray
    console.log("Updated themeArray:", updatedThemeArray);
    themeArray = updatedThemeArray
  };

  const saveFav = async (val, isFavourite: boolean) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    debugger;
    const data = {
      referenceId: themeArray[index].BookId,
      isFavourite: isFavourite,
      typeId: 1,
    };
    //dismiss()
    console.log(data);
    try {
      const response = await fetch(url + "/User/Favourite", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      const json = await response.json();
      console.log(json);
      //dismiss()
      if (json.StatusCode == 1) {
        getthemes();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };

  const decreasePlaybackSpeed = () => {
    if (playbackSpeed > 1) {
      changePlaybackSpeed(-1);
    }
  };

  const increasePlaybackSpeed = () => {
    if (playbackSpeed < 15) {
      changePlaybackSpeed(1);
    }
  };

  const changePlaybackSpeed = (speed: number) => {
    if (rap) {
      const audioElement = rap.audioEl.current;
      if (audioElement) {
        audioElement.playbackRate += speed;
        // Ensure playback speed is within bounds (1 to 15)
        if (audioElement.playbackRate < 1) {
          audioElement.playbackRate = 1;
        } else if (audioElement.playbackRate > 15) {
          audioElement.playbackRate = 15;
        }
        setPlaybackSpeed(audioElement.playbackRate);
      }
    }
  }

  //End Setting
  return (
    <IonPage className="intro" placeholder={undefined}>
      <IonHeader className="ion-text-center dubbletitle" placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <div>
            <IonButtons slot="start" placeholder={undefined}>
              <IonButton color="primary" onClick={gobackPagethemesports} placeholder={undefined}>
                <IonIcon icon={chevronBack} placeholder={undefined} />
              </IonButton>
            </IonButtons>
            <IonTitle color="primary" className="font20 ion-no-padding" placeholder={undefined}>
              <span>

                {/* {index+1 || tDataCon[index ? index : 0]["number"] } */}
                {/* {t("lesson.lesson")} {index+1}: P. */}
                {t("lesson.lesson")} {themeArray?themeArray[index]?.number:""}
                {/* : P. {showResults} */}
              </span>{" "}
              <p className="ion-no-margin">{lessonname}</p>
            </IonTitle>
            <IonButtons slot="end" placeholder={undefined}>
              <IonButton onClick={openDrawer} color="primary" placeholder={undefined}>
                <IonIcon icon={ellipsisVertical} placeholder={undefined} />
              </IonButton>
            </IonButtons>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className="listing lessonpage" placeholder={undefined}>
        {itemlist.length == 0 ? (
          <IonList className="ion-no-padding" placeholder={undefined}>
            <IonRow placeholder={undefined}>
              <IonCol size="12" className="ion-no-padding lesson-img" placeholder={undefined}>
                <IonSkeletonText
                  animated
                  style={{ width: "100%", height: "360px" }} placeholder={undefined} />
              </IonCol>
            </IonRow>

          </IonList>
        ) : (
          ""
        )}
        <IonSlides
          pager={true}
          scrollbar={true}
          options={options}
          onIonSlideNextEnd={handleSlideNextEnded}
          onIonSlideDidChange={(event: any) => getIndex(event)}
          onIonSlideTransitionEnd={handleSlideDidChanged}
          ref={slidesRef} placeholder={undefined}>
          {itemlist.map((items, index) => {
            return (
              <IonSlide key={index} placeholder={undefined}>
                <IonList className="ion-no-padding" placeholder={undefined}>
                  <IonRow onClick={dismissPopover} placeholder={undefined}>
                    <IonCol size="12" className="ion-no-padding lesson-img" placeholder={undefined}>
                      {showImage ? (
                        <img
                          src={items.ImageUrl
                            ? items.ImageUrl
                            : "./assets/images/unicone.jpg"} placeholder={undefined} />
                      ) : (
                        <IonSkeletonText
                          animated
                          style={{ width: "100%", height: "360px" }} placeholder={undefined} />
                      )}
                    </IonCol>
                  </IonRow>
                  <IonRow className="cornarbox">
                    <IonCol size="12" className="ion-no-padding"></IonCol>
                  </IonRow>
                </IonList>
              </IonSlide>
            );
          })}
        </IonSlides>

          {itemlist.length > 0 ? (
                <IonRow className="lession-details lession-page" placeholder={undefined}>
                  <IonCol size="12" className="ion-text-left audio-content" placeholder={undefined}>
                    {platform ?
                      <CustomAudioPlayer
                        src={itemlist.length > 0 ? itemlist[length == -1 ? 0 : length]?.SoundUrl : ""}
                      />
                      :
                      <ReactAudioPlayer
                        style={{ border: "none" }}
                        ref={(element) => { rap = element; }}
                        className="String"
                        controls={true}
                        controlsList="nodownload noplaybackrate"
                        src={itemlist.length > 0 ? itemlist[length == -1 ? 0 : length]?.SoundUrl : ""}
                      />
                      //   <source
                      //     src={
                      //       itemlist.length > 0
                      //      -   ? itemlist[length == -1 ? 0 : length]?.SoundUrl
                      //         : ""
                      //     }
                      //     type="audio/mpeg"
                      //   ></source>
                      // </ReactAudioPlayer> 
                    }

                    {/* <ReactAudioPlayer
                autoPlay
                className="String"
                controls
                controlsList="nodownload noplaybackrate"
                src={itemlist.length > 0
                  ? itemlist[length == -1 ? 0 : length]?.SoundUrl
                  : ""}
                /> */}

                    {/* <ReactAudioPlayer
                  ref={(element) => {
                    rap = element;
                  }}
                  className="String"
                  controls={true}
                  // controls

                  controlsList="nodownload noplaybackrate"
                  src={
                    itemlist.length > 0
                      ? itemlist[length == -1 ? 0 : length]?.SoundUrl
                      : ""
                  }
                >
                  <source
                    src={
                      itemlist.length > 0
                        ? itemlist[length == -1 ? 0 : length]?.SoundUrl
                        : ""
                    }
                    type="audio/mpeg"
                  ></source>
                </ReactAudioPlayer> */}

                    {/* <audio src={
                      itemlist.length > 0
                        ? itemlist[length == -1 ? 0 : length]?.SoundUrl
                        : ""
                    }></audio>  */}
                    <div className="ion-padding-start ion-padding-bottom">
                      <ul className="lession-icon decitemlist">
                        {showPopover ? (
                          <div className="popup-modal">
                            <div className="downarrow"></div>
                            <div className="ion-padding popup-Popover">
                              <div className="mainsection">
                                <div>
                                  <span>{decitemlist[0]?.Word}</span>
                                  <IonIcon color="warning" icon={playCircle} placeholder={undefined} />
                                </div>
                                <IonImg
                                  onClick={() => favouriteWord(decitemlist[0])}
                                  src={decitemlist[0].IsFavourite
                                    ? "./assets/images/starfill.svg"
                                    : "./assets/images/star.svg"} placeholder={undefined} />
                              </div>
                              <div className="mainsubtitle">
                                {t("lesson.pinyin")}{" "}
                                <span>{decitemlist[0]?.Pinyin}</span>
                              </div>
                              <hr />
                              <p
                                style={{ fontSize: `${fontSize}px` }}
                                className="title cap"
                              >
                                {decitemlist[0]?.Type}
                              </p>
                              <div className="datadiv">
                                <p className="subtitle">{decitemlist[0]?.Value}</p>
                              </div>
                              <p onClick={openDrawertc} className="smalltext">
                                {t("lesson.see_sen_exp")}
                              </p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {/* <li><span className="ion-text-center" id="top-center">我</span><p className="wordpy">Wǒ</p></li>
                            <li><span className="ion-text-center">们</span><p className="wordpy">men</p></li>
                            <li><span className="ion-text-center">去</span><p className="wordpy">qù</p></li>
                            <li><span className="ion-text-center">天</span><p className="wordpy">Tiān’</p></li>
                            <li><span className="ion-text-center">安</span><p className="wordpy">ān</p></li>
                            <li><span className="ion-text-center">门。</span><p className="wordpy">mén</p></li> */}
                        {itemlist.length === 0 ? (
                          <>
                            <IonSkeletonText animated placeholder={undefined} />

                          </>
                        ) : (
                          <>
                          </>
                        )}

                        {textset_tc(
                          itemlist.length > 0
                            ? itemlist[length == -1 ? 0 : length]?.Content_tc
                            : "",
                          itemlist.length > 0
                            ? itemlist[length == -1 ? 0 : length]?.Content_py
                            : "",
                          itemlist.length > 0
                            ? itemlist[length == -1 ? 0 : length]?.Content_py_dc
                            : "",
                        )}
                      </ul>
                    </div>
                  </IonCol>
                  <IonCol size="12" className="ion-padding-bottom ion-text-left" placeholder={undefined}>
                    {isTransChecked ? (
                      <><IonImg src="./assets/images/transliteration-yellow.png" alt="" placeholder={undefined} /><div className="ion-padding-start ion-padding-bottom no-margin">
                        <div className="lession-icon decitemlist">
                          <p className="translatetext"
                            style={{ fontSize: `${fontSize}px` }}
                            dangerouslySetInnerHTML={{
                              __html: `${itemlist.length > 0
                                ? itemlist[length == -1 ? 0 : length]?.Content_en
                                : ""}`,
                            }}
                          ></p>
                        </div>
                      </div></>
                    ) : (<></>
                      // <p>{t("lesson.no_tran_txt")}</p>
                    )}
                  </IonCol>
                  {/* {itemlist[length == -1 ? 0 : length]?.SoundUrl} */}
                </IonRow>
            ) : (
            ""
          )}

      </IonContent>

      <Drawer
        className="bottompopup skateInBottom"
        isVisible={isVisible}
        onClose={onClose}
      >
        <IonRow placeholder={undefined}>
          <IonList className="list" placeholder={undefined}>
            <IonItem class="ion-no-padding playbook" placeholder={undefined}>
              <div>
                <IonImg src="./assets/images/play.png" placeholder={undefined} />
              </div>
              <div className="details ion-padding-start">
                <h4>{t("lesson.drawer.play_book")}</h4>
                <span>{t("lesson.drawer.atoply_bok_ado")}</span>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding">
              <IonLabel>{t("lesson.drawer.book_language")}</IonLabel>
              <div className="right-space">
                <IonText onClick={() => setIsLangueTrans("Chinese, Simplified", 1)} style={{ color: IsTranLangSim === true ? "var(--ion-color-primary)" : "grey" }}>簡</IonText>
                <IonText onClick={() => setIsLangueTrans("Chinese, Traditional", 2)} style={{ color: IsTranLangSim === false ? "var(--ion-color-primary)" : "grey" }}>繁</IonText>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("lesson.drawer.chinese_characters")}</IonLabel>
              <IonToggle
                checked={cheChar}
                onClick={toggleChe}
                slot="end" placeholder={undefined} />
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("lesson.drawer.pinyin")}</IonLabel>
              <IonToggle
                checked={piniyin}
                onClick={togglepiniyin}
                slot="end" placeholder={undefined} />
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("lesson.drawer.translation")}</IonLabel>
              <IonToggle
                onClick={translationkModeToggle}
                checked={isTransChecked}
                slot="end" placeholder={undefined} />
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("lesson.drawer.font_size")}</IonLabel>
              <div className="right-space">
                <div className={`fontselect ${clickedDown ? 'clickedown' : ''}`} onClick={() => { fontsize(0); }} >
                  <IonImg className={fontSize <= 18 ? "active" : ""} src="./assets/images/FontdownGray.png" placeholder={undefined} />
                  <IonImg className={fontSize > 18 ? "active" : ""} src="./assets/images/Fontdown.png" placeholder={undefined} />
                </div>
                <div className={`fontselect ${clickedUp ? 'clickedup' : ''}`} onClick={() => { fontsize(1); }} >
                  <IonImg className={fontSize >= 24 ? "active" : ""} src="./assets/images/FontupGray.png" placeholder={undefined} />
                  <IonImg className={fontSize < 24 ? "active" : ""} src="./assets/images/Fontup.png" placeholder={undefined} />
                </div>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding preview" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("lesson.drawer.preview")}</IonLabel>
              <div className="right-space">
                <span style={{ fontSize: "18px", color: fontSize === 18 ? "black" : "grey", padding: "0 0 0 10px" }}>字</span>
                <span style={{ fontSize: "21px", color: fontSize === 21 ? "black" : "grey", padding: "0 0 0 10px" }}>字</span>
                <span style={{ fontSize: "24px", color: fontSize === 24 ? "black" : "grey", padding: "0 0 0 10px" }}>字</span>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("lesson.drawer.playback_speed")}</IonLabel>
              <div className="leftright">
                {/* <span onClick={() => changePlaybackSpeed(-1)}>&lt;</span> {playbackSpeed.toFixed(1)} <span onClick={() => changePlaybackSpeed(1)}>&gt;</span> */}
                <span onClick={decreasePlaybackSpeed} style={{ cursor: playbackSpeed > 1 ? 'pointer' : 'not-allowed' }}> &lt; </span>
                {playbackSpeed.toFixed(1)}
                <span onClick={increasePlaybackSpeed} style={{ cursor: playbackSpeed < 15 ? 'pointer' : 'not-allowed' }}> &gt; </span>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("lesson.drawer.bookmark")}</IonLabel>
              <div className="right-space">
                {/* <IonIcon color="primary" icon={bookmark} /> */}
                <IonImg
                  onClick={() => savebookmark(themeArray, index)}
                  className="ion-padding-bottom bookmark"
                  src={(themeArray?themeArray[index]?.IsFavourite:"")
                    ? "./assets/images/Bookmarkfill.svg"
                    : "./assets/images/Bookmark.svg"}
                  alt="" placeholder={undefined} />
              </div>
            </IonItem>
          </IonList>
        </IonRow>
      </Drawer>
      <Drawer
        className="bottompopup skateInBottom"
        isVisible={isVisibletc}
        onClose={onClose}
      >
        <IonRow placeholder={undefined}>
          <IonList className="list Leisue" placeholder={undefined}>
            <IonItem class="ion-no-padding playbook" placeholder={undefined}>
              <div className="moredata">
                {t("lesson.drawer2.more_examples")}
              </div>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <div className="mainpart">
                <div className="more-example">
                  <div>
                    <span>{t("lesson.drawer2.leisue_lesson")}</span>
                    {t("lesson.drawer2.making_a_cake")}
                  </div>
                  <IonImg src="./assets/images/rightarrow.png" placeholder={undefined} />
                </div>
                <ul>
                  <li>
                    <IonImg src="./assets/images/bigReader.png" placeholder={undefined} />{" "}
                    <span>{t("lesson.drawer2.chinese_text")}</span>
                  </li>
                  <li>
                    <IonImg src="./assets/images/Pinyin.png" placeholder={undefined} />{" "}
                    <span>{t("lesson.drawer2.pinyin")}</span>
                  </li>
                  <li>
                    <IonImg src="./assets/images/transliteration.png" placeholder={undefined} />
                    {t("lesson.drawer2.you_can_use_creativity")}
                  </li>
                </ul>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <div className="mainpart">
                <div className="more-example">
                  <div>
                    <span>{t("lesson.drawer2.leisue_lesson")}</span>
                    {t("lesson.drawer2.making_a_cake")}
                  </div>
                  <IonImg src="./assets/images/rightarrow.png" placeholder={undefined} />
                </div>
                <ul>
                  <li>
                    <IonImg src="./assets/images/bigReader.png" placeholder={undefined} />{" "}
                    <span>{t("lesson.drawer2.chinese_text")}</span>
                  </li>
                  <li>
                    <IonImg src="./assets/images/Pinyin.png" placeholder={undefined} />{" "}
                    <span>{t("lesson.drawer2.pinyin")}</span>
                  </li>
                  <li>
                    <IonImg src="./assets/images/transliteration.png" placeholder={undefined} />
                    {t("lesson.drawer2.you_can_use_creativity")}
                  </li>
                </ul>
              </div>
            </IonItem>
          </IonList>
        </IonRow>
      </Drawer>
    </IonPage>
  );
};
export default React.memo(Lesson);
