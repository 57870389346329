import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonToggle,
  IonCol,
  IonText,
  IonCard,
  IonCardContent,
  IonRow,
  IonPage,
  useIonViewWillEnter,
  useIonLoading,
  useIonToast,
  IonSlides,
  IonSlide,
  IonImg,
} from "@ionic/react";
import "./Practicequestion.scss";
import "./E6s.scss";
import Drawer from "react-bottom-drawer";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { CONFIG } from "../App";
import HanziWriter from "hanzi-writer";
import ReactAudioPlayer from "react-audio-player";
import useMediaRecorder from "@wmik/use-media-recorder";
import { RouteComponentProps, useHistory } from "react-router";
import { json } from "stream/consumers";
import { string } from "yup";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

function Player({ srcBlob }: { srcBlob: any }) {
  //console.log('srcBlob', srcBlob);
  if (!srcBlob) {
    return null;
  } else {
    return <audio src={URL.createObjectURL(srcBlob)} controls />;
  }
}
interface MyObject {
  id: number;
  name: string;
  type: "e6s";
  pagecouter: number;
  totalque: number;
  back: number;
}

interface E6sProps {
  itemE6s: any;
  onObjectChange: (object: MyObject) => void;
  lastSlide: any;
  cheChar:any;
  piniyin:any;
}

const E6s: React.FC<E6sProps> = (props: E6sProps) => {
  let url: string = CONFIG.API_ENDPOINT; //live

  const { t } = useTranslation();

  let lastValue: any;
  const [isVisible, setIsVisible] = React.useState(false);
  const openDrawer = React.useCallback(() => setIsVisible(true), []);
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [showLoading, setShowLoading] = useState(false);
  const [isDraggable, setIsDraggable] = useState(false);
  const [showResults, setShowResults] = React.useState(0);
  const [FinalScore, setFinalScore] = useState<any>(0);
  const [length, setLength] = React.useState(-1);
  const [index, setIndex] = React.useState(0);
  //CompareSentences
  const [sentence1, setSentence1] = useState("");
  const [sentence2, setSentence2] = useState("");

  const [showImage, setShowImage] = useState(false);

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [ansIndex, setAnsSlideIndex] = useState(0);
  
  // DnD Start

  // Sample data
  //console.log(props.itemE6s);
  //console.log(props.itemE6s[index].Word);
  //console.log(props.itemE6s[index].WordPy);

  const dataTxt = props.itemE6s[showResults].WordCh;
  const dataPy = props.itemE6s[showResults].WordPy;
  debugger;

  const initialList1 = dataTxt ? dataTxt.split("") : [];

  const iniPinyinList1 = dataPy ? dataPy.split(" ") : [];

  const initialList2 = [];
  const iniPinyinList2 = [];

  const listResult = [];

  for (let i = 0; i < props.itemE6s.length; i++) {
    const element = props.itemE6s[i];

    const initialList3 = props.itemE6s[i].WordCh
      ? props.itemE6s[i].WordCh.split("")
      : [];

    const iniPinyinList4 = props.itemE6s[i].WordPy
      ? props.itemE6s[i].WordPy.split(" ")
      : [];

    // return word_ch.Word
    let mergedArray = initialList3.map((element, index) => ({
      value1: element,
      value2: iniPinyinList4[index],
    }));
    //console.log('mergedArray', mergedArray);

    let myArray_chaines = mergedArray;

    myArray_chaines.sort(randomSort);
    // console.log('myArray', myArray_chaines);

    //Merge two arrays
    let array1 = myArray_chaines.map((obj) => obj.value1);
    let array2 = myArray_chaines.map((obj) => obj.value2);
    // console.log('array11', array1);
    // console.log('array22', array2);

    element["list_ch"] = array1;
    element["list_py"] = array2;
  }

  console.log("props.itemE6s", props.itemE6s);
  debugger;
  // //rearrage function
  // let mergedArray = initialList1.map((element, index) => ({ value1: element, value2: iniPinyinList1[index] }));
  // // console.log('mergedArray', mergedArray);

  // let myArray_chaines = mergedArray;
  function randomSort() {
    return 0.5 - Math.random();
  }
  // myArray_chaines.sort(randomSort);
  // // console.log('myArray', myArray_chaines);
  // debugger

  // //Merge two arrays
  // let array1 = myArray_chaines.map(obj => obj.value1);
  // let array2 = myArray_chaines.map(obj => obj.value2);

  // console.log('array1', array1);
  // console.log('array2', array2);
  //end

  const [list1, setList1] = useState<any>(props.itemE6s[showResults].list_ch);
  const [pinyinList1, setPinyinList1] = useState<any>(
    props.itemE6s[showResults].list_py
  );

  const [list2, setList2] = useState<any>(initialList2);
  const [pinyinList2, setPinyinList2] = useState<any>(iniPinyinList2);
  const [resultList, setResultList] = useState<any>(listResult);
  //const [finalresultList, setFinalResultList] = useState<any>([]);
  const [myanswer, setmyanswer] = useState("");

  let token = "";
  let resfreshtoken = "";
  let pagecouter: any = 0;
  let finalanswer = {};
  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);

  useIonViewWillEnter(() => {
    setLength(1);
    //console.log("length nw", props.itemE6s.length);
    slidesRef.current?.getActiveIndex().then((index) => {
      // console.log("index", index + 1);
      // console.log("length", props.itemE6s.length);
      if (props.itemE6s.length == index + 1) {
        //  console.log("Done");
        // setLength(-1)
        // saveContent()
        // handleChange()
      }
    });

    setShowResults(0);
  });

  function handleChange(back) {
    const myObject: MyObject = {
      id: 1,
      name: "My Object",
      type: "e6s",
      pagecouter: pagecouter,
      totalque: props.itemE6s.length,
      back: back,
    };
    props.onObjectChange(myObject);
  }
  const slidesRef = useRef<HTMLIonSlidesElement>(null);

  const options = {
    keyboard: true,
  };

  const handleSlideNextEnded = (event: CustomEvent<any>) => {
    console.log("Slide Next ended", event.detail);

    slidesRef.current?.getActiveIndex().then((index) => {
      console.log("Slide Next ended index", index);
      setLength(index + 1);
      setList1(props.itemE6s[showResults + 1].list_ch);
      setPinyinList1(props.itemE6s[showResults + 1].list_py);
      setList2([]);
      setPinyinList2([]);
      //  debugger
      setShowResults(index);
      savequestion();
      //console.log("length", props.itemE6s.length);
    });
  };

  const handleSlidePrevEnded = (event: CustomEvent<any>) => {
    console.log("Slide Prev ended", event.detail);
    // setLength(length - 1)
    slidesRef.current?.getActiveIndex().then((index) => {
      setIndex(index);
      console.log("Slide Prev ended index", index);
      setLength(index - 1);
      setList1(props.itemE6s[showResults - 1].list_ch);
      setPinyinList1(props.itemE6s[showResults - 1].list_py);
      setList2([]);
      setPinyinList2([]);

      setShowResults(index);
      //console.log("length", props.itemE6s.length);
    });
  };

  const handleSlideDidChanged = async (event: CustomEvent<any>) => {
    console.log("Slide Did ended", length);
    const swiper = await slidesRef.current?.getSwiper();
    if (swiper) {
      const swipeDirection = swiper.swipeDirection;
      console.log("Swipe direction:", swipeDirection);
      const currentIndex = swiper.activeIndex;
      if (swipeDirection == "prev") {
        //Back Login code
        setAnsSlideIndex(currentIndex);
        slidesRef.current?.getActiveIndex().then((index) => {
          console.log(index - 1);
          if (index - 1 === -1) {
            handleChange(1);
          }
        });
      } else {
        setAnsSlideIndex(currentIndex);
        slidesRef.current?.getActiveIndex().then((index) => {
          //   console.log("index", index + 1);
          debugger;
          SaveExerciseQueAnswer(length);
          if (index + 1 == length) {
            console.log("Go next page");
            saveContent();
            handleChange(0);
            setLength(-1);
          }
        });
      }
    }
  };

  const SaveExerciseQueAnswer = async (indexdb) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }

    // score start

    //// enable this code if want remve filtered array
    // const spChar = ["！","(","?","!","[","，","。",";","：","”","”","'","'","？","（","）","、","]",")",];
    // const filteredResult = result.filter((item) => !spChar.includes(item.Text));
    // console.log(filteredResult);

    const totalLength = resultList.length;
    console.log(totalLength);

    const eachCharVal = 100 / totalLength;
    console.log(eachCharVal);

    const status = "true";
    const filtrdResult = resultList.filter((item) =>
      status.includes(item.status)
    );
    const correctLength = filtrdResult.length;
    console.log(correctLength);

    const totalScore = correctLength * eachCharVal;
    console.log(totalScore);

    const finalScore = parseFloat(totalScore.toFixed(0));

    // score end

    const namesArray = resultList.map((obj) => obj.lText);
    const namesString = namesArray.join(""); // Modify the separator as per your requirement
    debugger;
    console.log(namesString);

    const data = {
      eId: props.itemE6s[ansIndex].EId,
      eType: props.itemE6s[ansIndex].TemplateCode,
      answer: namesString,
      rearrageAnswer: JSON.stringify(resultList), //only for rearrange exercise otherwise blank
      writingScore: finalScore,
    };
    let final = FinalScore + finalScore

    localStorage.setItem("sce6", final);


    setFinalScore(final)
    console.log("FinalScore", FinalScore);
    console.log("SaveExerciseQueAnswer", data);


    props.itemE6s[ansIndex]["answer"] = namesString;

    console.log("itemE6s_result", props.itemE6s);
    console.log("SaveExerciseQueAnswer", data);
    debugger;

    try {
      const response = await fetch(url + "/Book/SaveExerciseQueAnswer", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });

      const json = await response.json();
      console.log(json);
      //debugger
      if (json.StatusCode == 1) {
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const saveContent = async () => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    // present({
    //   message: '',
    // })

    let anw = {};
    let tmscore = 0;
    pagecouter = 0;
    for (let index = 0; index < props.itemE6s.length; index++) {
      const element = props.itemE6s[index];
      const propName = ("q" + (index + 1)).toString();
      const propValue = element.answer;

      anw[propName] = propValue;
      finalanswer = anw;
      // if (element.score) {
      //   tmscore += element.score;
      // }
    }
    console.log("tmpscore E1", tmscore);
    console.log("finalanswer E1", finalanswer);

    const stringWithBackslashes = JSON.stringify(finalanswer);
    const stringWithoutBackslashes = stringWithBackslashes.replace(/\\/g, "");
    console.log(stringWithoutBackslashes); // Output: {"q1":"2","q2":"4","q3":"4"}
    const myArray = Object.entries(finalanswer);
    console.log(myArray);
    debugger;
    for (let k = 0; k < myArray.length; k++) {
      const element = myArray[k][1];
      if (element != "") {
        pagecouter++;
      }
    }
    // console.log(pagecouter);

    // console.log(correctAnswerUser);
    debugger;
    // debugger
    let finalsc = localStorage.getItem("sce6")
    const data = {
      contentPageCode: props.itemE6s[0].TemplateCode,
      type: "exercise-score",
      bookId: props.itemE6s[0].BookId,
      contentPageId: props.itemE6s[0].PageId,
      answer: stringWithoutBackslashes,
      score: finalsc,
      options: null,
      pageRead: pagecouter,
    };
    //dismiss()
    console.log("Final_Save", data);
    debugger;

    try {
      const response = await fetch(url + "/Book/SaveContent", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });

      const json = await response.json();
      console.log(json);
      localStorage.setItem("sce6", "0")
      //dismiss()
      //  debugger
      if (json.StatusCode == 1) {
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };

  // Function to handle drag-and-drop
  const onDragEnd = (result) => {
    setIsDraggable(true);
    if (!result.destination) {
      return;
    }

    if (
      result.source.droppableId === "list1" &&
      result.destination.droppableId === "list1"
    ) {
      // Reorder within the same list
      // const newList1 = swapElements(
      //   list1,
      //   result.source.index,
      //   result.destination.index
      // );
      // setList1(newList1);
    } else if (
      result.source.droppableId === "list2" &&
      result.destination.droppableId === "list2"
    ) {
      // Reorder within the same list
      // const newList2 = swapElements(
      //   list2,
      //   result.source.index,
      //   result.destination.index
      // );
      // setList2(newList2);
    } else {
      // Move between lists
      const sourceList = result.source.droppableId === "list1" ? list1 : list2;
      const destList =
        result.destination.droppableId === "list1" ? list1 : list2;

      const sourcePinyinList =
        result.source.droppableId === "list1" ? pinyinList1 : pinyinList2;
      const destPinyinList =
        result.destination.droppableId === "list1" ? pinyinList1 : pinyinList2;

      const updatedSourceList = [...sourceList];
      const [removed] = updatedSourceList.splice(result.source.index, 1);
      const updatedDestList = [...destList];
      updatedDestList.splice(result.destination.index, 0, removed);

      const updatedSourcePinyinList = [...sourcePinyinList];
      const [removedPinyin] = updatedSourcePinyinList.splice(
        result.source.index,
        1
      );
      const updatedDestPinyinList = [...destPinyinList];
      updatedDestPinyinList.splice(result.destination.index, 0, removedPinyin);
      //debugger
      if (result.source.droppableId === "list1") {
        // props.itemE6s[showResults].list_ch = updatedSourceList;
        // console.log(props.itemE6s)

        setList1(updatedSourceList);
        setList2(updatedDestList);
        setPinyinList1(updatedSourcePinyinList);
        setPinyinList2(updatedDestPinyinList);
      } else {
        setList1(updatedDestList);
        setList2(updatedSourceList);
        setPinyinList2(updatedSourcePinyinList);
        setPinyinList1(updatedDestPinyinList);
      }
    }
  };

  useEffect(() => {
    listCheck();
    const timeoutId = setTimeout(() => {
      setShowImage(true);
      // Calculate the index of the next slide
      const nextSlideIndex = activeSlideIndex + 1;
      setActiveSlideIndex(nextSlideIndex);
      // Call the slideTo method to swipe to the next slide
      // slidesRef.current?.slideTo(nextSlideIndex);
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [list2]);

  // console.log(list2, pinyinList2);
  // console.log(list1, pinyinList1);

  const listCheck = () => {
    console.log("Call Done");

    const result = initialList1.map((text, i) => ({
      Text: text,
      lText: list2[i] || text,
      piniyin: pinyinList2[i] || iniPinyinList1[i],
      status: text === list2[i] ? true : false,
    }));
    debugger;
    setResultList(result);
  };

  const savequestion = async () => {
    console.log("Final result", resultList);

    const namesArray = resultList.map((obj) => obj.Text);
    const namesString = namesArray.join(""); // Modify the separator as per your requirement

    console.log(namesString);
    props.itemE6s[showResults + 1]["answer"] = namesString;
    debugger;
  };

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
  };

  // drag and drop end

  return (
    <IonPage className="intro" placeholder={undefined}>
      <IonContent className="listing" placeholder={undefined}>
        <IonSlides
          pager={false}
          scrollbar={true}
          options={{
            initialSlide: props.lastSlide === 1
              ? props.itemE6s.length - 1
              : activeSlideIndex,
            options,
            slideOpts,
          }}
          onIonSlideTransitionEnd={handleSlideDidChanged}
          onIonSlideNextEnd={handleSlideNextEnded}
          onIonSlidePrevEnd={handleSlidePrevEnded}
          ref={slidesRef} placeholder={undefined}>
          {props.itemE6s.map((items, index) => {
            return (
              <IonSlide placeholder={undefined}>
                <div
                  key={index}
                  style={{
                    display: showResults == index ? "block" : "none",
                  }}
                  className="audiorecord"
                >
                  <IonList className="ion-no-padding" placeholder={undefined}>
                    <IonRow className="Practice spacing ion-padding-horizontal" placeholder={undefined}>
                      <IonCol placeholder={undefined}>
                        <IonText placeholder={undefined}>{t("e6s.listen_to_audio")}</IonText>
                      </IonCol>
                      <IonCol
                        size="12"
                        className="test-audio ion-text-left whitebg" placeholder={undefined}>
                        <ReactAudioPlayer
                          className="String"
                          controls
                          controlsList="nodownload noplaybackrate"
                        >
                          <source
                            src={items?.AudioUrl}
                            type="audio/mpeg"
                          ></source>
                        </ReactAudioPlayer>
                      </IonCol>
                    </IonRow>

                    <IonRow className="answer spacing droppablefield ion-padding-horizontal swiper-no-swiping" placeholder={undefined}>
                      <IonCol size="12" placeholder={undefined}>
                        {/* {list1.length} */}
                        {/* <IonCard className="select"> */}
                        <DragDropContext onDragEnd={onDragEnd}>
                          <div
                            style={{
                              display: "block",
                              width: "100%",
                              overflow: "scroll",
                            }}
                          >
                            {/* Second list */}
                            <Droppable
                              droppableId="list2"
                              direction="horizontal"
                            >
                              {(provided) => (
                                <div
                                  className="answer single Practiceresultanswer borderbottom"
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  {list2.map((item, index) => (
                                    <Draggable
                                      key={index}
                                      draggableId={`list2-${index}`}
                                      index={index}
                                    >
                                      {(provided) => (
                                        <div
                                          className="droppableProps"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            border:
                                              initialList1.length ===
                                              list2.length
                                                ? resultList[index]["status"]
                                                  ? "2px solid var(--ion-color-success)"
                                                  : "2px solid var(--ion-color-danger)"
                                                : "2px solid var(--ion-color-medium-rgb)",
                                            ...provided.draggableProps.style,
                                          }}
                                        >
                                          {props.cheChar && (item)}
                                          <br />
                                          {props.piniyin && (pinyinList2[index])}
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>

                            {/* First list */}
                            <div className="dragcotext">
                              <Droppable
                                droppableId="list1"
                                direction="horizontal"
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                  >
                                    {list1.map((item, indexl1) => (
                                      <Draggable
                                        key={indexl1}
                                        draggableId={`list1-${indexl1}`}
                                        index={indexl1}
                                      >
                                        {(provided) => (
                                          <div
                                            className="droppableProps"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                              border:
                                                "2px solid var(--ion-color-medium-rgb)",
                                              ...provided.draggableProps.style,
                                            }}
                                          >
                                            {/* {list1.length} */}
                                            {/* {rearrangeList(item,pinyinList1[index],index)} */}
                                            {props.cheChar && (item)}
                                            <br />
                                            {props.piniyin && (pinyinList1[indexl1])}
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </div>
                          </div>
                        </DragDropContext>
                        {/* </IonCard> */}
                      </IonCol>
                    </IonRow>

                    {showImage && (
                      <IonImg
                        className="slide"
                        src="./assets/images/swipe-left-right.gif" placeholder={undefined}/>
                    )}
                  </IonList>
                </div>
              </IonSlide>
            );
          })}
        </IonSlides>
      </IonContent>
      <Drawer
        className="bottompopup skateInBottom"
        isVisible={isVisible}
        onClose={onClose}
      >
        <IonRow placeholder={undefined}>
          <IonList className="list" placeholder={undefined}>
            {/* <IonItem class="ion-no-padding playbook">
              <div>
                <IonImg src="./assets/images/play.png" />
              </div>
              <div className="details ion-padding-start">
                <h4>Play book</h4>
                <span>Autoplay book audio</span>
              </div>
            </IonItem> */}
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("e6s.drawer.lesson_language")}</IonLabel>
              <div className="right-space">
                <IonText color="primary" placeholder={undefined}>簡</IonText>
                <IonText placeholder={undefined}>繁</IonText>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("e6s.drawer.chinese_characters")}</IonLabel>
              <IonToggle checked slot="end" placeholder={undefined}></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("e6s.drawer.pinyin")}</IonLabel>
              <IonToggle checked slot="end" placeholder={undefined}></IonToggle>
            </IonItem>
            {/* <IonItem class="ion-no-padding">
              <IonLabel>Translation</IonLabel>
              <IonToggle slot="end"></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding">
              <IonLabel>Font size</IonLabel>
              <IonImg src="./assets/images/Fontdown.png" />
              <IonImg src="./assets/images/Fontup.png" />
            </IonItem> */}
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("e6s.drawer.playback_speed")}</IonLabel>
              <div className="leftright">
                <a href="#">&lt;</a> 1.0 <a href="#">&gt;</a>
              </div>
            </IonItem>
            {/* <IonItem class="ion-no-padding">
              <IonLabel>Bookmark</IonLabel>
              <IonIcon color='primary' icon={bookmark} />
            </IonItem> */}
          </IonList>
        </IonRow>
      </Drawer>
    </IonPage>
  );
};

export default React.memo(E6s);
