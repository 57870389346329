import React, { useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonList,
  IonCol,
  IonText,
  IonCard,
  IonCardContent,
  IonImg,
  IonIcon,
  IonPage,
  IonTitle,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
} from "@ionic/react";
import "./Detailedreports.scss";
import { chatboxEllipsesOutline, chevronBack, headset } from "ionicons/icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { CONFIG } from "../App";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
interface OwnProps extends RouteComponentProps {}
interface ProgressProps extends OwnProps {}
const Progress: React.FC<ProgressProps> = ({ history }) => {
  let url: string = CONFIG.API_ENDPOINT; //live
  const { t } = useTranslation();
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any>();
  const [newItemList, setNewItems] = React.useState<any>();
  const [newThemeId, setNewThemeId] = useState<any>(0);
  let ThemesId: number = 0;
  const [newThemeList, setNewThemeList] = React.useState<any>();
  const [activeLevel, setActiveLevel] = useState(-1);
  const [itemlevellist, setlevelitems] = React.useState<any>({});
  const historyss = useHistory<{
    themeId: string;
    lessonsId: string;
    index: string;
    lessonname: string;
    themename: string;
    levelId: string;
    level: string;
    levname: string;
  }>();
  const themeId = historyss.location.state
    ? historyss.location.state.themeId
    : "";
  const lessonsId = historyss.location.state
    ? historyss.location.state.lessonsId
    : "";
  const index = historyss.location.state ? historyss.location.state.index : "";
  const lessonname = historyss.location.state
    ? historyss.location.state.lessonname
    : "";
  const themename = historyss.location.state
    ? historyss.location.state.themename
    : "";
  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";
  const levelId = historyss.location.state
    ? historyss.location.state.levelId
    : "";
  const level = historyss.location.state ? historyss.location.state.level : "";
  let token = "";
  let resfreshtoken = "";
  useIonViewWillEnter(() => {
    //setMenuEnabled(false);
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    handleClick(0)
    if (newThemeId === 0) {
      GetLevelBooks();
      // setNewThemeId(0)
    }
    GetLevels();
  });
  const GetLevelBooks = async () => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 2000,
    });
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    try {
      const response = await fetch(
        url + "/Book/GetDetailReport?themeId=" + (ThemesId || 0),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        }
      );
      const json = await response.json();
      console.log("GetDetailReports", json);
      // setActiveLevel(1);
      dismiss();
      if (json.StatusCode == 1) {
        setitems(json.Result);
        if (json.Result.Themes) {
          debugger;
          // localStorage.setItem("themeList", JSON.stringify(json.Result.Themes));
          setNewThemeList(json.Result.Themes);
        }

        for (let index = 0; index < json.Result.Lessons.length; index++) {
          const element = json.Result.Lessons[index];
        }
        let test: any = [];
        // const array1 = [{ BookId: 14335 }, { BookId: 14336 }];
        // const array2 = [{ BookId: 1111 }, { BookId: 2222 }, { BookId: 3333 }, { BookId: 4444 }];

        const array1 = json.Result.Lessons;
        const array2 = json.Result.Dialogues;

        const combinedArray = array1.concat(array2);
        const maxLength = Math.max(array1.length, array2.length);

        for (let i = 0; i < maxLength; i++) {
          // Your logic here
          if (array1[i]) {
            array1[i]["type"] = "lessons";
            array1[i]["number"] = i + 1;
            test.push(array1[i]);
          }
          if (array2[i]) {
            array2[i]["type"] = "dialogues";
            array2[i]["number"] = i + 1;
            test.push(array2[i]);
          }
        }

        console.log("test array", test);
        setNewItems(test);
        //debugger
      } else if (json.StatusCode == 2) {
        //   refreshtoken()
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const handleClick = async (index) => {
    ThemesId = index;
    setNewThemeId(index);
    await GetLevelBooks();
  };
  const GetLevels = async () => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 1000,
    });
    try {
      const response = await fetch(url + "/User/GetUserLevel", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });
      const json = await response.json();
      console.log("GetLevels", json);
      dismiss();
      if (json.StatusCode == 1) {
        setlevelitems(json.Result);
        //debugger
      } else if (json.StatusCode == 2) {
        //refreshtoken()
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const goBack = () => {
    history.push({
      pathname: "/tabs/home/Practicequestion",
      state: {
        themeId: themeId,
        lessonname: lessonname,
        index: index,
        lessonsId: lessonsId,
        themename: themename,
        levelId: levelId,
        level: level,
        levname: levname,
      },
    });
    history.go(0);
   // window.location.reload();
  };
  return (
    <IonPage className="intro" placeholder={undefined}>
      <IonHeader className="ion-text-center" placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <IonButtons slot="start" placeholder={undefined}>
            <IonButton
              href="/tabs/progress"
              color="primary" placeholder={undefined}            >
              <IonIcon icon={chevronBack} placeholder={undefined} />
            </IonButton>
          </IonButtons>
          <IonTitle
            color="primary"
            className="font20 ion-no-padding" placeholder={undefined}          >
            <span>{t("detailreports.detailed_reports")}</span>
          </IonTitle>
          <IonButtons slot="end" placeholder={undefined}>
            {/* <IonButton color='primary'>
              <IonIcon icon={ellipsisVertical} />
            </IonButton> */}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding-horizontal" placeholder={undefined}>
        <IonList className="themes" placeholder={undefined}>
          <IonCol className="data-selection Detailed" placeholder={undefined}>
            {/* {1 <= itemlevellist["Level"] ? (
              <IonText
                onClick={() => handleClick(1)}
                className={activeLevel == 1 ? "select" : ""}
              >
                {t("detailreports.level_1")}
              </IonText>
            ) : (
              ""
            )} */}
            {newThemeList?.map((items, index) => {
              return (
                <IonText
                  key={index}
                  onClick={() => handleClick(items.Id)}
                  className={items.Id === newThemeId ? "select" : ""} placeholder={undefined} >
                  {" "}
                  {items.Name}
                </IonText>
              );
            })}
            {/* {2 <= itemlevellist["Level"] ? (
              <IonText
                onClick={() => handleClick(2)}
                className={activeLevel == 2 ? "select" : ""}
              >
                {t("detailreports.level_2")}
              </IonText>
            ) : (
              ""
            )}
            {3 <= itemlevellist["Level"] ? (
              <IonText
                onClick={() => handleClick(3)}
                className={activeLevel == 3 ? "select" : ""}
              >
                {t("detailreports.level_3")}
              </IonText>
            ) : (
              ""
            )}
            {4 <= itemlevellist["Level"] ? (
              <IonText
                onClick={() => handleClick(4)}
                className={activeLevel == 4 ? "select" : ""}
              >
                {t("detailreports.level_4")}
              </IonText>
            ) : (
              ""
            )}
            {5 <= itemlevellist["Level"] ? (
              <IonText
                onClick={() => handleClick(5)}
                className={activeLevel == 5 ? "select" : ""}
              >
                {t("detailreports.level_5")}
              </IonText>
            ) : (
              ""
            )} */}
          </IonCol>
        </IonList>
        <IonList className="themes reports" placeholder={undefined}>
          {/* {itemlist.Themes.length > 0 ? (
            <IonCol>
              <IonText>
                {itemlist.Themes[1].Name}
                {t("detailreports.lessons")}{" "}
              </IonText>
            </IonCol>
          ) : (
            ""
          )}
          {itemlist.Themes.length > 0 ? (
            <IonCol>
              <IonText>
                {itemlist.Themes[2].Name}
                {t("detailreports.lessons")}{" "}
              </IonText>
            </IonCol>
          ) : (
            ""
          )}
          {itemlist.Themes.length > 0 ? (
            <IonCol>
              <IonText>
                {itemlist.Themes[3].Name}
                {t("detailreports.lessons")}{" "}
              </IonText>
            </IonCol>
          ) : (
            ""
          )}
          {itemlist.Themes.length > 0 ? (
            <IonCol>
              <IonText>
                {itemlist.Themes[4].Name}
                {t("detailreports.lessons")}{" "}
              </IonText>
            </IonCol>
          ) : (
            ""
          )}
          {itemlist.Themes.length > 0 ? (
            <IonCol>
              <IonText>
                {itemlist.Themes[5].Name}
                {t("detailreports.lessons")}{" "}
              </IonText>
            </IonCol>
          ) : (
            ""
          )} */}

          {/* {activeLevel == 1 && itemlist.Themes.length > 0
            ? itemlist["L1s"].map((items, index) => {
                return (
                  <IonCard>
                    <IonCardContent
                      class="ion-no-padding"
                    >
                      <div className="themelesson">
                        <IonImg
                          src={items.ImageUrl}
                          alt=""
                        />
                      </div>
                      <div className="details">
                        <h4>
                          {items.Theme}: {t("detailreports.lessons")}{" "}
                          {index + 1}
                        </h4>
                        <span>{items.Title_tc}</span>
                        <ul>
                          <li>
                            <IonImg
                              src="./assets/images/Reader.png"
                              alt=""
                            />{" "}
                            {items.Reading}
                          </li>
                          <li>
                            <IonImg
                              src="./assets/images/Writing.png"
                              alt=""
                            />{" "}
                            {items.Writing}
                          </li>
                          <li>
                            <IonIcon
                              color="primary"
                              icon={headset}
                            />{" "}
                            {items.Listening}
                          </li>
                          <li>
                            <IonIcon
                              color="primary"
                              icon={chatboxEllipsesOutline}
                            />{" "}
                            {items.Speaking}
                          </li>
                        </ul>
                      </div>
                    </IonCardContent>
                  </IonCard>
                );
              })
            : ""} */}

          {/* {activeLevel == 1 && itemlist.Themes.length > 0
            ? itemlist["L1s"].map((items, index) => {
                return (
                  <IonCard>
                    <IonCardContent
                      class="ion-no-padding"
                    >
                      <div className="themelesson">
                        <IonImg
                          src={items.ImageUrl}
                          alt=""
                        />
                      </div>
                      <div className="details">
                        <h4>
                          {items.Theme}: {t("detailreports.lessons")}{" "}
                          {index + 1}
                        </h4>
                        <span>{items.Title_tc}</span>
                        <ul>
                          <li>
                            <IonImg
                              src="./assets/images/Reader.png"
                              alt=""
                            />{" "}
                            {items.Reading}
                          </li>
                          <li>
                            <IonImg
                              src="./assets/images/Writing.png"
                              alt=""
                            />{" "}
                            {items.Writing}
                          </li>
                          <li>
                            <IonIcon
                              color="primary"
                              icon={headset}
                            />{" "}
                            {items.Listening}
                          </li>
                          <li>
                            <IonIcon
                              color="primary"
                              icon={chatboxEllipsesOutline}
                            />{" "}
                            {items.Speaking}
                          </li>
                        </ul>
                      </div>
                    </IonCardContent>
                  </IonCard>
                );
              })
            : ""}
          {activeLevel == 2 && itemlist["L2s"].length > 0
            ? itemlist["L2s"].map((items, index) => {
                return (
                  <IonCard>
                    <IonCardContent
                      class="ion-no-padding"
                    >
                      <div className="themelesson">
                        <IonImg
                          src={items.ImageUrl}
                          alt=""
                        />
                      </div>
                      <div className="details">
                        <h4>
                          {items.Theme}: {t("detailreports.lessons")}{" "}
                          {index + 1}
                        </h4>
                        <span>{items.Title_tc}</span>
                        <ul>
                          <li>
                            <IonImg
                              src="./assets/images/Reader.png"
                              alt=""
                            />{" "}
                            {items.Reading}
                          </li>
                          <li>
                            <IonImg
                              src="./assets/images/Writing.png"
                              alt=""
                            />{" "}
                            {items.Writing}
                          </li>
                          <li>
                            <IonIcon
                              color="primary"
                              icon={headset}
                            />{" "}
                            {items.Listening}
                          </li>
                          <li>
                            <IonIcon
                              color="primary"
                              icon={chatboxEllipsesOutline}
                            />{" "}
                            {items.Speaking}
                          </li>
                        </ul>
                      </div>
                    </IonCardContent>
                  </IonCard>
                );
              })
            : ""}
          {activeLevel == 3 && itemlist["L3s"].length > 0
            ? itemlist["L3s"].map((items, index) => {
                return (
                  <IonCard>
                    <IonCardContent
                      class="ion-no-padding"
                    >
                      <div className="themelesson">
                        <IonImg
                          src={items.ImageUrl}
                          alt=""
                        />
                      </div>
                      <div className="details">
                        <h4>
                          {items.Theme}: {t("detailreports.lessons")}{" "}
                          {index + 1}
                        </h4>
                        <span>{items.Title_tc}</span>
                        <ul>
                          <li>
                            <IonImg
                              src="./assets/images/Reader.png"
                              alt=""
                            />{" "}
                            {items.Reading}
                          </li>
                          <li>
                            <IonImg
                              src="./assets/images/Writing.png"
                              alt=""
                            />{" "}
                            {items.Writing}
                          </li>
                          <li>
                            <IonIcon
                              color="primary"
                              icon={headset}
                            />{" "}
                            {items.Listening}
                          </li>
                          <li>
                            <IonIcon
                              color="primary"
                              icon={chatboxEllipsesOutline}
                            />{" "}
                            {items.Speaking}
                          </li>
                        </ul>
                      </div>
                    </IonCardContent>
                  </IonCard>
                );
              })
            : ""}
          {activeLevel == 4 && itemlist["L4s"].length > 0
            ? itemlist["L4s"].map((items, index) => {
                return (
                  <IonCard>
                    <IonCardContent
                      class="ion-no-padding"
                    >
                      <div className="themelesson">
                        <IonImg
                          src={items.ImageUrl}
                          alt=""
                        />
                      </div>
                      <div className="details">
                        <h4>
                          {items.Theme}: {t("detailreports.lessons")}{" "}
                          {index + 1}
                        </h4>
                        <span>{items.Title_tc}</span>
                        <ul>
                          <li>
                            <IonImg
                              src="./assets/images/Reader.png"
                              alt=""
                            />{" "}
                            {items.Reading}
                          </li>
                          <li>
                            <IonImg
                              src="./assets/images/Writing.png"
                              alt=""
                            />{" "}
                            {items.Writing}
                          </li>
                          <li>
                            <IonIcon
                              color="primary"
                              icon={headset}
                            />{" "}
                            {items.Listening}
                          </li>
                          <li>
                            <IonIcon
                              color="primary"
                              icon={chatboxEllipsesOutline}
                            />{" "}
                            {items.Speaking}
                          </li>
                        </ul>
                      </div>
                    </IonCardContent>
                  </IonCard>
                );
              })
            : ""}
          {activeLevel == 5 && itemlist["L5s"].length > 0
            ? itemlist["L5s"].map((items, index) => {
                return (
                  <IonCard>
                    <IonCardContent
                      class="ion-no-padding"
                    >
                      <div className="themelesson">
                        <IonImg
                          src={items.ImageUrl}
                          alt=""
                        />
                      </div>
                      <div className="details">
                        <h4>
                          {items.Theme}: {t("detailreports.lessons")}{" "}
                          {index + 1}
                        </h4>
                        <span>{items.Title_tc}</span>
                        <ul>
                          <li>
                            <IonImg
                              src="./assets/images/Reader.png"
                              alt=""
                            />{" "}
                            {items.Reading}
                          </li>
                          <li>
                            <IonImg
                              src="./assets/images/Writing.png"
                              alt=""
                            />{" "}
                            {items.Writing}
                          </li>
                          <li>
                            <IonIcon
                              color="primary"
                              icon={headset}
                            />{" "}
                            {items.Listening}
                          </li>
                          <li>
                            <IonIcon
                              color="primary"
                              icon={chatboxEllipsesOutline}
                            />{" "}
                            {items.Speaking}
                          </li>
                        </ul>
                      </div>
                    </IonCardContent>
                  </IonCard>
                );
              })
            : ""} */}
          {/* <IonCard>
            <IonCardContent class="ion-no-padding">
              <div className='themelesson'><IonImg src="./assets/images/girl.jpg" alt="" /></div>
              <div className='details'>
                <h4>Sports: Lesson 2</h4>
                <span>Book Title</span>
                <ul>
                  <li><IonImg src="./assets/images/Reader.png" alt="" /> 39</li>
                  <li><IonImg src="./assets/images/Writing.png" alt="" /> 48</li>
                  <li><IonIcon color='primary' icon={headset} /> 53</li>
                  <li><IonIcon color='primary' icon={chatboxEllipsesOutline} /> 61</li>
                </ul>
              </div>
            </IonCardContent>
          </IonCard> */}
        </IonList>
        <IonList className="themes reports" placeholder={undefined}>
          {/* <IonCol>
            <IonText>
              {t("detailreports.dialogues")}
            </IonText>
          </IonCol>
          <IonCard>
            <IonCardContent class="ion-no-padding">
              <div className="themelesson">
                <IonImg
                  src="./assets/images/unicone.jpg"
                  alt=""
                />
              </div>
              <div className="details">
                <h4>{t("detailreports.dialogue_1")}</h4>
                <span>{t("detailreports.do_you_play_sports")}</span>
                <ul>
                  <li>
                    <IonIcon
                      color="primary"
                      icon={chatboxEllipsesOutline}
                    />{" "}
                    100
                  </li>
                </ul>
              </div>
            </IonCardContent>
          </IonCard>
          <IonCard>
            <IonCardContent class="ion-no-padding">
              <div className="themelesson">
                <IonImg
                  src="./assets/images/girl.jpg"
                  alt=""
                />
              </div>
              <div className="details">
                <h4>{t("detailreports.dialogue_2")}</h4>
                <span>{t("detailreports.dialogue_title")}</span>
                <ul>
                  <li>
                    <IonIcon
                      color="primary"
                      icon={chatboxEllipsesOutline}
                    />{" "}
                    61
                  </li>
                </ul>
              </div>
            </IonCardContent>
          </IonCard> */}
          {newItemList?.map((items, index) => {
            return (
              <div key={index}>
                {items.type === "lessons" ? (
                  <>
                    <IonCard placeholder={undefined}>
                      <IonCardContent
                        class="ion-no-padding" placeholder={undefined}                      >
                        <div className="themelesson">
                          <IonImg
                            src={items.ImageUrl}
                            alt="" placeholder={undefined}                          />
                        </div>
                        <div className="details">
                          <h4>
                            {/* {items.Theme}:  */}
                            {t("detailreports.lesson")} {items.number}
                          </h4>
                          <span>{items.Name}</span>
                          <ul>
                            <li>
                              <IonImg
                                src={items.Reading === 0
                                  ? "./assets/images/Reader-light.png"
                                  : "./assets/images/Reader.png"}
                                alt="" placeholder={undefined}                              />{" "}
                              <span
                                style={{
                                  color:
                                    items.Reading === 0 ? "var(--ion-color-primary-shade)" : "var(--ion-color-primary)",
                                }}
                              >
                                {items.Reading !== 0 ? items.Reading : "--"}
                              </span>
                            </li>
                            <li>
                              <IonImg
                                src={items.Writing === 0
                                  ? "./assets/images/Writing-light.png"
                                  : "./assets/images/Writing.png"}
                                alt="" placeholder={undefined}                              />{" "}
                              <span
                                style={{
                                  color:
                                    items.Writing === 0 ? "var(--ion-color-primary-shade)" : "var(--ion-color-primary)",
                                }}
                              >
                                {items.Writing !== 0 ? items.Writing : "--"}
                              </span>
                            </li>
                            <li>
                              <IonImg
                                src={items.Listening === 0
                                  ? "./assets/images/Listening-light.png"
                                  : "./assets/images/ListeningOrg.png"}
                                alt="" placeholder={undefined}                              />{" "}
                              <span
                                style={{
                                  color:
                                    items.Listening === 0
                                      ? "var(--ion-color-primary-shade)"
                                      : "var(--ion-color-primary)",
                                }}
                              >
                                {items.Listening !== 0 ? items.Listening : "--"}
                              </span>
                            </li>
                            <li>
                              <IonImg
                                src={items.Speaking === 0
                                  ? "./assets/images/Speaking-light.png"
                                  : "./assets/images/SpeakingOrg.png"}
                                alt="" placeholder={undefined}                              />{" "}
                              <span
                                style={{
                                  color:
                                    items.Speaking === 0
                                      ? "var(--ion-color-primary-shade)"
                                      : "var(--ion-color-primary)",
                                }}
                              >
                                {items.Speaking !== 0 ? items.Speaking : "--"}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </IonCardContent>
                    </IonCard>
                  </>
                ) : (
                  <>
                    <IonCard placeholder={undefined}>
                      <IonCardContent
                          class="ion-no-padding" placeholder={undefined}                      >
                        <div className="themelesson">
                          <IonImg
                              src={items.ImageUrl}
                              alt="" placeholder={undefined}                          />
                        </div>
                        <div className="details">
                          <h4>
                            {/* {items.Theme}:  */}
                            {t("detailreports.dialogue")} {items.number}
                          </h4>
                          <span>{items.Name}</span>
                          <ul>
                            <li>
                              <IonImg
                                  src={items.Reading === 0
                                    ? "./assets/images/Reader-light.png"
                                    : "./assets/images/Reader.png"}
                                  alt="" placeholder={undefined}                              />{" "}
                              <span
                                style={{
                                  color:
                                    items.Reading === 0 ? "var(--ion-color-primary-shade)" : "var(--ion-color-primary)",
                                }}
                              >
                                {items.Reading !== 0 ? items.Reading : "--"}
                              </span>
                            </li>
                            <li>
                              <IonImg
                                  src={items.Writing === 0
                                    ? "./assets/images/Writing-light.png"
                                    : "./assets/images/Writing.png"}
                                  alt="" placeholder={undefined}                              />{" "}
                              <span
                                style={{
                                  color:
                                    items.Writing === 0 ? "var(--ion-color-primary-shade)" : "var(--ion-color-primary)",
                                }}
                              >
                                {items.Writing !== 0 ? items.Writing : "--"}
                              </span>
                            </li>
                            <li>
                              <IonImg
                                  src={items.Listening === 0
                                    ? "./assets/images/Listening-light.png"
                                    : "./assets/images/ListeningOrg.png"}
                                  alt="" placeholder={undefined}                              />{" "}
                              <span
                                style={{
                                  color:
                                    items.Listening === 0
                                      ? "var(--ion-color-primary-shade)"
                                      : "var(--ion-color-primary)",
                                }}
                              >
                                {items.Listening !== 0 ? items.Listening : "--"}
                              </span>
                            </li>
                            <li>
                              <IonImg
                                  src={items.Speaking === 0
                                    ? "./assets/images/Speaking-light.png"
                                    : "./assets/images/SpeakingOrg.png"}
                                  alt="" placeholder={undefined}                              />{" "}
                              <span
                                style={{
                                  color:
                                    items.Speaking === 0
                                      ? "var(--ion-color-primary-shade)"
                                      : "var(--ion-color-primary)",
                                }}
                              >
                                {items.Speaking !== 0 ? items.Speaking : "--"}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </IonCardContent>
                    </IonCard>
                    {/* <IonCard>
                      <IonCardContent
                        class="ion-no-padding"
                      >
                        <div className="lessongbox">
                          <div className="themelesson">
                            <IonImg
                              src={
                                items.ImageUrl !== ""
                                  ? items.ImageUrl
                                  : "./assets/images/noimg.jpg"
                              }
                            />
                          </div>
                          <div className="details">
                            <h4>
                              {t("themesports.dialogue")} - {items.number}{" "}
                            </h4>
                            <span className="gamename">{items.Name}</span>
                          </div>
                        </div>
                      </IonCardContent>
                    </IonCard> */}
                  </>
                )}
              </div>
            );
          })}
        </IonList>
      </IonContent>
    </IonPage>
  );
};
export default React.memo(Progress);
