import React, { useEffect, useRef, useState } from "react";
import {
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonToggle,
  IonCol,
  IonText,
  IonCard,
  IonCardContent,
  IonRow,
  IonPage,
  useIonViewWillEnter,
  useIonLoading,
  useIonToast,
  IonSlides,
  IonSlide,
  IonImg,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./E4s.scss";
import Drawer from "react-bottom-drawer";
import ReactAudioPlayer from "react-audio-player";
import { CONFIG } from "../App";
import { Swiper as SwiperCore, Pagination, Navigation, A11y } from "swiper";
import { string } from "yup";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import { RouteComponentProps, useHistory } from "react-router";
import { chevronBack, ellipsisVertical } from "ionicons/icons";
interface OwnProps extends RouteComponentProps {}
interface MyObject {
  id: number;
  name: string;
  type: "e4s";
  answ: true;
  pagecouter: 0;
  totalque: 0;
  back: number;
}
interface E4sProps {
  itemE4s: any;
  onObjectChange: (object: MyObject) => void;
  lastSlide: any;
}
const E4sResultDetails: React.FC<E4sProps> = (
  props: E4sProps,
  { history, isSuccess, isWrong }
) => {
  let url: string = CONFIG.API_ENDPOINT; //live
  const { t } = useTranslation();
  const themeData: any = localStorage.getItem("itemlist");
  //const tDataCon = JSON.parse(themeData);
  const [allexcieslist, setAllExcies] = React.useState<any[]>([]);
  const [isApicall, setAPIcall] = useState<boolean | undefined>(true);
  const [itemE4slist, setE4stems] = React.useState<any[]>([]);
  const [itemlist, setitems] = React.useState<any[]>([]);
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [isVisible, setIsVisible] = React.useState(false);
  const openDrawer = React.useCallback(() => setIsVisible(true), []);
  const [showMessage, setShowMessage] = useState(false);
  const [myObject, setMyObject] = useState<MyObject>({
    id: 0,
    name: "",
    answ: true,
    type: "e4s",
    pagecouter: 0,
    totalque: 0,
    back: 0,
  });
  const [length, setLength] = React.useState(-1);
  const [showResults, setShowResults] = React.useState(1);
  const [successOption, setSuccessOption] = useState<boolean | undefined>();
  const [wrongOption, setWrongOption] = useState<boolean | undefined>();
  const [setRightAns, setRightAnsOption] = useState<boolean | undefined>();
  const [selectedDiv, setSelectedDiv] = useState<any>(null);
  let [swiper, setSwiper] = useState<SwiperCore>();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [showImage, setShowImage] = useState(false);
  const [myanswer, setmyanswer] = useState("");
  const [pageRead, setpageRead] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);
  const [correctAnswer, setCorrectAnswer] = useState<string | null>(null);
  const [isCorrect, setIsCorrect] = useState<boolean | null>(null);
  const [correctAnswerIndex, setCorrectAnswerIndex] = useState<number | null>(
    null
  );
  const [correctAnswerIndices, setCorrectAnswerIndices] = useState<number[]>(
    []
  );
  const [correctAnswerUser, setCorrectAnswerUser] = useState<any>([]);
  const [ansIndex, setAnsSlideIndex] = useState(0);
  let lastValue: any;
  let finalanswer = {};
  let token = "";
  let resfreshtoken = "";
  let Ise4ev1: any = true;
  let pagecouter: any = 0;
  let totalque: any = 0;
  let indexdbq: any = -1;
  // let myanswer = ''
  const historyss = useHistory<{
    themeArray: string;
    themeId: string;
    lessonsId: string;
    index: string;
    lessonname: string;
    themename: string;
    levelId: string;
    level: string;
    levname: string;
    backpage: string;
    pagename: string;
    backpageValue: any;
  }>();
  const themeArray = historyss.location.state
    ? historyss.location.state.themeArray
    : "";
  const itemArray: any = themeArray;
  const themeId = historyss.location.state
    ? historyss.location.state.themeId
    : "";
  const lessonsId = historyss.location.state
    ? historyss.location.state.lessonsId
    : "";
  const indexs = historyss.location.state ? historyss.location.state.index : "";
  const lessonname = historyss.location.state
    ? historyss.location.state.lessonname
    : "";
  const themename = historyss.location.state
    ? historyss.location.state.themename
    : "";
  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";
  const levelId = historyss.location.state
    ? historyss.location.state.levelId
    : "";
  const level = historyss.location.state ? historyss.location.state.level : "";
  const backpage = historyss.location.state
    ? historyss.location.state.backpage
    : "";
  const pagename = historyss.location.state
    ? historyss.location.state.pagename
    : "";
    const backpageValue = historyss.location.state
    ? historyss.location.state.backpageValue
    : "";
  useIonViewWillEnter(() => {
    getExercise();
  });
  const getExercise = async () => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 1000,
    });
    console.log(lessonsId);
    try {
      const response = await fetch(
        url +
          "/Book/GetExerciseResult?lessonGuid=" +
          lessonsId +
          "&type=" +
          pagename,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        }
      );
      const json = await response.json();
      //console.log('GetExercise ', json)
      // debugger
      dismiss();
      if (json.StatusCode == 1) {
        // setitems(json.Result.E4ev1s)
        // setLengthQue(json.Result.length)
        console.log("GetExercise", json.Result);
        setAllExcies(json.Result);
        // setAPIcall(false);
        //New Code
        // setE1stems(json.Result.E1s);
        //Exercise E1s  4 images and 1 text logic set
        // let tmpoptione1s: any = [];
        // let resulteE1s = json.Result.E1s;
        // resulteE1s.forEach((axq: any[], indexq: number) => {
        //   // console.log('Option', result[indexq].Word1)
        //   let qq = [
        //     {
        //       Word: resulteE1s[indexq].ImageUrl1,
        //       questionno: 1,
        //     },
        //     {
        //       Word: resulteE1s[indexq].ImageUrl2,
        //       questionno: 2,
        //     },
        //     {
        //       Word: resulteE1s[indexq].ImageUrl3,
        //       questionno: 3,
        //     },
        //     {
        //       Word: resulteE1s[indexq].ImageUrl4,
        //       questionno: 4,
        //     },
        //   ];
        //   const random_o = qq
        //     .map((a) => ({ sort: Math.random(), value: a }))
        //     .sort((a, b) => a.sort - b.sort)
        //     .map((a) => a.value);
        //   console.log("random", qq);
        //   let final = {
        //     EId: resulteE1s[indexq].EId,
        //     //Ev_guid: resulteE1s[indexq].Ev_guid,
        //     TemplateCode: resulteE1s[indexq].TemplateCode,
        //     PageId: resulteE1s[indexq].PageId,
        //     BookId: resulteE1s[indexq].BookId,
        //     Options: random_o,
        //     Question:
        //       resulteE1s[indexq].WordCh + " " + resulteE1s[indexq].WordPy,
        //     WordCh: resulteE1s[indexq].WordCh,
        //     WordPy: resulteE1s[indexq].WordPy,
        //     answer: resulteE1s[indexq].CorrectImgUrl,
        //     score: false,
        //   };
        //   tmpoptione1s.push(final);
        // });
        // setitems(tmpoptione1s);
        // setE1stems(tmpoptione1s);
        // console.log("this.itemlist", itemlist);
        // End E1s
        //Exercise E2s 1 images and 4 text logic set
        // let tmpoptione2s: any = [];
        // let resulteE2s = json.Result.E2s;
        // resulteE2s.forEach((axq: any[], indexq: number) => {
        //   // console.log('Option', result[indexq].Word1)
        //   let qq = [
        //     {
        //       Word: resulteE2s[indexq].WordCh1,
        //       WordPy: resulteE2s[indexq].WordPy1,
        //       questionno: 1,
        //     },
        //     {
        //       Word: resulteE2s[indexq].WordCh2,
        //       WordPy: resulteE2s[indexq].WordPy2,
        //       questionno: 2,
        //     },
        //     {
        //       Word: resulteE2s[indexq].WordCh3,
        //       WordPy: resulteE2s[indexq].WordPy3,
        //       questionno: 3,
        //     },
        //     {
        //       Word: resulteE2s[indexq].WordCh4,
        //       WordPy: resulteE2s[indexq].WordPy4,
        //       questionno: 4,
        //     },
        //   ];
        //   const random_b = qq
        //     .map((a) => ({ sort: Math.random(), value: a }))
        //     .sort((a, b) => a.sort - b.sort)
        //     .map((a) => a.value);
        //   console.log("random", qq);
        //   let final = {
        //     EId: resulteE2s[indexq].EId,
        //     //Ev_guid: resulteE1s[indexq].Ev_guid,
        //     TemplateCode: resulteE2s[indexq].TemplateCode,
        //     PageId: resulteE2s[indexq].PageId,
        //     BookId: resulteE2s[indexq].BookId,
        //     Options: random_b,
        //     Question: resulteE2s[indexq].ImageUrl,
        //     // WordCh: resulteE2s[indexq].WordCh,
        //     // WordPy: resulteE2s[indexq].WordPy,
        //     answer: resulteE2s[indexq].CorrectCh,
        //     score: false,
        //   };
        //   tmpoptione2s.push(final);
        // });
        // // setitems(tmpoptione2s);
        // setE2stems(tmpoptione2s);
        // console.log("this.itemlist", tmpoptione2s);
        // End E2s
        //Exercise E4s 1 audio and 4 text logic set
        let tmpoptione4s: any = [];
        let resulteE4s = json.Result;
        resulteE4s.forEach((axq: any[], indexq: number) => {
          // console.log('Option', result[indexq].Word1)
          let qq = [
            {
              Word: resulteE4s[indexq].WordCh1,
              WordPy: resulteE4s[indexq].WordPy1,
              questionno: 1,
            },
            {
              Word: resulteE4s[indexq].WordCh2,
              WordPy: resulteE4s[indexq].WordPy2,
              questionno: 2,
            },
            {
              Word: resulteE4s[indexq].WordCh3,
              WordPy: resulteE4s[indexq].WordPy3,
              questionno: 3,
            },
            {
              Word: resulteE4s[indexq].WordCh4,
              WordPy: resulteE4s[indexq].WordPy4,
              questionno: 4,
            },
          ];
          const random_c = qq
            .map((a) => ({ sort: Math.random(), value: a }))
            .sort((a, b) => a.sort - b.sort)
            .map((a) => a.value);
          console.log("random", qq);
          let final = {
            EId: resulteE4s[indexq].EId,
            Answer: resulteE4s[indexq].Answer,
            //Ev_guid: resulteE1s[indexq].Ev_guid,
            TemplateCode: resulteE4s[indexq].TemplateCode,
            PageId: resulteE4s[indexq].PageId,
            BookId: resulteE4s[indexq].BookId,
            Options: random_c,
            Question: resulteE4s[indexq].AudioUrl,
            RightWord: resulteE4s[indexq].RightAnswerCh,
            RightWordPy: resulteE4s[indexq].RightAnswerPy,
            // WordCh: resulteE2s[indexq].WordCh,
            // WordPy: resulteE2s[indexq].WordPy,
            // answer: resulteE4s[indexq].CorrectCh,
            score: false,
          };
          tmpoptione4s.push(final);
        });
        setitems(tmpoptione4s);
        setE4stems(tmpoptione4s);
        console.log("this.itemlist", tmpoptione4s);
        // End E2s
        //Old Code
        // setLengthQue(3);
        //Logic E4ev1s
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      // console.log(json)
      if (json.StatusCode == 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  function handleChange(back) {
    // const myObject: MyObject = {
    //   id: 1,
    //   name: "My Object",
    //   answ: Ise4ev1,
    //   type: "e4s",
    //   pagecouter: pagecouter,
    //   back: back,
    //   totalque: itemE4slist.length,
    // };
    // props.onObjectChange(myObject);
  }
  useIonViewWillEnter(() => {
    setShowResults(1);
    setLength(1);
    console.log("itemE4s", itemE4slist);
    console.log("length nw", itemE4slist.length);
    debugger;
    setShowMessage(true);
  });
  SwiperCore.use([Navigation, Pagination, A11y]);
  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  const options = {
    keyboard: true,
  };
  const slideRef = useRef<HTMLIonSlidesElement>(null);
  const slideOpts = {
    initialSlide: 0,
    speed: 400,
  };
  const handleSlideNextEnded = (event: CustomEvent<any>) => {
    console.log("Slide Next ended", event.detail);
    slidesRef.current?.getActiveIndex().then((index) => {
      console.log("Slide Next ended index", index + 1);
      setLength(index + 1);
      resetstate();
      setShowResults(index + 1);
      // console.log('length', itemE4slist.length);
    });
  };
  const handleSlidePrevEnded = (event: CustomEvent<any>) => {
    console.log("Slide Prev ended", event.detail);
    // setLength(length - 1)
    slidesRef.current?.getActiveIndex().then((index) => {
      //console.log('index', (index + 1));
      setLength(index - 1);
      // resetstate()
      // setShowResults((index-1))
      if (index === 0) {
        handleChange(1);
        console.log("Call Done");
      }
    });
  };
  const handleSlideDidChanged = async (event: CustomEvent<any>) => {
    console.log("Length", length);
    const swiper = await slidesRef.current?.getSwiper();
    if (swiper) {
      const swipeDirection = swiper.swipeDirection;
      const currentIndex = swiper.activeIndex;
      console.log("Swipe direction:", swipeDirection);
      if (swipeDirection == "prev") {
        //Back Login code
        setAnsSlideIndex(currentIndex);
        slidesRef.current?.getActiveIndex().then((index) => {
          console.log(index - 1);
          if (index - 1 === -1) {
            handleChange(1);
          }
        });
      } else {
        setAnsSlideIndex(currentIndex);
        slidesRef.current?.getActiveIndex().then(async (index) => {
          console.log("index", index + 1);
          debugger;
          // if (slidesRef.current) {
          //   const ionSlides = await slidesRef.current.getSwiper();
          //   console.log('ionSlides.activeIndex',ionSlides.activeIndex);
          // }
          SaveExerciseQueAnswer(length);
          if (index + 1 == length) {
            console.log("Go next page");
            setLength(-1);
            saveContent();
            handleChange(0);
          }
        });
      }
    }
  };
  const gotonextques = async (items: any, index: any, type) => {
    //1 for Understand and 2= dont understand
    console.log(items);
    // console.log(type)
    // console.log('soundno ', items?.Question?.[0].soundno)
    // console.log('questionno', type)
    if (items?.Question?.[0].soundno == type) {
      isSuccess = true;
      isWrong = false;
    } else {
      isSuccess = false;
      isWrong = true;
    }
    // console.log(showResults)
    //console.log(itemlist)
    //console.log(itemlist[showResults - 1])
    //  let data = itemlist[showResults - 1];
    //debugger
    if (items) {
      resetstate();
      setShowResults(index);
      // console.log('len ', showResults - 1)
      setLength(index);
      // ansques = data.QuestionId + ',' + type;
      // setQuestion([...quesdata, ansques]);
    }
  };
  let tmpscore = 0;
  const handleQuestionCheckClick = (
    items: any,
    index: any,
    indexq: any,
    type,
    indexnew
  ) => {
    // console.log(type)
    //console.log('soundno ', items?.Question?.[0].soundno)
    //console.log('questionno', type)
    debugger;
    // indexdbq = index
    if (items?.Question?.[0].soundno == type) {
      isSuccess = true;
      isWrong = false;
      setSuccessOption(isSuccess);
      setWrongOption(isWrong);
      setSelectedDiv(type);
      console.log("q1", isSuccess);
      // myanswer = itemE4slist[index].Options?.[indexnew].Word
      setmyanswer("");
      setmyanswer(itemE4slist[index].Options?.[indexnew].Word);
      console.log("myanswer", myanswer);
      debugger;
      if (!itemE4slist[index].answer) {
        itemE4slist[index].answer = type;
        itemE4slist[index].score = true;
        tmpscore += tmpscore;
      }
    } else {
      isSuccess = false;
      isWrong = true;
      setRightAnsOption(true);
      setSuccessOption(isSuccess);
      setWrongOption(isWrong);
      setSelectedDiv(type);
      setmyanswer("");
      setmyanswer(itemE4slist[index].Options?.[indexnew].Word);
      console.log("myanswer", myanswer);
      if (!itemE4slist[index].answer) {
        itemE4slist[index].answer = type;
        itemE4slist[index].score = false;
      }
    }
    // console.log('result', result)
    //console.log('isSuccess', isSuccess)
    //console.log('isWrong', isWrong)
  };
  const handleAnswerSelection = (
    selectedOption: string,
    correctAnswer: string,
    index: number
  ) => {
    setSelectedAnswer(selectedOption);
    setCorrectAnswer(correctAnswer);
    if (selectedOption === correctAnswer) {
      debugger;
      setIsCorrect(true);
      setRightAnsOption(true);
      setmyanswer("");
      setmyanswer(selectedOption);
      const pushValue = (selectedOption) => {
        setCorrectAnswerUser((prevValue) => [...prevValue, selectedOption]);
      };
      pushValue(selectedOption);
    } else {
      setIsCorrect(false);
      setRightAnsOption(false);
      setmyanswer("");
      setmyanswer(selectedOption);
      // setCorrectAnswerIndex(index); // Set the correct answer index
    }
    // Set the correct answer index
    const correctIndex = itemE4slist[index].Options.findIndex(
      (option) => option.Word === correctAnswer
    );
    setCorrectAnswerIndex(correctIndex);
    setCorrectAnswerIndices((prevIndices) => {
      const newIndices = [...prevIndices];
      newIndices[index] = correctIndex;
      return newIndices;
    });
    // Update the state or perform other actions as needed
    // Example: props.itemE1s[index].answer = selectedOption;
  };
  const resetstate = () => {
    setSuccessOption(false);
    setWrongOption(false);
    setSelectedDiv(false);
    setRightAnsOption(false);
  };
  useEffect(() => {
    // console.log(quesdata);
    //console.log('String', quesdata.join('#'))
    // console.log('showResults', showResults)
    //  console.log('finalindex', length)
    // console.log('lengthque', lengthque)
    //console.log('teatemediaBlob', mediaBlob)
    if (length > itemE4slist.length) {
      console.log("Done");
      setLength(-1);
      saveContent();
      handleChange(0);
    }
    const timeoutId = setTimeout(() => {
      setShowImage(true);
      // Calculate the index of the next slide
      const nextSlideIndex = activeSlideIndex + 1;
      setActiveSlideIndex(nextSlideIndex);
      // Call the slideTo method to swipe to the next slide
      // slidesRef.current?.slideTo(nextSlideIndex);
    }, 3000);
    return () => {
      clearTimeout(timeoutId);
    };
    //debugger
  }, [activeSlideIndex, itemE4slist.length]);
  const saveContent = async () => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    // present({
    //   message: '',
    // })
    let anw = {};
    let tmscore = 0;
    pagecouter = 0;
    for (let index = 0; index < itemE4slist.length; index++) {
      const element = itemE4slist[index];
      const propName = ("q" + (index + 1)).toString();
      const propValue = element.answer;
      anw[propName] = propValue.toString();
      finalanswer = anw;
      if (element.score) {
        tmscore += element.score;
      }
    }
    console.log("tmpscore", tmscore);
    console.log("finalanswer", finalanswer);
    debugger;
    const stringWithBackslashes = JSON.stringify(finalanswer);
    const stringWithoutBackslashes = stringWithBackslashes.replace(/\\/g, "");
    console.log(stringWithoutBackslashes); // Output: {"q1":"2","q2":"4","q3":"4"}
    const myArray = Object.entries(finalanswer);
    console.log(myArray);
    for (let k = 0; k < myArray.length; k++) {
      const element = myArray[k][1];
      if (element != "") {
        pagecouter++;
      }
    }
    console.log(pagecouter);
    debugger;
    const data = {
      contentPageCode: itemE4slist[0].TemplateCode,
      type: "exercise-score",
      bookId: itemE4slist[0].BookId,
      contentPageId: itemE4slist[0].PageId,
      answer: stringWithoutBackslashes,
      score: correctAnswerUser.length,
      options: null,
      pageRead: pagecouter,
    };
    //dismiss()
    console.log(data);
    debugger;
    try {
      const response = await fetch(url + "/Book/SaveContent", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      const json = await response.json();
      console.log(json);
      //dismiss()
      debugger;
      if (json.StatusCode == 1) {
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };
  const SaveExerciseQueAnswer = async (indexdb) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    //console.log('rigthans', rigthans)
    console.log("myanswer11", myanswer);
    console.log("indexdb", indexdb);
    const data = {
      eId: itemE4slist[ansIndex].EId,
      eType: itemE4slist[ansIndex].TemplateCode,
      //question: props.itemE2s[indexdb - 1]?.Question?.[0].ImageUrl,
      //rightAnswer: foundValue.Choice,
      rearrageAnswer: "", //only for rearrange exercise otherwise blank
      answer: myanswer,
      writingScore: 0,
    };
    console.log("SaveExerciseQueAnswer", data);
    debugger;
    try {
      const response = await fetch(url + "/Book/SaveExerciseQueAnswer", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      const json = await response.json();
      console.log(json);
      //  debugger
      if (json.StatusCode == 1) {
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);
  const gobackpage = () => {
    // Check if historyss.location.state is defined
    if (historyss.location.state) {
      historyss.replace({
        pathname: "/tabs/home/Practiceresult",
        state: {
          themeArray: themeArray,
          themename: themename,
          themeId: themeId,
          lessonname: lessonname,
          index: indexs,
          lessonsId: lessonsId,
          levelId: levelId,
          level: level,
          levname: levname,
          backpage: historyss.location.state.backpage, // Access the backpage property safely
          pagename: "E4s",
          backpageValue: backpageValue,
        },
      });
    } else {
      // Handle the case when historyss.location.state is undefined
      console.error("historyss.location.state is undefined");
    }
  };
  return (
    <IonPage className="pages-space intro" placeholder={undefined}>
      <IonHeader className="ion-text-center dubbletitle" placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <div>
            <IonButtons slot="start" placeholder={undefined}>
              <IonButton color="primary" onClick={gobackpage} placeholder={undefined}>
                <IonIcon icon={chevronBack} placeholder={undefined} />
              </IonButton>
            </IonButtons>
            <IonTitle color="primary" className="font20 ion-no-padding" placeholder={undefined}>
              <span>
                {t("practicequestion.lesson")}{" "}
                {themeArray[indexs ? indexs : 0]?.number}:{" "}
                {t("practicequestion.practice")}
                <p className="ion-no-margin">{themename}</p>
              </span>
            </IonTitle>
            <IonButtons slot="end" placeholder={undefined}>
              {/* <IonButton onClick={openDrawer} color="primary">
                <IonIcon icon={ellipsisVertical} />
              </IonButton> */}
            </IonButtons>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className="listing" placeholder={undefined}>
        <IonSlides
          pager={false}
          scrollbar={true}
          options={{
            initialSlide: props.lastSlide === 1 ? itemE4slist.length - 1 : activeSlideIndex,
            options,
          }}
          onIonSlideNextEnd={handleSlideNextEnded}
          onIonSlidePrevEnd={handleSlidePrevEnded}
          onIonSlideTransitionEnd={handleSlideDidChanged}
          ref={slidesRef} // initialSlide={activeSlideIndex}
          placeholder={undefined}        >
          {itemE4slist.map((items, index) => {
            return (
              <IonSlide placeholder={undefined}>
                <IonList key={index} className="ion-no-padding height100" placeholder={undefined}>
                  <IonRow className="Practice leftrightspace ion-padding-horizontal" placeholder={undefined}>
                    <IonCol placeholder={undefined}>
                      <IonText placeholder={undefined}>{t("e4s.choose_correct_answer")}</IonText>
                    </IonCol>
                  </IonRow>
                  <IonRow className="Practice spacing ion-padding-horizontal" placeholder={undefined}>
                    <IonCol size="12" placeholder={undefined}>
                      {/* {index + 1}
                      {items?.Question?.[0].SoundUrl} */}
                      {showMessage ? (
                        <ReactAudioPlayer
                          className="String"
                          controls
                          controlsList="nodownload noplaybackrate"
                        >
                          <source
                            src={items?.Question}
                            type="audio/mpeg"
                          ></source>
                        </ReactAudioPlayer>
                      ) : (
                        ""
                      )}
                    </IonCol>
                  </IonRow>
                  <IonRow className="answer spacing ion-padding-horizontal" placeholder={undefined}>
                    {itemE4slist[index]?.Options?.map((datav, indexnew) => {
                      return (
                        <IonCol size="6" key={indexnew} placeholder={undefined}>
                          {/* <p>{datav.Word}-{indexnew}</p> */}
                          <IonCard
                            id={datav.questionno}
                            style={{
                              borderColor: datav.Word === items.RightWord &&
                                datav.WordPy === items.RightWordPy
                                ? "var(--ion-color-success)"
                                : datav.Word === items.Answer
                                  ? "#f63b12"
                                  : "",
                            }} placeholder={undefined}>
                            <IonCardContent class="ion-no-padding" placeholder={undefined}>
                              <div className="min184">
                                <IonText placeholder={undefined}>{datav.Word}</IonText>
                                <p>{datav.WordPy}</p>
                              </div>
                            </IonCardContent>
                          </IonCard>
                        </IonCol>
                      );
                    })}
                    <IonCol size="12" placeholder={undefined}>
                      {showImage && (
                        <IonImg
                          className="slide"
                          src="./assets/images/swipe-left-right.gif" placeholder={undefined}/>
                      )}
                      {/* <IonButton expand="block" onClick={() => gotonextques(items, showResults + 1, 1)}>Next</IonButton> */}
                    </IonCol>
                  </IonRow>
                </IonList>
              </IonSlide>
            );
          })}
        </IonSlides>
      </IonContent>
      {/* <IonContent className='listing'>
        {itemE4slist.map((items, index) => {
          return (
            <IonList key={index} style={{ display: showResults == index + 1 ? "block" : "none" }} className="ion-no-padding height100">
              <IonRow className='Practice ion-padding-horizontal'>
                <IonCol size='12'>
                  {showMessage ?
                    <ReactAudioPlayer className='String' controls controlsList="nodownload noplaybackrate">
                      <source src={items?.Question?.[0].SoundUrl} type="audio/mpeg"></source>
                    </ReactAudioPlayer> : ''}
                </IonCol>
              </IonRow>
              <IonRow className='answer ion-padding-horizontal'>
                {itemE4slist?.[index].Options?.map((datav, indexnew) => {
                  return (
                    <IonCol size='6' key={indexnew}>
                      <IonCard id={datav.questionno} style={{ borderColor: setRightAns && (items?.Question?.[0].soundno === datav.questionno) ? var(--ion-color-warning-shade)' : '' }} className={successOption && (selectedDiv === datav.questionno) ? 'answer-right' : wrongOption && (selectedDiv === datav.questionno) ? 'answer-wrong' : ''} onClick={() => handleQuestionCheckClick(items, index, showResults + 1, datav.questionno)} >
                        <IonCardContent class="ion-no-padding">
                          <div>
                            <IonText>{datav.Word} </IonText>
                          </div>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                  )
                })}
                <IonCol size='12'>
                  <IonButton expand="block" onClick={() => gotonextques(items, showResults + 1, 1)}>Next</IonButton>
                </IonCol>
              </IonRow>
            </IonList>
          )
        })}
      </IonContent> */}
      <Drawer
        className="bottompopup skateInBottom"
        isVisible={isVisible}
        onClose={onClose}
      >
        <IonRow placeholder={undefined}>
          <IonList className="list" placeholder={undefined}>
            {/* <IonItem class="ion-no-padding playbook">
              <div>
                <IonImg src="./assets/images/play.png" />
              </div>
              <div className="details ion-padding-start">
                <h4>Play book</h4>
                <span>Autoplay book audio</span>
              </div>
            </IonItem> */}
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("e4s.drawer.lesson_language")}</IonLabel>
              <div className="right-space">
                <IonText color="primary" placeholder={undefined}>簡</IonText>
                <IonText placeholder={undefined}>繁</IonText>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("e4s.drawer.chinese_characters")}</IonLabel>
              <IonToggle checked slot="end" placeholder={undefined}></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("e4s.drawer.pinyin")}</IonLabel>
              <IonToggle checked slot="end" placeholder={undefined}></IonToggle>
            </IonItem>
            {/* <IonItem class="ion-no-padding">
              <IonLabel>Translation</IonLabel>
              <IonToggle slot="end"></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding">
              <IonLabel>Font size</IonLabel>
              <IonImg src="./assets/images/Fontdown.png" />
              <IonImg src="./assets/images/Fontup.png" />
            </IonItem> */}
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("e4s.drawer.playback_speed")}</IonLabel>
              <div className="leftright">
                <a href="#">&lt;</a> 1.0 <a href="#">&gt;</a>
              </div>
            </IonItem>
            {/* <IonItem class="ion-no-padding">
              <IonLabel>Bookmark</IonLabel>
              <IonIcon color='primary' icon={bookmark} />
            </IonItem> */}
          </IonList>
        </IonRow>
      </Drawer>
    </IonPage>
  );
};
export default React.memo(E4sResultDetails);
