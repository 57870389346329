import React, { useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonText,
  IonCard,
  IonCardContent,
  IonList,
  IonCol,
  IonIcon,
  IonPage,
  IonTitle,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
  IonSkeletonText,
} from "@ionic/react";
import "./Dialoguedone.scss";
import {
  chevronBack,
  close,
  lockClosed,
  lockClosedOutline,
} from "ionicons/icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { CONFIG } from "../App";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface OwnProps extends RouteComponentProps {}

interface DialoguedoneProps extends OwnProps {}

const Dialoguedone: React.FC<DialoguedoneProps> = ({ history }) => {
  let url: string = CONFIG.API_ENDPOINT; //live

  const { t } = useTranslation();

  // const params = useParams<{id: string}>();
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any[]>([]);
  const [itemLessonslist, setLessonsitems] = React.useState<any>();
  const [showImage, setShowImage] = useState(false);
  debugger;
  const historyss = useHistory<{
    themeArray:any;
    index: string;
    dialogueId: string;
    themeId: string;
    themename: string;
    levelId: string;
    level: string;
    levname: string;
    backpage: string;
    title: string;
    videoUrl: string;
    role: string;
    allrole: string;
    rolename: string;
  }>();
  const themeArray = historyss.location.state
  ? historyss.location.state.themeArray
  : "";
  console.log(themeArray);  
  const index2 = historyss.location.state ? historyss.location.state.index : "";
  const dialogueId = historyss.location.state
    ? historyss.location.state.dialogueId
    : "";
  const themeIddb = historyss.location.state
    ? historyss.location.state.themeId
    : "";
  const themename = historyss.location.state
    ? historyss.location.state.themename
    : "";
  debugger;
  const levelId = historyss.location.state
    ? historyss.location.state.levelId
    : "";
  const level = historyss.location.state ? historyss.location.state.level : "";
  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";
  const backpage = historyss.location.state
    ? historyss.location.state.backpage
    : "";
  const title = historyss.location.state ? historyss.location.state.title : "";
  const videoUrl = historyss.location.state
    ? historyss.location.state.videoUrl
    : "";
  const role = historyss.location.state ? historyss.location.state.role : "";
  const allrole = historyss.location.state
    ? historyss.location.state.allrole
    : "";
  const rolename = historyss.location.state
    ? historyss.location.state.rolename
    : "";

  const string1 = String(localStorage.getItem("pagecode1"));
  const btn1 = string1.replace(/"/g, "");

  const string2 = String(localStorage.getItem("pagecode2"));
  const btn2 = string2.replace(/"/g, "");

  const Subscribe = async () => {
    history.push("/tabs/Subscribe", { direction: "none" });
  };
  let string = allrole;
  let one = "";
  let two = "";
  if (string) {
    let result = string.split(",");
    one = result[0];
    two = result[1];
  }

  let token = "";
  let resfreshtoken = "";

  useIonViewWillEnter(() => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    console.log(role);
    console.log(rolename);
    console.log(allrole);
    getthemes()
  });

  const getthemes = async () => {
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   duration: 1000,
    // });

    try {
      const response = await fetch(url + "/book/GetThemesAndRecLesson", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      // if (!response.ok) {
      //   throw Error(response.statusText)
      // }
      const json = await response.json();
      console.log(json);
      localStorage.setItem("ThemesAndRecLesson", JSON.stringify(json))
      setShowImage(true);
      if (json.StatusCode == 1) {
        setLessonsitems(json.Result.Lessons);
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const refreshtoken = async () => {
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   //duration: 3000
    // });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      console.log(json);

      if (json.StatusCode == 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <div className="close">
              <IonIcon onClick={onClose} icon={close} placeholder={undefined} />
            </div>
            <div className="icon">
              <IonIcon icon={lockClosed} placeholder={undefined} />
            </div>
            <p>{t("dialoguedone.submit.subscribe_to_unlock")}</p>
            <div className="react-confirm-alert-button-group">
              <IonButton
                onClick={() => {
                  Subscribe();
                  onClose();
                } }
                className="alertcancel w-150" placeholder={undefined}>
                {t("dialoguedone.submit.subscribe")}
              </IonButton>
            </div>
          </div>
        );
      },
    });
  };
  const submit2 = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <p className="mt0">{t("dialoguedone.submit2.message")}</p>
            <div className="react-confirm-alert-button-group">
              <div
                onClick={() => {
                  goDialoguepractice(one, 0);
                  onClose();
                }}
                className="usercircle"
              >
                {one}
              </div>
              <div
                onClick={() => {
                  goDialoguepractice(two, 1);
                  onClose();
                }}
                className="usercircle"
              >
                {two}
              </div>
            </div>
          </div>
        );
      },
    });
  };

  const goDialogueresult = () => {
    history.replace({
      pathname: "/tabs/home/Dialogueresult",
      state: {
        themeArray:themeArray,
        videoUrl: videoUrl,
        index: index2,
        themeId: themeIddb,
        themename: themename,
        levelId: levelId,
        level: level,
        levname: levname,
        dialogueId: dialogueId,
        rolename: rolename,
        role: role,
        allrole: one + "," + two,
        backpage: backpage,
      },
    });
  };

  const goDialoguepractice = (rolename, index) => {
    // presenttost({
    //   message: 'Practices not available',
    //   duration: 1500,
    //   position: 'bottom'
    // });

    history.replace({
      pathname: "/tabs/home/Dialoguepractice",
      state: {
        themeArray:themeArray,
        videoUrl: videoUrl,
        index: index2,
        themeId: themeIddb,
        themename: themename,
        levelId: levelId,
        level: level,
        levname: levname,
        dialogueId: dialogueId,
        role: index,
        rolename: rolename,
        allrole: one + "," + two,
        backpage: "Dialoguedone",
      },
    });
  };

  const back = () => {
    if (btn2 === "Practice again") {
      history.replace({
        pathname: "/tabs/home/Dialoguepractice",
        state: {
          themeArray:themeArray,
          index: index2,
          themeId: themeIddb,
          themename: themename,
          levelId: levelId,
          level: level,
          levname: levname,
          dialogueId: dialogueId,
          rolename: rolename,
          role: role,
          allrole: one + "," + two,
          videoUrl: videoUrl,
          backpage: backpage,
        },
      });
    } else {
      history.replace({
        pathname: "/tabs/home/Dialoguevideo",
        state: {
          themeArray:themeArray,
          index: index2,
          themeId: themeIddb,
          themename: themename,
          levelId: levelId,
          level: level,
          levname: levname,
          dialogueId: dialogueId,
          rolename: rolename,
          role: role,
          allrole: one + "," + two,
          videoUrl: videoUrl,
        },
      });
      localStorage.removeItem("pagecode1");
      localStorage.removeItem("pagecode1");
    }
  };
  return (
    <IonPage className="intro" placeholder={undefined}>
      <IonHeader className="ion-text-center dubbletitle" placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <div>
            <IonButtons slot="start" placeholder={undefined}>
              <IonButton color="primary" onClick={back} placeholder={undefined}>
                <IonIcon icon={chevronBack} placeholder={undefined} />
              </IonButton>
            </IonButtons>
            <IonTitle color="primary" className="font20 ion-no-padding" placeholder={undefined}>
              <span>
                {t("dialoguedone.dialogue")} : {t("dialoguedone.practice")} <p className="ion-no-margin">{themeArray[index2]?.ContentEn}</p>
              </span>
            </IonTitle>
            <IonButtons slot="end" placeholder={undefined}>
              {/* <IonButton color='primary'>
                <IonIcon icon={ellipsisVertical} />
              </IonButton> */}
            </IonButtons>
          </div>
        </IonToolbar>
      </IonHeader>

      <IonContent className="listing ion-padding-horizontal" placeholder={undefined}>
        <IonList className="completed ion-text-center" placeholder={undefined}>
          <IonCol size="12" placeholder={undefined}>
            <img src="./assets/images/Dialogue.png" alt="" />
            <p>{t("dialoguedone.practice_completed")}</p>
          </IonCol>
          <IonCol size="12" placeholder={undefined}>
            {/* <IonButton color="primary" onClick={submit2} expand='block'>See Result</IonButton>
            <IonButton expand='full' shape="round" onClick={back} className='blueborder'>Redo Practice</IonButton>  */}
            <IonButton
              onClick={btn1 === "Practice" ? submit2 : goDialogueresult}
              expand="block" placeholder={undefined}>
              {btn1}
            </IonButton>
            <IonButton
              expand="full"
              shape="round"
              onClick={back}
              className="blueborder" placeholder={undefined}>
              {btn2}
            </IonButton>
          </IonCol>
        </IonList>

        {/* <IonList className="themes bordertop" placeholder={undefined}>
          <IonCol placeholder={undefined}>
            <IonText placeholder={undefined}>{t("dialoguedone.check_this_out")}</IonText>
          </IonCol>
        </IonList> */}

        {itemLessonslist !== null ? (<></>):(<>
          <IonList className="themes bordertop" placeholder={undefined}>
            <IonCol placeholder={undefined}>
              <IonText placeholder={undefined}>{t("dialoguedone.check_this_out")}</IonText>
            </IonCol>
          </IonList>

          {itemLessonslist.length === 0 ? (
                  <IonSkeletonText animated style={{ width: "100%", height: "138px", margin: "0px" }} placeholder={undefined} />
                ) : (
                  <>
                    {itemLessonslist.map((items, index) => {
                      return (
                        // onClick={() => goLessonPage(items, index)}
                        <IonCard key={index}  placeholder={undefined}>
                          {showImage ? (
                            <div className="imglist">
                              <img src={items.ImageUrl} alt="Silhouette of mountains" />
                            </div>
                          ) : (
                            <IonSkeletonText animated style={{ width: "100%", height: "138px", margin: "0px" }} placeholder={undefined} />
                          )}
                          <IonCardContent placeholder={undefined}>
                            <span>{items.Title_tc}</span>
                          </IonCardContent>
                        </IonCard>
                      );
                    })}
                  </>
        )}
        </>)}

        {/* <IonList className="recommended-listing checkout" placeholder={undefined}>
          <IonCard placeholder={undefined}>
            <div className="overlay" onClick={submit}></div>
            <IonIcon className="lock" icon={lockClosedOutline} placeholder={undefined} />
            <div className="imglist">
              <img src="./assets/images/img1.png" alt="" />
            </div>
            <IonCardContent placeholder={undefined}>{t("dialoguedone.dialogue")}</IonCardContent>
          </IonCard>
          <IonCard placeholder={undefined}>
            <div className="overlay" onClick={submit}></div>
            <IonIcon className="lock" icon={lockClosedOutline} placeholder={undefined} />
            <div className="imglist">
              <img src="./assets/images/img2.png" alt="" />
            </div>
            <IonCardContent placeholder={undefined}>{t("dialoguedone.lesson")}</IonCardContent>
          </IonCard>
        </IonList> */}
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Dialoguedone);
