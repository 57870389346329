import React from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonList,
  IonCol,
  IonIcon,
  IonPage,
  IonTitle,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
} from "@ionic/react";
import "./Vocabcompleted.scss";
import { chevronBack, close, lockClosed } from "ionicons/icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { CONFIG } from "../App";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface OwnProps extends RouteComponentProps {}

interface VocabcompletedProps extends OwnProps {}

const Vocabcompleted: React.FC<VocabcompletedProps> = ({ history }) => {
  let url: string = CONFIG.API_ENDPOINT; //live

  const { t } = useTranslation();

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any[]>([]);

  const historyss = useHistory<{ levelIdtype: string }>();
  const levelId = historyss.location.state
    ? historyss.location.state.levelIdtype
    : "";
  debugger;
  let token = "";
  let resfreshtoken = "";

  useIonViewWillEnter(() => {
    //setMenuEnabled(false);
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
  });

  const Subscribe = async () => {
    history.push("/tabs/Subscribe", { direction: "none" });
  };
  const readagain = async () => {
    history.replace({
      pathname: "/tabs/Practicevocab",
      state: { levelIdtype: levelId },
    });

    // history.push({
    //   pathname: '/tabs/home/Practicequestion',
    //   state: { themeId: themeId, lessonname: lessonname, index: index, lessonsId: lessonsId, themename: themename, levelId: levelId, level: level, levname: levname }
    // })
    history.go(0);
    // window.location.reload();
  };
  const goBack = () => {
    history.push({
      pathname: "/tabs/progress/Vocabularybank",
      state: { levelIdtype: levelId },
    });

    history.go(0);
    // window.location.reload();
  };

  const Seeresult = async () => {
    //tabs/home/Practiceresult
    history.push({
      pathname: "/tabs/home/Practiceresult",
      state: { levelIdtype: levelId },
    });
    history.go(0);
    // window.location.reload();
  };

  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <div className="close">
              <IonIcon onClick={onClose} icon={close} placeholder={undefined} />
            </div>
            <div className="icon">
              <IonIcon icon={lockClosed} placeholder={undefined} />
            </div>
            <p>{t("vocabcompleted.submit.sub_to_unlk")}</p>
            <div className="react-confirm-alert-button-group">
              <IonButton
                onClick={() => {
                  Subscribe();
                  onClose();
                } }
                className="alertcancel w-150" placeholder={undefined}>
                {t("vocabcompleted.submit.subscribe_btn")}
              </IonButton>
            </div>
          </div>
        );
      },
    });
  };
  const submit1 = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <div className="close">
              <IonIcon onClick={onClose} icon={close} placeholder={undefined} />
            </div>
            <div className="icon">
              <IonIcon icon={lockClosed} placeholder={undefined} />
            </div>
            <p>{t("vocabcompleted.submit.sub_to_unlk")}</p>
            <div className="react-confirm-alert-button-group">
              <IonButton
                onClick={() => {
                  Subscribe();
                  onClose();
                } }
                className="alertcancel w-150" placeholder={undefined}>
                {t("vocabcompleted.submit.subscribe_btn")}
              </IonButton>
            </div>
          </div>
        );
      },
    });
  };
  return (
    <IonPage className="intro" placeholder={undefined}>
      <IonHeader className="ion-text-center" placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <IonButtons slot="start" placeholder={undefined}>
            <IonButton color="primary" onClick={goBack} placeholder={undefined}>
              <IonIcon icon={chevronBack} placeholder={undefined} />
            </IonButton>
          </IonButtons>
          <IonTitle color="primary" className="font20 ion-no-padding" placeholder={undefined}>
            <span>{t("vocabcompleted.vocab")} {levelId}: {t("vocabcompleted.practice")}</span>
          </IonTitle>
          <IonButtons slot="end" placeholder={undefined}>
            {/* <IonButton color='primary'>
              <IonIcon icon={ellipsisVertical} />
            </IonButton> */}
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="listing ion-padding-horizontal" placeholder={undefined}>
        <IonList className="completed ion-text-center" placeholder={undefined}>
          <IonCol size="12" placeholder={undefined}>
            <img src="./assets/images/Practicecompleted.png" alt="" />
            <p>{t("vocabcompleted.comp_msg")}</p>
          </IonCol>
          <IonCol size="12" placeholder={undefined}>
            {/* <div className='rightside'>
              <IonIcon onClick={submit} icon={lockClosedOutline} />
              <IonButton color="primary" onClick={Seeresult} expand='block'>See result</IonButton>
            </div> */}
            <IonButton
              expand="full"
              onClick={readagain}
              shape="round"
              className="blueborder" placeholder={undefined}            >
              {t("vocabcompleted.read_again_btn")}
            </IonButton>
          </IonCol>
        </IonList>

        {/* <IonList className="themes bordertop">
          <IonCol>
            <IonText>Check this out</IonText>
          </IonCol>
        </IonList>

        <IonList className='recommended-listing checkout'>
          <IonCard>
            <div className='imglist'><img src="./assets/images/unicone.jpg" alt="" /></div>
            <IonCardContent>
              Lesson
            </IonCardContent>
          </IonCard>
          <IonCard>
            <div className='overlay' onClick={submit1}>
            </div>
            <IonIcon className='lock' icon={lockClosedOutline} />
            <div className='imglist'><img src="./assets/images/girl.jpg" alt="" /></div>
            <IonCardContent>
              Flashcard
            </IonCardContent>
          </IonCard>
        </IonList> */}
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Vocabcompleted);
