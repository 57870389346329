import React, { useState } from 'react';
import { IonHeader, IonToolbar, IonButtons, IonButton, IonContent, IonRow, IonImg, IonText, IonCol, IonCard, IonCardContent, IonList, IonIcon, IonPage, IonTitle, useIonLoading, useIonToast, useIonViewWillEnter } from '@ionic/react';
import './Adjustmylevel.scss';
import { chevronBack, close } from 'ionicons/icons';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { CONFIG } from '../App';
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface AdjustmylevelProps { }

const Adjustmylevel: React.FC<AdjustmylevelProps> = () => {
  const { t } = useTranslation();
  let url: string = CONFIG.API_ENDPOINT;//live
  const [activeIndex, setActiveIndex] = useState(1);
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any[]>([])
  const [itemlistremcount, setitemsRemCount] = React.useState<any>([])
  const [itemprofilelist, setprofileitems] = React.useState<any>({})

  let token = ''
  let resfreshtoken = ''
  let rap
  useIonViewWillEnter(() => {
    //setMenuEnabled(false);
    let currentUser = JSON.parse(localStorage.getItem('alltoken') || '{}');
    if (currentUser) {
      token = currentUser.JwtToken
      resfreshtoken = currentUser.RefreshToken
    }
    GetProfie()
    GetLevels()
    GetRemainBooksCount()
  });

  const handleClick = (index) => {
    setActiveIndex(index);
  }

  const GetProfie = async () => {
    present({
      message: '',
      cssClass: 'custom-spinner',
      duration: 1000
    })

    try {

      const response = await fetch(url + '/User/GetProfile', {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token
        },
        method: "GET",
      })


      const json = await response.json()
      console.log('Profice', json)


      dismiss()
      
      if (json.StatusCode === 1) {

        setprofileitems(json.Result)
        //debugger

      } else if (json.StatusCode === 2) {
        refreshtoken()
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: 'bottom'
        });
      }


    } catch (error) {
      console.error(error)
      dismiss()
    }
  }
  const GetLevels = async () => {
    present({
      message: '',
      cssClass: 'custom-spinner',
      duration: 1000
    })

    try {

      const response = await fetch(url + '/User/GetUserLevel', {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token
        },
        method: "GET",
      })


      const json = await response.json()
      console.log('GetLevels', json)


      dismiss()
      if (json.StatusCode === 1) {

        setitems(json.Result)
        handleClick(json.Result.Level)
        //debugger

      } else if (json.StatusCode === 2) {
        refreshtoken()
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: 'bottom'
        });
      }


    } catch (error) {
      console.error(error)
      dismiss()
    }
  }
  const saveContent = async () => {
    let currentUser = JSON.parse(localStorage.getItem('alltoken') || '{}');
    if (currentUser) {
      token = currentUser.JwtToken
      resfreshtoken = currentUser.RefreshToken
    }
    // present({
    //   message: '',
    //   //duration: 3000
    // })
    const userData:any = localStorage.getItem("userlevel")
    const userConData = JSON.parse(userData)
    console.log(userConData);    

    const data = {
      "oldLevel": itemlist['Level'],
      "newLevel": activeIndex,
    }
    try {
      const response = await fetch(url + '/User/SaveLevel', {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token
        },
        method: "POST",
      })

      const json = await response.json()
      console.log(json)
      GetLevels()
      //dismiss()

      if (json.StatusCode === 1) {

        localStorage.setItem("userlevel", JSON.stringify({
          Description: userConData.Description,
          Level: activeIndex,
          LevelGuid: userConData.LevelGuid,
          LevelId: userConData.LevelId,
          Name: userConData.Name,
        }));
        // history.push('/login');

      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: 'bottom'
        });
      }
      // dismiss()
    } catch (error) {
      console.error(error)
      // dismiss()
    }

  }
  const GetRemainBooksCount = async () => {
    present({
      message: '',
      cssClass: 'custom-spinner',
      duration: 1000
    })

    try {

      const response = await fetch(url + '/Book/GetRemainBooksCount', {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token
        },
        method: "GET",
      })


      const json = await response.json()
      console.log('GetRemainBooksCount', json)


      dismiss()
      if (json.StatusCode === 1) {

        setitemsRemCount(json.Result.RemainBooksCount)
        //debugger

      } else if (json.StatusCode === 2) {
        refreshtoken()
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: 'bottom'
        });
      }


    } catch (error) {
      console.error(error)
      dismiss()
    }
  }

  const refreshtoken = async () => {
    present({
      message: '',
      cssClass: 'custom-spinner',
      //duration: 3000
    })
    const data = { refreshToken: resfreshtoken }
    try {
      const response = await fetch(url + '/account/refreshtoken', {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      })
      if (!response.ok) {
        throw Error(response.statusText)
      }
      const json = await response.json()
      console.log(json)

      if (json.StatusCode === 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken
        }
        localStorage.setItem('alltoken', JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: 'bottom'
        });
      }

      dismiss()

    } catch (error) {
      console.error(error)
      dismiss()
    }
  }
  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <div className='close'><IonIcon onClick={onClose} icon={close} placeholder={undefined} /></div>
            <div className='imgview'><IonImg src="./assets/images/bicycle.svg" placeholder={undefined} /></div>
            <p>Level 2: Advanced Beginner</p>
            <p className='subtext popuppadding'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          </div>
        );
      }
    });
  };
  return (
    // {t("adjustmylevel")}
    <IonPage className='intro' placeholder={undefined}>
      <IonHeader className='ion-text-center' placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <IonButtons slot="start" placeholder={undefined}>
            <IonButton href='/tabs/profile/currentlevel' color='primary' placeholder={undefined}>
              <IonIcon icon={chevronBack} placeholder={undefined} />
            </IonButton>
          </IonButtons>
          <IonTitle color='primary' className='font20 ion-no-padding' placeholder={undefined}><span>{t("adjustmylevel.adj_my_level")}</span></IonTitle>
          <IonButtons slot="end" placeholder={undefined}>
            {/* <IonButton color='success'>
              <IonIcon icon={ellipsisVertical} />
            </IonButton> */}
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className='listing' placeholder={undefined}>
        <IonList className="ion-padding-horizontal adjust Subscription" placeholder={undefined}>
          <IonCol placeholder={undefined}>
            <IonText placeholder={undefined}>{itemprofilelist?.Name}, {t("adjustmylevel.you_are_now_at_level")}</IonText>
          </IonCol>
          <IonCard className='adjectcard' placeholder={undefined}>
            <IonCardContent class="ion-no-padding" placeholder={undefined}>
              <CircularProgressbar strokeWidth={10} text={itemlist['Level']} value={70}
                styles={{
                  background: { fill: "var(--ion-color-primary-tint)", textAlign: "center" },
                  path: { stroke: "var(--ion-color-primary)" },
                  trail: { stroke: "var(--ion-color-primary-tint)" },
                  text: { fill: 'var(--ion-color-primary)', textAlign: 'center', position: 'absolute', verticalAlign: 'middle', lineHeight: '50', fontSize: '52px' },
                }} />
              {/* <div className="lessons_score donut-chart">
                <div className="slice one"></div>
                <div className="slice two"></div>
                <div className="chart-center"><span>2</span></div>
              </div> */}
              <div className='name'>
                <h5>{itemlist['Name']}</h5>
                <span>{itemlistremcount} {t("adjustmylevel.more_lessons_to_next_level")} </span>
              </div>
              {/* <IonIcon onClick={submit} color='primary' icon={informationCircleOutline} /> */}
              <IonImg onClick={submit} src="./assets/images/bang-circle-blue.svg" alt="" placeholder={undefined} />
            </IonCardContent>
          </IonCard>
          <IonCol placeholder={undefined}>
            <IonText placeholder={undefined}>{t("adjustmylevel.drag_to_adj_level_manually")}</IonText>
          </IonCol>

          <IonCard className='adjectcard' placeholder={undefined}>
            <IonCardContent class="ion-no-padding" placeholder={undefined}>
              <div className='processline'>
                <div className='numbers one' onClick={() => handleClick(1)} >
                  <div className='number-border'>
                    <span className='dott'></span>
                  </div>
                  {activeIndex === 1 ?
                  <div className='number-border start'>
                    <span className='number-dott'>1</span>
                    <IonText placeholder={undefined}>{t("adjustmylevel.beginner")}</IonText>
                  </div>: ''}
                </div>
                
                <div className='numbers two' onClick={() => handleClick(2)} >
                  <div className='number-border'>
                    <span className='dott'></span>
                  </div>
                  {activeIndex === 2 ?
                  <div className='number-border start'>
                    <span className='number-dott'>2</span>
                    <IonText placeholder={undefined}>{t("adjustmylevel.advanced_beginner")}</IonText>
                  </div>: ''}
                </div>

                <div className='numbers three' onClick={() => handleClick(3)} >
                  <div className='number-border'>
                    <span className='dott'></span>
                  </div>
                  {activeIndex === 3 ?
                  <div className='number-border start'>
                    <span className='number-dott'>3</span>
                    <IonText placeholder={undefined}>{t("adjustmylevel.novice")}</IonText>
                  </div>: ''}
                </div>

                <div className='numbers four' onClick={() => handleClick(4)} >
                  <div className='number-border'>
                    <span className='dott'></span>
                  </div>
                  {activeIndex === 4 ?
                  <div className='number-border start'>
                    <span className='number-dott'>4</span>
                    <IonText placeholder={undefined}>{t("adjustmylevel.intermediate")}</IonText>
                  </div>: ''}
                </div>

                <div className='numbers five' onClick={() => handleClick(5)} >
                  <div className='number-border'>
                    <span className='dott'></span>
                  </div>
                  {activeIndex === 5 ?
                  <div className='number-border start'>
                    <span className='number-dott'>5</span>
                    <IonText placeholder={undefined}>{t("adjustmylevel.proficient")}</IonText>
                  </div>: ''}
                </div>

                {/* <div className='numbers'>
                  <div className='number-border'>
                    <span className='number-dott'>2 </span>
                  </div>
                  <IonText>Advanced Beginner</IonText>
                </div> */}
              </div>
            </IonCardContent>
          </IonCard>
        </IonList>

        <IonRow placeholder={undefined}>
          <IonCol size="12" className='levelsave ion-text-center' placeholder={undefined}>
            <IonButton onClick={saveContent} color="warning" placeholder={undefined}>{t("adjustmylevel.save")}</IonButton>
          </IonCol>
        </IonRow>
      </IonContent>

    </IonPage>
  );
};

export default React.memo(Adjustmylevel);