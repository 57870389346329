import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonToggle,
  IonCol,
  IonText,
  IonCard,
  IonCardContent,
  IonRow,
  IonPage,
  useIonViewWillEnter,
  useIonLoading,
  useIonToast,
  IonSlides,
  IonSlide,
  IonImg,
  IonSpinner,
} from "@ionic/react";
import "./E5s.scss";
import Drawer from "react-bottom-drawer";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { CONFIG } from "../App";
import HanziWriter from "hanzi-writer";
import ReactAudioPlayer from "react-audio-player";
import useMediaRecorder from "@wmik/use-media-recorder";
import { RouteComponentProps, useHistory } from "react-router";
import { json } from "stream/consumers";
import { string } from "yup";
import "swiper/swiper.min.css";
import "@ionic/react/css/ionic-swiper.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

function Player({ srcBlob }: { srcBlob: any }) {
  //console.log('srcBlob', srcBlob);
  if (!srcBlob) {
    return null;
  } else {
    return <audio src={URL.createObjectURL(srcBlob)} controls />;
  }
}
interface MyObject {
  id: number;
  name: string;
  type: "e5s";
  pagecouter: number;
  totalque: number;
  back: number;
}
interface E5sProps {
  itemE5s: any;
  onObjectChange: (object: MyObject) => void;
  lastSlide : any;
  cheChar:any;
  piniyin:any;
}

const E5s: React.FC<E5sProps> = (props: E5sProps) => {
  let url: string = CONFIG.API_ENDPOINT; //live

  const { t } = useTranslation();

  let tmscorefinal: any;
  const [isVisible, setIsVisible] = React.useState(false);
  const openDrawer = React.useCallback(() => setIsVisible(true), []);
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [showLoading, setShowLoading] = useState(false);

  const [showResults, setShowResults] = React.useState(1);
  const [progress, setProgress] = useState(0);
  const [isApicall, setApicall] = useState(0);
  const [length, setLength] = React.useState(-1);
  //CompareSentences
  const [sentence1, setSentence1] = useState("");
  const [sentence2, setSentence2] = useState("");

  const words1 = sentence1.split(" ");
  const words2 = sentence2.split(" ");
  const [showImage, setShowImage] = useState(false);
  const [myanswer, setmyanswer] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [ansIndex, setAnsSlideIndex] = useState(0);

  const [seconds, setSeconds] = useState(1);
  const [isRunning, setIsRunning] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);

  // DnD Start

  // Sample data
  const initialList1 = ["請", "問", "您", "想", "喝", "什", "麼", "？"];
  const initialList2 = [];

  const [list1, setList1] = useState<any>(initialList1);
  const [list2, setList2] = useState<any>(initialList2);

  // DnD End

  const notMatchingWords = words1
    .filter((word) => !words2.includes(word))
    .concat(words2.filter((word) => !words1.includes(word)));
  //end
  const spanRef = useRef(null);
  let lastValue : any;
  let token = "";
  let resfreshtoken = "";
  let pagecouter: any = 0;
  let totalque: any = 0;
  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);
  useIonViewWillEnter(() => {
    setLength(1);
    console.log("length nw", props.itemE5s.length);
    slidesRef.current?.getActiveIndex().then((index) => {
      console.log("index", index + 1);
      console.log("length", props.itemE5s.length);
      if (props.itemE5s.length == index + 1) {
        console.log("Done");
        // setLength(-1)
        // saveContent()
        // handleChange()
      }
    });

    setShowResults(1);
  });
  function handleChange(back) {
    const myObject: MyObject = {
      id: 1,
      name: "My Object",
      type: "e5s",
      pagecouter: pagecouter,
      totalque: props.itemE5s.length,
      back: back,
    };
    props.onObjectChange(myObject);
  }
  const slidesRef = useRef<HTMLIonSlidesElement>(null);

  const options = {
    keyboard: true,
  };
  const onDataAvailable = (data) => {
    // Update your progress bar using the data.size property
    const progress = setProgress((prevProgress) => prevProgress + data.size);
  };

  // const { startRecording, stopRecording } = useMediaRecorder({ onDataAvailable });

  let { error, status, mediaBlob, stopRecording, startRecording } =
    useMediaRecorder({
      recordScreen: false,
      blobOptions: { type: "audio/webm" },
      mediaStreamConstraints: { audio: true, video: false },
      onDataAvailable,
    });

  const handleStartRecording = () => {
    setIsRunning(true);
    setProgress(0);
    setApicall(0);
    setIsRecording(true); // Set isRecording to true when recording starts
    startRecording();
    console.log("Start");
  };

  const handleStopRecording = () => {
    debugger
    setIsRunning(false);
    stopRecording();
    setIsRecording(false); // Set isRecording to false when recording stops
    console.log(mediaBlob);
    console.log(error);
    console.log("Stop");
    setIsSpinner(true)
    // setE4EV1(false)
    // setE5EV1(false)
    // setE6EV1(true)
  };

  const handleSlideTouchEnd = (event: CustomEvent) => {
    console.log("Slide touch end", event.detail);
  };

  //dbaudio
  function handleCheckword() {
    debugger;
    const spanElement = document.querySelector(".dbaudio");

    if (spanElement) {
      console.log("sentence1", spanElement.textContent);
      if (spanElement.textContent) {
        // setSentence1('hello my testing')
        setSentence1(spanElement.textContent);
      }

      console.log("sentence2", sentence2);
    } else {
      console.log('No span element found with class "dbaudio"');
    }
  }

  function blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => {
        const base64String = reader.result as string;
        resolve(base64String.split(",")[1]);
      };
      reader.onerror = () => {
        reject("Failed to convert blob to base64");
      };
    });
  }

  const convertaudiototext = async (audiobasebase64: any) => {
    // present({
    //   message: "",
    //   duration: 3000,
    // });
    let data = {
      base64String: audiobasebase64,
    };

    try {
      const response = await fetch(url + "/account/SpeechAudio", {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        body: JSON.stringify(data),
      });
      const json = await response.json();

      console.log(json);
      setmyanswer(json.result);
      // debugger
      if (json.statusCode == 1) {
        setSentence2("");
        setSentence2(json.result || "hello");

        handleCheckword();
        // status = 'idle'
      } else {
        // presenttost({
        //   message: json.Message,
        //   duration: 1500,
        //   position: 'bottom'
        // });
      }
      setApicall(1);
      // dismiss();
    } catch (error) {
      console.error(error);
      // dismiss();
    }
  };

  const handleQuestionCheckClick = (
    items: any,
    index: any,
    indexq: any,
    type
  ) => {
    // console.log(type)
    //console.log('soundno ', items?.Question?.[0].soundno)
    //console.log('questionno', type)
    // if (items?.Question?.[0].soundno == type) {
    //   isSuccess = true
    //   isWrong = false
    //   setSuccessOption(isSuccess);
    //   setWrongOption(isWrong);
    //   setSelectedDiv(type)
    //   console.log('q1', isSuccess)
    //   if (!props.itemE6EV1[index].answer) {
    //     props.itemE6EV1[index].answer = type
    //     props.itemE6EV1[index].score = true
    //     tmpscore += tmpscore
    //   }
    // } else {
    //   isSuccess = false
    //   isWrong = true
    //   setRightAnsOption(true)
    //   setSuccessOption(isSuccess);
    //   setWrongOption(isWrong);
    //   setSelectedDiv(type)
    //   if (!props.itemE6EV1[index].answer) {
    //     props.itemE6EV1[index].answer = type
    //     props.itemE6EV1[index].score = false
    //   }
    // }
    // console.log('itemE6EV1', props.itemE6EV1)
    //console.log('isSuccess', isSuccess)
    //console.log('isWrong', isWrong)
  };

  const gotonextques = async (items: any, index: any, type) => {
    //1 for Understand and 2= dont understand
    console.log(items);

    // console.log(type)
    // console.log('soundno ', items?.Question?.[0].soundno)
    // console.log('questionno', type)

    // if (items?.Question?.[0].soundno == type) {
    //   isSuccess = true
    //   isWrong = false
    // } else {
    //   isSuccess = false
    //   isWrong = true
    // }

    // console.log(showResults)
    //console.log(itemlist)
    //console.log(itemlist[showResults - 1])

    //  let data = itemlist[showResults - 1];
    //debugger
    if (items) {
      // resetstate()
      setShowResults(index);
      // console.log('len ', showResults - 1)
      setLength(index);

      // ansques = data.QuestionId + ',' + type;
      // setQuestion([...quesdata, ansques]);
    }
  };

  useEffect(() => {
    if (mediaBlob) {
      // present({
      //   message: "",
      //   duration: 3000,
      // });
      debugger;
      //convertaudiototextlive(mediaBlob)

      blobToBase64(mediaBlob)
        .then((base64String) => {
          // console.log('base64String', base64String);
          if (status === "stopped" && isApicall == 0) {
            convertaudiototext(base64String);
            // dismiss();
          }
          // dismiss();
        })
        .catch((error) => {
          console.error(error);
          // dismiss();
        });
      // dismiss();
    }
    // dismiss();
  }, [mediaBlob]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowImage(true);
      // Calculate the index of the next slide
      if (mediaBlob === null && sentence2 === null) {
        const nextSlideIndex = activeSlideIndex + 1;
        setActiveSlideIndex(nextSlideIndex);
      }
      // Call the slideTo method to swipe to the next slide
      // slidesRef.current?.slideTo(nextSlideIndex);
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [activeSlideIndex, props.itemE5s.length]);

  const handleSlideNextEnded = (event: CustomEvent<any>) => {
    console.log("Slide Next ended", event.detail);

    slidesRef.current?.getActiveIndex().then((index) => {
      console.log("index", index + 1);
      setLength(index + 1);
      console.log("length", props.itemE5s.length);
    });
  };

  const handleSlidePrevEnded = (event: CustomEvent<any>) => {
    console.log("Slide Prev ended", event.detail);
    // setLength(length - 1)
    slidesRef.current?.getActiveIndex().then((index) => {
      console.log("index", index + 1);
      setLength(index - 1);
      console.log("length", props.itemE5s.length);
    });
  };

  const handleSlideDidChanged = async (event: CustomEvent<any>) => {
    console.log("Slide Did ended", length);
    const swiper = await slidesRef.current?.getSwiper();
    if (swiper) {
      const swipeDirection = swiper.swipeDirection;
      const currentIndex = swiper.activeIndex;
      console.log("Swipe direction:", swipeDirection);

      if (swipeDirection == "prev") {
        //Back Login code
        setAnsSlideIndex(currentIndex);
        slidesRef.current?.getActiveIndex().then((index) => {
          console.log(index - 1);
          if (index - 1 === -1) {
            handleChange(1);
          }
        });
      } else {
        setAnsSlideIndex(currentIndex);
        slidesRef.current?.getActiveIndex().then((index) => {
          console.log("index", index + 1);

          SaveExerciseQueAnswer(length);
          if (index + 1 == length) {
            console.log("Go next page");
            setLength(-1);
            saveContent();
            handleChange(0);
          }
        });
      }
    }
  };

  const SaveExerciseQueAnswer = async (length) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }

    // let rigthans = '';
    // for (let index = 0; index < props.itemE4EV1.length; index++) {
    //   const element = props.itemE4EV1[index];
    //   for (let j = 0; j < element.Options.length; j++) {
    //     const elementans = element.Options[j];

    //     if (props.itemE4EV1[0]?.Question?.[0].soundno == elementans.questionno) {
    //       rigthans = elementans.Word;

    //     }
    //   }

    // }
    // console.log('rigthans', rigthans)
    // console.log('myanswer11', myanswer)
    const spanElement = document.querySelector(".dbaudio");

    console.log(spanElement);
    console.log(srcText);
    console.log(myanswer);
    // console.log(sentence1);

    let ans = {
      srcCompare: srcText ? srcText : "",
      destCompare: sentence2,
    };

    const stringWithBackslashes = JSON.stringify(ans);
    const stringWithoutBackslashes = stringWithBackslashes.replace(/\\/g, "");
    console.log(stringWithoutBackslashes); // Output: {"q1":"2","q2":"4","q3":"4"}
    // const data = {
    //   ev_id: props.itemE5s[length - 1].Ev_Id,
    //   templateCode: props.itemE5s[length - 1].TemplateCode,
    //   question: props.itemE5s[length - 1]?.SoundUrl,
    //   rightAnswer: stringWithoutBackslashes,
    //   answer: myanswer,
    // };
    const data = {
      eId: props.itemE5s[ansIndex].EId,
      eType: props.itemE5s[ansIndex].TemplateCode,
      answer: myanswer,
      rearrageAnswer: "", //only for rearrange exercise otherwise blank
      writingScore: 0
    };
    console.log(length);
    console.log("SaveExerciseQueAnswer_Audio", data);
    debugger;

    try {
      const response = await fetch(url + "/Book/SaveExerciseQueAnswer", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });

      const json = await response.json();
      console.log(json);
      debugger;
      if (json.StatusCode == 1) {
      } else {
        // presenttost({
        //   message: json.Message,
        //   duration: 1500,
        //   position: "bottom",
        // });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const saveContent = async () => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    // present({
    //   message: '',
    // })

    // let anw = {};
    // let tmscore = 0;
    // for (let index = 0; index < props.itemE5s.length; index++) {
    //   const element = props.itemE5s[index];
    //   const propName = ("q" + (index + 1)).toString();
    //   const propValue = element.answer;;

    //   anw[propName] = (propValue).toString();
    //   // finalanswer = anw
    //   if (element.score) { tmscore += element.score }

    // }
    // console.log('tmpscore', tmscore)
    pagecouter = 0;
    // const spanElement = document.querySelector(".dbaudio");

    let ans = {
      srcCompare: srcText ? srcText : "",
      destCompare: sentence2,
    };

    const stringWithBackslashes = JSON.stringify(ans);
    const stringWithoutBackslashes = stringWithBackslashes.replace(/\\/g, "");
    console.log(stringWithoutBackslashes); // Output: {"q1":"2","q2":"4","q3":"4"}
    const myArray = Object.entries(ans);
    console.log(myArray);
    // for (let k = 0; k < myArray.length; k++) {
    //   const element = myArray[k][1];
    //   if (element !='') {
    //     pagecouter++
    //   }

    // }

    if (myArray[0][1]) {
      pagecouter = 1;
    }
    console.log(pagecouter);

    const data = {
      contentPageCode: props.itemE5s[0]
        ? props.itemE5s[0].TemplateCode
        : props.itemE5s[length - 1].TemplateCode,
      type: "exercise-score",
      bookId: props.itemE5s[0].BookId,
      contentPageId: props.itemE5s[0]
        ? props.itemE5s[0].PageId
        : props.itemE5s[length - 1].PageId,
      answer: stringWithoutBackslashes,
      score: tmscorefinal,
      options: "",
      pageRead: pagecouter,
    };

    //dismiss()
    console.log(data);
    debugger;

    try {
      const response = await fetch(url + "/Book/SaveContent", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });

      const json = await response.json();
      console.log(json);
      //dismiss()
      debugger;
      if (json.StatusCode == 1) {
      } else {
        // presenttost({
        //   message: json.Message,
        //   duration: 1500,
        //   position: "bottom",
        // });
      }

      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };

  let srcText = "";
  const test_main = (text, textpy) => {
    srcText = text;
    const characters1 = text.split("");
    const charPy = textpy.split(" ");
    debugger;
    const result = characters1.map((text, i) => ({
      Text: text,
      Piniyan: charPy[i] || null,
    }));
    // const fragments = result.map((word, index) => (
    //   <React.Fragment key={index}>
    //     <li className={index === 0 ? "active" : ""}>
    //       <span className={"ion-text-center dbaudio"} id="top-center">
    //         {word["Text"]}
    //       </span>
    //     </li>{" "}
    //   </React.Fragment>
    // ));

    const fragments = result.map((word, index) => (
      <React.Fragment key={index}>
        <li className={index === 0 ? "active" : ""}>
          <span className={"ion-text-center dbaudio"} id="top-center">
            {props.cheChar && (word["Text"])}
          </span>
          <p>{props.piniyin && (word["Piniyan"])}</p>
        </li>{" "}
      </React.Fragment>
    ));
    return fragments;
  };

  const test = (text, textpy) => {
    // present({
    //   message: "",
    //   duration: 1000,
    // });
    // const text123 = "hello";
    // const test = "請問您想";

    const characters1 = text.split("");
    const characters2 = sentence2.split("");

    //  const sentencepy = textpy.split("");
    const charPy = textpy.split(" ");

    const spChar = [
      "！",
      "(",
      "?",
      "!",
      "[",
      "，",
      "。",
      ";",
      "：",
      "”",
      "”",
      "'",
      "'",
      "？",
      "（",
      "）",
      "、",
      "]",
      ")",
    ];
    debugger;
    const result = characters1.map((text, i) => ({
      Text: text,
      Piniyan: charPy[i] || null,
      status: spChar.includes(text) ? true : characters2.includes(text),
    }));
    console.log(result);

    // score count start

    const filteredChar1 = characters1.filter((char) => !spChar.includes(char));
    const charLength: number = filteredChar1.length;
    const charValue: number = 100 / charLength;
    const rouCharValue: number = parseFloat(charValue.toFixed(0));
    console.log(rouCharValue);

    const filteredResult = filteredChar1.map((text, i) => ({
      Text: text,
      status: characters2.includes(text),
    }));
    const trueStatusLength: number = filteredResult.filter(
      (item) => item.status === true
    ).length;
    console.log(trueStatusLength);

    const score: number = trueStatusLength * rouCharValue;

    console.log(score);
    tmscorefinal = score;

    // score count end

    // dismiss();
    const fragments = result.map((word, index) => (
      <React.Fragment key={index}>
        <li className={index === 0 ? "active" : ""}>
          <span
            className={
              word["status"] === false
                ? "ion-text-center red"
                : "ion-text-center"
            }
            id="top-center"
          >
            {props.cheChar && (word["Text"])}
          </span>
          <p className={word["status"] === false ? "wordpy red" : "wordpy"}>
          {props.piniyin && (word["Piniyan"])}
          </p>
        </li>{" "}
      </React.Fragment>
    ));
    return fragments;
  };

  useEffect(() => {
    let timer;

    if (isRunning) {
      timer = setInterval(() => {
        if (seconds < 60) {
          setSeconds((prevSeconds) => prevSeconds + 1);
        } else {
          clearInterval(timer);
          setIsRunning(false);
          handleStopRecording()
        }
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isRunning, seconds]);

  const percentage = (seconds / 60) * 100;

  return (
    <IonPage className="intro" placeholder={undefined}>
      <IonContent className="listing" placeholder={undefined}>
        <IonSlides
          pager={false}
          scrollbar={true}
          options={{
            initialSlide: props.lastSlide === 1 ? props.itemE5s.length - 1 : activeSlideIndex,
            options,
          }}
          onIonSlideTransitionEnd={handleSlideDidChanged}
          onIonSlideNextEnd={handleSlideNextEnded}
          onIonSlidePrevEnd={handleSlidePrevEnded}
          ref={slidesRef} placeholder={undefined}>
          {props.itemE5s.map((items, index) => {
            return (
              <IonSlide placeholder={undefined}>
                {props.itemE5s.map((itemse5, indexe5) => {
                  return (
                    <div
                      key={itemse5.EId}
                      style={{
                        display: showResults == indexe5 + 1 ? "block" : "none",
                      }}
                      className="audiorecord"
                    >
                      <IonList className="ion-no-padding bottomfix record" placeholder={undefined}>
                        <IonRow className="Practice spacing ion-padding-horizontal" placeholder={undefined}>
                          <IonCol placeholder={undefined}>
                            <IonText placeholder={undefined}>{t("e5s.record_sentence")}</IonText>
                          </IonCol>
                          <IonCol
                            size="12"
                            className="test-audio ion-text-left whitebg" placeholder={undefined}>
                            <ReactAudioPlayer
                              className="String"
                              controls
                              controlsList="nodownload noplaybackrate"
                            >
                              <source
                                src={itemse5?.AudioUrl}
                                type="audio/mpeg"
                              ></source>
                            </ReactAudioPlayer>
                          </IonCol>
                        </IonRow>

                        <IonRow className="answer spacing ion-padding-horizontal" placeholder={undefined}>
                          <IonCol size="12" placeholder={undefined}>
                            <IonCard className="select" placeholder={undefined}>
                              <IonCardContent className="Lessonpracticeaudio" placeholder={undefined}>
                                <ul className="lession-icon">
                                  {!sentence2
                                    ? test_main(itemse5.WordCh, itemse5.WordPy)
                                    : ""}

                                  {sentence2
                                    ? test(itemse5.WordCh, itemse5.WordPy)
                                    : ""}
                                </ul>
                              </IonCardContent>
                            </IonCard>
                          </IonCol>
                        </IonRow>
                      </IonList>
                      <IonList className="ion-text-center audio-bottom ion-padding-horizontal" placeholder={undefined}>
                        <IonRow placeholder={undefined}>
                          <IonCol size="12" placeholder={undefined}>
                            {showImage && (
                              <IonImg
                                className="slide"
                                src="./assets/images/swipe-left-right.gif" placeholder={undefined}                              />
                            )}
                          </IonCol>
                          <IonCol size="12" className="back-forward" placeholder={undefined}>
                            <div className="audio-record">
                              {/* {status == "stopped" ? (
                                <Player srcBlob={mediaBlob} />
                              ) : (
                                ""
                              )} */}
                              {/* {status} */}
                              {/* {status === 'stopped' || status === 'idle' ?
                                <span onClick={(e: any) => handleStartRecording()}><IonImg src="./assets/images/micCircle.svg" /></span> : ''}
                              {status == 'recording' ?
                                <span onClick={(e: any) => handleStopRecording()} ><IonImg src="./assets/images/stop.svg" /></span> : ''} */}
                              {/* <span><IonIcon icon={micCircleSharp} /></span> */}
                              {!sentence2 ? <>{!isSpinner?<>
                              <span onClick={(e: any) => { isRecording ? handleStopRecording() : handleStartRecording(); }} >
                                <IonImg src={`./assets/images/${isRecording ? "stop" : "micCircle"}.svg`} placeholder={undefined}/>
                                <div style={{ position: "absolute",  left: "0",right: "0", top: "-15px", margin: "0 auto", width: "150px" }}>
                                  {isRecording ?
                                  <CircularProgressbar
                                    value={percentage}
                                    // text={`${seconds}s`}
                                    styles={buildStyles({
                                      pathTransition: 'none',
                                      // textSize: '16px',
                                      // textColor: '#fff',
                                      trailColor: 'var(--ion-color-warning-contrast-rgb)',
                                      pathColor: 'var(--ion-color-warning)',
                                    })}
                                  />:""}
                                </div>
                              </span><p>{t("e5s.press_to_record")}</p></>
                              :
                              <IonSpinner name="crescent" style={{height:"70px",width:"70px",color:"var(--ion-color-warning)"}} />} 
                              
                              </> : ""}
                            </div>
                          </IonCol>
                          <IonCol size="12" placeholder={undefined}>
                            {/* <IonButton expand="block" onClick={() => gotonextques(itemse5, showResults + 1, 1)}>Next</IonButton> */}
                          </IonCol>
                        </IonRow>
                      </IonList>
                    </div>
                  );
                })}
              </IonSlide>
            );
          })}
        </IonSlides>
      </IonContent>
      <Drawer
        className="bottompopup skateInBottom"
        isVisible={isVisible}
        onClose={onClose}
      >
        <IonRow placeholder={undefined}>
          <IonList className="list" placeholder={undefined}>
            {/* <IonItem class="ion-no-padding playbook">
              <div>
                <IonImg src="./assets/images/play.png" />
              </div>
              <div className="details ion-padding-start">
                <h4>Play book</h4>
                <span>Autoplay book audio</span>
              </div>
            </IonItem> */}
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("e5s.drawer.lesson_language")}</IonLabel>
              <div className="right-space">
                <IonText color="primary" placeholder={undefined}>簡</IonText>
                <IonText placeholder={undefined}>繁</IonText>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("e5s.drawer.chinese_characters")}</IonLabel>
              <IonToggle checked slot="end" placeholder={undefined}></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("e5s.drawer.pinyin")}</IonLabel>
              <IonToggle checked slot="end" placeholder={undefined}></IonToggle>
            </IonItem>
            {/* <IonItem class="ion-no-padding">
              <IonLabel>Translation</IonLabel>
              <IonToggle slot="end"></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding">
              <IonLabel>Font size</IonLabel>
              <IonImg src="./assets/images/Fontdown.png" />
              <IonImg src="./assets/images/Fontup.png" />
            </IonItem> */}
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("e5s.drawer.playback_speed")}</IonLabel>
              <div className="leftright">
                <a href="#">&lt;</a> 1.0 <a href="#">&gt;</a>
              </div>
            </IonItem>
            {/* <IonItem class="ion-no-padding">
              <IonLabel>Bookmark</IonLabel>
              <IonIcon color='primary' icon={bookmark} />
            </IonItem> */}
          </IonList>
        </IonRow>
      </Drawer>
    </IonPage>
  );
};

export default React.memo(E5s);
