import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonItem,
  IonLabel,
  IonToggle,
  IonContent,
  IonList,
  IonText,
  IonRow,
  IonIcon,
  IonPage,
  IonTitle,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
  useIonViewDidLeave,
} from "@ionic/react";
import "./Practicevocab.scss";
import { chevronBack } from "ionicons/icons";
import Drawer from "react-bottom-drawer";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { CONFIG } from "../App";
import HanziWriter from "hanzi-writer";
import { RouteComponentProps, useHistory } from "react-router";
import E4ev1vocab from "./E4ev1vocab";
import E5ev1vocab from "./E5ev1vocab";
import E6ev1vocab from "./E6ev1vocab";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

function Player({ srcBlob }: { srcBlob: any }) {
  //console.log('srcBlob', srcBlob);
  if (!srcBlob) {
    return null;
  } else {
    return <audio src={URL.createObjectURL(srcBlob)} controls />;
  }
}
// setTimeout(() => {
//   var writer = HanziWriter.create('character-target-div', '蛋', {
//     width: 150,
//     height: 150,
//     showCharacter: false,
//     showOutline: false,
//     showHintAfterMisses: 1,
//     highlightOnComplete: false,
//     padding: 5
//   });
//   writer.quiz();
// }, 100);

interface OwnProps extends RouteComponentProps {}

interface PracticevocabProps extends OwnProps {
  isSuccess: boolean;
  isWrong: boolean;
}

interface IObjectKeys {
  [key: string]: string | number | boolean | null;
}
interface MyObject {
  id: number;
  name: string;
  type: string;
  pagecouter: number;
  totalque: number;
}

interface Answer extends IObjectKeys {
  q1: string | null;
  q2: string | null;
  q3: string | null;
}
interface Result extends IObjectKeys {
  q1: boolean | null;
  q2: boolean | null;
  q3: boolean | null;
  score: number | null;
}

const Practicevocab: React.FC<PracticevocabProps> = ({
  history,
  isSuccess,
  isWrong,
}) => {
  let url: string = CONFIG.API_ENDPOINT; //live

  const { t } = useTranslation();

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any[]>([]);

  const [itemE4EV1list, setE4EV1tems] = React.useState<any[]>([]);
  const [itemE5EV1list, setE5EV1tems] = React.useState<any[]>([]);
  const [itemE6EV1list, setE6EV1tems] = React.useState<any[]>([]);

  const [isitemE4EV1, setE4EV1] = useState<boolean | undefined>(true);
  const [isitemE5EV1, setE5EV1] = useState<boolean | undefined>(false);
  const [isitemE6EV1, setE6EV1] = useState<boolean | undefined>(false);

  const [lengthE4EV1, setLengthE4EV1] = React.useState(1);
  const [lengthE5EV1, setLengthE5EV1] = React.useState(1);
  const [lengthE6EV1, setLengthE6V1] = React.useState(1);

  const [sum, setSum] = useState(0);
  const [successOption, setSuccessOption] = useState<boolean | undefined>();
  const [wrongOption, setWrongOption] = useState<boolean | undefined>();
  const [setRightAns, setRightAnsOption] = useState<boolean | undefined>();
  const [selectedDiv, setSelectedDiv] = useState<any>(null);

  const [showMessage, setShowMessage] = useState(false);

  const [myValue, setMyValue] = useState("");
  const [myObject, setMyObject] = useState<MyObject>({
    id: 0,
    name: "",
    type: "",
    pagecouter: 0,
    totalque: 0,
  });

  const [sete4, setE4data] = useState({ total: 0, attendans: 0 });
  const [sete5, setE5data] = useState({ total: 0, attendans: 0 });
  const [sete6, setE6data] = useState({ total: 0, attendans: 0 });
  const myStateRef = useRef(sete6);

  let te4: any = "";
  let te5: any = "";
  let te6: any = "";
  async function handleMyObjectChange(newObject: MyObject) {
    console.log("newObject", newObject);
    console.log("itemE5EV1list.length", itemE5EV1list.length);
    console.log("itemE6EV1list.length", itemE6EV1list.length);
    setMyObject(newObject);
    setEXcApicall(1);

    debugger;
    if (
      newObject.type === "e4ev1" &&
      itemE4EV1list.length > 0 &&
      itemE5EV1list.length > 0
    ) {
      setE4EV1(false);
      setE5EV1(true);
      setE6EV1(false);
      te4 = newObject.pagecouter;
      setE4data({
        total: itemE4EV1list.length,
        attendans: newObject.pagecouter,
      });

      getExercise();
    } else if (
      newObject.type === "e5ev1" &&
      itemE5EV1list.length > 0 &&
      itemE6EV1list.length > 0
    ) {
      setE4EV1(false);
      setE5EV1(false);
      setE6EV1(true);
      te5 = newObject.pagecouter;
      setE5data({
        total: itemE5EV1list.length,
        attendans: newObject.pagecouter,
      });

      getExercise();
    } else if (
      newObject.type === "e6ev1" &&
      itemE6EV1list.length > 0 &&
      itemE5EV1list.length > 0
    ) {
      setE4EV1(false);
      setE5EV1(false);
      setE6EV1(false);

      te6 = newObject.pagecouter;
      setE6data({
        total: itemE6EV1list.length,
        attendans: newObject.pagecouter,
      });

      gopracticecompleted(newObject);

      getExercise();
    } else {
      setE4EV1(false);
      setE5EV1(false);
      setE6EV1(false);
      gopracticecompleted("");
    }

    debugger;
  }

  useEffect(() => {
    myStateRef.current = sete6;
    // debugger
  });

  const [isApicall, setApicall] = useState(0);
  const [isExcCall, setEXcApicall] = useState(0);

  const [showResults, setShowResults] = React.useState(1);
  const [length, setLength] = React.useState(-1);
  const [lengthque, setLengthQue] = React.useState(1);

  const result: Result = { q1: false, q2: false, q3: false, score: 0 };

  //CompareSentences
  const [sentence1, setSentence1] = useState("");
  const [sentence2, setSentence2] = useState("");

  const words1 = sentence1.split(" ");
  const words2 = sentence2.split(" ");

  const notMatchingWords = words1
    .filter((word) => !words2.includes(word))
    .concat(words2.filter((word) => !words1.includes(word)));
  //end

  const spanRef = useRef(null);
  const historyss = useHistory<{
    themeId: string;
    lessonsId: string;
    index: string;
    lessonname: string;
    themename: string;
    levelId: string;
    level: string;
    levname: string;
    backpage: string;
    levelIdtype: string;
  }>();
  const levelIdtype = historyss.location.state
    ? historyss.location.state.levelIdtype
    : "";

  const themeId = historyss.location.state
    ? historyss.location.state.themeId
    : "";
  const lessonsId = historyss.location.state
    ? historyss.location.state.lessonsId
    : "";
  const index = historyss.location.state ? historyss.location.state.index : "";
  const lessonname = historyss.location.state
    ? historyss.location.state.lessonname
    : "";

  const themename = historyss.location.state
    ? historyss.location.state.themename
    : "";
  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";
  const levelId = historyss.location.state
    ? historyss.location.state.levelId
    : "";
  const level = historyss.location.state ? historyss.location.state.level : "";
  const backpage = historyss.location.state
    ? historyss.location.state.backpage
    : "";

  const [isVisible, setIsVisible] = React.useState(false);
  const openDrawer = React.useCallback(() => setIsVisible(true), []);
  let token = "";
  let resfreshtoken = "";

  useIonViewWillEnter(() => {
    //setMenuEnabled(false);
    // mediaBlob = null
    // status = 'idle';
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    setLength(-1);
    setShowResults(1);
    setApicall(0);
    //  const history = useHistory<any>();
    //     console.log('isExcCall', isExcCall)
    // debugger

    getExercise();
  });

  const getExercise = async () => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");

    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 1000,
    });
    console.log(lessonsId);

    try {
      const response = await fetch(
        url + "/Book/GetExercise?lessonGuid=" + levelIdtype,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        }
      );

      const json = await response.json();
      //console.log('GetExercise ', json)
      // debugger
      dismiss();
      if (json.StatusCode == 1) {
        // setitems(json.Result.E4ev1s)
        // setLengthQue(json.Result.length)

        // if (json.Result.E4ev1s.length > 0) {
        //   setE4EV1(true)
        //   setE5EV1(false)
        //   setE6EV1(false)
        // } else if (json.Result.E5ev1s.length > 0) {
        //   setE4EV1(false)
        //   setE5EV1(true)
        //   setE6EV1(false)
        // } else if (json.Result.E6ev1s.length > 0) {
        //   setE4EV1(false)
        //   setE5EV1(false)
        //   setE6EV1(true)
        // }

        setE4EV1tems(json.Result.E4ev1s);
        setLengthE4EV1(json.Result.E4ev1s.length);

        setE5EV1tems(json.Result.E5ev1s);
        setLengthE5EV1(json.Result.E5ev1s.length);

        // setE6EV1tems(json.Result.E6ev1s)
        setLengthE6V1(json.Result.E6ev1s.length);

        setLengthQue(3);
        // let questionArray = getArrayShuffle(itemlist);
        // setitems(questionArray)

        //Logic E4ev1s
        let tmpoptione4ev1s: any = [];
        //Live
        // let resulte4ev1s = itemlist
        let resulte4ev1s = json.Result.E4ev1s;
        console.log(result);

        resulte4ev1s.forEach((axq: any[], indexq: number) => {
          //console.log('Option', result[indexq].Word1)
          let qq = [
            {
              Word: resulte4ev1s[indexq].Word1,
              questionno: 1,
            },
            {
              Word: resulte4ev1s[indexq].Word2,
              questionno: 2,
            },
            {
              Word: resulte4ev1s[indexq].Word3,
              questionno: 3,
            },
            {
              Word: resulte4ev1s[indexq].Word4,
              questionno: 4,
            },
          ];
          const random_o = qq
            .map((a) => ({ sort: Math.random(), value: a }))
            .sort((a, b) => a.sort - b.sort)
            .map((a) => a.value);

          // console.log(random, qq);

          let sq = [
            {
              SoundUrl: resulte4ev1s[indexq].SoundUrl1,
              soundno: 1,
            },
            {
              SoundUrl: resulte4ev1s[indexq].SoundUrl2,
              soundno: 2,
            },
            {
              SoundUrl: resulte4ev1s[indexq].SoundUrl3,
              soundno: 3,
            },
            {
              SoundUrl: resulte4ev1s[indexq].SoundUrl4,
              soundno: 4,
            },
          ];

          const random_q = sq
            .map((a) => ({ sort: Math.random(), value: a }))
            .sort((a, b) => a.sort - b.sort)
            .map((a) => a.value);

          let final = {
            Ev_Id: resulte4ev1s[indexq].Ev_Id,
            Ev_guid: resulte4ev1s[indexq].Ev_guid,
            TemplateCode: resulte4ev1s[indexq].TemplateCode,
            Page_id: resulte4ev1s[indexq].Page_id,
            Book_id: resulte4ev1s[indexq].Book_id,
            Options: random_o,
            Question: random_q,
            answer: "",
            score: false,
          };

          tmpoptione4ev1s.push(final);
        });

        //console.log('this.tmpoption', tmpoption);
        setitems(tmpoptione4ev1s);
        setE4EV1tems(tmpoptione4ev1s);
        // console.log('this.itemlist', itemlist);

        setShowMessage(true);
        // console.log('this.questionArray', questionArray);

        //End E4ev1s

        //E6ev1s Start
        let tmpoptione6ev1s: any = [];
        let resulte6ev1s = json.Result.E6ev1s;
        console.log(result);

        resulte6ev1s.forEach((axq: any[], indexe6: number) => {
          //console.log('Option', result[indexq].Word1)
          let qq1 = [
            {
              Choice: resulte6ev1s[indexe6].Choice1,
              questionno: 1,
            },
            {
              Choice: resulte6ev1s[indexe6].WrongChoice1a,
              questionno: 2,
            },
            {
              Choice: resulte6ev1s[indexe6].WrongChoice1b,
              questionno: 3,
            },
            {
              Choice: "",
              questionno: 4,
            },
          ];

          let qq2 = [
            {
              Choice: resulte6ev1s[indexe6].Choice2,
              questionno: 1,
            },
            {
              Choice: resulte6ev1s[indexe6].WrongChoice2a,
              questionno: 2,
            },
            {
              Choice: resulte6ev1s[indexe6].WrongChoice2b,
              questionno: 3,
            },
            {
              Choice: "",
              questionno: 4,
            },
          ];

          let qq3 = [
            {
              Choice: resulte6ev1s[indexe6].Choice3,
              questionno: 1,
            },
            {
              Choice: resulte6ev1s[indexe6].WrongChoice3a,
              questionno: 2,
            },
            {
              Choice: resulte6ev1s[indexe6].WrongChoice3b,
              questionno: 3,
            },
            {
              Choice: "",
              questionno: 4,
            },
          ];

          let qq4 = [
            {
              Choice: resulte6ev1s[indexe6].Choice4,
              questionno: 1,
            },
            {
              Choice: resulte6ev1s[indexe6].WrongChoice4a,
              questionno: 2,
            },
            {
              Choice: resulte6ev1s[indexe6].WrongChoice4b,
              questionno: 3,
            },
            {
              Choice: "",
              questionno: 4,
            },
          ];

          let qq = [
            {
              Choice: resulte6ev1s[indexe6].Choice1,
              WrongChoicea: resulte6ev1s[indexe6].WrongChoice1a,
              WrongChoiceb: resulte6ev1s[indexe6].WrongChoice1b,
              questionno: 1,
            },
            {
              Choice: resulte6ev1s[indexe6].Choice2,
              WrongChoicea: resulte6ev1s[indexe6].WrongChoice2a,
              WrongChoiceb: resulte6ev1s[indexe6].WrongChoice2b,
              questionno: 2,
            },
            {
              Choice: resulte6ev1s[indexe6].Choice3,
              WrongChoicea: resulte6ev1s[indexe6].WrongChoice3a,
              WrongChoiceb: resulte6ev1s[indexe6].WrongChoice3b,
              questionno: 3,
            },
            {
              Choice: resulte6ev1s[indexe6].Choice4,
              WrongChoicea: resulte6ev1s[indexe6].WrongChoice4a,
              WrongChoiceb: resulte6ev1s[indexe6].WrongChoice4b,
              questionno: 4,
            },
          ];

          const myArray = [1, 2, 3, 4, 5];
          const randomIndex = Math.floor(Math.random() * myArray.length);
          const randomElement = myArray[randomIndex];
          console.log(randomElement); // Output: a random element from the array

          const randome6_o = qq
            .map((a) => ({ sort: Math.random(), value: a }))
            .sort((a, b) => a.sort - b.sort)
            .map((a) => a.value);

          // console.log(random, qq);

          let sq = [
            {
              ImageUrl: resulte6ev1s[indexe6].ImageUrl1,
              soundno: 1,
            },
            {
              ImageUrl: resulte6ev1s[indexe6].ImageUrl2,
              soundno: 2,
            },
            {
              ImageUrl: resulte6ev1s[indexe6].ImageUrl3,
              soundno: 3,
            },
            {
              ImageUrl: resulte6ev1s[indexe6].ImageUrl4,
              soundno: 4,
            },
          ];

          const randome6_q = sq
            .map((a) => ({ sort: Math.random(), value: a }))
            .sort((a, b) => a.sort - b.sort)
            .map((a) => a.value);

          let final = {
            Ev_Id: resulte6ev1s[indexe6].Ev_Id,
            Ev_guid: resulte6ev1s[indexe6].Ev_guid,
            TemplateCode: resulte6ev1s[indexe6].TemplateCode,
            Page_id: resulte6ev1s[indexe6].Page_id,
            Book_id: resulte6ev1s[indexe6].Book_id,
            Options: randome6_o,
            Question: randome6_q,
            answer: "",
            score: false,
          };

          tmpoptione6ev1s.push(final);
        });

        //console.log('this.tmpoption', tmpoption);
        setE6EV1tems(tmpoptione6ev1s);
        //E6ev1s End
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      // console.log(json)

      if (json.StatusCode == 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);

  const resetstate = () => {
    setSuccessOption(false);
    setWrongOption(false);
    setSelectedDiv(false);
    setRightAnsOption(false);
  };

  useEffect(() => {
    // console.log(quesdata);

    //console.log('String', quesdata.join('#'))

    // console.log('showResults', showResults)
    console.log("finalindex", length);
    console.log("lengthque", lengthque);
    //console.log('teatemediaBlob', mediaBlob)

    console.log("this.itemlist-Final", itemlist);

    if (length > lengthque) {
      console.log("Done");
      debugger;
      setE4EV1(false);
      setE5EV1(true);
      setLength(-1);
      //gobackLessoncompleted()
      //SaveOptionAnswer()
    }

    //debugger
  });

  const gobackpage = () => {
    history.push("/tabs/progress/Vocabularybank");

    // history.push({
    //   pathname: '/tabs/' + backpage,
    //   state: { themeId: themeId, lessonname: lessonname, index: index, lessonsId: lessonsId, themename: themename, levelId: levelId, level: level, levname: levname }
    // })
    history.go(0);
    // window.location.reload();
  };

  const gopracticecompleted = (newObject) => {
    //history.push('/tabs/home/themesports/5');

    console.log("sete4", sete4);
    console.log("sete5", sete5);
    console.log("sete6", sete6);
    console.log(myStateRef.current);
    console.log("myObject", myObject);

    if (te4 == "") {
      te4 = sete4.attendans;
    }
    if (te5 == "") {
      te5 = sete5.attendans;
    }
    if (te6 == "") {
      te6 = sete6.attendans;
    }
    console.log("te4", te4);
    console.log("te5", te5);
    console.log("te6", te6);

    if (te4 && te5 && te6) {
      if (
        te4 == itemE4EV1list.length &&
        te5 == itemE5EV1list.length &&
        te6 == itemE6EV1list.length
      ) {
        LessonComplete();
      }
    } else if (te4 && te5) {
      if (te4 == itemE4EV1list.length && te5 == itemE5EV1list.length) {
        LessonComplete();
      }
    } else if (te4 && te6) {
      if (te4 == itemE4EV1list.length && te6 == itemE6EV1list.length) {
        LessonComplete();
      }
    } else if (te5 && te6) {
      if (te5 == itemE5EV1list.length && te6 == itemE6EV1list.length) {
        LessonComplete();
      }
    } else if (te4) {
      if (te4 == itemE4EV1list.length) {
        LessonComplete();
      }
    } else if (te5) {
      if (te5 == itemE5EV1list.length) {
        LessonComplete();
      }
    } else if (te6) {
      if (te6 == itemE6EV1list.length) {
        LessonComplete();
      }
    }

    // setE6data({ total: itemE6EV1list.length, attendans: newObject.pagecouter })
    debugger;

    history.push({
      pathname: "/tabs/Vocabcompleted",
      state: { levelIdtype: levelIdtype },
    });
  };

  const LessonComplete = async () => {
    console.log("sete4", sete4);
    console.log("sete5", sete5);
    console.log("sete6", sete6);

    debugger;
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 1000,
    });
    console.log(lessonsId);
    const data = {
      bookGuid: lessonsId,
    };
    try {
      const response = await fetch(url + "/Book/LessonComplete", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      const json = await response.json();
      dismiss();

      if (json.StatusCode == 1) {
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  useIonViewDidLeave(() => {
    setApicall(0);

    setSentence1("");
    setSentence2("");
    setE4EV1(true);
    setE5EV1(false);
    setE6EV1(false);
  });

  return (
    <IonPage className="intro" placeholder={undefined}>
      <IonHeader className="ion-text-center dubbletitle" placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <div>
            <IonButtons slot="start" placeholder={undefined}>
              <IonButton color="primary" onClick={gobackpage} placeholder={undefined}>
                <IonIcon icon={chevronBack} placeholder={undefined} />
              </IonButton>
            </IonButtons>
            <IonTitle color="primary" className="font20 ion-no-padding" placeholder={undefined}>
              <span>
                {t("practicevocab.vocabulary")} {levelIdtype}: {t("practicevocab.practice")}
                <p className="ion-no-margin">{themename}</p>
              </span>
            </IonTitle>
            <IonButtons slot="end" placeholder={undefined}>
              {/* <IonButton onClick={openDrawer} color='primary'>
                <IonIcon icon={ellipsisVertical} />
              </IonButton> */}
            </IonButtons>
          </div>
        </IonToolbar>
      </IonHeader>

      <IonContent className="listing" placeholder={undefined}>
        {/* E4EV1 Listening */}
        {isitemE4EV1 && itemE4EV1list.length > 0 ? (
          <div>
            <E4ev1vocab
              itemE4EV1={itemE4EV1list}
              onObjectChange={handleMyObjectChange}
            />
          </div>
        ) : (
          ""
        )}
        {/* End E4EV1 Listening */}

        {/* Start E5EV1 Speaking */}
        {isitemE5EV1 && itemE5EV1list.length > 0 ? (
          <div className="audiorecord">
            <E5ev1vocab
              itemE5EV1={itemE5EV1list}
              onObjectChange={handleMyObjectChange}
            />
          </div>
        ) : (
          ""
        )}

        {/* End E5EV1 Speaking */}

        {/* Start E6EV1 Speaking */}

        {isitemE6EV1 && itemE6EV1list.length > 0 ? (
          <div className="audiorecord">
            <E6ev1vocab
              itemE6EV1={itemE6EV1list}
              onObjectChange={handleMyObjectChange}
            />
          </div>
        ) : (
          ""
        )}

        {/* End E6EV1 Matching */}
      </IonContent>

      <Drawer
        className="bottompopup skateInBottom"
        isVisible={isVisible}
        onClose={onClose}
      >
        <IonRow placeholder={undefined}>
          <IonList className="list" placeholder={undefined}>
            {/* <IonItem class="ion-no-padding playbook">
              <div>
                <IonImg src="./assets/images/play.png" />
              </div>
              <div className="details ion-padding-start">
                <h4>Play book</h4>
                <span>Autoplay book audio</span>
              </div>
            </IonItem> */}
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("practicevocab.drawer.lesson_language")}</IonLabel>
              <div className="right-space">
                <IonText color="primary" placeholder={undefined}>簡</IonText>
                <IonText placeholder={undefined}>繁</IonText>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("practicevocab.drawer.chinese_characters")}</IonLabel>
              <IonToggle checked slot="end" placeholder={undefined}></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("practicevocab.drawer.pinyin")}</IonLabel>
              <IonToggle checked slot="end" placeholder={undefined}></IonToggle>
            </IonItem>
            {/* <IonItem class="ion-no-padding">
              <IonLabel>Translation</IonLabel>
              <IonToggle slot="end"></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding">
              <IonLabel>Font size</IonLabel>
              <IonImg src="./assets/images/Fontdown.png" />
              <IonImg src="./assets/images/Fontup.png" />
            </IonItem> */}
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("practicevocab.drawer.playback_speed")}</IonLabel>
              <div className="leftright">
                <a href="#">&lt;</a> 1.0 <a href="#">&gt;</a>
              </div>
            </IonItem>
            {/* <IonItem class="ion-no-padding">
              <IonLabel>Bookmark</IonLabel>
              <IonIcon color='primary' icon={bookmark} />
            </IonItem> */}
          </IonList>
        </IonRow>
      </Drawer>
    </IonPage>
  );
};

export default React.memo(Practicevocab);
