import React, { useState } from 'react';
import { IonContent, IonPage, IonHeader, IonTitle, IonRow, IonCol, IonImg, IonList, IonToolbar, IonButton, useIonViewWillEnter, useIonLoading, useIonToast } from '@ionic/react';
import { setMenuEnabled } from '../data/sessions/sessions.actions';
import { setHasSeenTutorial } from '../data/user/user.actions';
import './Pretesttwo.scss';
import { RouteComponentProps } from 'react-router';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactAudioPlayer from 'react-audio-player';
import { CONFIG } from '../App';
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

function Player({ srcBlob }: { srcBlob: any }) {
  console.log(srcBlob);
  if (!srcBlob) {
    return null;
  } else {
    return <audio src={URL.createObjectURL(srcBlob)} controls />;
  }
}

interface OwnProps extends RouteComponentProps { };

interface DispatchProps {
  setHasSeenTutorial: typeof setHasSeenTutorial;
  setMenuEnabled: typeof setMenuEnabled;
}

interface Pretesttwo extends OwnProps, DispatchProps { };

const Pretesttwo: React.FC<Pretesttwo> = ({ history, setMenuEnabled }) => {
  let url: string = CONFIG.API_ENDPOINT;//live

  const { t } = useTranslation();

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any>({})

  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <div className='icon'><IonImg src="./assets/images/info.png" alt="" placeholder={undefined} /></div>
            <p>{t("pretesttwo.submit.cfrm_skp_tst")}</p>
            <div className='react-confirm-alert-button-group'>
              <IonButton onClick={onClose} className="alertcancel" placeholder={undefined}>{t("pretesttwo.submit.cancel")}</IonButton>
              <IonButton onClick={() => {
                continuebtn();
                onClose();
              } } className="alertsubmit" placeholder={undefined}>{t("pretesttwo.submit.continue")}</IonButton>
            </div>
          </div>
        );
      }
      /*confirmAlert({
        title: 'Confirm to submit',
        message: 'You will not get your suggested level if you skip the test, are you sure?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
            onClick: () => alert('Click No')
          }
        ]*/
    });
  };
  let token = ''
  let resfreshtoken = ''
  useIonViewWillEnter(() => {
    //setMenuEnabled(false);
    let currentUser = JSON.parse(localStorage.getItem('alltoken') || '{}');
    if (currentUser) {
      token = currentUser.JwtToken
      resfreshtoken = currentUser.RefreshToken
    }
    gettestproficiencyquestion()
  });

  const gettestproficiencyquestion = async () => {
    present({
      message: '',
      cssClass: 'custom-spinner',
      duration: 1000
    })

    try {
      const response = await fetch(url + '/account/gettestproficiencyquestion', {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token
        },
        method: "GET",
      })
      // if (!response.ok) {
      //   throw Error(response.statusText)
      // }
      const json = await response.json()
      console.log(json)

      dismiss()

      if (json.StatusCode == 1) {
        setitems(json.Result)

        // setitems(prevArray => [...json.Result, itemlist])
        // setVehicleData([...vehicleData, ...json.Result]);

      } else if (json.StatusCode == 2) {
        refreshtoken()
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: 'bottom'
        });
      }


    } catch (error) {
      console.error(error)
      dismiss()
    }

  }

  const refreshtoken = async () => {
    present({
      message: '',
      cssClass: 'custom-spinner',
      //duration: 3000
    })
    const data = { refreshToken: resfreshtoken }
    try {
      const response = await fetch(url + '/account/refreshtoken', {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      })
      if (!response.ok) {
        throw Error(response.statusText)
      }
      const json = await response.json()
      console.log(json)
      debugger
      if (json.StatusCode == 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken
        }
        localStorage.setItem('alltoken', JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: 'bottom'
        });
      }

      dismiss()

    } catch (error) {
      console.error(error)
      dismiss()
    }
  }

  const gototestque = async () => {
    // await setHasSeenTutorial(true);
    // await setMenuEnabled(true);
    history.push('/testquestion', { direction: 'none' });
  };
  const continuebtn = async () => {
    history.push('/tabs/home', { direction: 'none' });
  };
  //    history.push('/tabs/home', { direction: 'none' });
  const gotoskip = async () => {
    submit()
  };

  return (
    <IonPage className='pages-space' id="tutorial-page" placeholder={undefined}>
      <IonHeader className='ion-text-center' placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <IonTitle color='primary' placeholder={undefined}><span>{t("pretesttwo.prf_tst")}</span></IonTitle>
        </IonToolbar>
      </IonHeader>
      {/* <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="end">
            {showSkip && <IonButton color='primary' onClick={startApp}>Skip</IonButton>}
          </IonButtons>
        </IonToolbar>
      </IonHeader> */}
      <IonContent fullscreen placeholder={undefined}>
          <IonList className='height100' placeholder={undefined}>
            <IonRow className='testpage' placeholder={undefined}>
              <IonCol size="12" class='testspace content' placeholder={undefined}>
                <ul>
                  <li><p>{t("pretesttwo.tst_inf.inst1.1")} {itemlist.Count} {t("pretesttwo.tst_inf.inst1.2")}</p></li>
                  <li><p>{t("pretesttwo.tst_inf.inst2")}</p></li>
                  <li><p>{t("pretesttwo.tst_inf.inst3")}</p></li>
                </ul>
              </IonCol>
              <IonCol size="12" className='ion-text-left' placeholder={undefined}>
                <p className='ion-no-padding'>{t("pretesttwo.ado_inf.mk_sur")}</p>
              </IonCol>
              <IonCol size="12" className='test-audio ion-text-left' placeholder={undefined}>
                <b>{t("pretesttwo.ado_inf.tst_ado")}</b>
                <ReactAudioPlayer className='String' controls controlsList="nodownload noplaybackrate">
                  <source src="https://mmxjp.azurefd.net/data/book/L1LV1/sound/9294ed4a-49bb-4bd6-81ec-d11be4828a8a.mp3" type="audio/mpeg"></source>
                </ReactAudioPlayer>
                {/* <ReactAudioPlayer className='String' controls controlsList="nodownload noplaybackrate">
                  <source src={itemlist.FilePath} type="audio/mpeg"></source>
                </ReactAudioPlayer> */}
              
                {/* <audio controls preload="auto">
                  <source type="audio/mpeg" src="/assets/audio.mp3" />
                </audio> */}
                {/* <IonImg src="./assets/images/audio.png" /> */}
                {/* <audio controls src="/media/cc0-audio/t-rex-roar.mp3"></audio> */}
                {/* <div className='audio'></div> */}
              </IonCol>
            </IonRow>
            <IonRow class='skiptestpage' placeholder={undefined}>
              <IonCol size="12" className='ion-text-center' placeholder={undefined}>
                <IonButton onClick={gototestque} className="nextbtn" color="primary" placeholder={undefined}>{t("pretesttwo.start")}</IonButton>
                <div className='skip ion-text-center'><a onClick={gotoskip}>{t("pretesttwo.skp_tst")}</a></div>
              </IonCol>
            </IonRow>
          </IonList>
        {/* <IonButton  expand="block" fill="outline" shape="round">Go to Test question</IonButton> */}
      </IonContent>
    </IonPage>
  );
};
export default Pretesttwo
// export default connect<OwnProps, {}, DispatchProps>({
//   mapDispatchToProps: ({
//     setHasSeenTutorial,
//     setMenuEnabled
//   }),
//   component: Beforewestart
// });