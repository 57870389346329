import React from 'react';
import { IonHeader, IonToolbar, IonButtons, IonButton, IonContent, IonPage, IonTitle, IonIcon, IonList, IonCol, IonText, IonImg, IonCard, IonCardContent } from '@ionic/react';
import './Mysubscription.scss';
import { chevronBack, chevronForward } from 'ionicons/icons';
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface MysubscriptionProps { }

const Mysubscription: React.FC<MysubscriptionProps> = () => {

  const { t } = useTranslation();

  return (
    <IonPage className='intro' placeholder={undefined}>
      <IonHeader className='ion-text-center' placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <IonButtons slot="start" placeholder={undefined}>
            <IonButton href='/tabs/profile' color='primary' placeholder={undefined}>
              <IonIcon icon={chevronBack} placeholder={undefined} />
            </IonButton>
          </IonButtons>
          <IonTitle color='primary' className='font20 ion-no-padding' placeholder={undefined}><span>{t("mysubscription.my_sub")}</span></IonTitle>
          <IonButtons slot="end" placeholder={undefined}>
            {/* <IonButton color='success'>
              <IonIcon icon={ellipsisVertical} />
            </IonButton> */}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='listing' placeholder={undefined}>
        <IonList className="ion-padding-horizontal adjust Subscription" placeholder={undefined}>
          <IonCol placeholder={undefined}>
            <IonText placeholder={undefined}>{t("mysubscription.your_plan")}</IonText>
          </IonCol>
          <IonCard className='subscription' placeholder={undefined}>
            <IonCardContent class="ion-no-padding" placeholder={undefined}>
              <ul className='icon'>
                <li><IonImg src="./assets/images/mycalendar.png" alt="" placeholder={undefined} />{t("mysubscription.ends_on")}</li>
                <li><IonImg src="./assets/images/arrowIcon.png" alt="" placeholder={undefined} />{t("mysubscription.unli_ac")}</li>
                <li><IonImg src="./assets/images/arrowIcon.png" alt="" placeholder={undefined} />{t("mysubscription.tech_sp")}</li>
              </ul>
            </IonCardContent>
          </IonCard>

          <IonCol placeholder={undefined}>
            <IonText placeholder={undefined}>{t("mysubscription.bill_and_pymnt")}</IonText>
          </IonCol>
          <IonCard className='mysubscription' placeholder={undefined}>
            <IonCardContent class="ion-no-padding" placeholder={undefined}>
              <IonText placeholder={undefined}>{t("mysubscription.bill_detls")}</IonText>
              <IonIcon color='primary' className='ion-float-right' icon={chevronForward} placeholder={undefined} />
            </IonCardContent>
          </IonCard>

          <IonCol placeholder={undefined}>
            <IonText placeholder={undefined}>{t("mysubscription.mng_pln")}</IonText>
          </IonCol>
          <IonCard className='mysubscription' placeholder={undefined}>
            <IonCardContent class="ion-no-padding" placeholder={undefined}>
              <IonText placeholder={undefined}>{t("mysubscription.cncl_sub")}</IonText>
              <IonIcon color='primary' className='ion-float-right' icon={chevronForward} placeholder={undefined} />
            </IonCardContent>
          </IonCard>
        </IonList>
    </IonContent>

  </IonPage>
  );
};

export default React.memo(Mysubscription);