import React, { useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonText,
  IonCol,
  IonCard,
  IonRow,
  IonCardContent,
  IonImg,
  IonList,
  IonIcon,
  IonPage,
  IonTitle,
  useIonViewWillEnter,
  useIonLoading,
  useIonToast,
  IonSkeletonText,
} from "@ionic/react";
import "./Proficient.scss";
import { chevronBack, close } from "ionicons/icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { RouteComponentProps, useHistory } from "react-router";
import { CONFIG } from "../App";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface OwnProps extends RouteComponentProps { }

interface ProficientProps extends OwnProps { }

const Proficient: React.FC<ProficientProps> = ({ history }) => {
  let url: string = CONFIG.API_ENDPOINT; //live

  const { t } = useTranslation();

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemLessonslist, setLessonsitems] = React.useState<any[]>([]);
  const [itemThemeslist, setThemesitems] = React.useState<any[]>([]);
  const [showImage, setShowImage] = useState(false);
  const [itemlogin, setLogindata] = React.useState<any>({});
  const [imageLoaded, setImageLoaded] = useState(false);

  const historyss = useHistory<{
    themeArray: string;
    levelId: string;
    levname: string;
    level: string;
  }>();
  const levelId = historyss.location.state
    ? historyss.location.state.levelId
    : "";

  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";
  const level = historyss.location.state
    ? Number(historyss.location.state.level)
    : "";

  const themeArray = historyss.location.state
    ? historyss.location.state.themeArray
    : "";


  //console.log('themeId. ', levelId)

  let token = "";
  let resfreshtoken = "";
  useIonViewWillEnter(() => {
    //setMenuEnabled(false);
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    let logindata = JSON.parse(localStorage.getItem("logininfo") || "{}");
    //userdata = logindata.Result
    setLogindata(logindata.Result);
    const ThemesAndRecLesson: any = localStorage.getItem("ThemesAndRecLesson")
    const data = JSON.parse(ThemesAndRecLesson)
    debugger
    if (data === null) {
      getthemes();
    } else {
      setThemesitems(data.Result.Themes);
      setLessonsitems(data.Result.Lessons);
      setShowImage(true);
      getthemesLocalstorage()
    }

  });
  const getthemesLocalstorage = async () => {


    try {
      const response = await fetch(url + "/book/GetThemesAndRecLesson", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      // if (!response.ok) {
      //   throw Error(response.statusText)
      // }
      const json = await response.json();
      console.log(json);
      localStorage.setItem("ThemesAndRecLesson", JSON.stringify(json))
      setShowImage(true);
      if (json.StatusCode == 1) {
        setThemesitems(json.Result.Themes);
        setLessonsitems(json.Result.Lessons);
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const getthemes = async () => {
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   duration: 1000,
    // });

    try {
      const response = await fetch(url + "/book/GetThemesAndRecLesson", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      // if (!response.ok) {
      //   throw Error(response.statusText)
      // }
      const json = await response.json();
      console.log(json);
      localStorage.setItem("ThemesAndRecLesson", JSON.stringify(json))
      setShowImage(true);
      if (json.StatusCode == 1) {
        setLessonsitems(json.Result.Lessons);
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const refreshtoken = async () => {
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   //duration: 3000
    // });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      console.log(json);

      if (json.StatusCode == 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const gonextPagethemesports = (val) => {
    //history.push('/tabs/home/themesports/5');
    console.log("Theme_guid", val.Theme_guid);
    // history.push('/tabs/home/themesports/'+val.Theme_guid);
    if (val.IsBookExists == true) {
      history.push(
        {
          pathname: "/tabs/home/themesports",
          state: {
            themeId: val.Theme_guid,
            themename: val.DisplayName,
            levelId: levelId,
            level: level,
            levname: levname,
            backpage: "proficient",
          },
        },
        { direction: "none" }
      );
      // history.go(0)
      // window.location.reload();
    } else {
      presenttost({
        message: "Books not available for selected theme and your level.",
        duration: 1500,
        position: "bottom",
      });
    }
  };
  const gobackPagehome = () => {
    history.push({
      pathname: "/tabs/home/",
      state: { levelId: levelId, level: level, levname: levname, },
    });
    history.go(0)
    // window.location.reload();
  };

  const goLessonPage = (val, index) => {
    //history.push('/tabs/home/themesports/5');

    history.replace({
      pathname: "/tabs/home/lesson",
      state: {
        themeArray: itemLessonslist,
        themeId: val.ThemeGuid,
        lessonname: val.Title,
        index: index,
        lessonsId: val.BookGuid,
        bookId: val.BookId,
        themename: "",
        levelId: levelId,
        level: level,
        levname: levname,
        backpage: "proficient",
        backpageValue: 0,
      },
    });
  };

  const handleImageLoad = () => {
    console.log("Image Loaded");
    setImageLoaded(true);
  };

  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <div className="close">
              <IonIcon onClick={onClose} icon={close} placeholder={undefined} />
            </div>
            <div className="imgview">
              {level === 1 && <IonImg src="./assets/images/shoeprint.svg" placeholder={undefined} />}
              {level === 2 && <IonImg src="./assets/images/bicycle.svg" placeholder={undefined} />}
              {level === 3 && <IonImg src="./assets/images/car.svg" placeholder={undefined} />}
              {level === 4 && <IonImg src="./assets/images/plane.svg" placeholder={undefined} />}
              {level === 5 && <IonImg src="./assets/images/rocet.svg" placeholder={undefined} />}
            </div>
            <p>
              {t("proficient.submit.level")} {level}: {levname}
            </p>
            <p className="subtext popuppadding">
              {t("proficient.submit.subtext")}
            </p>
          </div>
        );
      },
    });
  };

  //shoeprint.svg
  //bicycle.svg
  //car.svg
  //plane.svg
  //rocet.svg

  return (
    <IonPage className="intro" placeholder={undefined}>
      <IonHeader className="ion-text-center" placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <IonButtons slot="start" placeholder={undefined}>
            <IonButton color="primary" onClick={gobackPagehome} placeholder={undefined}>
              <IonIcon icon={chevronBack} placeholder={undefined} />
            </IonButton>
          </IonButtons>
          <IonTitle color="primary" className="font20 ion-no-padding" placeholder={undefined}>
            <span>
              {t("proficient.level")} {level}: {levname}
            </span>
          </IonTitle>
          <IonButtons onClick={submit} slot="end" placeholder={undefined}>
            <IonButton color="warning" placeholder={undefined}>
              {/* <IonIcon icon={informationCircleOutline} /> */}
              <IonImg
                className="info"
                src="./assets/images/bang-circle-blue.svg"
                alt="" placeholder={undefined} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding-horizontal" placeholder={undefined}>



        {itemlogin.IsCorporate === true && itemLessonslist.length === 0 ? (
          <>
          </>
        ) : (
          <>
            <IonList className="themes" placeholder={undefined}>
              <IonCol placeholder={undefined}>
                <IonText placeholder={undefined}>{t("proficient.rcmnd_fr_u")}</IonText>
              </IonCol>
            </IonList>
            <IonList className="recommended-listing" placeholder={undefined}>
              {itemlogin.IsCorporate === true && itemLessonslist.length === 0 ? (
                <IonSkeletonText animated style={{ width: "100%", height: "138px", margin: "0px" }} placeholder={undefined} />
              ) : (
                <>
                  {itemLessonslist.map((items, index) => {
                    return (
                      <IonCard key={index} onClick={() => goLessonPage(items, index)} placeholder={undefined}>
                        {showImage ? (
                          <div className="imglist">
                            <img src={items.ImageUrl} alt="Silhouette of mountains" />
                          </div>
                        ) : (
                          <IonSkeletonText animated style={{ width: "100%", height: "138px", margin: "0px" }} placeholder={undefined} />
                        )}
                        <IonCardContent placeholder={undefined}>
                          <span>{items.Title_tc}</span>
                        </IonCardContent>
                      </IonCard>
                    );
                  })}
                </>
              )}
              {/* <IonCard>
                    <div className='imglist'><img src="./assets/images/girl.jpg" alt="Silhouette of mountains" /></div>
                    <IonCardContent>
                      Chinese New Year
                    </IonCardContent>
                  </IonCard> */}
            </IonList>
          </>
        )}

        {itemThemeslist.length == 0 ? (
          <>
            <IonList className="themes" placeholder={undefined}>
              <IonCol placeholder={undefined}>
                <IonText placeholder={undefined}>{t("proficient.themes")}</IonText>
              </IonCol>
            </IonList>
            <IonRow className="theme-listing" placeholder={undefined}>
              <ul>

                <li>
                  <IonCard placeholder={undefined}>
                    <IonCardContent class="ion-no-padding" placeholder={undefined}>
                      {/* <h4>{items.DisplayName}</h4> */}

                      <IonSkeletonText animated style={{ width: "100%", height: "250px", margin: "0px" }} placeholder={undefined} />
                    </IonCardContent>
                  </IonCard>
                </li>
                <li>
                  <IonCard placeholder={undefined}>
                    <IonCardContent class="ion-no-padding" placeholder={undefined}>
                      {/* <h4>{items.DisplayName}</h4> */}

                      <IonSkeletonText animated style={{ width: "100%", height: "150px", margin: "0px" }} placeholder={undefined} />
                    </IonCardContent>
                  </IonCard>
                </li>
                <li>
                  <IonCard placeholder={undefined}>
                    <IonCardContent class="ion-no-padding" placeholder={undefined}>
                      {/* <h4>{items.DisplayName}</h4> */}

                      <IonSkeletonText animated style={{ width: "100%", height: "150px", margin: "0px" }} placeholder={undefined} />
                    </IonCardContent>
                  </IonCard>
                </li>
                <li>
                  <IonCard placeholder={undefined}>
                    <IonCardContent class="ion-no-padding" placeholder={undefined}>
                      {/* <h4>{items.DisplayName}</h4> */}

                      <IonSkeletonText animated style={{ width: "100%", height: "150px", margin: "0px" }} placeholder={undefined} />
                    </IonCardContent>
                  </IonCard>
                </li>
                <li>
                  <IonCard placeholder={undefined}>
                    <IonCardContent class="ion-no-padding" placeholder={undefined}>
                      {/* <h4>{items.DisplayName}</h4> */}

                      <IonSkeletonText animated style={{ width: "100%", height: "250px", margin: "0px" }} placeholder={undefined} />
                    </IonCardContent>
                  </IonCard>
                </li>
                <li>
                  <IonCard placeholder={undefined}>
                    <IonCardContent class="ion-no-padding" placeholder={undefined}>
                      {/* <h4>{items.DisplayName}</h4> */}

                      <IonSkeletonText animated style={{ width: "100%", height: "150px", margin: "0px" }} placeholder={undefined} />
                    </IonCardContent>
                  </IonCard>
                </li>
              </ul>
            </IonRow>
          </>

        ) : (
          <>
            <IonList className="themes" placeholder={undefined}>
              <IonCol placeholder={undefined}>
                <IonText placeholder={undefined}>{t("proficient.themes")}</IonText>
              </IonCol>
            </IonList>

            <IonRow className="theme-listing" placeholder={undefined}>
              <ul>


                {itemThemeslist.map((items, index) => {
                  return (
                    <li key={index} onClick={() => gonextPagethemesports(items)}>
                      <IonCard placeholder={undefined}>
                        <IonCardContent class="ion-no-padding" placeholder={undefined}>
                          <h4>{items.DisplayName}</h4>
                          {items.ImageUrl ? (
                            <>
                              {!imageLoaded && (
                                <IonSkeletonText animated style={{ width: "100%", height: "138px", margin: "0px" }} placeholder={undefined} />
                              )}
                              <img
                                src={items.ImageUrl
                                  ? items.ImageUrl
                                  : "./assets/images/sport.png"}
                                onLoad={handleImageLoad}
                                style={{ display: imageLoaded ? 'block' : 'none' }}
                              />
                              {/* <img
                                onLoad={handleImageLoad}
                                src={items.ImageUrl
                                  ? items.ImageUrl
                                  : "./assets/images/sport.png"} placeholder={undefined} /> */}
                            </>
                          ) : (
                            <>
                              <IonSkeletonText animated style={{ width: "100%", height: "138px", margin: "0px" }} placeholder={undefined} />
                            </>
                          )}



                        </IonCardContent>
                      </IonCard>
                    </li>
                  );
                })}


                {/* // <li>
            //   <IonCard href='tabs/home/themesports'>
            //     <IonCardContent class="ion-no-padding">
            //       <h4>Sports</h4>
            //       <IonImg src="./assets/images/sport.jpg" />
            //     </IonCardContent>
            //   </IonCard>
            // </li>
            // <li>
            //   <IonCard>
            //     <IonCardContent class="ion-no-padding">
            //       <h4>Food</h4>
            //       <IonImg src="./assets/images/food.jpg" />
            //     </IonCardContent>
            //   </IonCard>
            // </li>
            // <li>
            //   <IonCard>
            //     <IonCardContent class="ion-no-padding">
            //       <h4>Workplace</h4>
            //       <IonImg src="./assets/images/workplace.png" />
            //     </IonCardContent>
            //   </IonCard>
            // </li> */}
              </ul>
            </IonRow>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Proficient);
