import React, { useEffect, useRef, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonItem,
  IonLabel,
  IonToggle,
  IonContent,
  IonList,
  IonCol,
  IonText,
  IonCard,
  IonCardContent,
  IonRow,
  IonIcon,
  IonPage,
  IonTitle,
  IonSlide,
  IonSlides,
  IonImg,
  useIonToast,
  useIonViewWillEnter,
  IonSkeletonText,
} from "@ionic/react";
import "./E3s.scss";
import {
  chevronBack,
  close,
  ellipsisVertical,
  options,
  videocam,
} from "ionicons/icons";
import Drawer from "react-bottom-drawer";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import HanziWriter, { CharacterJson } from "hanzi-writer";
import "./E3s.scss";
import { CONFIG } from "../App";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

// setTimeout(() => {
//   var writer = HanziWriter.create("character-target-1", "蛋", {
//     width: 150,
//     height: 150,
//     //showCharacter: false,
//    // showOutline: false,
//     showHintAfterMisses: 1,
//     highlightOnComplete: false,
//     padding: 5,
//   });
//   writer.quiz();
// }, 100);

interface MyObject {
  id: number;
  name: string;
  type: "e3s";
  pagecouter: number;
  totalque: number;
  back: number;
}

interface E3s1Props {
  itemE3s: any;
  onObjectChange: (object: MyObject) => void;
  lastSlide: any;
  cheChar: any;
  piniyin: any;
}

const E3s: React.FC<E3s1Props> = (props: E3s1Props) => {
  let url: string = CONFIG.API_ENDPOINT; //live

  const { t } = useTranslation();

  console.log(props.itemE3s);
  const [presenttost] = useIonToast();
  const [showImage, setShowImage] = useState(false);
  const [showAnimation, setShowAnimation] = useState(true);
  const [itemlist, setItemList] = useState<any>(props.itemE3s);
  const [showResults, setShowResults] = React.useState(1);
  const [index, setIndex] = useState<number>(0);
  const [isVisible, setIsVisible] = React.useState(false);
  const openDrawer = React.useCallback(() => setIsVisible(true), []);
  const [length, setLength] = React.useState(-1);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  const [ansIndex, setAnsSlideIndex] = useState<number>(0);
  const [scoreAns, setScoreAns] = useState<any>();
  const [answer, setAnswer] = useState<any>();
  const [JSONAnswer, setAnswerJSON] = useState<any>();
  const [JSONScore, setScoreJSON] = useState<any>();
  const [FinalScore, setFinalScore] = useState<any>(0);
  const ctdRef = React.useRef<any>();
  let scoreArray: any[] = [];
  let answerArray: any[] = [];
  let answerArrayJSON: any[] = [];
  let scoreArrayJSON: any[] = [];
  let lastValue: any;
  let finalanswer = {};
  let pagecouter: any = 0;
  let token = "";
  let resfreshtoken = "";

  useIonViewWillEnter(() => { });

  React.useEffect(() => {
    props.itemE3s.forEach(async (item, i) => {
      const containerId = `hanziWriterContainer_${i}`;
      // Remove existing hanziWriter
      const existingContainer = document.getElementById(containerId);
      if (existingContainer) {
        existingContainer.innerHTML = "";
      }
      // Create a new hanziWriter
      const newContainer = document.createElement("div");
      newContainer.id = containerId;
      document
        .getElementById(`hanziWriterContainer_${i}`)
        ?.appendChild(newContainer);
      // Load character data from the HanziWriter library
      const charData = (await HanziWriter.loadCharacterData(
        item.WordCh
      )) as CharacterJson;
      // Access the strokes array in charData
      const strokes = charData.strokes;
      // Log or use the strokes data as needed
      console.log("All Strokes:", strokes.length);

      HanziWriter.create(containerId, item.WordCh, {
        width: 235,
        height: 235,
        showCharacter: false,
        showOutline: false,
        showHintAfterMisses: 1,
        highlightOnComplete: true,
        padding: 5,
        drawingWidth: 50,
      }).quiz({
        onMistake: function (strokeData) {
          const answerArray = {
            index: i,
            strokeNumber: strokeData.strokeNum,
            mistakes: strokeData.mistakesOnStroke,
            totalMistakes: strokeData.totalMistakes,
            remaingStroke: strokeData.strokesRemaining,
          };
          console.log("Mistake", answerArray);
        },
        onCorrectStroke: function (strokeData) {
          const answerArray = {
            index: i,
            strokeNumber: strokeData.strokeNum,
            mistakes: strokeData.mistakesOnStroke,
            totalMistakes: strokeData.totalMistakes,
            remaingStroke: strokeData.strokesRemaining,
            totalStroke: strokes.length,
          };

          const finalAnswer = answerArray;
          const totalStrokes = strokes.length;
          handleScoreAndAnswer(finalAnswer, totalStrokes, i);
          handleSaveContent(finalAnswer, totalStrokes, i);
        },
        onComplete: function (summaryData) {
          const finalAnswerArray = {
            index: i,
            character: summaryData.character,
            totalMistakes: summaryData.totalMistakes,
          };
          console.log("Complete", finalAnswerArray);
        },
      });
    });

    const timeoutId = setTimeout(() => {
      setShowImage(true);
      const nextSlideIndex = activeSlideIndex + 1;
      setActiveSlideIndex(nextSlideIndex);
      // Call the slideTo method to swipe to the next slide
      // slidesRef.current?.slideTo(nextSlideIndex);
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [props.itemE3s]);

  const handleScoreAndAnswer = (
    finalAnswer: any,
    totalStrokes: any,
    index: number
  ) => {
    if (lastValue === index) {
      // If index remains the same, push data into arrays
      answerArray.push(finalAnswer);
      scoreArray.push(finalAnswer);
    } else {
      // If index changes, clear arrays and push new data
      answerArray = [finalAnswer];
      scoreArray = [finalAnswer];
    }

    const answerJSON = JSON.stringify(answerArray);
    setAnswer(answerJSON);

    const zeroMistakesCount = scoreArray.filter(
      (entry) => entry.mistakes === 0
    ).length;
    const eachStrokeValue = 100 / totalStrokes;
    const score = zeroMistakesCount * eachStrokeValue;
    const finalScore = parseFloat(score.toFixed(0));
    setScoreAns(finalScore);

    lastValue = index; // Update lastValue for the next iteration
  };

  const handleSaveContent = (
    finalAnswer: any,
    totalStrokes: any,
    index: number
  ) => {
    answerArrayJSON.push(finalAnswer);
    scoreArrayJSON.push(finalAnswer);

    let formattedAnswer = {};
    let currentIndex = -1;

    // Iterate through the answerArrayJSON
    answerArrayJSON.forEach((entry) => {
      // If index changes, create a new key in formattedAnswer
      if (currentIndex !== entry.index) {
        currentIndex = entry.index;
        formattedAnswer[`q${currentIndex + 1}`] = [];
      }

      // Push the entry into the corresponding array in formattedAnswer
      formattedAnswer[`q${currentIndex + 1}`].push(entry);
    });

    console.log(formattedAnswer);
    // Convert the formattedAnswer to a JSON string
    const answerJSON = JSON.stringify(formattedAnswer);
    setAnswerJSON(answerJSON);

    // Total Score
    const zeroMistakesCount = scoreArrayJSON.filter(
      (entry) => entry.mistakes === 0
    ).length;
    const eachStrokeValue = 100 / totalStrokes;
    const score = zeroMistakesCount * eachStrokeValue;
    const finalScore = parseFloat(score.toFixed(0));
    setScoreJSON(finalScore);
  };

  function handleChange(back) {
    const myObject: MyObject = {
      id: 1,
      name: "My Object",
      type: "e3s",
      back: back,
      pagecouter: pagecouter,
      totalque: props.itemE3s.length,
    };
    props.onObjectChange(myObject);
  }
  const handleSlideNextEnded = (event: CustomEvent<any>) => {
    console.log("Slide Next ended", event.detail);

    slidesRef.current?.getActiveIndex().then((index) => {
      console.log("index", index);
      setIndex(index);
      setLength(index + 1);
      setActiveSlideIndex(index);
      console.log("length", props.itemE3s.length);
    });
  };

  const handleSlidePrevEnded = (event: CustomEvent<any>) => {
    console.log("Slide Prev ended", event.detail);
    setLength(length - 1);
    slidesRef.current?.getActiveIndex().then((index) => {
      console.log("index", index);
      setIndex(index);
      setLength(index);
      console.log("length", props.itemE3s.length);
    });
  };

  const handleSlideDidChanged = async (event: CustomEvent<any>) => {
    const swiper = await slidesRef.current?.getSwiper();

    if (swiper) {
      const swipeDirection = swiper.swipeDirection;
      const currentIndex = swiper.activeIndex;
      console.log(currentIndex);
      console.log("Swipe direction:", swipeDirection);

      if (swipeDirection == "prev") {
        //Back Login code
        setAnsSlideIndex(currentIndex);
        slidesRef.current?.getActiveIndex().then((index) => {
          console.log(index - 1);
          if (index - 1 === -1) {
            handleChange(1);
          }
        });
      } else {
        setAnsSlideIndex(currentIndex);
        slidesRef.current?.getActiveIndex().then((index) => {
          console.log("index", index);
          console.log("length", length);
          setIndex(index);
          debugger;
          SaveExerciseQueAnswer(answer);
          if (props.itemE3s.length === 1) {
            console.log("Go next page");
            setLength(-1);
            saveContent(JSONScore, JSONAnswer);
            handleChange(0);
          } else {
            if (index + 1 == length) {
              console.log("Go next page");
              setLength(-1);
              saveContent(JSONScore, JSONAnswer);
              handleChange(0);
            }
          }
        });
      }
    }
  };
  let sc: any = []
  const SaveExerciseQueAnswer = async (answer) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    console.log(props.itemE3s);
    debugger;
    const data = {
      eId: props.itemE3s[ansIndex].EId,
      eType: props.itemE3s[ansIndex].TemplateCode,
      rearrageAnswer: "", //only for rearrange exercise otherwise blank
      answer: answer,
      writingScore: scoreAns,
    };
    let final = FinalScore + scoreAns

    localStorage.setItem("sc", final);


    setFinalScore(final)
    console.log("FinalScore", FinalScore);
    console.log("SaveExerciseQueAnswer", data);
    debugger

    try {
      const response = await fetch(url + "/Book/SaveExerciseQueAnswer", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });

      const json = await response.json();
      console.log(json);
      // debugger
      if (json.StatusCode == 1) {
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const saveContent = async (JSONScore, JSONAnswer) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }

    // present({
    //   message: '',
    // })
    pagecouter = 0;

    for (let index = 0; index < props.itemE3s.length; index++) {
      pagecouter++;
    }
    let finalsc = localStorage.getItem("sc")
    //console.log(FinalScore)
    debugger
    const data = {
      contentPageCode: props.itemE3s[0].TemplateCode,
      type: "exercise-score",
      bookId: props.itemE3s[0].BookId,
      contentPageId: props.itemE3s[0].PageId,
      answer: JSONAnswer,
      score: Number(finalsc),
      options: null,
      pageRead: pagecouter,
    };
    //dismiss()
    console.log(data);
    //debugger

    try {
      const response = await fetch(url + "/Book/SaveContent", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });

      const json = await response.json();
      console.log(json);
      localStorage.setItem("sc", "0")
      //dismiss()
      // debugger
      if (json.StatusCode == 1) {
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };

  useEffect(() => {
    const data = {
      JSONAnswer,
      JSONScore,
      scoreAns,
      answer,
    };

    console.log(data);
  }, [JSONAnswer, JSONScore, scoreAns, answer]);

  const hanziWriter = (i) => {
    const data_ch: string = props.itemE3s[i].WordCh
    const containerId = `hwContainer_${i}`;
    HanziWriter.create(containerId, data_ch, {
      width: 200,
      height: 200,
      padding: 5,
      showOutline: true
    }).loopCharacterAnimation()
    setShowAnimation(false)
    // .animateCharacter();
  }

  const submit = (i) => {
    const timer = setTimeout(() => {
      hanziWriter(i);
    }, 100); // Adjust the delay as needed
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body popupimg">
            <div className="close">
              <IonIcon onClick={onClose} icon={close} placeholder={undefined} />
            </div>
            <div className="ion-padding-top">
              {/* {showAnimation ? (
                <IonSkeletonText animated style={{ width: "100%", height: "250px", margin: "0px" }} placeholder={undefined} />
              ) : (<></>)} */}

              <div id={`hwContainer_${i}`} />
              {/* <img src={props.itemE3s[index].ImageUrl} alt="No Data Found API Error" /> */}
            </div>
          </div>
        );
      },
    });
    return () => clearTimeout(timer);
  };
  const gotoskip = async (index) => {
    submit(index);
  };
  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
    }, 3000); // Delay in milliseconds (e.g. 2000ms = 2 seconds)

    return () => clearTimeout(timer);
  }, []);

  return (
    <IonPage className="intro" placeholder={undefined}>
      <IonContent className="listing bigbox" placeholder={undefined}>
        <IonSlides
          options={{
            initialSlide: props.lastSlide === 1
              ? props.itemE3s.length - 1
              : activeSlideIndex,
            // on: {
            //   slideNextTransitionEnd: handleSlideNextEnded,
            //   slidePrevTransitionEnd: handleSlidePrevEnded,
            //   slideChange: handleSlideDidChanged,
            // },
          }}
          onIonSlideTransitionEnd={handleSlideDidChanged}
          onIonSlideNextEnd={handleSlideNextEnded}
          onIonSlidePrevEnd={handleSlidePrevEnded}
          ref={slidesRef} placeholder={undefined}>
          {props.itemE3s.map((item, i) => (
            <IonSlide key={i} placeholder={undefined}>
              <IonList className="ion-no-padding height100" placeholder={undefined}>
                <IonRow className="Practice leftrightspace ion-padding-horizontal" placeholder={undefined}>
                  <IonCol placeholder={undefined}>
                    <IonText className="ion-text-center" placeholder={undefined}>{t("e3s.stroke_order")}</IonText>
                  </IonCol>
                  <IonCol size="12" className="ion-text-center" placeholder={undefined}>
                    <ul className="lession-icon">
                      <li>
                        {/* <span>{itemse8.Word}</span>{itemse8.WordPy} */}
                        <span>{props.cheChar && (item.WordCh)}</span>
                        {props.piniyin && (item.WordPy)}
                      </li>
                    </ul>
                  </IonCol>
                </IonRow>

                {/* <IonRow className='bigbox'>
                              <IonCol size="12" className="ion-no-padding ion-text-center">
                                <IonImg src="./assets/images/cake.png"/>
                              </IonCol>
                            </IonRow> */}

                <IonRow className="answer gray ion-padding-horizontal practie swiper-no-swiping" placeholder={undefined}>
                  <IonCol size="12" placeholder={undefined}>
                    <IonButton
                      expand="full"
                      shape="round"
                      onClick={() => gotoskip(i)}
                      className="practie-btn" placeholder={undefined}>
                      <IonIcon icon={videocam} className="ion-padding-end" placeholder={undefined} />{" "}
                      Guide
                    </IonButton>
                  </IonCol>
                  <IonCol size="12" placeholder={undefined}>
                    <IonCard class="bg-write" placeholder={undefined}>
                      <div className="horizontal-line"></div>
                      <div className="vertical-line"></div>
                      <IonCardContent class="ion-no-padding" placeholder={undefined}>
                        {/* <div id="character-target-1"></div> */}
                        {/* <div className="hanzi-writer" id={`hanziWriterContainer_${i}`}></div> */}
                        <div id={`hanziWriterContainer_${i}`} />

                        {/* Use a unique ID for each hanziWriterContainer */}
                        {/* <div>
                                      <p>dàngāo</p>
                                      <IonText>蛋糕</IonText>
                                    </div> */}
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                  {/* <IonCol size="12">
                    {showImage && (
                      <IonImg
                        className="slide"
                        src="./assets/images/swipe-left-right.gif"
                      />
                    )}
                  </IonCol> */}
                </IonRow>
                <IonRow placeholder={undefined}>
                  <IonCol size="12" placeholder={undefined}>
                    {showImage && (
                      <IonImg
                        className="slide"
                        src="./assets/images/swipe-left-right.gif" placeholder={undefined} />
                    )}
                  </IonCol>
                </IonRow>
              </IonList>
            </IonSlide>
          ))}
        </IonSlides>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(E3s);
