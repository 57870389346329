import React from "react";
import {
  IonPage,
  IonContent,
  IonList,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonItem,
  IonLabel,
  IonButton,
  useIonViewWillEnter,
} from "@ionic/react";
import "./Starting.scss";
import { RouteComponentProps } from "react-router";
import Drawer from "react-bottom-drawer";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface OwnProps extends RouteComponentProps {}

interface StartingProps extends OwnProps {}

const Starting: React.FC<StartingProps> = ({ history }) => {
  const { t } = useTranslation();

  const [isVisible, setIsVisible] = React.useState(false);
  const openDrawer = React.useCallback(() => setIsVisible(true), []);
  useIonViewWillEnter(() => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");

    if (Object.keys(currentUser).length > 0) {
      console.log("login");
      history.push("/tabs/home", { direction: "none" });
    }
  });
  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);
  return (
    <IonPage>
      <IonContent fullscreen>
        <IonList className="full-height">
          <IonGrid className="center">
            <IonRow>
              <IonCol size="12" class="mainlogo">
                <IonImg
                  className="logo"
                  src="./assets/images/dummylogo.png"/>
                {/* <IonItem lines="none">
                  <IonLabel className="ion-text-center welcome">
                    <h1 className="no-margin">
                      <b>{t("starting.slogun")}</b>
                    </h1>
                  </IonLabel>
                </IonItem> */}
              </IonCol>
              <IonCol size="12" className="begin ion-text-center">
                <IonButton onClick={openDrawer} color="primary">
                  {t("starting.strt_btn_txt")}
                </IonButton>
                {/* <IonButton routerLink="/startinglogreg" color="primary">Begin</IonButton> */}
              </IonCol>
              <IonCol size="12" className="powered ion-text-center">
                {/* <p>{t("starting.powered_by")}</p> */}
                <IonImg src="./assets/images/logo_Blue Horizontal.png" />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonList>
      </IonContent>
      <Drawer
        className="journey bottompopup skateInBottom bottompopupresult"
        isVisible={isVisible}
        onClose={onClose}
      >
        <IonRow className="joinpage">
          <IonGrid className="bg-box">
            <div className="middle">
              <IonRow>
                <IonCol size="12">
                  <h4 className="ion-text-center">
                    {t("starting.join_us_title")}
                    <br />
                    {t("starting.learning_title")}
                  </h4>
                </IonCol>
                {/* <IonCol size="12" className="ion-text-center">
                  <IonButton
                    routerLink="/signup"
                    color="white"
                    className="whitebtn"
                    expand="full">
                    {t("starting.drawer.reg_btn")}
                  </IonButton>
                </IonCol> */}
                <IonCol size="12" className="ion-text-center">
                  <IonButton
                    routerLink="/login"
                    className="blueborder"
                    color="primary"
                    expand="full">
                    {t("starting.drawer.sign_in_btn")}
                  </IonButton>
                </IonCol>
              </IonRow>
            </div>
          </IonGrid>
        </IonRow>
      </Drawer>
    </IonPage>
  );
};

export default React.memo(Starting);
