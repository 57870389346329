import React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import './CustomAudioPlayer.scss'; // Import the CSS file


const CustomAudioPlayer = ({ src }) => {
  return (
    <div className="custom-audio-player">
      <ReactAudioPlayer src={src} autoPlay={false} controls={true} />
    </div>
  );
};

export default CustomAudioPlayer;