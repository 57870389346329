import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonItem,
  IonLabel,
  IonToggle,
  IonContent,
  IonText,
  IonList,
  IonCol,
  IonRow,
  IonIcon,
  IonPage,
  IonTitle,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
  IonSlide,
  IonSlides,
  IonImg,
} from "@ionic/react";
import "./Dialoguevideo.scss";
import {
  chevronBack,
  ellipsisVertical,
  playBack,
  playForward,
} from "ionicons/icons";
import Drawer from "react-bottom-drawer";
import ReactAudioPlayer from "react-audio-player";
import { CONFIG } from "../App";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
interface OwnProps extends RouteComponentProps {
  id: string;
}
interface DialoguevideoProps extends OwnProps {}
const Dialoguevideo: React.FC<DialoguevideoProps> = ({ history }) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const openDrawer = React.useCallback(() => setIsVisible(true), []);
  let url: string = CONFIG.API_ENDPOINT; //live
  const { t } = useTranslation();
  // const params = useParams<{id: string}>();
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any[]>([]);
  // setting start
  const [fontSize, setFontSize] = useState(18);
  const [clickedUp, setClickedUp] = useState(false);
  const [clickedDown, setClickedDown] = useState(false);
  const [cheChar, setCheChar] = useState<any>();
  const [piniyin, setPiniyin] = useState<any>();
  const [isTransChecked, setIsTransChecked] = useState<any>();
  const [bookmarkindex, setBookmarkindex] = React.useState<any>(-1);
  const [playbackSpeed, setPlaybackSpeed] = useState(1.0);
  // setting end
  const [showImage, setShowImage] = useState(false);
  const historyss = useHistory<{
    themeArray:any;
    dialogueId: string;
    themeId: string;
    themename: string;
    levelId: string;
    level: string;
    levname: string;
    backpage: string;
    title: string;
    videoUrl: string;
    role: string;
    rolename: string;
    allrole: string;
    index: string;
  }>();
  let themeArray = historyss.location.state
  ? historyss.location.state.themeArray
  : "";
  console.log(themeArray);  
  const index2 = historyss.location.state ? historyss.location.state.index : "";
  console.log(index2);  
  const dialogueId = historyss.location.state
    ? historyss.location.state.dialogueId
    : "";
  const themeIddb = historyss.location.state
    ? historyss.location.state.themeId
    : "";
  const themename = historyss.location.state
    ? historyss.location.state.themename
    : "";
  // debugger;
  const levelId = historyss.location.state
    ? historyss.location.state.levelId
    : "";
  const level = historyss.location.state ? historyss.location.state.level : "";
  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";
  const backpage = historyss.location.state
    ? historyss.location.state.backpage
    : "";
  const videoUrl = historyss.location.state
    ? historyss.location.state.videoUrl
    : "";
  const title = historyss.location.state ? historyss.location.state.title : "";
  const rolename = historyss.location.state
    ? historyss.location.state.rolename
    : "";
  debugger;
  const role = historyss.location.state ? historyss.location.state.role : "";
  const allrole = historyss.location.state
    ? historyss.location.state.allrole
    : "";
  let string = role || allrole;
  let one = "";
  let two = "";
  if (string) {
    let result = string.split(",");
    one = result[0];
    two = result[1];
  }
  debugger;
  const [index, setIndex] = useState(0);
  const [displayedItems, setDisplayedItems] = useState<any[]>([]);
  const ionListRef = useRef<any>(null);
  const videoRef = useRef<any>(null);
  const [showDialogues, setShowDialogues] = useState(false);
  const [clickId, setClickId] = useState<any>();
  const [isAutoplaying, setIsAutoplaying] = useState(false);
  const [isVideoAutoplaying, setVideoIsAutoplaying] = useState(true);
  const index3 = index + 1
  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  let token = "";
  let resfreshtoken = "";
  let rap;
  let allThemes: any[] = []
  const [getThemes, setThemes] = useState<any>()
  let nextindex: any = 0;
  useIonViewWillEnter(() => {
    // console.log("useIonViewWillEnter");
    console.log(role);
    console.log(rolename);
    console.log(allrole);
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    let pagecode1 = "Practice";
    localStorage.setItem("pagecode1", JSON.stringify(pagecode1));
    let pagecode2 = "Read again";
    localStorage.setItem("pagecode2", JSON.stringify(pagecode2));
    GetSettings()
    // getdialogue()
  });

  const getthemes = async () => {
    // present({
    //   message: "",
    //   duration: 1000,
    // });
    console.log("APIthemeId. ", themeIddb);
    try {
      const response = await fetch(
        url + "/Book/GetLessons?themeGuid=" + themeIddb,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        }
      );
      const json = await response.json();
      // console.log(json);
      if (json.StatusCode == 1) {
        debugger
        for (let index = 0; index < json.Result.Lessons.length; index++) {
          const element = json.Result.Lessons[index];
        }
        let test: any[] = [];
        const array1 = json.Result.Lessons;
        const array2 = json.Result.Dialogues;
        // const combinedArray = array1.concat(array2);
        const maxLength = Math.max(array1.length, array2.length);
        for (let i = 0; i < maxLength; i++) {
          // Your logic here
          if (array1[i]) {
            array1[i]["type"] = "lesson";
            array1[i]["number"] = i + 1;
            test.push(array1[i]);
          }
          if (array2[i]) {
            array2[i]["type"] = "dialogues";
            array2[i]["number"] = i + 1;
            test.push(array2[i]);
          }
        }
        localStorage.setItem("itemlist", JSON.stringify(test));
        console.log("test array", test);
        allThemes = test
        setThemes(test)
        // console.log(themeArray);        
        // console.log("Get Themes",getThemes);


      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      // dismiss();
    } catch (error) {
      console.error(error);
      // dismiss();
    }
  };

  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      //  console.log(json)
      if (json.StatusCode === 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const scrollToBottom = () => {
    if (ionListRef.current) {
      // ionListRef.current.lastChild.scrollIntoView({behavior: 'smooth'});
      ionListRef.current.scrollIntoView({behavior: 'smooth'})
      const scrollHeight = ionListRef.current.scrollHeight;
      const clientHeight = ionListRef.current.clientHeight;
      ionListRef.current.scrollTop = scrollHeight - clientHeight;
    }
  };
  
  useEffect(() => {
    const scrollTimeout = setTimeout(() => {
       scrollToBottom();
    }, 1);
    return () => {
      clearTimeout(scrollTimeout);
    };
  }, [displayedItems]);

  useEffect(() => {
    // Add touchstart event listener to video element
    const videoElement = videoRef.current;
    const handleTouchStart = () => {
      // Toggle video play/pause state
      if (videoElement.paused) {
        videoElement.play();
        if (showDialogues) {
          allaudiostop();
        }
      } else {
        videoElement.pause();
      }
    };
    // Attach touchstart event listener to video element
    videoElement.addEventListener("touchstart", handleTouchStart);
    // Clean up event listener when the component is unmounted
    return () => {
      videoElement.removeEventListener("touchstart", handleTouchStart);
    };
  }, []);
  const toggleVideoPlayPause = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play(); // Play the video
      if (showDialogues) {
        allaudiostop();
      }
    } else {
      video.pause(); // Pause the video
    }
  };
  const onPlayVideo = () => {
    if (showDialogues) {
      allaudiostop();
    }
  };
  const allaudiostop = () => {
    displayedItems.forEach((item) => {
      // debugger;
      // Call your function here with the item as an argument
      const itemId = item.Id;
      audiostopbyid(itemId);
    });
  };
  const audiostopbyid = (itemId) => {
    // Call your function here with the item as an argument
    const audioElement = document.getElementById(
      `${itemId}`
    ) as HTMLAudioElement;
    audioElement.pause();
  };
  const handleSkipVideo = () => {
    setShowDialogues(true);
  };
  const handleVideoEnd = () => {
    setShowDialogues(true);
  };

  const handleAudioEnd = (index) => {
    return () => {
      // scrollToBottom();
      if (index3 !== itemlist.length) {
        // scrollToBottom();
        debugger;
        setTimeout(() => {
          // handleClickNext();
          // setActivePlayer(index);
          // console.log(index);
          // You can perform actions related to this audio here.
          const prevItems = itemlist[index + 1];
          const lastIndex = displayedItems.length - 1;
          // console.log(lastIndex);
          if (index === lastIndex) {
            setDisplayedItems([...displayedItems, prevItems]); // Add item.Id to displayedItems array
            setIndex((prevIndex) => prevIndex + 1);
          }
        }, 3000); // Adjust the delay as needed
      } 
      if (index3 === itemlist.length) {
        scrollToBottom();
        setShowImage(true)
      }
    };
  };

  const audioClick = (id) => {
    return () => {
      scrollToBottom();
      const video = videoRef.current;
      video.pause(); // Pause video
      // console.log(id);
      displayedItems.forEach((item) => {
        if (id !== item.Id) {
          // debugger;
          // Call your function here with the item as an argument
          const audioElement = document.getElementById(
            `${item.Id}`
          ) as HTMLAudioElement;
          audioElement.pause();
        }
      });
      if (index3 === itemlist.length) {
        scrollToBottom();
        setShowImage(true)
      }
    };
  };

  const handleClickNext = async () => {

    //debugger
    const swiper = await slidesRef.current?.getSwiper();
    if (swiper) {
      const swipeDirection = swiper.swipeDirection;
      const currentIndex = swiper.activeIndex;
      console.log('Swipe direction:', swipeDirection);
      console.log('Current index:', currentIndex);

      if (swipeDirection == "prev") {
        console.log("Wait");        
      } else {
        // if (index < itemlist.length) {
        //   // setDisplayedItems([...displayedItems, itemlist[index]]);
        //   setDisplayedItems((prevItems) => [...prevItems, itemlist[index]]);
        //   setIndex((prevIndex) => prevIndex + 1);
        //   scrollToBottom();
        // }
        const Index = index + 1;
        // console.log(Index);
        // console.log(itemlist.length);
        if (Index === itemlist.length) {
          // console.log(index);
          debugger;
          history.replace({
            pathname: "/tabs/home/Dialoguedone",
            state: {
              themeArray:themeArray,
              index: index2,
              themeId: themeIddb,
              themename: themename,
              levelId: levelId,
              level: level,
              levname: levname,
              dialogueId: dialogueId,
              videoUrl: videoUrl,
              rolename: rolename,
              role: role,
              allrole: one + "," + two,
            },
          });
        }
        else {
          console.log("Wait");
        }
      }
    }

  };
  // const handleClickPrev = () => {
  //   if (index > 1) {
  //     setDisplayedItems((prevItems) => {
  //       const updatedItems = [...prevItems];
  //       updatedItems.splice(-1, 1); // Remove the last item from the array
  //       return updatedItems;
  //     });
  //     setIndex((prevIndex) => prevIndex - 1);
  //   }
  // };
  const textset_tc = (data_tc, data_py) => {
    //console.log(data_tc)
    //debugger
    const html_tc = data_tc;
    const html_py = data_py;
    const range = document.createRange();
    const fragment_tc = range.createContextualFragment(html_tc);
    const fragment_py = range.createContextualFragment(html_py);
    const textreturn_tc = fragment_tc.textContent;
    const textreturn_py = fragment_py.textContent;
    const text_tc = textreturn_tc;
    const text_py = textreturn_py;
    const fragmentsa: any = [];
    if (text_tc && text_py) {
      const outputArray = text_tc.split(/(?![，。;：””''！？（）、])/g);
      const words_tc = outputArray;
      const words_py = text_py.split(" ");
      if (words_tc.length === words_py.length) {
        for (let i = 0; i < words_tc.length; i++) {
          const wordtc = words_tc[i] as never;
          let data = {
            words_tc: wordtc,
            words_py: "",
          };
          fragmentsa.push(data);
        }
        for (let i = 0; i < words_py.length; i++) {
          const wordpy = words_py[i] as never;
          if (wordpy !== "" && wordpy !== " " && wordpy !== undefined) {
            fragmentsa[i]["words_py"] = wordpy ? wordpy : "";
          } else {
            fragmentsa[i]["words_py"] = "";
          }
        }
        const fragments = fragmentsa.map((word, index) => (
          <React.Fragment key={index}>
            <li>
              {cheChar ? (
                <span className="ion-text-center" id="top-center">
                  {word["words_tc"]}
                </span>
              ) : (
                ""
              )}
              {piniyin ? <p className="wordpy">{word["words_py"]}</p> : ""}
            </li>{" "}
          </React.Fragment>
        ));
        //const fragments = ''
        return fragments;
      } else {
        const arr1 = words_tc;
        const arr2 = words_py;
        const maxLength = Math.max(arr1.length, arr2.length);
        const paddedArr1 = arr1.concat(Array(maxLength - arr1.length).fill(""));
        const paddedArr2 = arr2.concat(Array(maxLength - arr2.length).fill(""));
        for (let i = 0; i < paddedArr1.length; i++) {
          const wordtc = paddedArr1[i] as never;
          let data = {
            words_tc: wordtc,
            words_py: "",
          };
          fragmentsa.push(data);
        }
        for (let i = 0; i < paddedArr2.length; i++) {
          const wordpy = paddedArr2[i] as never;
          if (wordpy !== "" && wordpy !== " " && wordpy !== undefined) {
            fragmentsa[i]["words_py"] = wordpy ? wordpy : "";
          } else {
            fragmentsa[i]["words_py"] = "";
          }
        }
        const fragments = fragmentsa.map((word, index) => (
          <React.Fragment key={index}>
            <li>
              <span className="ion-text-center" id="top-center">
                {word["words_tc"]}
              </span>
              <p className="wordpy">{word["words_py"]}</p>
            </li>{" "}
          </React.Fragment>
        ));
        //const fragments = ''
        return fragments;
      }
    } else if (text_tc) {
      const words_tc = text_tc.split("");
      for (let i = 0; i < words_tc.length; i++) {
        const word = words_tc[i] as never;
        let data = {
          words_tc: word,
          words_py: "",
        };
        fragmentsa.push(data);
      }
      const fragments = fragmentsa.map((word, index) => (
        <React.Fragment key={index}>
          <li>
            <span className="ion-text-center" id="top-center">
              {word["words_tc"]}
            </span>
          </li>{" "}
        </React.Fragment>
      ));
      return fragments;
    } else if (text_py) {
      const words_py = text_py.split(" ");
    }
    // console.log('spans', fragments)
    //const htmls = { __html: spans.join('') };
  };
  const back = () => {
    history.replace({
      pathname: "/tabs/home/Themesports",
      state: {
        themeArray:themeArray,
        index: index2,
        themeId: themeIddb,
        themename: themename,
        levelId: levelId,
        level: level,
        levname: levname,
      },
    });
  };
  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);
  useEffect(() => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 1000,
    });
    // console.log("dialogueId. ", dialogueId);
    const fetchData = async () => {
      try {
        const response = await fetch(
          url + "/Book/GetDialogueQueAns?dialogueId=" + dialogueId,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            method: "GET",
          }
        );
        const json = await response.json();
        console.log(json);
        dismiss();
        if (json.StatusCode === 1) {
          console.log("json.Result ", json.Result);
          setitems(json.Result);
          // setDisplayedItems(json.Result);
          setDisplayedItems([json.Result[0]]);
          // handleAudioEnd(0)
          // handleClickNext();
        } else if (json.StatusCode === 2) {
          // refreshtoken()
        } else {
          presenttost({
            message: json.Message,
            duration: 1500,
            position: "bottom",
          });
        }
      } catch (error) {
        console.error(error);
        dismiss();
      }
    };
    fetchData();
  }, []);
  
  const savebookmark = (val, index) => {
    // Toggle the bookmark status of the current lesson
    themeArray[index].IsFavourite = !themeArray[index].IsFavourite;

    // Create a copy of the themeArray with the updated bookmark status
    const updatedThemeArray = [...themeArray];
    updatedThemeArray[index] = { ...themeArray[index] }; // Create a new object to trigger a state update

    if (themeArray[index].IsFavourite === 0) {
      // If the lesson is no longer a favorite, update the state
      setBookmarkindex(-1);
      saveFav(val, updatedThemeArray[index]?.IsFavourite);
    } else {
      // If the lesson is now a favorite, update the state
      setBookmarkindex(index);
      saveFav(val, updatedThemeArray[index]?.IsFavourite);
    }

    // Log the updated themeArray
    console.log("Updated themeArray:", updatedThemeArray);
    themeArray = updatedThemeArray
  };

  const saveFav = async (val, isFavourite: boolean) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    debugger;
    const data = {
      referenceId: themeArray[index].BookId,
      isFavourite: isFavourite,
      typeId: 1,
    };
    //dismiss()
    console.log(data);
    try {
      const response = await fetch(url + "/User/Favourite", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      const json = await response.json();
      console.log(json);
      //dismiss()
      if (json.StatusCode == 1) {
        getthemes();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };

  const decreasePlaybackSpeed = () => {
    if (playbackSpeed > 1) {
      changePlaybackSpeed(-1);
    }
  };

  const increasePlaybackSpeed = () => {
    if (playbackSpeed < 15) {
      changePlaybackSpeed(1);
    }
  };

  const changePlaybackSpeed = (speed: number) => {
    if (rap) {
      const audioElement = rap.audioEl.current;
      if (audioElement) {
        audioElement.playbackRate += speed;
        // Ensure playback speed is within bounds (1 to 15)
        if (audioElement.playbackRate < 1) {
          audioElement.playbackRate = 1;
        } else if (audioElement.playbackRate > 15) {
          audioElement.playbackRate = 15;
        }
        setPlaybackSpeed(audioElement.playbackRate);
      }
    }
  }
  //settings start

  const SaveUserSettings = async (char: any, piniyin: any, trans: any) => {
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   duration: 1000,
    // });
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    const settings = {
      isPinyin: piniyin,
      isTranslation: trans,
      isChineseCharacter: char,
    };
    try {
      const response = await fetch(url + "/User/SaveSetting", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
        body: JSON.stringify(settings),
      });
      const json = await response.json();
      console.log("SaveSetting Response", json);
      // dismiss();
      if (json.StatusCode === 1) {
        // Settings saved successfully
        // Handle the response as needed
      } else if (json.StatusCode === 2) {
        // Handle token refresh logic
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      // dismiss();
    }
  };

  const GetSettings = async () => {
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   duration: 1000,
    // });
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    try {
      const response = await fetch(url + `/User/GetSetting`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
          // Include other headers if needed for User/GetSetting
        },
      });
      const json = await response.json();
      console.log("Settings", json);
      // dismiss();
      if (json.StatusCode === 1) {
        console.log(json.Result);
        setCheChar(json.Result.IsChineseCharacter)
        setPiniyin(json.Result.IsPinyin)
        setIsTransChecked(json.Result.IsTranslation)   
      } else if (json.StatusCode === 2) {
      } else {
        // Handle other StatusCode values
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      // dismiss();
    }
  };

  const toggleChe = () => {
    setCheChar((prevCheChar) => {
      const newCheChar = !prevCheChar;
      console.log(newCheChar); // Log the new state (true or false)
      let newpiniyin:any
      if(newCheChar === false){
        setPiniyin(true)
        newpiniyin = true
      } else {
        newpiniyin = null
      }
      SaveUserSettings(newCheChar, newpiniyin !== null ? newpiniyin : piniyin, isTransChecked); // Save the new state if needed
      return newCheChar;
    });
  };

  const togglepiniyin = () => {
    setPiniyin((prevPiniyin) => {
      const newPiniyin = !prevPiniyin;
      console.log(newPiniyin); // Log the new state (true or false)
      let newchechar:any
      if (newPiniyin === false) {
        setCheChar(true)
        newchechar = true
      } else {
        newchechar = null
      }
      SaveUserSettings( newchechar !== null ? newchechar : cheChar, newPiniyin, isTransChecked); // Save the new state if needed
      return newPiniyin;
    });
  };

  const translationkModeToggle = () => {
    setIsTransChecked((prevIsTransChecked) => {
      const newIsTransChecked = !prevIsTransChecked;
      console.log(newIsTransChecked); // Log the new state (true or false)
      SaveUserSettings(cheChar, piniyin, newIsTransChecked); // Save the new state if needed
      return newIsTransChecked;
    });
  };

  //settings end
  return (
    <IonPage className="intro" placeholder={undefined}>
      <IonHeader className="ion-text-center dubbletitle" placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <div>
            <IonButtons slot="start" placeholder={undefined}>
              <IonButton color="primary" onClick={back} placeholder={undefined}>
                <IonIcon icon={chevronBack} placeholder={undefined} />
              </IonButton>
            </IonButtons>
            <IonTitle color="primary" className="font20 ion-no-padding" placeholder={undefined}>
              <span>
                {t("dialoguevideo.dialogue")}
                <p className="ion-no-margin">{themeArray[index2]?.ContentEn}</p>
              </span>
            </IonTitle>
            <IonButtons slot="end" placeholder={undefined}>
              <IonButton onClick={openDrawer} color="primary" placeholder={undefined}>
                <IonIcon icon={ellipsisVertical} placeholder={undefined} />
              </IonButton>
            </IonButtons>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className="listing" placeholder={undefined}>
        {/* <div className={`video-container ${isFullScreen ? 'fullscreen' : ''}`}> */}
        {/* <div onClick={toggleVideoPlayPause} className="ClickClass" /> */}
        {/* <span ref={ionListRef}> */}
        <video
          webkit-playsinline
          playsInline
          autoPlay={isVideoAutoplaying}
          onPlay={onPlayVideo}
          ref={videoRef}
          controls
          onEnded={handleVideoEnd}
          className="video-player"
          src={videoUrl}
          controlsList="nodownload"
        />
        {/* {videoUrl} */}
        <div className="video-button">
          {!showDialogues && (
            <IonButton className="w-100 skip-button" onClick={handleSkipVideo} placeholder={undefined}>
              {t("dialoguevideo.skip_video")}
            </IonButton>
          )}
        </div>
        {showDialogues && (
          <>
          <IonSlides pager={false} onIonSlideTransitionEnd={handleClickNext} ref={slidesRef}>
            {itemlist && itemlist.length > 0 && (
              <IonSlide>
                <IonList
                  // ref={ionListRef}
                  className="leftrightspace ion-padding-horizontal dialogue" placeholder={undefined}>
                  <IonRow placeholder={undefined}>
                    {itemlist && itemlist.length > 0 ? (
                      <>
                        {displayedItems.map((items, index) => {
                          return (
                            <IonCol
                              className={items?.Type === two ? "play" : ""}
                              size="12"
                              key={index} placeholder={undefined}>
                              <div className="user">
                                {items?.Type === one ? (
                                  <div className="usercircle">{items?.Type}</div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="dialoguebox">
                                <ul className="lession-icon">
                                  {/* <li><span>你</span>Nǐ</li>
                                      <li><span>们</span>mén</li>
                                      <li><span>做</span>zuò</li>
                                      <li><span>做</span>dàn</li>
                                      <li><span>蛋</span>gāo</li> */}
                                  {textset_tc(items?.ContentTc, items?.ContentPy)}
                                </ul>
                                <p>{items?.ContentEn}</p>
                                <div className="test-audio ion-text-left player">
                                  <ReactAudioPlayer
                                    id={`${items?.Id}`}
                                    autoPlay
                                    onPlay={audioClick(items?.Id)}
                                    className="String"
                                    controls
                                    controlsList="nodownload noplaybackrate"
                                    onEnded={handleAudioEnd(index)} // Call handleClickNext when audio is paused
                                    src={items?.FileUrl}
                                  />
                                </div>
                              </div>
                              <div className="user">
                                {items?.Type === two ? (
                                  <div className="usercircle">{items?.Type}</div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </IonCol>
                          );
                        })}
                      </>
                    ) : (
                      <p></p>
                    )}
                    {/* <IonCol size='12' className='play'>
                    <div className='user'>
                      <div className='usercircle'></div>
                    </div>
                    <div className='dialoguebox'>
                      <ul className='lession-icon'>
                        <li><span>你</span>Nǐ</li>
                        <li><span>们</span>mén</li>
                        <li><span>做</span>zuò</li>
                        <li><span>做</span>dàn</li>
                        <li><span>蛋</span>gāo</li>
                      </ul>
                      <p>Hello, Chen laoshi!</p>
                      <div className='test-audio ion-text-left player'>
                        <ReactAudioPlayer className='String' controls controlsList="nodownload noplaybackrate">
                          <source src="/assets/audio.mp3" type="audio/mpeg"></source>
                        </ReactAudioPlayer>
                      </div>
                    </div>
                    <div className='user'>
                    </div>
                  </IonCol> */}
                  </IonRow>
                </IonList>
                {showImage && (
                  <IonImg className="slide" src="./assets/images/swipe-left-right.gif" placeholder={undefined}/>
                )}
              </IonSlide>
            )}
          </IonSlides>
          </>
        )}
        {/* </div> */}
        <div ref={ionListRef} />
        {/* </span> */}
      </IonContent>

      <Drawer
        className="bottompopup skateInBottom"
        isVisible={isVisible}
        onClose={onClose}
      >
        <IonRow placeholder={undefined}>
          <IonList className="list" placeholder={undefined}>
            {/* <IonItem class="ion-no-padding playbook">
            <div>
              <IonImg src="./assets/images/play.png" />
            </div>
            <div className="details ion-padding-start">
              <h4>Play book</h4>
              <span>Autoplay book audio</span>
            </div>
          </IonItem> */}
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("dialoguevideo.drawer.lesson_language")}</IonLabel>
              <div className="right-space">
                <IonText color="primary" placeholder={undefined}>簡</IonText>
                <IonText placeholder={undefined}>繁</IonText>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>
                {t("dialoguevideo.drawer.chinese_characters")}
              </IonLabel>
              <IonToggle
                checked={cheChar}
                onClick={toggleChe}
                slot="end" placeholder={undefined}></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("dialoguevideo.drawer.pinyin")}</IonLabel>
              <IonToggle
                checked={piniyin}
                onClick={togglepiniyin}
                slot="end" placeholder={undefined}></IonToggle>
            </IonItem>
            {/* <IonItem class="ion-no-padding">
            <IonLabel>Translation</IonLabel>
            <IonToggle slot="end"></IonToggle>
          </IonItem>
          <IonItem class="ion-no-padding">
            <IonLabel>Font size</IonLabel>
            <IonImg src="./assets/images/Fontdown.png" />
            <IonImg src="./assets/images/Fontup.png" />
          </IonItem> */}
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>{t("dialoguevideo.drawer.playback_speed")}</IonLabel>
              <div className="leftright">
                <a href="#">&lt;</a> 1.0 <a href="#">&gt;</a>
              </div>
            </IonItem>
            {/* <IonItem class="ion-no-padding">
            <IonLabel>Bookmark</IonLabel>
            <IonIcon color='primary' icon={bookmark} />
          </IonItem> */}
          </IonList>
        </IonRow>
      </Drawer>
    </IonPage>
  );
};
export default React.memo(Dialoguevideo);
